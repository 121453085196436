import React from "react";
import { fullProductImageUrl, setTopContainerWrapperSettings } from "../Util";
import { cartSummary } from "../cart/cartUtil";
import InStoreCustomerForm from "../store/InStoreCustomerForm";
import "../../generic.css";
import "../../layaway.css";

class LayawayContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      summary: cartSummary(this.props.taxRate, 0),
    };
  }
  componentDidMount() {
    const summary = this.state.summary;
    if (
      summary.storeId <= 0 ||
      summary.grandTotal <= 0 ||
      !summary.isStoreLayaway ||
      summary.depositPercent < 10.0
    ) {
      this.props.history.push("/");
      return;
    }
    this.adjustWidth();
    window.addEventListener("resize", this.adjustWidth);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustWidth);
  }
  adjustWidth() {
    setTopContainerWrapperSettings();
  }
  render() {
    if (!this.state.summary) return <div>Please wait ...</div>;

    const days = this.state.summary.depositPercent >= 20.0 ? 90 : 30;
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div className="flex-wrapper">
            <div>
              <img
                src={fullProductImageUrl("layaway-deposit.jpg")}
                height="35"
              ></img>
            </div>
            <div className="left-10">
              <font size="5">Layaway</font>
            </div>
          </div>

          <p />
          <div align="left" className="layaway-msg-wrapper">
            The customer has made ${this.state.summary.depositAmount.toFixed(2)}{" "}
            ({this.state.summary.depositPercent.toFixed(2)}%) layaway deposit,
            which allows the customer to have upto {days} days to make next
            payment. Please enter customer information below and then click the
            "Continue" button.
          </div>
          <p />
          <InStoreCustomerForm
            addressType="LayawayContact"
            title="Customer Information"
            nextUrl="/layawaySummary"
          />
        </div>
      </div>
    );
  }
}

export default LayawayContact;
