import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { apiBaseUrl } from "../Util";
import { AXIOS_HEADER } from "../config/constants";
import { setLoggedIn, setUserId, setUserInfo } from "../actions/userActions";
import "../../App.css";
import "../../form.css";
import "../../user.css";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      errorMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.processChangeResponse = this.processChangeResponse.bind(this);
  }
  componentDidMount() {
    if (this.props.userId && this.props.userId.indexOf("@") > 0) {
      this.setState({ email: this.props.userId });
    }
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  processChangeResponse(response) {
    if (response.status) {
      this.props.setLoggedIn(response.pwdToken);
      this.props.setUserId(this.state.email);
      this.props.setUserInfo(response.contactAddr);
      this.setState({ errorMessage: null });

      if (this.props.returnUrl) {
        this.props.history.push(this.props.returnUrl);
      } else {
        this.props.history.push("/myAccount");
      }
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  changePassword(event) {
    event.preventDefault();

    const errorMessage = this.validateData();
    if (errorMessage) {
      this.setState({ errorMessage });
      return;
    }

    const url = apiBaseUrl() + "ChangePassword";
    const req = {
      userId: this.props.userId,
      email: this.state.email,
      password: this.state.password,
      newPassword: this.state.newPassword,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processChangeResponse(res.data);
      })
      .catch((error) => {
        this.setState({ errorMessage: "Unable to change/reset password" });
      });
  }

  validateData() {
    let errorMessage = null;
    if (!this.state.email) errorMessage = "Email is required";
    else if (!this.state.password || this.state.password.length < 8)
      errorMessage = "Password is required and at least 8 characters";
    else if (!this.state.newPassword || this.state.newPassword.length < 8)
      errorMessage = "Confirm Password is required and at least 8 characters";
    else if (
      !this.state.confirmPassword ||
      this.state.confirmPassword != this.state.newPassword
    ) {
      errorMessage = "The two new passwords don't match";
    }
    return errorMessage;
  }
  render() {
    const isReset = this.props.match.params.reason == "reset";
    const title = isReset ? "Reset Password" : "Change Password";
    const title2 = isReset ? "Temp Password" : "Current Password";
    let msg;

    if (isReset) {
      msg =
        "We have emailed you a temporary password. Please enter it belong along with your desired new password, then click the Reset Password button";
    } else {
      msg =
        "Please enter your current password and new password below, then click the Change Password button";
    }
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          {msg}
          <p />
          <ValidatorForm ref="form" onSubmit={this.changePassword}>
            <div className="twocol-tbl">
              <div className="form-item-value">Email: </div>
              <div className="form-item-value">
                <TextInput
                  onChange={this.handleChange}
                  type="email"
                  name="email"
                  size="30"
                  value={this.state.email}
                  validators={["required", "isEmail"]}
                  errorMessages={["Email is required", "Invalid email address"]}
                />
              </div>
              <div className="form-item-value">{title2}: </div>
              <div className="form-item-value">
                <TextInput
                  onChange={this.handleChange}
                  type="password"
                  name="password"
                  size="15"
                  value={this.state.password}
                  validators={["required"]}
                  errorMessages={["Password is required"]}
                />
              </div>
              <div className="form-item-value">New Password: </div>
              <div className="form-item-value">
                <TextInput
                  onChange={this.handleChange}
                  type="password"
                  name="newPassword"
                  size="15"
                  value={this.state.newPassword}
                  validators={["required"]}
                  errorMessages={["New Password is required"]}
                />
              </div>
              <div className="form-item-value">Confirm: </div>
              <div className="form-item-value">
                <TextInput
                  onChange={this.handleChange}
                  type="password"
                  name="confirmPassword"
                  size="15"
                  value={this.state.confirmPassword}
                  validators={["required"]}
                  errorMessages={["Confirm  is required"]}
                />
              </div>
            </div>
            <div className="top-10">
              <button name="reset" type="submit" className="btn-style">
                {title}
              </button>
            </div>
          </ValidatorForm>
          <p />
          <font color="red">{this.state.errorMessage}</font>
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userId: state.user.userId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (pwdToken) => {
      dispatch(setLoggedIn(pwdToken));
    },
    setUserId: (payload) => {
      dispatch(setUserId(payload));
    },
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangePassword));
