import {
  SP_ADD_TO_CART,
  SP_REMOVE_ITEM,
  SP_SUB_QUANTITY,
  SP_ADD_QUANTITY,
  SP_CLEAR_CART,
} from "./actionTypes";

//add cart action
export const spAddToCart = (payload) => {
  return {
    type: SP_ADD_TO_CART,
    payload: payload,
  };
};

//remove item action
export const spRemoveItem = (sku) => {
  return {
    type: SP_REMOVE_ITEM,
    sku,
  };
};
//subtract qt action
export const spSubtractQuantity = (sku) => {
  return {
    type: SP_SUB_QUANTITY,
    sku,
  };
};
//add qt action
export const spAddQuantity = (sku) => {
  return {
    type: SP_ADD_QUANTITY,
    sku,
  };
};

export const spClearCart = () => {
  return {
    type: SP_CLEAR_CART,
  };
};
