import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { setUserInfo } from "../actions/userActions";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, setTopContainerWrapperSettings, toAmount } from "../Util";
import log from "loglevel";
import "../myLogger";
import "../../contact.css";
import "../../info.css";

class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      contactInfo: null,
      layaways: null,
      orders: null,
      editMode: false,
      isLoading: true,
      errorMessage: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.changeToEditMode = this.changeToEditMode.bind(this);
    this.updateContactInfo = this.updateContactInfo.bind(this);
  }
  componentDidMount() {
    if (!this.props.isLoggedIn || !this.props.pwdToken) {
      this.props.history.push("/login");
      return;
    }

    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
    this.fetchUserData();
  }
  processResponse(response) {
    if (response.status) {
      this.setState({
        ...response,
        isLoading: false,
      });
    } else {
      this.setState({ errorMessage: response.errorMessage, isLoading: false });
    }
  }
  fetchUserData() {
    const url = apiBaseUrl() + "GetUserInfo";
    const req = {
      userId: this.props.userId,
      email: this.props.userId,
      password: this.props.pwdToken,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        log.error(error);
      });
  }
  updateContactInfo(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UpdateContactInfo";
    const req = {
      userId: this.props.userId,
      email: this.props.userId,
      password: this.props.pwdToken,
      address: this.state.contactInfo,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        log.error(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
    this.setState({ errMessage: "" });
  }
  adjustSettings() {
    setTopContainerWrapperSettings();
  }
  handleChange(event) {
    const contactInfo = {
      ...this.state.contactInfo,
      [event.target.name]: event.target.value,
    };
    this.setState({ contactInfo });
  }
  changeToEditMode() {
    this.setState({ editMode: true });
  }
  showContactInfo() {
    if (!this.state.isLoading) {
      let html = "";
      if (this.state.contactInfo) {
        const info = this.state.contactInfo;
        html = info.firstName + " " + info.lastName + "<br/>";
        html += info.addressLine1 + "<br/>";
        if (info.addressLine2) {
          html += info.addressLine2 + "<br/>";
        }
        html += info.city + ", " + info.state + " " + info.zipCode + "<br/>";
        if (info.phone) {
          html += "Phone: " + info.phone + "<br/>";
        }
        if (info.email) {
          html += "Email: " + info.email + "<br/>";
        }
      } else {
        html = this.state.firstName + " " + this.state.lastName + "<br/>";
        if (this.state.phone) {
          html += "Phone: " + this.state.phone + "<br/>";
        }
        if (this.state.email) {
          html += "Email: " + this.email + "<br/>";
        }
      }

      return (
        <React.Fragment>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
          <div>
            <a href="#" onClick={this.changeToEditMode}>
              Edit
            </a>
          </div>
        </React.Fragment>
      );
    }
  }
  showLayaways() {
    if (this.state.layaways && this.state.layaways.length > 0) {
      return (
        <div>
          <p />
          <hr className="hr-300" align="left" />
          <div>
            <font size="4">Layaways</font>
          </div>
          <br />
          {this.state.layaways.map((layaway, idx) => {
            const url = "/layawayDetail/" + layaway.orderNumber;
            return (
              <div className="nodec-div" key={idx}>
                <Link to={url}>{layaway.orderNumber}</Link>&nbsp;&nbsp;
                {layaway.dateOrderedStr}&nbsp;&nbsp;
                {toAmount(layaway.totalPrice)}
              </div>
            );
          })}
        </div>
      );
    }
  }
  showOrders() {
    if (this.state.orders && this.state.orders.length > 0) {
      return (
        <div>
          <p />
          <hr className="hr-300" align="left" />
          <div>
            <font size="4">Orders</font>
          </div>
          <br />
          {this.state.orders.map((order, idx) => {
            const url = "/orderDetail/" + order.orderNumber;
            return (
              <div className="nodec-div">
                <Link to={url}>{order.orderNumber}</Link>&nbsp;&nbsp;
                {order.dateOrderedStr}&nbsp;&nbsp;
                {toAmount(order.totalPrice)}
              </div>
            );
          })}
        </div>
      );
    }
  }
  contactForm() {
    const info = this.state.contactInfo
      ? this.state.contactInfo
      : { addressLine1: "" };
    const txtLen = 30;
    return (
      <div>
        <div className="cttbl-container">
          <div className="cttbl-item-left">
            First Name<font color="red">*</font>:
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="firstName"
              size="20"
              value={info.firstName}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">
            Last Name<font color="red">*</font>:
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="lastName"
              size="20"
              value={info.lastName}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">Phone:</div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="phone"
              size="15"
              value={info.phone}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">Email:</div>
          <div className="cttbl-item-left">{info.email}</div>
          <div className="cttbl-item-left">
            Address<font color="red">*</font>:
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="addressLine1"
              size="30"
              value={info.addressLine1}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">Address2:</div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="addressLine2"
              size={txtLen}
              placeholder="(Apartment number, etc.)"
              value={info.addressLine2}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">
            City<font color="red">*</font>:{" "}
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="city"
              value={info.city}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">
            State<font color="red">*</font>:{" "}
          </div>
          <div className="cttbl-item-left">Georgia</div>
          <div className="cttbl-item-left">
            ZIP code<font color="red">*</font>:{" "}
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="zipCode"
              size="10"
              value={info.zipCode}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="left-10">
          <button
            className="btn-style"
            name="update"
            onClick={this.updateContactInfo}
          >
            Update
          </button>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div>
            <font size="5">My Account</font>
          </div>
          <div className="contact-title">
            <b>
              <font size="4">{this.props.title}</font>
            </b>
          </div>
          {this.state.editMode ? this.contactForm() : this.showContactInfo()}
          {this.showLayaways()}
          {this.showOrders()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    isLoggedIn: state.user.isLoggedIn,
    pwdToken: state.user.pwdToken,
    userInfo: state.user.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyAccount));
