import React from "react";
import {
  FormatCurrency,
  fullProductImageUrl,
  setTopContainerWrapperSettings,
  isMobileMode,
  soldOutImageClickable,
  getGroupWrapperWidth,
} from "../Util";

import "../../App.css";
import "../../product.css";
import "../../vd.css";

const MODE_DROPDOWN = 1;
const MODE_THUMBNAIL = 2;
const MODE_NAME_IN_BOX = 3;
const MODE_NAME_WITH_PRICE = 4;
const MODE_NAMECOLOR_IN_BOX = 5;
const MODE_NAMECOLOR_WITH_PRICE = 6;

class VariantDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      skuIdx: 0,
      product: this.props.product,
      vdMode: this.props.vdMode
        ? this.props.vdMode
        : this.props.product.variantDisplay,
      mobileMode: false,
      thumbnailWidth: 85,
    };
    this.selectVariant = this.selectVariant.bind(this);
    this.adjustScreenSize = this.adjustScreenSize.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateImageWidth = this.updateImageWidth.bind(this);
    this.initState = this.initState.bind(this);
  }
  componentDidMount() {
    this.initState();
    this.adjustScreenSize();
    window.addEventListener("resize", this.adjustScreenSize);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.product !== prevProps.product ||
      this.props.vdMode !== prevProps.vdMode ||
      this.props.sku !== prevProps.sku
    ) {
      this.initState();
    }
  }
  initState() {
    let skuIdx = 0;

    if (this.props.sku && this.props.product && this.props.product.pvList) {
      for (let i = 0; i < this.props.product.pvList.length; i++) {
        const pv = this.props.product.pvList[i];
        if (pv.sku === this.props.sku) {
          skuIdx = i;
          break;
        }
      }
    }
    this.setState({
      skuIdx,
      product: this.props.product,
      vdMode: this.props.vdMode
        ? this.props.vdMode
        : this.props.product.variantDisplay,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustScreenSize);
  }

  adjustScreenSize() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
    this.updateImageWidth();
  }
  selectVariant(idx) {
    this.setState({ skuIdx: idx });
    if (this.props.callbackFunc) {
      this.props.callbackFunc(idx);
    }
  }
  updateImageWidth() {
    const prod = this.state.product;
    let imageWidth;
    let thumbnailWidth;
    let cntPerRow = 5;

    if (prod.pvList) {
      if (prod.pvList.length < cntPerRow) cntPerRow = prod.pvList.length;
    }

    if (isMobileMode()) {
      imageWidth = getGroupWrapperWidth();
      thumbnailWidth = (imageWidth - 3 * (cntPerRow - 1)) / cntPerRow;
    } else {
      imageWidth = (getGroupWrapperWidth() * 60) / 100;
      thumbnailWidth =
        (getGroupWrapperWidth() * 0.4 - 3 * (cntPerRow - 1)) / cntPerRow;
    }
    if (thumbnailWidth > 85) thumbnailWidth = 85;

    this.setState({ thumbnailWidth });
  }
  handleChange(event) {
    const idx = parseInt(event.target.value);
    this.selectVariant(idx);
  }
  showThumbnails(prod) {
    const ratio = prod.thumbnailHWRatio > 0 ? prod.thumbnailHWRatio : 0.75;
    const height = this.state.thumbnailWidth * ratio;
    return (
      <div className="prod-thumbnail-wrapper">
        {prod.pvList.map((pv, idx) => {
          let imageUrl = pv.thumbnailImage;

          if (!imageUrl) {
            imageUrl = pv.imageUrl ? pv.imageUrl : prod.imageUrl;
          }
          if (pv.forSale) {
            let clsName = idx === 0 ? "brightness0" : "brightness3";
            if (idx === this.state.skuIdx) clsName += "b";
            return (
              <div className={clsName} key={idx}>
                <img
                  src={fullProductImageUrl(imageUrl)}
                  width={this.state.thumbnailWidth}
                  height={height}
                  onClick={() => this.selectVariant(idx)}
                ></img>
              </div>
            );
          } else {
            let clsName = idx === 0 ? "left0" : "left3";
            if (idx === this.state.skuIdx) clsName += "b";
            if (soldOutImageClickable()) {
              return (
                <div className={clsName}>
                  <img
                    id="soldOutImage"
                    src={fullProductImageUrl(imageUrl)}
                    width={this.state.thumbnailWidth}
                    height={height}
                    onClick={() => this.selectVariant(idx)}
                  ></img>
                </div>
              );
            } else {
              return (
                <div className={clsName}>
                  <img
                    id="soldOutImage"
                    src={fullProductImageUrl(imageUrl)}
                    width={this.state.thumbnailWidth}
                    height={height}
                  ></img>
                </div>
              );
            }
          }
        })}
      </div>
    );
  }
  showDropdown(prod) {
    return (
      <div>
        <b>{prod.variantName}: </b>&nbsp;&nbsp;
        <select
          onChange={this.handleChange}
          onSelect={this.handleChange}
          value={this.state.skuIdx}
        >
          {prod.pvList.map((pv, idx) => {
            const price = prod.pvList[idx].finalPrice;
            let vv = pv.variantValue;
            if (vv.length > 35 && this.state.mobileMode)
              vv = vv.substring(0, 35);
            return (
              <option key={pv.sku} value={idx} key={idx}>
                {vv} ${price}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
  showNameInBox(prod, withPrice) {
    let isLarge = false;
    if (this.state.mobileMode) {
      if (prod.pvList.length > 3) {
        let totalLen = 0;
        for (let i = 0; i < 4; i++) {
          const pv = prod.pvList[i];
          const name = pv.shortName ? pv.shortName : pv.variantValue;
          totalLen += name.length;
        }
        if (totalLen > 55) isLarge = true;
      }
    }
    const clsName = isLarge ? "large-nbox-wrapper" : "prod-thumbnail-wrapper";
    return (
      <div>
        <div className="bottom5">
          <b>{prod.variantName}</b>
        </div>
        <div className={clsName}>
          {prod.pvList.map((pv, idx) => {
            const val = pv.shortName ? pv.shortName : pv.variantValue;
            const pColor = pv.forSale ? "red" : "gray";
            let clsName = "varname-box";

            if (!pv.forSale) clsName += "-nfs";
            if (idx === this.state.skuIdx) clsName += "-selected";
            return (
              <div
                className={clsName}
                key={idx}
                onClick={() => this.selectVariant(idx)}
              >
                {val}
                {withPrice && (
                  <React.Fragment>
                    <br />
                    <font color={pColor}>
                      <FormatCurrency amount={pv.finalPrice} />
                    </font>
                  </React.Fragment>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  showNameColorInBox(prod, withPrice) {
    return (
      <div>
        <b>{prod.variantName}</b>
        <br />
        <div className="prod-thumbnail-wrapper">
          {prod.pvList.map((pv, idx) => {
            const color = pv.color;
            const val = pv.shortName ? pv.shortName : pv.variantValue;
            const pColor = pv.forSale ? "red" : "gray";
            let clsName = "varnamef-box";

            if (!pv.forSale) clsName += "-nfs";
            if (idx === this.state.skuIdx) clsName += "-selected";

            return (
              <div
                className={clsName}
                key={idx}
                onClick={() => this.selectVariant(idx)}
              >
                {color && (
                  <div
                    style={{
                      backgroundColor: color,
                      border: "1px solid black",
                      height: "15px",
                      width: "15px",
                      marginRight: "5px",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </div>
                )}
                <div>
                  {val}
                  {withPrice && (
                    <React.Fragment>
                      <br />
                      <font color={pColor}>
                        <FormatCurrency amount={pv.finalPrice} />
                      </font>
                    </React.Fragment>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  render() {
    if (this.state.product.pvList && this.state.product.pvList.length > 1) {
      const prod = this.state.product;
      switch (this.state.vdMode) {
        case MODE_THUMBNAIL:
          return this.showThumbnails(prod);
        case MODE_NAME_IN_BOX:
          return this.showNameInBox(prod, false);
        case MODE_NAME_WITH_PRICE:
          return this.showNameInBox(prod, true);
        case MODE_NAMECOLOR_IN_BOX:
          return this.showNameColorInBox(prod, false);
        case MODE_NAMECOLOR_WITH_PRICE:
          return this.showNameColorInBox(prod, true);
        default:
          return this.showDropdown(prod);
      }
    }
    return "";
  }
}

export default VariantDisplay;
