import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import Deposit from "./Deposit";
import {
  apiBaseUrl,
  generateGuid,
  fullProductImageUrl,
  FormatCurrency,
} from "../Util";
import { setUserId } from "../actions/userActions";
import log from "loglevel";
import "../myLogger";
import "../../generic.css";
import { cartSummary } from "../cart/cartUtil";
import { spAddToCart } from "../actions/spCartActions";
import { isInStore, isClerk, getStoreId } from "./storeUtil";

class StoreItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      tariffRate: 0.1,
      name: null,
      price: null,
      finalPrice: 0,
      couponName: null,
      couponAmount: null,
      isLoading: true,
      addBtnEnabled: false,
      addCouponBtnEnabled: false,
      errorMessage: null,
    };
    this.fetchItems = this.fetchItems.bind(this);
    this.adjustSettings = this.adjustSettings.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addTypedInProd = this.addTypedInProd.bind(this);
    this.addCoupon = this.addCoupon.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.hasDeposit = this.hasDeposit.bind(this);
  }

  componentDidMount() {
    if (!isInStore()) {
      this.props.history.push("/");
    }
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
    this.fetchItems();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }
  adjustSettings() {}

  fetchItems() {
    const url = apiBaseUrl() + "GetStoreProducts";
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    const req = {
      userId: userId,
      id: getStoreId(),
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({
          items: res.data.storeItems,
          tariffRate: res.data.tariffRate,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  addToCart(item) {
    this.props.spAddToCart(item);
    this.props.history.push("/cart");
  }
  addTypedInProd(event) {
    event.preventDefault();
    const price = parseFloat(this.state.price);
    const item = {
      id: 0,
      typeId: 0,
      name: this.state.name,
      sku: "TYPED_" + this.state.name,
      price,
      finalPrice: price,
      quantity: 1,
    };
    this.props.spAddToCart(item);
    this.setState({ name: null, price: null });
    this.props.history.push("/cart");
  }
  addCoupon() {
    let amount = parseFloat(this.state.couponAmount);

    if (amount > 0) amount = 0 - amount;
    const item = {
      id: 0,
      typeId: 0,
      name: this.state.couponName,
      sku: "COUPON_" + this.state.couponName,
      price: amount,
      finalPrice: amount,
      quantity: 1,
      coupon: true,
    };
    this.props.spAddToCart(item);
    this.setState({ couponName: null, couponAmount: null });
    this.props.history.push("/cart");
  }
  handleChange(event) {
    const name = event.target.name;
    const val = event.target.value;

    if ((name === "price" || name === "couponAmount") && val) {
      if (isNaN(parseFloat(val))) {
        return;
      }
    }
    let finalPrice = this.state.finalPrice;
    if (name === "price") {
      finalPrice = 0;
      if (val && val.trim().length > 0) {
        try {
          finalPrice = parseFloat(val);
        } catch (error) {}
      }
    }
    this.setState({ [name]: val, finalPrice });

    let addBtnEnabled = this.state.addBtnEnabled;
    let addCouponBtnEnabled = this.state.addCouponBtnEnabled;
    if (name === "name" || name === "price") {
      addBtnEnabled = false;
      if (
        val &&
        ((name === "name" && this.state.price) ||
          (name === "price" && this.state.name))
      ) {
        addBtnEnabled = true;
      }
    } else {
      addCouponBtnEnabled = false;
      if (
        val &&
        ((name === "couponName" && this.state.couponAmount) ||
          (name === "couponAmount" && this.state.couponName))
      ) {
        addCouponBtnEnabled = true;
      }
    }
    this.setState({ addBtnEnabled, addCouponBtnEnabled });
  }
  showDeposit(item) {
    const name = "depositDialog" + item.id;
    document.getElementById(name).click();
  }

  displayStoreProducts(list, isProd) {
    if (list.length === 0) return "";

    let orderTotal = 0;
    let depositAmount = 0;
    if (!isProd) {
      const summary = cartSummary(0.07, 0);
      orderTotal = summary.grandTotal;
      depositAmount = summary.depositAmount;
    }

    let prefixElements = [];
    let padElements = [];
    let title;
    if (isProd) {
      const remainder = list.length % 3;
      if (remainder > 0) {
        for (let i = 0; i < 3 - remainder; i++) {
          padElements.push(<div>&nbsp;</div>);
        }
      }
      title = "Store Items";
    } else {
      prefixElements.push(<div>&nbsp;</div>);
      prefixElements.push(<div>&nbsp;</div>);
      prefixElements.push(<div>&nbsp;</div>);
      title = "Deposits";
    }
    prefixElements.push(
      <div>
        <b>{title}</b>
      </div>
    );
    prefixElements.push(<div>&nbsp;</div>);
    prefixElements.push(<div>&nbsp;</div>);

    return (
      <React.Fragment>
        {prefixElements}
        {list.map((item) => {
          const imageUrl = item.imageUrl ? item.imageUrl : "no-image.jpg";
          const desc =
            item.description && item.description.trim().length > 0
              ? item.description
              : null;
          if (isProd) {
            return (
              <div className="generic-flex">
                <div>
                  <img
                    src={fullProductImageUrl(imageUrl)}
                    alt="Image"
                    width={100}
                    height={75}
                  ></img>
                </div>
                <div className="left-10">
                  {item.name}
                  <br />
                  {desc && (
                    <label>
                      {desc}
                      <br />
                    </label>
                  )}
                  Price:{" "}
                  <font color="red">
                    <FormatCurrency amount={item.finalPrice} />
                  </font>
                  <p />
                  <button
                    name="addToCart"
                    className="small-btn"
                    onClick={() => this.addToCart(item)}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            );
          } else {
            const btnId = "depositDialog" + item.id;
            return (
              <div className="generic-flex">
                <div>
                  <img
                    src={fullProductImageUrl(imageUrl)}
                    alt="Image"
                    width={60}
                  ></img>
                </div>
                <div className="left-10">
                  {item.name}
                  <br />
                  <button
                    name="addDeposit"
                    className="small-green-btn"
                    onClick={() => this.showDeposit(item)}
                  >
                    Add Deposit
                  </button>
                </div>
                <Deposit
                  actionHandler={this.addToCart}
                  hidden={true}
                  btnId={btnId}
                  item={item}
                  orderTotal={orderTotal}
                  depositAmount={depositAmount}
                />
              </div>
            );
          }
        })}
        {padElements}
      </React.Fragment>
    );
  }
  productForm() {
    const btnStyle = this.state.addBtnEnabled
      ? "small-btn"
      : "small-btn-disabled";
    return (
      <div className="generic-flex">
        <div>
          <img
            src={fullProductImageUrl("storeprod-logo.jpg")}
            alt="Logo"
            height="23"
          ></img>
        </div>
        <div className="left-10">
          <input
            type="text"
            size="20"
            name="name"
            value={this.state.name || ""}
            placeholder="Product Name"
            onChange={this.handleChange}
          ></input>
        </div>
        <div className="left-10">
          <input
            type="text"
            size="5"
            name="price"
            inputmode="numeric"
            value={this.state.price || ""}
            placeholder="Price"
            onChange={this.handleChange}
          ></input>
        </div>
        <div className="left-10">
          <button
            className={btnStyle}
            name="add"
            disabled={!this.state.addBtnEnabled}
            onClick={this.addTypedInProd}
          >
            Add to Cart
          </button>
        </div>
      </div>
    );
  }
  couponForm() {
    const btnStyle = this.state.addCouponBtnEnabled
      ? "small-btn"
      : "small-btn-disabled";
    return (
      <div className="generic-flex">
        <div>
          <img
            src={fullProductImageUrl("coupon.jpg")}
            alt="Logo"
            height="23"
          ></img>
        </div>
        <div className="left-10">
          <input
            type="text"
            size="20"
            name="couponName"
            value={this.state.couponName}
            placeholder="Coupon Name/Code"
            onChange={this.handleChange}
          ></input>
        </div>
        <div className="left-10">
          <input
            type="text"
            size="5"
            name="couponAmount"
            inputmode="numeric"
            value={this.state.couponAmount}
            placeholder="Amount"
            onChange={this.handleChange}
          ></input>
        </div>
        <div className="left-10">
          <button
            className={btnStyle}
            name="addCoupon"
            disabled={!this.state.addCouponBtnEnabled}
            onClick={this.addCoupon}
          >
            Add Coupon
          </button>
        </div>
      </div>
    );
  }
  hasDeposit(layaway) {
    const items = this.props.storeItems;
    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].layaway === layaway) return true;
      }
    }
    return false;
  }
  gotoManageLayaway(event) {
    event.preventDefault();
    window.open("http://www.fwless.com/manageLayaways");
  }
  render() {
    if (!isInStore()) return null;

    let prodList = [];
    let depList = [];
    const hasLayawayDep = this.hasDeposit(true);
    const hasOtherDep = this.hasDeposit(false);
    if (this.state.items && this.state.items.length > 0) {
      for (let i = 0; i < this.state.items.length; i++) {
        if (this.state.items[i].typeId > 0) {
          if (this.state.items[i].layaway) {
            if (!hasOtherDep) {
              depList.push(this.state.items[i]);
            }
          } else {
            if (!hasLayawayDep) {
              depList.push(this.state.items[i]);
            }
          }
        } else {
          prodList.push(this.state.items[i]);
        }
      }
    }

    let message = null;
    if (prodList.length === 0 && depList.length === 0) {
      message = this.state.isLoading
        ? "Loading ..."
        : "No store products found";
    }
    const title = this.props.storeName ? this.props.storeName : "Store";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <div align="left">
            <font size="5">{title}</font>
          </div>
          <p />
          {message ? (
            <div>{message}</div>
          ) : (
            <div>
              <div className="threecol-tbl-wrapper">
                {this.displayStoreProducts(prodList, true)}
                {this.displayStoreProducts(depList, false)}
              </div>
            </div>
          )}
          <div>&nbsp;</div>
          <p />
          {this.productForm()}
          <p />
          {isClerk() && this.couponForm()}
          <p />

          {isClerk() && (
            <div className="nodec-div">
              <a target={"_fwlAdmin"} href="#" onClick={this.gotoManageLayaway}>
                <b>
                  <font size="4">Manage Layaway</font>
                </b>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    storeName: state.webOrder.storeName,
    storeItems: state.spCart.storeItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    spAddToCart: (payload) => {
      dispatch(spAddToCart(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreItems);
