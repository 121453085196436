import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import { getIconBaseUrl, apiBaseUrl, isMobileMode } from "../Util";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import log from "loglevel";
import "../myLogger";
import "../../layaway.css";
import "../../generic.css";
import "../../App.css";

class LayawayCCPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.userId,
      amount: null,
      creditCardInfo: this.ccInfo(),
      address: this.props.contactInfo,
      stateList: null,
      layawayInfo: this.props.layawayInfo,
      submitBtnEnabled: true,
      errorMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleExpChange = this.handleExpChange.bind(this);
    this.makePayment = this.makePayment.bind(this);
  }

  componentDidMount() {
    const url = apiBaseUrl() + "StatesForPayment";
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ stateList: res.data });
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });

    if (this.props.layawayInfo) {
      const p10 = this.props.layawayInfo.totalAmount * 0.1;
      if (p10 >= this.props.layawayInfo.balance) {
        this.setState({ amount: this.props.layawayInfo.balance.toFixed(2) });
      }
    }
  }
  makePayment() {
    this.setState({ submitBtnEnabled: false });

    const url = apiBaseUrl() + "MakeLayawayPayment";
    const req = {
      ...this.state.address,
      ...this.state.creditCardInfo,
      amount: this.state.amount,
      layaway: true,
      userId: this.props.userId,
      orderNumber: this.props.layawayInfo.orderNumber,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.success) {
          this.props.callback(this.state.amount);
        } else {
          this.setState({ errorMessage: res.data.errorMessage });
        }
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });

    if (this.props.layawayInfo) {
      const p10 = this.props.layawayInfo.totalAmount * 0.1;
      if (p10 >= this.props.layawayInfo.balance) {
        this.setState({ amount: this.props.layawayInfo.balance.toFixed(2) });
      }
    }
  }
  getThisMonAsString() {
    const nm = new Date().getMonth() + 1;
    const str = nm < 10 ? "0" + nm : "" + nm;
    return str;
  }
  ccInfo() {
    const year = new Date().getFullYear();
    const mon = this.getThisMonAsString();
    const name = this.props.contactInfo
      ? this.props.contactInfo.firstName + " " + this.props.contactInfo.lastName
      : "";
    const info = {
      name,
      number: "",
      cvv: "",
      expMonth: mon,
      expYear: year,
    };
    return info;
  }
  handleChange(event) {
    let name = event.target.name;

    if (name.startsWith("ADDR_")) {
      name = name.substring(5);
      const address = {
        ...this.state.address,
        [name]: event.target.value,
      };
      this.setState({ address, submitBtnEnabled: true });
    } else if (name.startsWith("CC_")) {
      name = name.substring(3);
      const creditCardInfo = {
        ...this.state.creditCardInfo,
        [name]: event.target.value,
      };
      this.setState({ creditCardInfo, submitBtnEnabled: true });
    } else {
      this.setState({
        [name]: event.target.value,
        errorMessage: null,
      });
    }
  }

  stateList() {
    if (this.state.stateList === null) return "Georgia";
    else {
      return (
        <select
          name="ADDR_state"
          value={this.state.address.state}
          onChange={this.handleChange}
        >
          {this.state.stateList.map((pair) => {
            return (
              <option value={pair.value} key={pair.value}>
                {pair.key}
              </option>
            );
          })}
        </select>
      );
    }
  }

  handleExpChange(event) {
    let thisYear = new Date().getFullYear();
    const thisMon = this.getThisMonAsString();
    let expYear = this.state.creditCardInfo.expYear;
    let expMonth = this.state.creditCardInfo.expMonth;

    if (event.target.name === "year") {
      expYear = event.target.value;

      if (event.target.value === thisYear) {
        const selMon = document.getElementById("month").value;
        if (selMon < thisMon) {
          expMonth = thisMon;
        }
      }
    } else {
      expMonth = event.target.value;

      if (thisMon > event.target.value) {
        const selYear = document.getElementById("year").value;
        if (selYear === thisYear) {
          expYear = thisYear + 1;
        }
      }
    }
    const creditCardInfo = {
      ...this.state.creditCardInfo,
      expYear,
      expMonth,
    };
    this.setState({ creditCardInfo, submitBtnEnabled: true });
  }

  expDate() {
    const thisYear = new Date().getFullYear();
    let years = [],
      i;
    for (i = 0; i < 15; i++) {
      years.push(thisYear + i);
    }

    return (
      <div className="flex-wrapper-ctr">
        <div>
          <select
            id="month"
            name="month"
            value={this.state.creditCardInfo.expMonth || ""}
            onChange={this.handleExpChange}
          >
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
        <div>&nbsp;/&nbsp;</div>
        <div>
          <select
            id="year"
            name="year"
            value={this.state.creditCardInfo.expYear || ""}
            onChange={this.handleExpChange}
          >
            {years.map((yr) => {
              return (
                <option value={yr} key={yr}>
                  {yr}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
  billingAddressForm() {
    const required = this.props.billingRequired;
    const numType = this.state.useHTML5 ? "number" : "text";
    const addr = this.state.address ? this.state.address : {};

    return (
      <div>
        <p>
          <b>Billing Information</b>
        </p>
        <div className="twocol-tbl">
          <div>
            <label>First Name{required && <font color="red">*</font>}: </label>
          </div>

          <div>
            {required ? (
              <TextInput
                type="text"
                name="ADDR_firstName"
                value={addr.firstName}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["First Name is required"]}
              />
            ) : (
              <input
                type="text"
                name="ADDR_firstName"
                value={addr.firstName}
                onChange={this.handleChange}
              />
            )}
          </div>
          <div>
            <label>Last Name{required && <font color="red">*</font>}: </label>
          </div>

          <div>
            {required ? (
              <TextInput
                type="text"
                name="ADDR_lastName"
                value={addr.lastName}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["Last Name is required"]}
              />
            ) : (
              <input
                type="text"
                name="ADDR_lastName"
                value={addr.lastName}
                onChange={this.handleChange}
              />
            )}
          </div>

          <div>
            <label>
              Address Line1{required && <font color="red">*</font>}:{" "}
            </label>
          </div>

          <div>
            {required ? (
              <TextInput
                type="text"
                name="ADDR_addressLine1"
                size="30"
                value={addr.addressLine1}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["Address is required"]}
              />
            ) : (
              <input
                type="text"
                name="ADDR_addressLine1"
                size="30"
                value={addr.addressLine1}
                onChange={this.handleChange}
              />
            )}
          </div>

          <div>
            <label>Address Line2: </label>
          </div>

          <div>
            <TextInput
              type="text"
              name="ADDR_addressLine2"
              size="30"
              value={addr.addressLine2}
              onChange={this.handleChange}
            />
          </div>

          <div>
            <label>City{required && <font color="red">*</font>}: </label>
          </div>

          <div>
            {required ? (
              <TextInput
                type="text"
                name="ADDR_city"
                value={addr.city}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["City is required"]}
              />
            ) : (
              <input
                type="text"
                name="ADDR_city"
                value={addr.city}
                onChange={this.handleChange}
              />
            )}
          </div>

          <div>
            <label>State{required && <font color="red">*</font>}: </label>
          </div>

          <div>{this.stateList()}</div>
          <div>
            <label>ZIP Code{required && <font color="red">*</font>}: </label>
          </div>

          <div>
            {required ? (
              <TextInput
                type={numType}
                name="ADDR_zipCode"
                size="10"
                value={addr.zipCode}
                onChange={this.handleChange}
                validators={[
                  "required",
                  "matchRegexp:^(\\d{5}(?:\\-\\d{4})?)$",
                ]}
                errorMessages={["ZIP Code is required", "Invalid ZIP code"]}
              />
            ) : (
              <input
                type={numType}
                name="ADDR_zipCode"
                size="10"
                value={addr.zipCode}
                onChange={this.handleChange}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  theForm() {
    const numType = this.state.useHTML5 ? "number" : "text";
    const errMsg = this.state.errorMessage
      ? this.state.errorMessage
      : this.props.paymentError;
    const enabled = this.state.submitBtnEnabled || errMsg ? true : false;
    const btnStyle = enabled ? "btn-style" : "disabled-btn-style";
    const cc = this.state.creditCardInfo;

    return (
      <ValidatorForm ref="form" onSubmit={this.makePayment}>
        <div className="twocol-tbl">
          <div>
            <label>
              Name on card<font color="red">*</font>:{" "}
            </label>
          </div>
          <div>
            <TextInput
              type="text"
              name="CC_name"
              value={cc.name}
              onChange={this.handleChange}
              validators={["required"]}
              errorMessages={["Name is required"]}
            ></TextInput>
          </div>
          <div>
            <label>
              Card number<font color="red">*</font>:{" "}
            </label>
          </div>
          <div>
            {this.props.validateCCNumber ? (
              <TextInput
                type={numType}
                name="CC_number"
                value={cc.number}
                onChange={this.handleChange}
                placeholder="Card number, no space"
                validators={[
                  "required",
                  "isNumber",
                  "minStringLength: 13",
                  "maxStringLength: 16",
                ]}
                errorMessages={[
                  "Card number is required",
                  "Must be a number, no space",
                  "Credit card number too short",
                  "Credit card number too long",
                ]}
              ></TextInput>
            ) : (
              <TextInput
                type={numType}
                name="CC_number"
                value={cc.number}
                onChange={this.handleChange}
                placeholder="Card number, no space"
                validators={["required"]}
                errorMessages={["Card number is required"]}
              ></TextInput>
            )}
          </div>
          <div>
            <label>
              Expiration date<font color="red">*</font>:{" "}
            </label>
          </div>
          <div>{this.expDate()}</div>
          <div>
            <label>
              CVV<font color="red">*</font>:{" "}
            </label>
          </div>
          <div>
            <TextInput
              type={numType}
              size="4"
              name="CC_cvv"
              value={cc.cvv}
              onChange={this.handleChange}
              validators={["required"]}
              errorMessages={["CVV is required"]}
            ></TextInput>
          </div>
          <div>
            Amount<font color="red">*</font>:&nbsp;{" "}
          </div>
          <div>
            <input
              type="text"
              size="6"
              name="amount"
              value={this.state.amount || ""}
              onChange={this.handleChange}
            />
          </div>
          {!this.props.useBillingAddress && (
            <React.Fragment>
              <div>Zip Code:</div>
              <div>
                <input
                  type={numType}
                  size="10"
                  name="zipCode"
                  value={this.state.zipCode}
                  onChange={this.handleChange}
                ></input>
              </div>
            </React.Fragment>
          )}
        </div>

        {this.billingAddressForm()}
        <p />
        <div>
          <button
            id="submitBtn"
            className={btnStyle}
            type="submit"
            disabled={!enabled}
          >
            Make Payment
          </button>
        </div>
      </ValidatorForm>
    );
  }
  header() {
    const imgUrl = getIconBaseUrl() + "CreditCard-Logos.png";
    const info = this.props.layawayInfo;
    const p10 = info.totalAmount * 0.1;
    const p20 = info.totalAmount * 0.2;
    let amtInfo = "Your balance: $" + info.balance.toFixed(2);

    if (info.balance > p20) {
      amtInfo =
        " 10%=$" +
        p10.toFixed(2) +
        ", 20%=$" +
        p20.toFixed(2) +
        ", Bal=$" +
        info.balance.toFixed(2);
    } else if (info.balance > p10) {
      amtInfo = "10%=$" + p10.toFixed(2) + ", Bal=$" + info.balance.toFixed(2);
    }
    return (
      <div className="bottom-15">
        <font size="4">Credit Card</font> &nbsp;&nbsp;
        <img src={imgUrl} height="20" alt="Credit Cards"></img>
        <div>
          You can use credit card or debit card to make layaway payments. If you
          make 20% or more, we will hold your furniture for additional 90 days.
          10% or more will give you 30 more days.&nbsp;{amtInfo}
          <br />
        </div>
      </div>
    );
  }
  render() {
    const errMsg = this.state.errorMessage
      ? this.state.errorMessage
      : this.props.paymentError;
    const errMsgHtml = '<font color="red">' + errMsg + "</font><p/>";

    return (
      <div align="left">
        {errMsg && (
          <div
            dangerouslySetInnerHTML={{
              __html: errMsgHtml,
            }}
          ></div>
        )}
        {this.header()}
        {this.theForm()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userId: state.user.userId,
    supportHTML5: state.cache.supportHTML5,
  };
};
export default connect(mapStateToProps)(withRouter(LayawayCCPayment));
