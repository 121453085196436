import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import { AXIOS_HEADER } from "./config/constants";
import { setHomes, setDebugInfo, setVersion } from "./actions/cacheActions";
import {
  apiBaseUrl,
  useCache,
  fullProductImageUrl,
  getHomeImageMaxWidth,
  generateGuid,
  getGroupWrapperWidth,
  getLeftMargin,
  setTopContainerWrapperSettings,
  getHomeImageRatio,
  getProdDetailUrl,
} from "./Util";
import Working from "./Working";
import { setCategory } from "./actions/searchActions";
import { setUserId } from "./actions/userActions";
import { priceRange, isMobileMode } from "./Util";
import log from "loglevel";
import "./myLogger";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css";
import "../home.css";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageList: this.props.homeImageList,
      flyerList: null,
      imageWidth: 0,
      promImgWidth: 0,
      promImgHeight: 0,
      verPromImgWidth: 0,
      verPromImgHeight: 0,
      maxPromImgWidth: 200,
      prodList: this.props.homePromList,
      verProdList: this.props.homeVerPromList,
      viewProdList: this.props.homeViewedList,
    };
    this.adjustImageWidth = this.adjustImageWidth.bind(this);
    this.promotionalProducts = this.promotionalProducts.bind(this);
    this.processHomeResponse = this.processHomeResponse.bind(this);
  }
  getHomeImageWidth() {
    if (isMobileMode()) return getGroupWrapperWidth();
    else {
      const maxForScreen = window.innerWidth - getLeftMargin() * 2;
      const imageWidth =
        getHomeImageMaxWidth() > maxForScreen
          ? maxForScreen
          : getHomeImageMaxWidth();
      return imageWidth;
    }
  }
  processHomeResponse(response) {
    this.setState({
      imageList: response.homeImageList,
      flyerList: response.flyerList,
      prodList: response.promProdList,
      verProdList: response.verPromProdList,
      viewedProdList: response.recentVisitedList,
    });

    if (useCache()) {
      const whLatitude = response.warehouse ? response.warehouse.latitude : 0;
      const whLongitude = response.warehouse ? response.warehouse.longitude : 0;
      const deliveryRadius = response.warehouse
        ? response.warehouse.deliveryRadius
        : 0;
      const payload = {
        android: response.android,
        supportHTML5: response.supportHTML5,
        autoCompleteAddress: response.autoCompleteAddress,
        autoCompleteCDF: response.autoCompleteCDF,
        whLatitude,
        whLongitude,
        deliveryRadius,
        newArrivalCount: response.newArrivalCount,
        homeImageList: response.homeImageList,
        homePromList: response.promProdList,
        homeVerPromList: response.verPromProdList,
        homeViewedList: response.recentVisitedList,
      };
      this.props.setHomes(payload);
      if (response.version) {
        if (response.version != this.props.version) {
          this.props.setVersion(response.version);
          window.location.reload();
        }
      }
    } else {
      console.log("Cache disabled.");
    }
    this.adjustImageWidth();
  }

  componentDidMount() {
    const url = apiBaseUrl() + "Home";

    this.props.setCategory(null);
    this.adjustImageWidth();
    window.addEventListener("resize", this.adjustImageWidth);

    let userId = this.props.userId;
    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }

    axiosRetry(axios, { retries: 3 });
    axios
      .post(
        url,
        { userId: userId },
        {
          headers: AXIOS_HEADER,
        }
      )
      .then((res) => {
        this.processHomeResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustImageWidth);
  }

  adjustImageWidth() {
    let promImgWidth = getGroupWrapperWidth() - 15 * 3;

    if (isMobileMode()) {
      promImgWidth = promImgWidth / 3 + 4;
    } else {
      promImgWidth = promImgWidth / 4;
    }

    const promImgHeight = (promImgWidth * 2) / 3;
    let imageWidth = this.getHomeImageWidth();
    let verPromImgWidth = 0;
    let verPromImgHeight = 0;

    if (!isMobileMode()) {
      verPromImgWidth = imageWidth / 4 - 5;
      verPromImgHeight = (verPromImgWidth * 2) / 3;
      imageWidth = (imageWidth * 3) / 4 - 5;
    } else {
      document.documentElement.style.setProperty(
        "--home-item-width",
        "" + promImgWidth + "px"
      );
    }
    this.setState({
      imageWidth,
      promImgWidth,
      promImgHeight,
      verPromImgWidth,
      verPromImgHeight,
    });
    setTopContainerWrapperSettings();
    try {
      const payload = {
        innerWidth: window.innerWidth,
        clientWidth: document.documentElement.clientWidth,
        catTop: document.getElementById("catTop").offsetWidth,
        catWrapper: document.getElementById("catWrapper").offsetWidth,
        menuTop: document.getElementById("menuTop").offsetWidth,
        homeTop: document.getElementById("homeTop").offsetWidth,
        homeWrapper: document.getElementById("homeWrapper").offsetWidth,
        promWrapper: document.getElementById("promWrapper").offsetWidth,
        groupWidth: getGroupWrapperWidth(),
        bigImageWidth: imageWidth,
        smallImageWidth: promImgWidth.toFixed(0),
      };
      this.props.setDebugInfo(payload);
    } catch (error) {
      console.log(error);
    }
  }

  promotionalProducts() {
    return (
      <div className="hmprom-container" id="promWrapper">
        {this.state.prodList.map((item) => {
          const imageUrl = item.thumbnailImage
            ? item.thumbnailImage
            : item.imageUrl;
          const soldOut = item.forSale === false ? "SOLD OUT" : null;
          let name = item.name;
          if (isMobileMode() && item.shortName && item.shortName.length > 5) {
            name = item.shortName;
          }
          const url = getProdDetailUrl(item);
          return (
            <div className="hmprom-item" key={item.name}>
              <Link to={url}>
                <img
                  width={this.state.promImgWidth}
                  height={this.state.promImgHeight}
                  src={fullProductImageUrl(imageUrl)}
                  alt={item.name}
                ></img>
              </Link>{" "}
              <br />
              {name}
              <br />
              {priceRange(item)}
              &nbsp;
              {soldOut && (
                <b>
                  {isMobileMode() && <br />}
                  <i>
                    <font color="red">{soldOut}</font>
                  </i>
                </b>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  recentlyViewedProducts() {
    if (!this.state.viewedProdList) return "";

    return (
      <React.Fragment>
        <div className="hmviewed-container">
          {this.state.viewedProdList.map((item) => {
            const imageUrl = item.thumbnailImage
              ? item.thumbnailImage
              : item.imageUrl;
            let name = item.name;
            if (isMobileMode() && item.shortName && item.shortName.length > 5) {
              name = item.shortName;
            }
            const url = getProdDetailUrl(item);
            return (
              <div className="hmprom-item">
                <Link to={url}>
                  <img
                    width={this.state.promImgWidth}
                    height={this.state.promImgHeight}
                    src={fullProductImageUrl(imageUrl)}
                    alt={item.name}
                  ></img>
                </Link>{" "}
                <br />
                {name}
                <br />
                {priceRange(item)}
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
  handleClick(homeSplashImage) {
    const url = homeSplashImage.nonProduct
      ? homeSplashImage.linkUrl
      : "/product/" + homeSplashImage.value;
    if (url && url.length > 0) {
      this.props.history.push(url);
    }
  }

  showHomeImages() {
    const imageList = this.state.imageList;

    if (imageList && imageList.length > 0) {
      if (imageList.length === 1) {
        return (
          <img
            src={fullProductImageUrl(imageList[0].key)}
            alt="Home"
            width={this.state.imageWidth}
          ></img>
        );
      } else {
        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
          arrows: true,
        };
        const height = this.state.imageWidth * getHomeImageRatio();

        if (height > 0) {
          return (
            <Slider {...settings} style={{ width: this.state.imageWidth }}>
              {imageList.map((img, idx) => (
                <div onClick={() => this.handleClick(img)} key={idx}>
                  <img
                    src={fullProductImageUrl(img.key)}
                    width={this.state.imageWidth}
                    height={height}
                    alt="Home"
                  />
                </div>
              ))}
            </Slider>
          );
        } else {
          return (
            <Slider {...settings} style={{ width: this.state.imageWidth }}>
              {imageList.map((img, idx) => (
                <div key={idx} onClick={() => this.handleClick(img)}>
                  <img
                    src={fullProductImageUrl(img.key)}
                    width={this.state.imageWidth}
                    alt="Home"
                  />
                </div>
              ))}
            </Slider>
          );
        }
      }
    }
  }
  showVerProducts() {
    if (this.state.verProdList && this.state.verProdList.length > 0) {
      return (
        <div className="ver-prod">
          {this.state.verProdList.map((item) => {
            const imageUrl = item.thumbnailImage
              ? item.thumbnailImage
              : item.imageUrl;
            let name = item.name;
            if (isMobileMode() && item.shortName && item.shortName.length > 5) {
              name = item.shortName;
            }
            const url = getProdDetailUrl(item);
            return (
              <div className="hmprom-item" key={item.name}>
                <Link to={url}>
                  <img
                    width={this.state.verPromImgWidth}
                    height={this.state.verPromImgHeight}
                    src={fullProductImageUrl(imageUrl)}
                    alt={name}
                  ></img>
                </Link>{" "}
                <br />
                {name}
                <br />
                {priceRange(item)}
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  showTopPortion() {
    if (isMobileMode()) {
      return <div className="home-image">{this.showHomeImages()}</div>;
    } else {
      return (
        <div className="home-image-siderow">
          <div>{this.showHomeImages()}</div>
          {this.showVerProducts()}
        </div>
      );
    }
  }

  render() {
    if (!this.state.prodList) return <Working />;

    return (
      <div className="top-wrapper" id="homeTop">
        <div className="hm-container" id="homeWrapper">
          {this.props.errorMessage && (
            <div>
              <font color="red">{this.props.errorMessage}</font>
              <p />
            </div>
          )}
          {this.showTopPortion()}
          {this.promotionalProducts()}
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    android: state.cache.android,
    homeImageList: state.cache.homeImageList,
    homePromList: state.cache.homePromList,
    homeVerPromList: state.cache.homeVerPromList,
    homeViewedList: state.cache.homeViewedList,
    version: state.cache.version,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCategory: (categoryName) => {
      dispatch(setCategory(categoryName));
    },
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    setHomes: (payload) => {
      dispatch(setHomes(payload));
    },
    setDebugInfo: (payload) => {
      dispatch(setDebugInfo(payload));
    },
    setVersion: (ver) => {
      dispatch(setVersion(ver));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
