import React from "react";
import { setTopContainerWrapperSettings, setInfoWrapperWidth } from "../Util";

import "../../info.css";

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
    setInfoWrapperWidth();
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="info-container">
          <div className="info-wrapper">
            <div className="info-item">
              <font size="5">Privacy Policy</font>
              <br />
              <hr width="80%"></hr>
            </div>
            <div className="info-item">
              <font size="5">Furniture Way Less Privacy Statement</font>
            </div>
            <div className="info-content-wrapper">
              <div className="info-paragraph">
                <b>This Privacy Policy applies to information we collect</b>
                <p />
                Furniture Way Less takes privacy very seriously, and we handle
                your information carefully and sensibly. We want to let you what
                we collect, how we collect, how we use, how we share, and how we
                protect your information. <p />
                The statement below describes our privacy practices for this
                website: furniturewayless.com
                <p />
              </div>
              <div className="info-paragraph">
                <b>How/What customer information do we collect?</b>
                <p />
                We collect information from you when you place an order, make a
                payment for delivery fee or security deposit, or send us an
                email through the "Contact Us" page. The information we collect
                includes:
                <ul>
                  <li>Your name</li>
                  <li>Your address</li>
                  <li>Your phone number</li>
                  <li>Your email address</li>
                  <li>Your credit card information</li>
                </ul>
              </div>
              <div className="info-paragraph">
                <b>How do we use/share your information?</b>
                <p />
                We use the information we collect to process your order, process
                your payment, and/or handle delivery. We also use your email
                address or phone number to communicate with you regarding your
                orders and your inquiries. We may occasionally send you
                promotional emails.
                <p />
                We don't share your information with third parties except when
                we process your payments, in such cases we send required
                information to our payment processor. We never sell your
                information to third parties.
              </div>
              <div className="info-paragraph">
                <b>How do we protect your information?</b>
                <p />
                We have implemented a variety of security measures to maintain
                the safety of your information. We store your information in a
                secure database behind a secure network. The information can
                only be accessed by a few authorized employees, and can only be
                accessed when necessary. These employees are trained to keep
                these information confidential. Sensitive information, such as
                credit card number, is encrypted. The credit card information is
                deleted as soon as payement is successfully processed.
              </div>
              <div className="info-paragraph">
                <b>What about cookies?</b>
                <p />
                Yes, our website does use cookies to keep the items in your
                shopping cart, to recoginize your browser or device in order to
                provide you with improved experiences with our website.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
