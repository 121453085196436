import React from "react";
import "./App.css";
import Category from "./components/product/Category";
import ProductDetail from "./components/product/ProductDetail";
import Products from "./components/product/ProductsByCat";
import Cart from "./components/cart/Cart";
import ShippingChoiceEx from "./components/order/ShippingChoiceEx";
import OrderSummary from "./components/order/OrderSummary";
import Home from "./components/Home";
import MenuBar from "./components/MenuBar";
import NonExistPage from "./components/NonExistPage";
import StoreLocation from "./components/store/StoreLocation";
import AboutUs from "./components/store/AboutUs";
import PrivacyPolicy from "./components/store/PrivacyPolicy";
import ContactUs from "./components/store/ContactUs";
import Layaway from "./components/store/Layaway";
import LeaseToOwn from "./components/store/LeaseToOwn";
import Search from "./components/product/Search";
import OrderComplete from "./components/order/OrderComplete";
import PickupInstruction from "./components/order/PickupInstruction";
import AssemblyFee from "./components/store/AssemblyFee";
import Footer from "./components/Footer";
import TariffNote from "./components/store/TariffNote";
import LocationsMap from "./components/store/LocationsMap";
import ReturnPolicy from "./components/store/ReturnPolicy";
import ComboProduct from "./components/product/ComboProduct";
import FAQ from "./components/store/FAQ";
import ClearStates from "./components/ClearStates";
import DebugInfo from "./components/DebugInfo";
import Register from "./components/store/Register";
import StoreItems from "./components/store/StoreItems";
import LayawayContact from "./components/store/LayawayContact";
import StoreLayawaySummary from "./components/store/StoreLayawaySummary";
import StoreLayawayComplete from "./components/store/StoreLayawayComplete";
import LoginUser from "./components/user/LoginUser";
import CreateAccount from "./components/user/CreateAccount";
import VerifyAccount from "./components/user/VerifyAccount";
import Checkout from "./components/order/Checkout";
import LayawaySummary from "./components/order/LayawaySummary";
import LayawayComplete from "./components/order/LayawayComplete";
import MyAccount from "./components/user/MyAccount";
import Logout from "./components/user/Logout";
import ResetPassword from "./components/user/ResetPassword";
import ChangePassword from "./components/user/ChangePassword";
import LayawayDetail from "./components/user/LayawayDetail";
import OrderDetail from "./components/user/OrderDetail";
import LeaseOrderSubmitted from "./components/progressive/LeaseOrderSubmitted";
import LeaseOrderComplete from "./components/progressive/LeaseOrderComplete";
import AfterEsignProcess from "./components/progressive/AfterEsignProcess";
import HtmlContent from "./components/store/HtmlContent";
import Specials from "./components/product/Specials";
import FlyerProducts from "./components/product/FlyerProducts";
import ErrorBoundary from "./components/ErrorBoundary";

import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="top-container">
        <ErrorBoundary>
          <MenuBar />
        </ErrorBoundary>
        <ErrorBoundary>
          <Category />
        </ErrorBoundary>
        <ErrorBoundary key={window.location.pathname}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/product/:id/:sku?" component={ProductDetail} />
            <Route path="/category/:id" component={Products} />
            <Route path="/product-category/:name/:page?" component={Products} />
            <Route path="/specials/:page?" component={Specials} />
            <Route path="/cart" component={Cart} />
            <Route path="/shippingChoice/:type?" component={ShippingChoiceEx} />
            <Route path="/orderSummary" component={OrderSummary} />
            <Route path="/location/:id" component={StoreLocation} />
            <Route path="/aboutUs" component={AboutUs} />
            <Route path="/contactUs" component={ContactUs} />
            <Route path="/layaway" component={Layaway} />
            <Route path="/leaseToOwn" component={LeaseToOwn} />
            <Route path="/search" component={Search} />
            <Route path="/orderComplete" component={OrderComplete} />
            <Route path="/pickupInstr" component={PickupInstruction} />
            <Route path="/assemblyFee" component={AssemblyFee} />
            <Route path="/tariffNote" component={TariffNote} />
            <Route path="/locationsMap" component={LocationsMap} />
            <Route path="/returnPolicy" component={ReturnPolicy} />
            <Route path="/privacyPolicy" component={PrivacyPolicy} />
            <Route path="/combo/:comboId" component={ComboProduct} />
            <Route path="/faq" component={FAQ} />
            <Route path="/storeItems" component={StoreItems} />
            <Route path="/layawayContact" component={LayawayContact} />
            <Route path="/layawaySummary" component={StoreLayawaySummary} />
            <Route path="/layawayComplete" component={StoreLayawayComplete} />
            <Route path="/login" component={LoginUser} />
            <Route path="/createAccount/:purpose?" component={CreateAccount} />
            <Route path="/verifyAccount/:encStr?" component={VerifyAccount} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/laSummary" component={LayawaySummary} />
            <Route path="/laComplete" component={LayawayComplete} />
            <Route path="/myAccount" component={MyAccount} />
            <Route path="/logout" component={Logout} />
            <Route path="/resetPass" component={ResetPassword} />
            <Route path="/changePass/:reason?" component={ChangePassword} />
            <Route path="/layawayDetail/:orderNo" component={LayawayDetail} />
            <Route path="/orderDetail/:orderNo" component={OrderDetail} />
            <Route
              path="/leaseOrderSubmitted"
              component={LeaseOrderSubmitted}
            />
            <Route path="/leaseComplete" component={LeaseOrderComplete} />
            <Route path="/content/:name" component={HtmlContent} />
            <Route
              path="/afterEsign/:orderNumber"
              component={AfterEsignProcess}
            />
            <Route path="/flyer/:mapId?" component={FlyerProducts} />
            <Route path="/__clear" component={ClearStates} />
            <Route path="/__debug" component={DebugInfo} />
            <Route
              path="/__register/:storeId/:token/:kiosk?"
              component={Register}
            />
            <Route component={NonExistPage} />
          </Switch>
        </ErrorBoundary>
        <ErrorBoundary>
          <Footer />
        </ErrorBoundary>
      </div>
    </Router>
  );
}

export default App;
