import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  FormatCurrency,
  setTopContainerWrapperSettings
} from "../Util";
import log from "loglevel";
import "../myLogger";
import "../../assembly.css";
import "../../tables.css";

class AssemblyFee extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assemblyList: null,
      isloadingAL: true,
      deliveryList: null,
      isLoadingDL: true,
      cityIdx: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  componentDidMount() {
    let url = apiBaseUrl() + "AssemblyList";

    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then(res => {
        this.setState({ assemblyList: res.data, isloadingAL: false });
      })
      .catch(error => {
        console.log(error);
        log.error(error);
      });

    url = apiBaseUrl() + "DeliveryList";
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then(res => {
        this.setState({ deliveryList: res.data, isLoadingDL: false });
      })
      .catch(error => {
        console.log(error);
        log.error(error);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
  }

  formatFee(item) {
    if (!item.feeMax || item.feeMax < 0) {
      return (
        <div>
          <FormatCurrency amount={item.fee} /> & Up
        </div>
      );
    } else if (item.feeMax && item.feeMax === 0) {
      return (
        <div>
          <FormatCurrency amount={item.fee} />
        </div>
      );
    } else {
      return (
        <div>
          <FormatCurrency amount={item.fee} /> -&nbsp;
          <FormatCurrency amount={item.feeMax} />
        </div>
      );
    }
  }

  selectedDeliveryFee() {
    const addr = this.state.deliveryList[this.state.cityIdx];
    if (addr) {
      return <FormatCurrency amount={addr.fee} />;
    }
  }

  handleChange(event) {
    const idx = event.target.value;
    this.setState({ cityIdx: idx });
  }

  showDeliveryFees() {
    if (this.state.isLoadingDL) return "";

    return (
      <div className="assembly-wrapper">
        <div className="assembly-title" align="left">
          <font size="5">Delivery Fees</font>
        </div>
        <div className="assembly-item" align="left">
          All of the furnitures are delivered from our central warehouse. We
          charge one fixed rate of delivery fee regardless of number of items
          you purchase. The delivery fee is based on the city where you live. To
          find out your delivery fee, select the city where you want the
          furnitures to be delivered. <p />
          We currently only delivery in metro Atlanta area. If your city is not
          listed below, please call us for the delivery fee.
          <br />
        </div>
        <div className="tbl-container2">
          <div>
            <select
              name="city"
              value={this.state.cityIdx}
              onChange={this.handleChange}
            >
              {this.state.deliveryList.map((addr, idx) => {
                return <option value={idx}>{addr.city}</option>;
              })}
            </select>
          </div>
          <div>
            &nbsp;&nbsp;<font color="green">{this.selectedDeliveryFee()}</font>
          </div>
        </div>
      </div>
    );
  }

  showAssemblyFees() {
    if (this.state.isloadingAL) return "";

    return (
      <div className="assembly-wrapper">
        <div className="assembly-title" align="left">
          <font size="5">Assembly Fees</font>
        </div>
        <div className="assembly-item" align="left">
          Most of the furnitures we sell require assembly. If you would like to
          ask us to assemble them for you, below is the fee table. Please pay
          the assembly fee to our delivery drivers.
        </div>
        <div className="tbl-container2">
          {this.state.assemblyList.map(item => {
            return (
              <React.Fragment>
                <div className="tbl-item-label">{item.name}</div>
                <div className="tbl-item-value">{this.formatFee(item)}</div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="assembly-container">
          {this.showAssemblyFees()}
          {this.showDeliveryFees()}
        </div>
      </div>
    );
  }
}

export default AssemblyFee;
