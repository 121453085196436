import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  isMobileMode,
  apiBaseUrl,
  setTopContainerWrapperSettings,
  setInfoWrapperWidth
} from "../Util";
import ContactUsForm from "./ContactUsForm";
import log from "loglevel";
import "../myLogger";
import "../../info.css";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      locations: null,
      mobileMode: false
    };
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    const url = apiBaseUrl() + "LocationDetails";
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then(res => {
        console.log(res.data);
        this.setState({
          isLoading: false,
          locations: res.data
        });
      })
      .catch(error => {
        console.log(error);
        log.error(error);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    let flag = isMobileMode() ? true : false;
    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
    setInfoWrapperWidth();
  }

  displayOneLoc(loc) {
    const url = "/location/" + loc.id;
    return (
      <div className="info-contact-loc-item" align="left">
        <Link to={url}>{loc.name}</Link>
        <br />
        {loc.info.phone} &nbsp;&nbsp; {loc.info.phone2}
      </div>
    );
  }

  storeLocations() {
    if (this.state.locations === null) return "Fetching ...";

    return (
      <div className="info-store-wrapper">
        <div align="left">
          <font size="4">Store Phone Numbers</font>
          <p />
        </div>
        {this.state.locations.map(loc => {
          return this.displayOneLoc(loc);
        })}
      </div>
    );
  }

  regularDisplay() {
    return (
      <div className="info-contact-row-container">
        <ContactUsForm />
        <div className="info-vr-style"></div>
        {this.storeLocations()}
      </div>
    );
  }

  mobileDisplay() {
    return (
      <div>
        <ContactUsForm />
        <div className="info-contact-mobile-wrapper">
          {this.storeLocations()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="top-wrapper">
        {this.state.mobileMode ? this.mobileDisplay() : this.regularDisplay()}
      </div>
    );
  }
}

export default ContactUs;
