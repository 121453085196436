import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setLocations } from "./actions/cacheActions";
import { AXIOS_HEADER } from "./config/constants";
import HamburgerMenu from "./HamburgerMenu";
import {
  isMobileMode,
  apiBaseUrl,
  getIconBaseUrl,
  useCache,
  setTopContainerWrapperSettings,
  getLogoUrl,
  getMobileLogoUrl,
  generateGuid,
} from "./Util";
import { setUserId } from "./actions/userActions";
import { searchProducts } from "./actions/searchActions";
import { registerWebOrder } from "./actions/webOrderActions";
import log from "loglevel";
import "./myLogger";
import MetaTags from "react-meta-tags";

import "../navBar.css";
import { isInStore } from "./store/storeUtil";

class MenuBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      locations: this.props.locations,
      mobileMode: false,
      smLink: null,
      smText: null,
      smColor: null,
      smWeight: null,
      smIconUrl: null,
      logoUrl: getLogoUrl(),
      mobileLogoUrl: getMobileLogoUrl(),
      mobileLogoOffset: 20,
    };
    this.storeLocations = this.storeLocations.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.searchProducts = this.searchProducts.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processSpecialMenu = this.processSpecialMenu.bind(this);
    this.processLogo = this.processLogo.bind(this);
  }

  componentDidMount() {
    this.fetchMenuSetting();
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  searchProducts(event) {
    if (event.key === "Enter") {
      const query = event.target.value;
      if (query && query.trim().length > 0) {
        this.props.searchProducts(query.trim());
        event.target.value = "";
        this.props.history.push("/search");
      }
    }
  }

  fetchMenuSetting() {
    const url = apiBaseUrl() + "GetMenuSetting";

    let userId = this.props.userId;
    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }

    axiosRetry(axios, { retries: 3 });
    axios
      .post(
        url,
        { userId: userId },
        {
          headers: AXIOS_HEADER,
        }
      )
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  processResponse(response) {
    if (response) {
      this.processSpecialMenu(response.specialMenu);
      this.processLogo(response.logos);

      this.setState({
        isLoading: false,
        locations: response.storeLocations,
      });
      if (useCache()) {
        this.props.setLocations(response.storeLocations);
      }

      const payload =
        response.regStoreId > 0
          ? {
              userId: this.props.userId,
              storeId: response.regStoreId,
              storeName: response.regStoreName,
            }
          : {
              userId: this.props.userId,
              storeId: 0,
              storeName: null,
            };
      this.props.registerWebOrder(payload);
    }
  }
  processSpecialMenu(spMenu) {
    if (spMenu) {
      this.setState({
        smLink: spMenu.link,
        smText: spMenu.text,
        smIconUrl: spMenu.iconUrl,
        smColor: spMenu.color,
        smWeight: spMenu.weight,
      });

      if (spMenu.color && spMenu.color.length > 0) {
        document.documentElement.style.setProperty(
          "--special-menu-color",
          spMenu.color
        );
      }
      if (spMenu.weight && spMenu.weight.length > 0) {
        document.documentElement.style.setProperty(
          "--special-menu-weight",
          spMenu.weight
        );
      }
    }
  }
  processLogo(logo) {
    if (logo) {
      const logoUrl =
        logo.logoUrl && logo.logoUrl.length > 0
          ? logo.logoUrl
          : this.state.logoUrl;
      const mobileLogoUrl =
        logo.mobileLogoUrl && logo.mobileLogoUrl.length > 0
          ? logo.mobileLogoUrl
          : this.state.mobileLogoUrl;
      const mobileLogoOffset = logo.mobileLogoOffset
        ? logo.mobileLogoOffset
        : this.state.mobileLogoOffset;

      this.setState({ logoUrl, mobileLogoUrl, mobileLogoOffset });
    }
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }

  handleLocClick() {
    document.getElementById("locMenu").style.display = "none";
  }

  storeLocations() {
    if (!this.state.locations) return "";

    if (this.props.android) {
      document.documentElement.style.setProperty(
        "--loc-icon-right-margin",
        "0px"
      );
    }
    //const locIcon = getIconBaseUrl() + "location.png";
    const locIcon = getIconBaseUrl() + "geoloc.jpg";
    const locMapName = isMobileMode() ? "All Stores" : "Location Map";
    return (
      <div className="menu-dropdown">
        {isMobileMode() ? (
          <div className="menu-loc-icon">
            <img src={locIcon} height="20"></img>
          </div>
        ) : (
          <div className="menu-dropdown-title">Locations</div>
        )}

        <div className="menu-dropdown-content" align="left" id="locMenu">
          {this.state.locations.map((loc) => {
            let url = "/location/" + loc.id;
            const name = isMobileMode() ? loc.shortName : loc.name;
            return (
              <a href={url} onClick={this.handleLocClick} key={loc.id}>
                {name}
              </a>
            );
          })}
          <a href="/locationsMap" onClick={this.handleLocClick}>
            {locMapName}
          </a>
        </div>
      </div>
    );
  }

  loggedInAcctItems() {
    const name = this.props.userInfo
      ? this.props.userInfo.firstName
      : "Customer";
    return (
      <React.Fragment>
        <a href="#">Hello, {name}</a>
        <a href="/myAccount" onClick={this.handleClick}>
          <img src={getIconBaseUrl() + "menu_user.jpg"} height="15" />
          &nbsp;My Account
        </a>
        <a href="/logout" onClick={this.handleClick}>
          <img src={getIconBaseUrl() + "logout.jpg"} height="15" />
          &nbsp;Logout
        </a>
      </React.Fragment>
    );
  }
  newAcctItems() {
    return (
      <React.Fragment>
        <a href="/login" onClick={this.handleClick}>
          Login
        </a>
        <a href="/createAccount" onClick={this.handleClick}>
          Register
        </a>
      </React.Fragment>
    );
  }
  accountMenu() {
    if (this.state.mobileMode || isInStore()) return "";

    if (this.props.android) {
      document.documentElement.style.setProperty(
        "--loc-icon-right-margin",
        "0px"
      );
    }

    return (
      <div className="menu-dropdown">
        <div className="menu-dropdown-title">Account</div>
        <div className="menu-dropdown-content" align="left" id="acctMenu">
          {this.props.isLoggedIn
            ? this.loggedInAcctItems()
            : this.newAcctItems()}
        </div>
      </div>
    );
  }

  handleInfoClick() {
    document.getElementById("infoMenu").style.display = "none";
  }

  infoMenu() {
    const { virtualTourUrl } = window["runConfig"];
    return (
      <div className="menu-dropdown">
        <div className="menu-dropdown-title">Info</div>
        <div className="menu-dropdown-content" align="left" id="infoMenu">
          <a href="/aboutUs" onClick={this.handleInfoClick}>
            About Us
          </a>
          <a href="/contactUs" onClick={this.handleInfoClick}>
            Contact Us
          </a>
          <a href="/layaway" onClick={this.handleInfoClick}>
            Layaway
          </a>
          <a href="/leaseToOwn" onClick={this.handleInfoClick}>
            Lease-to-Own
          </a>
          <a href="/returnPolicy" onClick={this.handleInfoClick}>
            Waranty & Return
          </a>
          <a href={virtualTourUrl} target="VirtualTour">
            Virtual Tour
          </a>
        </div>
      </div>
    );
  }

  showSpecialMenu() {
    if (this.state.smLink) {
      if (this.state.smIconUrl) {
        const altTxt = this.state.smText ? this.state.smText : "Special";
        return (
          <div className="menu-left-side">
            <Link to={this.state.smLink}>
              <div className="menu-logo-wrapper">
                <img src={this.state.smIconUrl} alt={altTxt} height="40"></img>
              </div>
            </Link>
          </div>
        );
      } else {
        return (
          <div className="menu-left-side-text">
            <Link to={this.state.smLink}>{this.state.smText}</Link>
          </div>
        );
      }
    } else {
      let storeName = this.props.storeName;
      if (storeName) {
        if (this.props.kiosk) storeName += " (Kiosk)";
        return (
          <div className="menu-left-side-text">
            <b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <font color="red" size="5">
                {storeName}
              </font>
            </b>
          </div>
        );
      }
    }
  }

  getItemCount() {
    let count = this.props.itemCount;
    if (isInStore()) {
      count += this.props.spItemCount;
    }
    return count;
  }
  regularLayout() {
    const iconUrl = getIconBaseUrl() + "shopping_cart2.png";

    return (
      <div className="menu-wrapper" id="menuTop">
        <div className="menu-left-side">
          <Link to="/">
            <div className="menu-logo-wrapper">
              <img
                src={this.state.logoUrl}
                alt="Furniture Way Less"
                height="40"
              ></img>
            </div>
          </Link>
        </div>
        {this.showSpecialMenu()}
        <div className="menu-middle">&nbsp;</div>
        <div className="menu-right-side">
          <div>
            <input
              type="search"
              placeholder="Search"
              results="0"
              height="25"
              size="35"
              onKeyDown={this.searchProducts}
            />
          </div>
          <div>{this.storeLocations()}</div>
          <div>{this.infoMenu()}</div>
          <div>{this.accountMenu()}</div>
          <div>
            <Link to="/cart">
              <div className="menu-cart-wrapper">
                <div className="menu-cart-icon">
                  <img src={iconUrl} alt="Cart" height="23"></img>
                </div>
                <div className="numberCircle">{this.getItemCount()}</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
  newArrivals() {
    if (
      !this.props.android &&
      this.props.newArrivalCount &&
      this.props.newArrivalCount > 0
    ) {
      const newIcon = getIconBaseUrl() + "NewArrivals.jpg";
      return (
        <div className="left-10">
          <Link to="/product-category/newarrivals">
            <div className="menu-logo-wrapper">
              <img src={newIcon} alt="New" height="23"></img>
            </div>
          </Link>
        </div>
      );
    }
  }
  mobileLayout() {
    const px = this.props.android
      ? "0px"
      : "" + this.state.mobileLogoOffset + "px";
    const cartIcon = getIconBaseUrl() + "shopping_cart2.png";
    const homeIcon = this.props.android
      ? "/icons/home.svg"
      : this.state.mobileLogoUrl;

    document.documentElement.style.setProperty("--mobile-home-img-right", px);

    return (
      <div className="menu-wrapper" id="menuBar">
        <MetaTags>
          <meta
            name="viewpoint"
            content="width=device-width, user-scalable=no"
          />
        </MetaTags>
        <div className="menu-left-side">
          <HamburgerMenu />
        </div>
        <div className="menu-mobile-home-img">
          <Link to="/">
            <div className="menu-logo-wrapper">
              <img src={homeIcon} alt="Home" height="23"></img>
            </div>
          </Link>
        </div>
        <div className="menu-middle">
          <input
            className="search-box"
            type="search"
            placeholder="Search"
            results="0"
            height="25"
            onKeyDown={this.searchProducts}
          />
        </div>
        <div className="menu-right-side">
          {this.storeLocations()}
          <Link to="/cart">
            <div className="menu-cart-wrapper">
              <div className="menu-cart-icon">
                <img src={cartIcon} alt="Cart" height="23"></img>
              </div>
              <div className="numberCircle">{this.getItemCount()}</div>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="top-wrapper">
        {this.state.mobileMode ? this.mobileLayout() : this.regularLayout()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userInfo: state.user.userInfo,
    itemCount: state.cart.itemCount,
    spItemCount: state.spCart.itemCount,
    locations: state.cache.locations,
    android: state.cache.android,
    newArrivalCount: state.cache.newArrivalCount,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
    kiosk: state.webOrder.kiosk,
    userId: state.user.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchProducts: (query) => {
      dispatch(searchProducts(query));
    },
    setLocations: (locations) => {
      dispatch(setLocations(locations));
    },
    registerWebOrder: (payload) => {
      dispatch(registerWebOrder(payload));
    },
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuBar));
