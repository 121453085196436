import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import UserInfoForm from "../user/UserInfoForm";
import PickupLocations from "./PickupLocations";
import {
  setDeliveryInfo,
  setContactInfo,
  setShippingChoice,
} from "../actions/orderActions";
import { setUserInfo } from "../actions/userActions";
import {
  apiBaseUrl,
  setTopContainerWrapperSettings,
  isMobileMode,
} from "../Util";
import Working from "../Working";
import { isInStore } from "../store/storeUtil";
import InStoreCustomerForm from "../store/InStoreCustomerForm";
import ActionConfirmation from "../ActionConfirmation";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import log from "loglevel";
import "../myLogger";
import "../../tables.css";
import "../../info.css";
import LoginUser from "../user/LoginUser";

class ShippingChoiceEx extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: this.props.match.params.type == "2" ? "Pickup" : null,
      shippingChoices: ["Delivery", "Pickup", "Layaway"],
      userInfo: null,
      hasLayaway: true,
      suggestedAddr: null,
      suggestedAddrMsg: null,
      loginExpanded: false,
      isLoading: true,
      showOoaDialog: false,
    };

    this.handleShippingChange = this.handleShippingChange.bind(this);
    this.adjustWidth = this.adjustWidth.bind(this);
    this.getShippingChoices = this.getShippingChoices.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.checkAddressResponse = this.checkAddressResponse.bind(this);
    this.useSuggestedAddress = this.useSuggestedAddress.bind(this);
    this.addressActionHandler = this.addressActionHandler.bind(this);
    this.gotoNextPage = this.gotoNextPage.bind(this);
    this.expandLogin = this.expandLogin.bind(this);
    this.loginSuccess = this.loginSuccess.bind(this);
  }
  componentDidMount() {
    this.adjustWidth();
    window.addEventListener("resize", this.adjustWidth);
    this.getShippingChoices();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustWidth);
  }
  adjustWidth() {
    setTopContainerWrapperSettings();
  }

  getShippingChoices() {
    const url = apiBaseUrl() + "ShippingChoices";
    const req = {
      userId: this.props.userId,
      id: this.props.storeId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        let choice = this.state.choice;
        let hasLayaway = false;
        if (!choice && res.data && res.data.length > 0) {
          choice = res.data[0];
          hasLayaway = res.data.find((item) => item === "Layaway")
            ? true
            : false;
        }
        this.setState({
          shippingChoices: res.data,
          choice,
          hasLayaway,
          isLoading: false,
        });
        this.props.setShippingChoice(choice);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        log.error(error);
        console.log(error);
      });
  }

  handleShippingChange(event) {
    if (event.target.name === "choice") {
      this.setState({ choice: event.target.value });
      this.props.setShippingChoice(event.target.value);
    }
  }
  gotoNextPage(userInfo) {
    this.props.setUserInfo(userInfo);
    this.props.setContactInfo(userInfo);
    this.props.setShippingChoice(this.state.choice);

    if (this.state.choice === "Layaway") {
      this.props.history.push("/laSummary");
    } else {
      if (this.state.choice === "Delivery") {
        const deliveryInfo = {
          ...userInfo,
          deliveryDistance: null, //theState.deliveryDistance,
          deliveryFee: 0, //theState.deliveryFee,
        };
        this.props.setDeliveryInfo(deliveryInfo);
      } else if (this.state.choice === "Pickup") {
        this.props.setDeliveryInfo(null);
      }
      this.props.history.push("/orderSummary");
    }
  }
  addressActionHandler(userInfo) {
    this.setState({ userInfo });

    if (this.state.choice === "Delivery") {
      this.checkDeliveryAddress(userInfo);
    } else {
      this.gotoNextPage(userInfo);
    }
  }
  openDialog() {
    this.setState({ showOoaDialog: true });
  }
  closeDialog() {
    this.setState({ showOoaDialog: false });
  }

  hiddenButton() {
    return (
      <button
        id="cityWarnBtn"
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  showDialogMessage() {
    const title = this.state.dialogTitle
      ? this.state.dialogTitle
      : "Out Of Delivery Area";
    return (
      <div>
        {this.hiddenButton()}
        <Dialog isOpen={this.state.showOoaDialog} onDismiss={this.closeDialog}>
          <b>{title}</b>
          <p>{this.state.outOfAreaMessage}</p>
          <a href="/location/103">FWL Warehouse</a>
          <p />
          <button onClick={this.closeDialog} className="btn-style">
            Okay
          </button>
        </Dialog>
      </div>
    );
  }
  getOrderSubtotal() {
    let subtotal = this.props.subtotal;
    const storeItems = this.props.storeItems;
    const discount = this.props.discount;

    if (isInStore() && storeItems && storeItems.length > 0) {
      for (let i = 0; i < storeItems.length; i++) {
        if (!storeItems[i].coupon) {
          subtotal += storeItems[i].quantity * storeItems[i].price;
        }
      }
    }

    if (discount) subtotal = subtotal - discount.amount;
    return subtotal;
  }
  checkDeliveryAddress(address) {
    const url = apiBaseUrl() + "CheckDeliveryCity";
    const req = {
      address,
      orderSubtotal: this.getOrderSubtotal(),
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.checkAddressResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  checkAddressResponse(res) {
    console.log(res);
    if (res.status === true) {
      const userInfo = {
        ...this.state.userInfo,
        deliveryDistance: res.distance,
        deliveryFee: res.deliveryFee,
      };
      this.gotoNextPage(userInfo);
    } else {
      if (res.correctAddress) {
        const suggestedAddr = res.correctAddress;
        const str =
          suggestedAddr.addressLine1 +
          "<br/>" +
          suggestedAddr.city +
          ", " +
          suggestedAddr.state +
          " " +
          suggestedAddr.zipCode;
        const suggestedAddrMsg =
          "We are not able to deliver to the address you provided. " +
          "However, we found the deliverable address below:<p/>" +
          str +
          "<p/>Click Yes if you would like to use this address instead.";
        this.setState({
          suggestedAddr,
          suggestedAddrMsg,
        });
        document.getElementById("hiddenConfirmAddrBtn").click();
      }

      this.setState({
        outOfAreaMessage: res.outOfAreaMessage,
        errMessage: res.errorMessage,
        dialogTitle: res.dialogTitle,
      });

      if (!res.correctAddress) {
        document.getElementById("cityWarnBtn").click();
      }
    }
  }
  useSuggestedAddress() {
    const addr = this.state.suggestedAddr;
    if (addr) {
      const userInfo = {
        ...this.state.userInfo,
        addressLine1: addr.addressLine1,
        city: addr.city,
        state: addr.state,
        zipCode: addr.zipCode,
        deliveryDistance: addr.deliveryDistance,
        deliveryFee: addr.deliveryFee,
      };
      this.gotoNextPage(userInfo);
    }
  }
  addressAndStoreInfo() {
    const cfTitle =
      this.state.choice === "Delivery"
        ? "Delivery Address"
        : "Contact Information";
    const addressType =
      this.state.choice === "Delivery" ? "Delivery" : "Contact";
    const noteTitle =
      this.state.choice === "Delivery"
        ? "Delivery Instruction"
        : this.state.choice + " Note";

    const autoCompleteAddress = this.state.choice === "Delivery";
    let requiredFields = ["firstName", "lastName", "phone", "email"];
    if (this.state.choice != "Pickup") {
      requiredFields = requiredFields.concat([
        "addressLine1",
        "city",
        "state",
        "zipCode",
      ]);
    }
    let userInfo = this.props.userInfo;
    const clsName = isMobileMode() ? "none" : "generic-flex";
    if (this.state.choice === "Delivery" && this.props.deliveryInfo) {
      const info = this.props.deliveryInfo;
      if (info.firstName && info.lastName && info.addressLine1) {
        userInfo = info;
      }
    }
    return (
      <div className={clsName}>
        <div>
          {isInStore() ? (
            <InStoreCustomerForm
              addressType={addressType}
              title={cfTitle}
              nextUrl="/orderSummary"
            />
          ) : (
            <UserInfoForm
              addressType={addressType}
              title={cfTitle}
              actionHandler={this.addressActionHandler}
              noteTitle={noteTitle}
              userInfo={userInfo}
              autoCompleteAddress={autoCompleteAddress}
              requiredFields={requiredFields}
              showAddress={true}
            />
          )}
        </div>
        {this.state.choice === "Pickup" && (
          <React.Fragment>
            {!isMobileMode() && <div className="info-vr-style"></div>}
            <div>
              <PickupLocations />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
  showMessage() {
    if (this.state.hasLayaway) {
      return (
        <div align="left" className="ex1">
          We can deliver the items to your home, or you can pick them up from
          one of our stores. If you plan to do layaway, you can decide
          delivery/pickup at a later time.
        </div>
      );
    } else {
      return (
        <div align="left" className="ex1">
          We can deliver the items to your home, or you can pick them up from
          one of our stores. Please confirm with our stores before you go pick
          up as the availability of these items may change.
        </div>
      );
    }
  }
  expandLogin() {
    this.setState({ loginExpanded: true });
  }
  loginSuccess(contactAddr) {
    this.setState({ userInfo: contactAddr });
  }
  showLoginLink() {
    if (!this.props.isLoggedIn && !this.props.userInfo) {
      if (this.state.loginExpanded) {
        return (
          <LoginUser
            embedded={true}
            actionHandler={this.loginSuccess}
            hideResetCreate={true}
          />
        );
      } else {
        return (
          <div>
            Already registered?{" "}
            <a href="#" onClick={this.expandLogin}>
              Login
            </a>
          </div>
        );
      }
    }
  }
  render() {
    if (this.state.isLoading) return <Working />;

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Shipping Choice</font>
          <p />
          {this.showMessage()}
          <p />
          <div className="tbl-container4">
            {this.state.shippingChoices.map((choice, idx) => {
              return (
                <div className="tbl-item-left" key={idx}>
                  <input
                    type="radio"
                    name="choice"
                    value={choice}
                    checked={this.state.choice === choice}
                    onChange={this.handleShippingChange}
                  ></input>
                  {choice}
                </div>
              );
            })}

            {this.state.choice === "Pickup" && (
              <div className="tbl-item-left">
                (
                {this.props.pickupLeadTime ? (
                  <font color="orange">{this.props.pickupLeadTime}</font>
                ) : (
                  <label>Select a Store</label>
                )}
                )
              </div>
            )}
          </div>
          <hr width={this.state.maxWidth} align="left"></hr>
          {this.showLoginLink()}
          {this.addressAndStoreInfo()}
          <ActionConfirmation
            hidden={true}
            btnId="hiddenConfirmAddrBtn"
            actionHandler={this.usesuggestedAddress}
            buttonTitle="Hidden"
            buttonClsName="btn-style"
            title="Use Adddress Below"
            message={this.state.suggestedAddrMsg}
            html={true}
          />
        </div>
        {this.showDialogMessage()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userInfo: state.user.userInfo,
    isLoggedIn: state.user.isLoggedIn,
    pickupLeadTime: state.order.pickupLeadTime,
    autoCompleteAddress: state.cache.autoCompleteAddress,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
    subtotal: state.cart.subtotal,
    storeItems: state.spCart.storeItems,
    discount: state.cart.discount,
    contactInfo: state.order.contactInfo,
    deliveryInfo: state.order.deliveryInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setShippingChoice: (choice) => {
      dispatch(setShippingChoice(choice));
    },
    setDeliveryInfo: (info) => {
      dispatch(setDeliveryInfo(info));
    },
    setContactInfo: (info) => {
      dispatch(setContactInfo(info));
    },
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingChoiceEx);
