import {
  SET_PRODUCTS,
  SET_CATEGORIES,
  SET_HOMES,
  SET_DEBUG_INFO,
  SET_LOCATIONS,
  CLEAR_CACHE,
  SET_VERSION,
} from "./actionTypes";

export const setProducts = (category, products) => {
  return {
    type: SET_PRODUCTS,
    category: category,
    products: products,
  };
};

export const setCategories = (categories, useCatImage) => {
  return {
    type: SET_CATEGORIES,
    categories: categories,
    useCatImage: useCatImage,
  };
};

export const setHomes = (payload) => {
  return {
    type: SET_HOMES,
    payload: payload,
  };
};

export const setDebugInfo = (payload) => {
  return {
    type: SET_DEBUG_INFO,
    payload: payload,
  };
};
export const setLocations = (locations) => {
  return {
    type: SET_LOCATIONS,
    locations: locations,
  };
};
export const setDebug = () => {
  return {
    type: "DEBUG",
  };
};
export const clearCache = () => {
  return {
    type: CLEAR_CACHE,
  };
};
export const setVersion = (ver) => {
  return {
    type: SET_VERSION,
    version: ver,
  };
};
