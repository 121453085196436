import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, setTopContainerWrapperSettings } from "../Util";
import StoreLocation from "../store/StoreLocation";
import {
  ORDERSTAGE_SUBMITTED,
  ORDERSTAGE_COMPLETE,
} from "../order/orderStages";
import { setOrderStage } from "../actions/orderActions";
import log from "loglevel";
import "../myLogger";

import "../../pickupInstr.css";
import "../../tables.css";

class PickupInstruction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickupLocation: null,
      isLoading: true,
      allLocations: null,
      isLoadingAllLocs: true,
    };
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  processResponse(loc) {
    this.setState({ pickupLocation: loc, isLoading: false });
    this.props.setOrderStage(ORDERSTAGE_COMPLETE);

    const url = apiBaseUrl() + "AllPickupLoc";
    const req = {
      userId: this.props.userId,
      id: this.props.storeId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ allLocations: res.data, isLoadingAllLocs: false });
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }

  componentDidMount() {
    if (
      this.props.orderStage &&
      this.props.orderStage >= ORDERSTAGE_SUBMITTED
    ) {
      const url = apiBaseUrl() + "LocationInfo/" + this.props.pickupLocId;
      this.adjustSettings();
      window.addEventListener("resize", this.adjustSettings);
      axiosRetry(axios, { retries: 3 });
      axios
        .get(url, { headers: AXIOS_HEADER })
        .then((res) => {
          this.processResponse(res.data);
        })
        .catch((error) => {
          log.error(error);
          console.log(error);
        });
    } else {
      this.props.history.push("/");
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
  }

  displayPickupStoreInfo(loc) {
    const leadTime = loc.info.warehouse
      ? ""
      : ", the lead time is " + loc.info.pickupLeadTime;

    return (
      <div className="pickup-container">
        <div className="pickup-wrapper">
          <div className="pickup-desc" align="left">
            <b>
              <font size="4" color="green">
                Thank You
              </font>
            </b>
            <p />
            We have chosen to pickup your items at our <b>{loc.name}</b> store
            <b>{leadTime}</b>. Below is the store location info and operation
            hours. Please call the store at your earliest convenient time as the
            store needs to prepare for your items and item availability may
            change.
          </div>
        </div>
        <p />
        <StoreLocation locationId={loc.id} />
      </div>
    );
  }

  displayOneLoc(loc) {
    const url = "/location/" + loc.id;

    return (
      <div className="tbl-item-left">
        <div className="pickup-store-item">
          <Link to={url}>{loc.info.description}</Link>
        </div>
        <div className="pickup-store-item2">
          {loc.info.phone}
          <br />
          {loc.info.phone2}
        </div>
      </div>
    );
  }

  storeLocations() {
    if (this.state.isLoadingAllLocs) return "Fetching ...";

    return (
      <div className="tbl-container2">
        {this.state.allLocations.map((loc) => {
          return this.displayOneLoc(loc);
        })}
      </div>
    );
  }

  displayInstruction() {
    if (this.state.pickupLocation) {
      return this.displayPickupStoreInfo(this.state.pickupLocation);
    } else {
      if (this.state.allLocations) {
        return (
          <div className="pickup-container">
            <div className="pickup-wrapper">
              <div className="pickup-desc" align="left">
                <b>
                  <font size="4" color="green">
                    Thank You
                  </font>
                </b>
                <p />
                Below is the list of stores that you can pickup your items.
                Please call the store at your earliest convenient time as the
                store needs to prepare for your items and item availability may
                change.
              </div>
            </div>
            {this.storeLocations()}
          </div>
        );
      }
    }
  }

  render() {
    if (this.state.isLoading) return "Loading ...";

    return <div className="top-wrapper">{this.displayInstruction()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    pickupLocId: state.order.pickupLocId,
    orderStage: state.order.stage,
    userId: state.user.userId,
    storeId: state.webOrder.storeId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOrderStage: (stage) => {
      dispatch(setOrderStage(stage));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PickupInstruction);
