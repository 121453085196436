import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Lightbox } from "react-modal-image";
import { addToCart, removeItem } from "../actions/cartActions";
import { setUserId } from "../actions/userActions";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  fullProductImageUrl,
  generateGuid,
  isMobileMode,
  setTopContainerWrapperSettings,
  getGroupWrapperWidth,
  FormatCurrency,
  getSoldoutIcon,
} from "../Util";
import Working from "../Working";
import ProductListView from "./ProductListView";
import log from "loglevel";
import "../myLogger";
import "../../App.css";
import "../../navBar.css";
import "../../product.css";
import "../../generic.css";
import "../../combo.css";

class ComboProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comboName: null,
      finalPrice: 0,
      description: null,
      groupList: null,
      imageUrl: null,
      productId: 0,
      comboImgWidth: 100,
      imageWidth: 200,
      soldOut: false,
      mobileMode: false,
      modalImageUrl: null,
      isLoading: true,
      errorMessage: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.adjustWidth = this.adjustWidth.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.selectGroupItem = this.selectGroupItem.bind(this);
    this.showModalImage = this.showModalImage.bind(this);
    this.closeModalImage = this.closeModalImage.bind(this);
  }

  componentDidMount() {
    this.adjustWidth();
    window.addEventListener("resize", this.adjustWidth);
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustWidth);
  }

  adjustWidth() {
    let imageWidth = (getGroupWrapperWidth() * 35) / 100;
    let comboImgWidth = getGroupWrapperWidth() / 4;

    if (isMobileMode()) {
      if (this.props.android) {
        imageWidth -= 10;
        comboImgWidth += 20;
      } else comboImgWidth += 5;
    }
    this.setState({ imageWidth, comboImgWidth, mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  showModalImage(imgUrl) {
    this.setState({ modalImageUrl: imgUrl });
  }
  closeModalImage() {
    this.setState({ modalImageUrl: null });
  }
  processResponse(response) {
    if (response) {
      const imageUrl = response.product ? response.product.imageUrl : null;
      let soldOut = false;
      if (response.soldOut) {
        soldOut = true;
      } else {
        if (response.groupList && response.groupList.length > 0) {
          let i;
          for (i = 0; i < response.groupList.length; i++) {
            if (response.groupList[i].itemIndex < 0) {
              soldOut = true;
            }
          }
        }
      }

      this.setState({
        comboName: response.comboName,
        finalPrice: response.finalPrice,
        description: response.description,
        groupList: response.groupList,
        productId: response.product.productId,
        imageUrl,
        soldOut,
        isLoading: false,
      });
    } else {
      this.props.history.push("/");
    }
  }

  fetchData() {
    const url = apiBaseUrl() + "GetCombo";
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    const req = {
      userId: userId,
      value: this.props.match.params.comboId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get Combo",
        });
      });
  }
  handleClick(event) {
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    let i;
    for (i = 0; i < this.state.groupList.length; i++) {
      const group = this.state.groupList[i];
      const itemIdx = group.itemIndex ? group.itemIndex : 0;
      const comboItem = group.itemList[itemIdx];
      if (comboItem) {
        const optionName =
          comboItem.product.pvList.length > 1
            ? comboItem.prodVar.optionValue
            : null;
        const cartItem = {
          sku: comboItem.sku,
          item: comboItem.product,
          price: comboItem.prodVar.finalPrice,
          name: comboItem.product.name,
          optionName,
        };
        this.props.addToCart(userId, cartItem);
      }
    }
    this.props.history.push("/cart");
  }

  handleChange(event) {
    const len = "Group_".length;
    const grpIdx = parseInt(event.target.name.substring(len));
    const itemIndex = parseInt(event.target.value);
    this.selectGroupItem(grpIdx, itemIndex);
  }

  selectGroupItem(grpIdx, itemIndex) {
    let groupList = [];
    let i;

    for (i = 0; i < this.state.groupList.length; i++) {
      const grp = this.state.groupList[i];
      if (grpIdx === i) {
        const mGrp = {
          ...grp,
          itemIndex,
        };
        groupList.push(mGrp);
      } else {
        groupList.push(grp);
      }
    }
    this.setState({ groupList });
  }
  showGroup(group, grpIdx) {
    if (group && group.itemList && group.itemList.length > 0) {
      const soldOut = group.itemIndex < 0;
      const itemIdx =
        group.itemIndex && group.itemIndex >= 0 ? group.itemIndex : 0;
      const selItem = group.itemList[itemIdx]; //selected item
      const imgUrl = selItem.prodVar.imageUrl
        ? selItem.prodVar.imageUrl
        : selItem.product.imageUrl;
      const desc = selItem.prodVar.description
        ? selItem.prodVar.description
        : selItem.product.description;
      let name = selItem.product.name;
      if (selItem.product.pvList.length > 1) {
        name = name + " - " + selItem.prodVar.variantValue;
      }
      const height = (this.state.imageWidth * 3) / 4;
      const sku = selItem.prodVar.sku;
      const bigImgId = soldOut ? "soldOutImage" : "regular";
      const imgWidth = this.state.mobileMode && this.props.android ? 49 : 60;
      const imgHeight = this.state.mobileMode && this.props.android ? 38 : 45;

      return (
        <div>
          {this.state.mobileMode && <b>{group.groupName}</b>}
          <div className="generic-flex">
            <div>
              <img
                id={bigImgId}
                src={fullProductImageUrl(imgUrl)}
                width={this.state.imageWidth}
                height={height}
                onClick={() => this.showModalImage(imgUrl)}
                alt="Combo"
              ></img>
            </div>
            <div className="left-10">
              {!this.state.mobileMode && (
                <div>
                  <b>{group.groupName} (Click an image below to select)</b>
                </div>
              )}
              <div className="combo-image-wrapper">
                {group.itemList.map((item, idx) => {
                  let img = item.prodVar.imageUrl
                    ? item.prodVar.imageUrl
                    : item.product.imageUrl;
                  const selectable = idx !== itemIdx && item.prodVar.forSale;

                  if (
                    img === item.product.imageUrl &&
                    item.product.thumbnailImage
                  ) {
                    img = item.product.thumbnailImage;
                  }
                  if (selectable) {
                    return (
                      <div className="brightness">
                        <img
                          src={fullProductImageUrl(img)}
                          width={imgWidth}
                          height={imgHeight}
                          onClick={() => this.selectGroupItem(grpIdx, idx)}
                          alt="Image"
                        ></img>
                      </div>
                    );
                  } else {
                    let imgId = idx === itemIdx ? "regular" : "soldOutImage";
                    if (soldOut) imgId = "soldOutImage";
                    const clsName =
                      idx === itemIdx && item.prodVar.forSale
                        ? "right-5_sel"
                        : "right-5";
                    return (
                      <div className={clsName} key={idx}>
                        <img
                          id={imgId}
                          src={fullProductImageUrl(img)}
                          width={imgWidth}
                          height={imgHeight}
                          alt="Image"
                        ></img>
                      </div>
                    );
                  }
                })}
              </div>
              {selItem.prodVar.forSale ? (
                <React.Fragment>
                  <div>
                    <font color="green">Selected SKU:</font>&nbsp;{sku}
                  </div>
                  {!this.state.mobileMode && (
                    <React.Fragment>
                      <p />
                      <i>{name}</i>
                    </React.Fragment>
                  )}
                  <p />
                  <div dangerouslySetInnerHTML={{ __html: desc }}></div>
                </React.Fragment>
              ) : (
                <div>
                  <p />
                  This group is <font color="red">Sold Out</font>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  regularHeader() {
    return (
      <div className="generic-flex">
        {this.state.imageUrl && (
          <div>
            <img
              src={fullProductImageUrl(this.state.imageUrl)}
              width={this.state.comboImgWidth}
              alt="ComboImage"
              onClick={() => this.showModalImage(this.state.imageUrl)}
            ></img>
          </div>
        )}
        <div className="left-15">
          <b>
            <font size="5">{this.state.comboName}</font>
          </b>
          <div
            dangerouslySetInnerHTML={{ __html: this.state.description }}
          ></div>
          <div>
            <b>
              Sale Price:{" "}
              <font color="red">
                <FormatCurrency amount={this.state.finalPrice}></FormatCurrency>
              </font>
            </b>
          </div>
          {this.state.soldOut && (
            <div>
              <img src={getSoldoutIcon()} height="70" alt="Sold"></img>
              <br />
              This product is temporarily sold out, please check back later.
            </div>
          )}
        </div>
      </div>
    );
  }
  mobileHeader() {
    return (
      <div>
        <div className="combo-title">
          <b>
            <font size="5">{this.state.comboName}</font>
          </b>
        </div>
        <div>
          {this.state.imageUrl && (
            <div>
              <img
                src={fullProductImageUrl(this.state.imageUrl)}
                width={this.state.comboImgWidth}
                alt="ComboImage"
                className="TextWrap"
                onClick={() => this.showModalImage(this.state.imageUrl)}
              ></img>
            </div>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: this.state.description }}
          ></div>
        </div>
        <div>
          <b>
            Sale Price:{" "}
            <font color="red">
              <FormatCurrency amount={this.state.finalPrice}></FormatCurrency>
            </font>
          </b>
        </div>
        {this.state.soldOut && (
          <div>
            <img src={getSoldoutIcon()} height="70" alt="Sold"></img>
            <br />
            This product is temporarily sold out, please check back later.
          </div>
        )}
      </div>
    );
  }
  showCombo() {
    if (this.state.comboName) {
      const backendApi = "RelatedProd/" + this.state.productId;
      const btnStyle = this.state.soldOut ? "disabled-btn-style" : "btn-style";
      const alt = this.state.mobileMode
        ? "Double tap to see larger picture"
        : "Double click to see larger picture";
      return (
        <div className="prod-wrapper">
          {this.state.mobileMode ? this.mobileHeader() : this.regularHeader()}
          <br />
          <div>
            {this.state.groupList.map((group, grpIdx) => {
              return this.showGroup(group, grpIdx);
            })}
          </div>
          <p />
          <div>
            <div className="generic-flex">
              <div>
                <button
                  name="addToCart"
                  className={btnStyle}
                  onClick={this.handleClick}
                  disabled={this.state.soldOut}
                >
                  Add to Cart
                </button>
              </div>
              <div className="left-10"></div>
              {this.selectedSmallImages()}
            </div>
            <p />
            <hr className="width400" align="left" />
            <ProductListView
              backendApi={backendApi}
              title="Product related to this item"
            />
          </div>
          {this.state.modalImageUrl && (
            <Lightbox
              large={fullProductImageUrl(this.state.modalImageUrl)}
              alt={alt}
              hideDownload={true}
              hideZoom={true}
              onClose={this.closeModalImage}
              imageBackgroundColor="white"
            />
          )}
        </div>
      );
    }
  }
  selectedSmallImages() {
    return (
      <div className="generic-flex">
        {this.state.groupList.map((group, grpIdx) => {
          const itemIdx =
            group.itemIndex && group.itemIndex >= 0 ? group.itemIndex : 0;
          const selItem = group.itemList[itemIdx]; //selected item
          const imgUrl = selItem.prodVar.imageUrl
            ? selItem.prodVar.imageUrl
            : selItem.product.imageUrl;
          return (
            <React.Fragment>
              {grpIdx > 0 && <div className="plus-div">+</div>}
              <div>
                <img
                  src={fullProductImageUrl(imgUrl)}
                  width="40"
                  height="30"
                  alt="Thumbnail"
                ></img>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working />;

    return <div className="top-wrapper">{this.showCombo()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.addedItems,
    userId: state.user.userId,
    android: state.cache.android,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (userId, payload) => {
      dispatch(addToCart(userId, payload));
    },
    removeFromCart: (sku) => {
      dispatch(removeItem(sku));
    },
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComboProduct);
