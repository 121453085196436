import React from "react";
import ModalImage from "react-modal-image";
import { Lightbox } from "react-modal-image";
import { fullProductImageUrl, isMobileMode } from "../Util";
import Slider from "react-slick";
import "../../App.css";
import "../../product.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ProductImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageList: null,
      imageWidth: 0,
      modalImgUrl: null,
      displayName: null,
      showModalImage: true,
    };
    this.initState = this.initState.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
  }
  componentDidMount() {
    this.initState();
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.imageList !== prevProps.imageList ||
      this.props.imageWidth !== prevProps.imageWidth ||
      this.props.displayName !== prevProps.displayName ||
      this.props.showModalImage !== prevProps.showModalImage
    ) {
      this.initState();
    }
  }
  initState() {
    this.setState({
      imageList: this.props.imageList,
      imageWidth: this.props.imageWidth,
      displayName: this.props.displayName || "Image",
      showModalImage: this.props.showModalImage,
      modalImgUrl: null,
    });
  }
  handleClick(imgUrl) {
    if (this.state.showModalImage) {
      this.setState({ modalImgUrl: imgUrl });
    }
  }
  closeLightbox() {
    this.setState({ modalImgUrl: null });
  }
  isPNG(img) {
    if (img && img.toLowerCase().endsWith(".png")) {
      return true;
    } else return false;
  }
  showImage() {
    const imgList = this.state.imageList;
    const imgWidth = this.state.imageWidth;

    if (!imgList || imgList.length === 0) return "";

    const alt = isMobileMode()
      ? "Double tap to see full size"
      : "Double click to see full size";

    if (this.state.modalImgUrl) {
      const modalImgUrl = this.state.modalImgUrl;
      if (this.isPNG(modalImgUrl)) {
        return (
          <Lightbox
            large={fullProductImageUrl(modalImgUrl)}
            alt={alt}
            hideDownload={true}
            hideZoom={true}
            style={{ maxWidth: this.state.imageWidth }}
            onClose={this.closeLightbox}
            imageBackgroundColor="white"
          />
        );
      } else {
        return (
          <Lightbox
            large={fullProductImageUrl(modalImgUrl)}
            alt={alt}
            hideDownload={true}
            hideZoom={true}
            style={{ maxWidth: this.state.imageWidth }}
            onClose={this.closeLightbox}
          />
        );
      }
    }

    if (imgList && imgList.length > 1) {
      const settings = {
        dots: true,
        adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: true,
      };

      return (
        <Slider {...settings} style={{ width: imgWidth }}>
          {imgList.map((imgUrl) => (
            <div onClick={() => this.handleClick(imgUrl)}>
              <img
                src={fullProductImageUrl(imgUrl)}
                width={imgWidth}
                alt={this.state.displayName}
              />
            </div>
          ))}
        </Slider>
      );
    } else {
      const imgUrl = imgList[0];

      if (this.state.showModalImage) {
        document.documentElement.style.setProperty(
          "--modal-image-width",
          "" + this.state.imageWidth + "px"
        );
        if (this.isPNG(imgUrl)) {
          return (
            <ModalImage
              className="modal-image"
              small={fullProductImageUrl(imgUrl)}
              large={fullProductImageUrl(imgUrl)}
              alt={alt}
              hideDownload={true}
              hideZoom={true}
              imageBackgroundColor="white"
            />
          );
        } else {
          return (
            <ModalImage
              className="modal-image"
              small={fullProductImageUrl(imgUrl)}
              large={fullProductImageUrl(imgUrl)}
              alt={alt}
              hideDownload={true}
              hideZoom={true}
            />
          );
        }
      } else {
        return (
          <img
            src={fullProductImageUrl(imgUrl)}
            width={this.state.imageWidth}
            alt={this.state.displayName}
          ></img>
        );
      }
    }
  }

  render() {
    if (this.state.imageList) {
      return this.showImage();
    } else return "";
  }
}
export default ProductImage;
