import React from "react";
import { connect } from "react-redux";
import { setLeaseOkInfo } from "../actions/orderActions";
import { setTopContainerWrapperSettings } from "../Util";
import Working from "../Working";
import ProductListView from "../product/ProductListView";
import PrintOrder from "../store/PrintOrder";
import { isInStore } from "../store/storeUtil";
import "../../tables.css";
import "../../orderComplete.css";

class LeaseOrderComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leaseOkInfo: null,
    };

    this.adjustSettings = this.adjustSettings.bind(this);
    this.checkInfo = this.checkInfo.bind(this);
    this.setStatesAndProps = this.setStatesAndProps.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  componentDidMount() {
    if (!this.props.leaseOkInfo) {
      setTimeout(this.checkInfo, 5000);
    } else {
      this.setStatesAndProps();
    }
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  checkInfo() {
    if (!this.props.leaseOkInfo) {
      this.props.history.push("/");
    } else {
      this.setStatesAndProps();
    }
  }
  setStatesAndProps() {
    const leaseOkInfo = this.props.leaseOkInfo;

    this.setState({ leaseOkInfo });
    this.props.setLeaseOkInfo(null);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
  }
  goHomePage() {
    this.props.history.push("/");
  }
  displayMsgForClerk() {
    return (
      <div className="oc-container">
        <div className="oc-paid-wrapper">
          <div className="oc-paid-desc" align="left">
            <b>
              <font size="4" color="green">
                Order Successfully Submitted
              </font>
            </b>
            <p />
            <div>
              Order number: <b>{this.state.leaseOkInfo.orderNumber}</b>.
              <p />
            </div>
            <div>
              The lease order was successfully placed.
              {this.state.leaseOkInfo.email && (
                <label>
                  &nbsp;&nbsp;A confirmation email has been sent to{" "}
                  {this.state.leaseOkInfo.email}, please ask the customer to
                  check email to make sure everything is correct.
                  <p />
                </label>
              )}
            </div>
            <PrintOrder orderNumber={this.state.leaseOkInfo.orderNumber} />
          </div>
        </div>
        <p />
        <button className="order-done-btn" onClick={this.goHomePage}>
          Done
        </button>
      </div>
    );
  }
  displayMsgForCustomer() {
    return (
      <div className="oc-container">
        <div className="oc-paid-wrapper">
          <div className="oc-paid-desc" align="left">
            <b>
              <font size="4" color="green">
                Order Successfully Submitted
              </font>
            </b>
            <p />
            <div>
              Order number: <b>{this.state.leaseOkInfo.orderNumber}</b>.
              <p />
            </div>
            <div>
              Your lease order was successfully placed.
              {this.state.leaseOkInfo.email && (
                <label>
                  A confirmation email has been sent to{" "}
                  {this.state.leaseOkInfo.email}, please check your email to
                  make sure everything is correct.
                  <p />
                </label>
              )}
            </div>
          </div>
        </div>
        <p />
        <button className="order-done-btn" onClick={this.goHomePage}>
          Done
        </button>
      </div>
    );
  }

  render() {
    if (!this.state.leaseOkInfo) return <Working />;

    if (isInStore()) {
      return <div className="top-wrapper">{this.displayMsgForClerk()}</div>;
    } else {
      const backendApi =
        "OrderRelatedProd/" + this.state.leaseOkInfo.orderNumber;
      const title = "Other Items You Might Be Interested";

      return (
        <div className="top-wrapper">
          {this.displayMsgForCustomer()}
          <p />
          <ProductListView backendApi={backendApi} title={title} />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    leaseOkInfo: state.order.leaseOkInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLeaseOkInfo: (info) => {
      dispatch(setLeaseOkInfo(info));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaseOrderComplete);
