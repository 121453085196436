import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import ProductListView from "./ProductListView";
import BuyTogether from "./BuyTogether";
import { addToCart, removeItem } from "../actions/cartActions";
import { setUserId, setUserInfo } from "../actions/userActions";
import Waitlist from "./Waitlist";
import { AXIOS_HEADER } from "../config/constants";
import VariantDisplay from "./VariantDisplay";
import ProductImage from "./ProductImage";
import { Helmet } from "react-helmet";
import {
  FormatCurrency,
  apiBaseUrl,
  generateGuid,
  priceColor,
  isMobileMode,
  showPickupDeliveryTime,
  priceRange,
  setTopContainerWrapperSettings,
  getGroupWrapperWidth,
  getSoldoutIcon,
  getIconBaseUrl,
  manualBaseUrl,
  fullProductImageUrl,
} from "../Util";
import Working from "../Working";
import log from "loglevel";
import "../myLogger";
import "../../App.css";
import "../../navBar.css";
import "../../product.css";
import "../../generic.css";

const MODE_NAME_WITH_PRICE = 4;

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sku: this.props.match.params.sku,
      name: null,
      displayName: null,
      optionName: null,
      description: null,
      imageUrl: null,
      price: null,
      waitlistKeys: null,
      item: null,
      pvIndex: 0,
      prodVar: null,
      imageWidth: 0,
      maxImageWidth: 600,
      thumbnailWidth: 85,
      showModalImage: true,
      showKeywords: false,
      pickupAvail: "Same day",
      deliveryAvail: "Next day",
      isLoading: true,
      errorMessage: null,
      itemAdded: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.showDetail = this.showDetail.bind(this);
    this.updateImageWidth = this.updateImageWidth.bind(this);
    this.adjustScreenSize = this.adjustScreenSize.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.setAvailability = this.setAvailability.bind(this);
    this.selectVariant = this.selectVariant.bind(this);
    this.addToWaitlist = this.addToWaitlist.bind(this);
    this.doAddWaitlist = this.doAddWaitlist.bind(this);
    this.processWLResponse = this.processWLResponse.bind(this);
    this.notificationMessage = this.notificationMessage.bind(this);
  }

  processResponse(data) {
    if (data) {
      if (
        data.product &&
        data.product.comboId &&
        data.product.comboId.length > 1 &&
        data.product.comboExists
      ) {
        const url = "/combo/" + data.product.comboId;
        this.props.history.push(url);
        return;
      }

      const idx = data.pvIndex;
      const product = data.product;
      const optionName = data.variantName;
      const prodVar = product.pvList.length > 0 ? product.pvList[idx] : null;
      const displayName = this.getDisplayName(product, idx);
      const imageUrl =
        prodVar && prodVar.imageUrl ? prodVar.imageUrl : product.imageUrl;
      this.setState({
        waitlistKeys: data.waitlistKeys,
        item: product,
        sku: prodVar.sku,
        imageUrl,
        price: prodVar.finalPrice,
        name: product.name,
        displayName,
        optionName,
        description: product.description,
        pvIndex: idx,
        isLoading: false,
        errorMessage: null,
        showModalImage: data.showModalImage,
        showKeywords: data.showKeywords,
        prodVar,
      });
      this.updateImageWidth(prodVar);
      this.setAvailability(data.product, 0);
    } else {
      this.setState({ isLoading: false, errorMessage: "Invalid product ID" });
    }
  }
  getDisplayName(product, skuIdx) {
    const pv = product.pvList.length > 0 ? product.pvList[skuIdx] : null;
    let displayName = product.name;

    if (pv) {
      if (pv.symLink) displayName = pv.variantValue;
      else {
        if (product.pvList.length > 1) {
          if (pv.fullName && pv.fullName.length > 0) {
            displayName = pv.fullName;
          } else {
            displayName += " - " + pv.variantValue;
          }
        }
      }
    }
    return displayName;
  }

  setAvailability(item, pvIdx) {
    let pickupAvail = "Same day";
    let deliveryAvail = "Next day";

    if (item.pvList[pvIdx].inStockTime && item.pvList[pvIdx].inStockTime > 0) {
      if (item.pvList[pvIdx].inStockTime === 1) {
        pickupAvail = "Next day";
        deliveryAvail = "In two days";
      } else {
        pickupAvail = "In " + item.pvList[pvIdx].inStockTime + " days";
        deliveryAvail = "In " + (item.pvList[pvIdx].inStockTime + 1) + " days";
      }
    }
    this.setState({ pickupAvail: pickupAvail, deliveryAvail: deliveryAvail });
  }

  fetchData() {
    const url = apiBaseUrl() + "ProductDetail";
    let sku = null;
    let userId = this.props.userId;

    if (this.props.match.params.sku !== undefined) {
      sku = this.props.match.params.sku;
    }
    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    const req = {
      userId: userId,
      productId: this.props.match.params.id,
      sku: sku,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        log.error(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get product detail",
        });
        this.props.history.push("/");
      });
  }
  addToWaitlist() {
    if (this.props.userInfo) {
      const info = this.props.userInfo;
      const name = info.firstName + " " + info.lastName;
      const email = info.email;

      if (
        email &&
        email.length > 0 &&
        email.indexOf("@") > 0 &&
        email.indexOf(".") > 0
      ) {
        this.doAddWaitlist(name, email);
        return;
      }
    }
    document.getElementById("wlDialog").click();
  }
  popupRegisterWindow() {
    document.getElementById("wlDialog").click();
  }
  processWLResponse(response) {
    if (response) {
      const uniqueKey = this.state.prodVar.uniqueKey;
      let waitlistKeys = this.state.waitlistKeys;

      if (!waitlistKeys) waitlistKeys = [];
      waitlistKeys.push(uniqueKey);
      this.setState({ waitlistKeys });
    }
  }
  doAddWaitlist(name, email, phone) {
    const url = apiBaseUrl() + "AddWaitlist";
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }

    if (!this.props.userInfo) {
      const pos = name.indexOf(" ");
      let firstName = name;
      let lastName = "";
      if (pos > 0) {
        firstName = name.substring(0, pos);
        lastName = name.substring(pos + 1).trim();
      }

      const userInfo = {
        firstName,
        lastName,
        email,
        phone,
      };
      this.props.setUserInfo(userInfo);
    }

    const req = {
      userId: userId,
      uniqueKey: this.state.prodVar.uniqueKey,
      name,
      email,
      phone,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processWLResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidMount() {
    this.adjustScreenSize();
    window.addEventListener("resize", this.adjustScreenSize);
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.match.params.id !== prevProps.match.params.id ||
      this.props.match.params.sku !== prevProps.match.params.sku
    ) {
      this.fetchData();
      this.setState({ itemAdded: false });
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustScreenSize);
  }

  adjustScreenSize() {
    this.updateImageWidth(null);
    setTopContainerWrapperSettings();
  }

  updateImageWidth(pv) {
    const prodVar = pv ? pv : this.state.prodVar;
    let imageInfo = null;
    let imageSetting = this.state.item ? this.state.item.imageSetting : 1;

    if (prodVar && prodVar.imageInfo) {
      imageInfo = prodVar.imageInfo;
    } else if (this.state.item && this.state.item.imageInfo) {
      imageInfo = this.state.item.imageInfo;
    }
    const nativeWidth = imageInfo !== null ? imageInfo.width : 0;

    let imageWidth;
    let thumbnailWidth;
    let cntPerRow = 5;
    if (this.state.item && this.state.item.pvList) {
      if (this.state.item.pvList.length > 5) cntPerRow = 5;
      else cntPerRow = this.state.item.pvList.length;
    }

    if (isMobileMode()) {
      imageWidth = getGroupWrapperWidth();
      thumbnailWidth = (imageWidth - 3 * (cntPerRow - 1)) / cntPerRow;
    } else {
      imageWidth = (getGroupWrapperWidth() * 60) / 100;
      thumbnailWidth =
        (getGroupWrapperWidth() * 0.4 - 3 * (cntPerRow - 1)) / cntPerRow;
    }
    if (thumbnailWidth > 85) thumbnailWidth = 85;
    if (imageSetting === 0) {
      if (nativeWidth > 0 && imageWidth > nativeWidth) {
        imageWidth = nativeWidth;
      }
    }

    this.setState({ imageWidth: imageWidth, thumbnailWidth });
  }

  handleChange(event) {
    const idx = event.target.value;
    this.selectVariant(idx);
  }

  selectVariant(idx) {
    const pv = this.state.item.pvList[idx];
    const displayName = this.getDisplayName(this.state.item, idx);
    let description;
    if (pv.symLink) {
      description = pv.description;
    } else {
      if (pv.useDesc && pv.description && pv.description.length > 0) {
        description = pv.description;
      } else {
        description = this.state.item.description;
      }
    }
    this.setState({
      sku: pv.sku,
      price: pv.finalPrice,
      displayName,
      description,
      optionName: pv.variantValue,
      prodVar: pv,
      itemAdded: false,
    });

    if (pv.imageUrl != null) {
      this.setState({ imageUrl: pv.imageUrl });
    } else {
      this.setState({ imageUrl: this.state.item.imageUrl });
    }
    this.setAvailability(this.state.item, idx);
    this.updateImageWidth(pv);
  }

  handleClick() {
    let userId = this.props.userId;

    if (!userId) {
      userId = generateGuid();
      this.props.setUserId(userId);
    }

    if (this.state.item.comboId) {
      const url = "/combo/" + this.state.item.comboId;
      this.props.history.push(url);
    } else {
      this.props.addToCart(userId, this.state);
      this.setState({ itemAdded: true });
    }
  }
  priceRange() {
    if (this.state.item.lowPriceVar === this.state.item.highPriceVar) return "";
    if (this.state.item.variantDisplay === MODE_NAME_WITH_PRICE) return "";

    return (
      <div>
        Price Range: {priceRange(this.state.item)}
        <p />
      </div>
    );
  }

  variantInfo() {
    if (this.state.item.pvList.length <= 1) return "";
    else {
      return (
        <div>
          <p />
          <VariantDisplay
            product={this.state.item}
            sku={this.props.match.params.sku}
            callbackFunc={this.selectVariant}
          />
        </div>
      );
    }
  }
  isForSale() {
    if (this.state.item) {
      if (this.state.prodVar && this.state.prodVar.forSale === true) {
        return true;
      }

      if (this.state.item.forSale === false) {
        return false;
      } else if (this.state.prodVar && this.state.prodVar.forSale === false) {
        return false;
      }
    }
    return true;
  }
  notificationMessage() {
    const uniqueKey = this.state.prodVar.uniqueKey;
    let icon = getIconBaseUrl();
    const registered =
      this.state.waitlistKeys &&
      this.state.waitlistKeys.find((key) => key === uniqueKey)
        ? true
        : false;
    if (registered) {
      icon += "email2.jpg";
      let name = "";
      let email = "";
      let phone = "";
      if (this.props.userInfo) {
        name =
          this.props.userInfo.firstName + " " + this.props.userInfo.lastName;
        email = this.props.userInfo.email;
        phone = this.props.userInfo.phone;
      }

      return (
        <React.Fragment>
          <a href="#" onClick={this.popupRegisterWindow}>
            <img src={icon} height="14"></img>{" "}
            <b>
              <font color="blue">We will notify you</font>
            </b>
          </a>
          <Waitlist
            actionHandler={this.doAddWaitlist}
            hidden={true}
            btnId="wlDialog"
            sku={this.state.prodVar.sku}
            registered={true}
            name={name}
            email={email}
            phone={phone}
          />
        </React.Fragment>
      );
    } else {
      icon += "email.jpg";
      return (
        <React.Fragment>
          <a href="#" onClick={this.addToWaitlist}>
            <img src={icon} height="14"></img> <b>GET NOTIFIED</b>
          </a>
          <Waitlist
            actionHandler={this.doAddWaitlist}
            hidden={true}
            btnId="wlDialog"
            sku={this.state.prodVar.sku}
            name=""
            email=""
          />
        </React.Fragment>
      );
    }
  }
  miscInfo() {
    if (this.isForSale() === false) {
      return (
        <div>
          <div className="generic-flex-b">
            <div>
              <img src={getSoldoutIcon()} height="65"></img>
            </div>
            <div className="somsg-div">{this.notificationMessage()}</div>
          </div>

          <div>
            This product is temporarily sold out, please check back later.
          </div>
        </div>
      );
    }

    if (showPickupDeliveryTime()) {
      return (
        <div className="avl-wrapper">
          <div className="prod-tbl-item-left">Available for Pickup:</div>
          <div className="prod-tbl-item-left">
            <font color="green">{this.state.pickupAvail}</font>
          </div>
          <div className="prod-tbl-item-left">Available for Delivery:</div>
          <div className="prod-tbl-item-left">
            <font color="green">{this.state.deliveryAvail}</font>
          </div>
        </div>
      );
    }
  }
  bestSellerInfo() {
    if (this.state.prodVar && this.state.prodVar.salesStats) {
      const stats = this.state.prodVar.salesStats;
      let catName = stats.categoryName;
      let overAll = "over all";

      if (stats.showTopProd) {
        const urlBase = "/product-category/BS_";
        catName = <a href={urlBase + stats.categoryId}>{catName}</a>;
        overAll = <a href={urlBase + "0"}>{overAll}</a>;
      }
      return (
        <div className="bs-wrapper">
          <div>
            Best Sellers
            <br />
            Ranking:
          </div>
          <div className="left-10">
            {stats.categoryRanking > 0 && (
              <span>
                #{stats.categoryRanking} in {catName}
                <br />
              </span>
            )}
            {stats.overAllRanking > 0 && (
              <span>
                #{stats.overAllRanking} {overAll}
              </span>
            )}
          </div>
        </div>
      );
    }
  }
  showAssemblyManual() {
    const manualList = this.state.prodVar.installManualList;
    if (manualList && manualList.length > 0) {
      return (
        <div className="generic-flex">
          <div>
            <b>Assembly Manual:</b>{" "}
          </div>
          {manualList.map((info, idx) => {
            const url = manualBaseUrl() + info.url;
            const icon = info.pdf ? getIconBaseUrl() + "pdf.jpg" : url;
            return (
              <div key={idx}>
                &nbsp;&nbsp;
                <a href={url} target="Manual">
                  <img src={icon} width="32" height="24"></img>
                </a>
              </div>
            );
          })}
        </div>
      );
    }
  }

  showImage() {
    const pv = this.state.prodVar;
    let imageList;
    if (pv.imageInfoList && pv.imageInfoList.length > 1) {
      imageList = pv.imageInfoList.map((info) => info.url);
    } else {
      imageList = [pv.imageUrl];
    }
    return (
      <ProductImage
        imageList={imageList}
        imageWidth={this.state.imageWidth}
        displayName={this.state.displayName}
        showModalImage={this.state.showModalImage}
      />
    );
  }
  showItemAddedMessage() {
    if (this.state.itemAdded) {
      const imgUrl = getIconBaseUrl() + "shopping_cart2.png";
      return (
        <div className="generic-flex">
          <div>
            &nbsp;&nbsp;<font color="orange">Item added. </font>
          </div>
          <div>
            {" "}
            <a href="/cart">
              <img src={imgUrl} height="25"></img>
            </a>
          </div>
          <div className="numberCircle">{this.props.itemCount}</div>
        </div>
      );
    }
  }
  showDetail() {
    const pv = this.state.prodVar;
    const backendApi = "RelatedProd/" + this.state.item.productId;
    const btnStyle = this.isForSale() ? "btn-style" : "disabled-btn-style";
    const btnTitle = this.state.item.comboId ? null : "Add to Cart";
    const hasBt = pv && pv.btList && pv.btList.length > 1;
    let belowImage = false,
      belowDesc = false,
      aboveRelatedProd = false;
    if (hasBt) {
      if (isMobileMode()) aboveRelatedProd = true;
      else {
        belowImage = pv.btPosition === 1;
        belowDesc = pv.btPosition === 2;
        aboveRelatedProd = pv.btPosition == 3;
      }
    }
    let desc =
      pv.useDesc && pv.description && pv.description.length > 0
        ? pv.description
        : this.state.description;
    if (pv.dimension && pv.dimension.length > 0) {
      desc += "<p/>Dimension: " + pv.dimension;
    }
    return (
      <div className="prod-wrapper">
        <div className="prod-detail-container">
          <div className="prod-detail-image">
            {this.showImage()}
            {belowImage && <BuyTogether btList={pv.btList} />}
          </div>
          <div className="prod-detail-info" align="left">
            <b>{this.state.displayName} </b>
            <p />
            Sales Price:&nbsp;&nbsp;
            <b>
              <font color={priceColor()}>
                <FormatCurrency amount={this.state.price} />
              </font>
            </b>
            <p />
            <div dangerouslySetInnerHTML={{ __html: desc }}></div>
            <p />
            <b>SKU: </b> {this.state.sku} <br />
            {this.priceRange()}
            {this.variantInfo()}
            <p />
            {btnTitle && this.isForSale() && (
              <div className="generic-flex">
                <div>
                  <button
                    className={btnStyle}
                    key="addToCart"
                    onClick={this.handleClick}
                    disabled={!this.isForSale()}
                  >
                    {btnTitle}
                  </button>
                </div>
                {this.showItemAddedMessage()}
              </div>
            )}
            <p />
            {this.miscInfo()}
            {this.bestSellerInfo()}
            {this.showAssemblyManual()}
            {belowDesc && <BuyTogether btList={pv.btList} />}
          </div>
        </div>
        {aboveRelatedProd && (
          <div>
            <BuyTogether btList={pv.btList} />
          </div>
        )}
        <ProductListView
          backendApi={backendApi}
          title="Product related to this item"
        />
      </div>
    );
  }
  productKeywords() {
    if (
      this.state.showKeywords &&
      this.state.item &&
      this.state.item.keywords
    ) {
      return (
        <Helmet>
          <meta charset="utf-8" />
          <meta name="description" content={this.state.item.name} />
          <meta name="keywords" content={this.state.item.keywords} />
        </Helmet>
      );
    }
  }

  linkPreviewInfo() {
    return (
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content="Furniture Way Less" data-rh="true" />
        <meta
          property="og:description"
          content={this.state.item.name}
          data-rh="true"
        />
        <meta
          property="og:image"
          content={fullProductImageUrl(this.state.prodVar.imageUrl)}
          data-rh="true"
        />
      </Helmet>
    );
  }

  render() {
    if (this.state.isLoading === true) return <Working />;

    if (this.state.item == null) {
      return (
        <div className="prod-detail-info" align="left">
          <font color="red">{this.state.errorMessage}</font>
        </div>
      );
    }

    return (
      <div className="top-wrapper">
        {this.linkPreviewInfo()}
        {this.productKeywords()}
        {this.showDetail()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.addedItems,
    userId: state.user.userId,
    userInfo: state.user.userInfo,
    itemCount: state.cart.itemCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (userId, payload) => {
      dispatch(addToCart(userId, payload));
    },
    removeFromCart: (sku) => {
      dispatch(removeItem(sku));
    },
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
