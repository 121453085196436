import React from "react";
import Home from "./Home";

class NonExistPage extends React.Component {
  render() {
    return (
      <Home errorMessage="Error: The page you are looking for doesn't exist!" />
    );
  }
}

export default NonExistPage;
