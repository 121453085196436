import {
  SEARCH_PRODUCTS,
  SET_CATEGORY,
  SET_SEARCH_RESULT,
  CLEAR_SEARCH
} from "./actionTypes";

export const searchProducts = query => {
  return {
    type: SEARCH_PRODUCTS,
    query: query
  };
};

export const setCategory = categoryName => {
  return {
    type: SET_CATEGORY,
    categoryName: categoryName
  };
};

export const setSearchResult = result => {
  return {
    type: SET_SEARCH_RESULT,
    result: result
  };
};

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH
  };
};
