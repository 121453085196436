import React from "react";
import log from "loglevel";
import "./myLogger";
import "../App.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    log.error({ error, info });
  }

  redirectHome() {
    window.location.replace("/");
  }

  render() {
    if (this.state.hasError) {
      setTimeout(this.redirectHome, 30000);
      return (
        <div className="top-wrapper">
          <div>
            <p />
            <font color="red">
              An error has occurred, please refresh the page and try again.
            </font>
            <p />
            If the problem persists, please kindly send us an email: &nbsp;
            <a href="mailto:fwlesssales@gmail.com">fwlesssales@gmail.com</a>.
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
