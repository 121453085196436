import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  isMobileMode,
  apiBaseUrl,
  setTopContainerWrapperSettings,
  setInfoWrapperWidth,
} from "../Util";
import Working from "../Working";
import "../../generic.css";

class HtmlContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      html: null,
      mobileMode: false,
    };
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    if (!this.props.match.params.name) {
      this.props.history.push("/");
      return;
    }

    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    const url = apiBaseUrl() + "GetHtml";
    const req = {
      value: this.props.match.params.name,
      userId: this.props.userId,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            isLoading: false,
            html: res.data.data,
          });
        } else {
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.history.push("/");
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    let flag = isMobileMode() ? true : false;
    this.setState({ mobileMode: flag });
    setTopContainerWrapperSettings();
    setInfoWrapperWidth();
  }

  showContent() {
    if (this.state.html) {
      return (
        <div
          className="html-content-container"
          dangerouslySetInnerHTML={{ __html: this.state.html }}
        />
      );
    }
    return "";
  }

  render() {
    if (this.state.isLoading) {
      return <Working />;
    }
    return <div className="top-wrapper">{this.showContent()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
  };
};
export default connect(mapStateToProps)(HtmlContent);
