import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setLoggedIn, setUserInfo } from "../actions/userActions";
import "../../App.css";
import "../../generic.css";

class Logout extends React.Component {
  componentDidMount() {
    this.props.setLoggedIn(null);
    this.props.setUserInfo(null);
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">Successfully logged out.</div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (pwdToken) => {
      dispatch(setLoggedIn(pwdToken));
    },
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Logout));
