export const ORDERSTAGE_INIT = 0;
export const ORDERSTAGE_CONTACTINFO = 1;
export const ORDERSTAGE_PICKUP = 2;
export const ORDERSTAGE_DELIVERYINFO = 3;
export const ORDERSTAGE_SUBMITTED = 4;
export const ORDERSTAGE_DELIVERYFEE_PAID = 5;
export const ORDERSTAGE_SCHEDULED = 6;
export const ORDERSTAGE_COMPLETE = 9;

export const ORDSTAGE_INIT = 0;
export const ORDSTAGE_CONTACTINFO = 1; //Contact info provided
export const ORDSTAGE_USERSTATE = 2; //Whether login or as guest
export const ORDSTAGE_SHIPPING = 3; //Shipping choice made
export const ORDSTAGE_SUBMITTED = 4;
