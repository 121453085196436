import {
  SET_CONTACTINFO,
  SET_DELIVERYINFO,
  SET_ORDERINFO,
  SET_FEEANDRATE,
  SET_SHIPPING_CHOICE,
  SET_PICKUP_LOC,
  SET_ORDERSTAGE,
  CLEAR_ORDER,
  SET_STAGED_ORDER,
  SET_STAGED_ORDERNUM,
  CLEAR_STAGED_ORDER,
  SET_SECURITY_DEPOSIT,
  SET_SCHEDULED_DATE,
  SET_PAYMENT_ERROR,
  SET_PROGRESSIVE_INFO,
  SET_LEASEOK_INFO,
} from "../actions/actionTypes";
import { initOrderState } from "../State";
import {
  ORDERSTAGE_SUBMITTED,
  ORDERSTAGE_CONTACTINFO,
  ORDERSTAGE_DELIVERYINFO,
  ORDERSTAGE_PICKUP,
  ORDERSTAGE_COMPLETE,
} from "../order/orderStages";

const orderReducer = (state = initOrderState, action) => {
  if (action.type === SET_DELIVERYINFO) {
    const pickup = action.payload ? false : true;
    return {
      ...state,
      shippingChoiceDecided: true,
      deliveryInfo: action.payload,
      stage: ORDERSTAGE_DELIVERYINFO,
      pickup,
    };
  } else if (action.type === SET_ORDERINFO) {
    return {
      ...state,
      orderNumber: action.payload.orderNumber,
      orderEmail: action.payload.orderEmail,
      codAmount: action.payload.codAmount,
      orderCompleteMsg: action.payload.orderCompleteMsg,
      stage: ORDERSTAGE_SUBMITTED,
    };
  } else if (action.type === SET_FEEANDRATE) {
    return {
      ...state,
      ratefees: action.ratefees,
    };
  } else if (action.type === SET_CONTACTINFO) {
    let outOfState = false;

    if (action.payload.state) {
      const state = action.payload.state.toUpperCase();
      if (state !== "GA" && state !== "GEORGIA") {
        outOfState = true;
      }
    }

    return {
      ...state,
      contactInfo: action.payload,
      outOfState: outOfState,
      stage: ORDERSTAGE_CONTACTINFO,
    };
  } else if (action.type === SET_SHIPPING_CHOICE) {
    return {
      ...state,
      shippingChoiceDecided: true,
      shippingChoice: action.choice,
      stage: ORDERSTAGE_PICKUP,
    };
  } else if (action.type === SET_PICKUP_LOC) {
    return {
      ...state,
      pickup: true,
      pickupLocId: action.pickupLocId,
      pickupLeadTime: action.pickupLeadTime,
    };
  } else if (action.type === SET_ORDERSTAGE) {
    if (action.stage === ORDERSTAGE_COMPLETE) {
      return initOrderState;
    } else {
      return {
        ...state,
        stage: action.stage,
      };
    }
  } else if (action.type === CLEAR_ORDER) {
    return initOrderState;
  } else if (action.type === SET_STAGED_ORDER) {
    return {
      ...state,
      stagedOrder: action.stagedOrder,
    };
  } else if (action.type === SET_STAGED_ORDERNUM) {
    return {
      ...state,
      stagedOrderNum: action.stagedOrderNum,
    };
  } else if (action.type === SET_SCHEDULED_DATE) {
    return {
      ...state,
      scheduledDate: action.scheduledDate,
    };
  } else if (action.type === CLEAR_STAGED_ORDER) {
    return {
      ...state,
      stagedOrder: null,
      stagedOrderNum: null,
    };
  } else if (action.type === SET_PAYMENT_ERROR) {
    return {
      ...state,
      paymentError: action.paymentError,
    };
  } else if (action.type === SET_SECURITY_DEPOSIT) {
    let ratefees = {
      ...state.ratefees,
      pickupDeposit: action.pickupDeposit,
      pickupDepositTerm: action.pickupDepositTerm,
    };
    return {
      ...state,
      ratefees: ratefees,
    };
  } else if (action.type === SET_PROGRESSIVE_INFO) {
    return {
      ...state,
      progressiveInfo: action.payload,
    };
  } else if (action.type === SET_LEASEOK_INFO) {
    return {
      ...state,
      leaseOkInfo: action.payload,
    };
  }
  return state;
};

export default orderReducer;
