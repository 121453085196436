import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  generateGuid,
  fullProductImageUrl,
  setTopContainerWrapperSettings,
  getProdDetailUrl,
} from "../Util";
import { setUserId } from "../actions/userActions";
import { setSearchResult } from "../actions/searchActions";
import { isMobileMode, priceRange } from "../Util";
import log from "loglevel";
import "../myLogger";

import "../../search.css";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
      message: "",
      imageWidth: 200,
      isLoading: true,
    };
    this.searchProducts = this.searchProducts.bind(this);
    this.processResponseData = this.processResponseData.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.handleSearchError = this.handleSearchError.bind(this);
  }

  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);

    if (this.props.searchResult !== null) {
      this.setState({ items: this.props.searchResult, isLoading: false });
    } else {
      this.searchProducts();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  componentDidUpdate(prevProps) {
    if (this.props.query !== prevProps.query) {
      this.searchProducts();
    }
  }

  adjustMode() {
    const imageWidth = isMobileMode() ? 150 : 200;
    this.setState({ imageWidth: imageWidth });
    setTopContainerWrapperSettings();
  }

  processResponseData(result) {
    this.props.setSearchResult(result.productList);

    const message = result.success ? "" : "No results for " + this.props.query;
    this.setState({
      items: result.productList,
      message: message,
      isLoading: false,
    });

    if (result.productList.length === 1) {
      const url = getProdDetailUrl(result.productList[0]);
      this.props.history.push(url);
    }
  }

  getUserId() {
    let userId = this.props.userId;
    if (userId === "" || userId === null || userId === undefined) {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    return userId;
  }

  handleSearchError(error) {
    this.setState({
      isLoading: false,
      message:
        "An error has occurred while searching products, please try again later",
    });
    log.error(error);

    const url = apiBaseUrl() + "SuggestedProducts";
    const req = { userId: this.getUserId() };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ items: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  searchProducts() {
    if (!this.props.query) {
      this.props.history.push("/");
    }

    const url = apiBaseUrl() + "Search";
    const searchCrit = {
      userId: this.getUserId(),
      categoryName: this.props.categoryName,
      query: this.props.query,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, searchCrit, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponseData(res.data);
      })
      .catch((error) => {
        this.handleSearchError(error);
      });
  }

  displayOneItem(item) {
    if (item) {
      const len = item.description.length > 60 ? 60 : item.description.length;
      const desc = item.description.substring(0, len + 2) + " ...";
      const soldOut = item.forSale === false ? "SOLD OUT" : "";
      const url = getProdDetailUrl(item);
      const imageUrl = item.thumbnailImage
        ? item.thumbnailImage
        : item.imageUrl;
      return (
        <div className="search-item-wrapper">
          <div className="search-item-image">
            <Link to={url}>
              <img
                src={fullProductImageUrl(imageUrl)}
                width={this.state.imageWidth}
                alt={item.name}
              ></img>
            </Link>
          </div>
          <div className="search-item-detail" align="left">
            <span>
              <b>{item.name}</b>
              <br />
            </span>
            <div>Price: {priceRange(item)}</div>
            <b>
              <i>
                <font color="red">{soldOut}</font>
              </i>
            </b>
            <p />
            <div>{desc}</div>
          </div>
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) return <div>Fetching ...</div>;

    if (!this.state.items) {
      return <div className="search-result-container">Please wait ...</div>;
    }

    return (
      <div className="top-wrapper">
        <div className="search-result-container">
          <div align="left">
            <font color="red">
              {this.state.message}
              <p />
            </font>
          </div>
          {this.state.items.map((item) => {
            return this.displayOneItem(item);
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    categoryName: state.search.categoryName,
    query: state.search.query,
    searchResult: state.search.result,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    setSearchResult: (result) => {
      dispatch(setSearchResult(result));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
