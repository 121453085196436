import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setCategories } from "../actions/cacheActions";
import { AXIOS_HEADER } from "../config/constants";
import {
  isMobileMode,
  apiBaseUrl,
  useCache,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
  getGroupWrapperWidth,
} from "../Util";
import Working from "../Working";
import log from "loglevel";
import "../myLogger";
import "../../categoryBar.css";

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: isMobileMode() ? null : this.props.categories,
      useImageForMobile: false,
      mobileMode: false,
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.showText = this.showText.bind(this);
    this.showImages = this.showImages.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.androidSpecial();
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchData();
  }
  fetchData() {
    const url = apiBaseUrl() + "GetCategories";
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        const categories = res.data.list;
        const useImageForMobile = res.data.useImageForMobile;
        this.setState({
          items: categories,
          useImageForMobile,
        });
        if (useCache()) {
          this.props.setCategories(categories, useImageForMobile);
        }
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  androidSpecial() {
    if (this.props.android) {
      document.documentElement.style.setProperty(
        "--category-text-transform",
        "capitalize"
      );
    }
  }
  showImages() {
    try {
      const totalGap = 10 * (this.state.items.length - 1);
      const totalWidth = getGroupWrapperWidth() - totalGap;
      const totalActual = this.state.items
        .map((item) => item.imageInfo.width)
        .reduce((a, b) => a + b, 0);
      const ratio = totalWidth / totalActual;
      const height = Math.ceil(this.state.items[0].imageInfo.height * ratio);

      document.documentElement.style.setProperty(
        "--category-image-height",
        "" + height + "px"
      );

      return (
        <div className="top-wrapper" id="catTop">
          <div className="cat-wrapper" id="catWrapper">
            <div className="cat-left-side">
              {this.state.items.map((item) => {
                const width = Math.floor(item.imageInfo.width * ratio);
                const img = getIconBaseUrl() + item.imageInfo.url;
                return (
                  <div className="cat-image" key={item.id}>
                    <Link
                      to={`/product-category/${item.name.toLowerCase()}`}
                      key={item.id}
                    >
                      <img src={img} width={width} alt={item.name}></img>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } catch (error) {
      return this.showText();
    }
  }
  showText() {
    const showSP =
      this.state.mobileMode === false &&
      this.props.storeName &&
      this.props.storeId &&
      this.props.storeId > 0
        ? true
        : false;
    const showNew =
      !showSP &&
      this.state.mobileMode === false &&
      this.props.newArrivalCount &&
      this.props.newArrivalCount > 0
        ? true
        : false;
    const newLabel = this.state.mobileMode === false ? "New*" : "New Arrivals";
    const showSpecial = this.state.items.length < 6 ? true : false;

    return (
      <div className="top-wrapper" id="catTop">
        <div className="cat-wrapper" id="catWrapper">
          <div className="cat-left-side">
            {this.state.items.map((item) => (
              <div className="cat-link" key={item.id}>
                <Link
                  to={`/product-category/${item.name.toLowerCase()}`}
                  key={item.id}
                >
                  {item.name}
                </Link>
              </div>
            ))}
            {this.state.mobileMode === false && showSpecial && (
              <div className="cat-link-special">
                <Link to="/product-category/specials">Specials</Link>
              </div>
            )}
            {showNew && (
              <div className="cat-link-new">
                <Link to="/product-category/newarrivals">{newLabel}</Link>
              </div>
            )}
            {showSP && (
              <div className="cat-link-sp">
                <Link to="/storeItems">Store</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  render() {
    if (!this.state.items) return <Working />;

    if (this.state.useImageForMobile) {
      return this.showImages();
    } else {
      return this.showText();
    }
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    categories: state.cache.categories,
    android: state.cache.android,
    newArrivalCount: state.cache.newArrivalCount,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
    kiosk: state.webOrder.kiosk,
    debug: state.cache.debug,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCategories: (categories, useCatImage) => {
      dispatch(setCategories(categories, useCatImage));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Category);
