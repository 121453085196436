import React from "react";
import { isMobileMode } from "./Util";
import "../generic.css";

class AddressInfo extends React.Component {
  render() {
    if (!this.props.address) {
      return "";
    }

    let addr = this.props.address;
    let lines = [];

    if (addr.firstName || addr.lastName) {
      let name = addr.firstName ? addr.firstName + " " : "";
      name += addr.lastName;
      lines.push(name);
    }
    if (addr.addressLine1) {
      lines.push(addr.addressLine1);
    }
    if (addr.addressLine2 !== null && addr.addressLine2 !== "") {
      lines.push(addr.addressLine2);
    }
    if (addr.city || addr.zipCode) {
      lines.push(addr.city + ", " + addr.state + " " + addr.zipCode);
    }
    if (addr.phone) {
      lines.push("Phone: " + addr.phone);
    }
    if (addr.email && addr.email.length > 0) {
      lines.push("Email: " + addr.email);
    }

    if (lines.length === 0) return "";

    let htmlLines = lines.map((line, idx) => {
      return (
        <span key={idx}>
          <label>{line}</label>
          <br />
        </span>
      );
    });

    let title = this.props.title;
    if (title === null || title === undefined || title === "") {
      title = "Delivery Address";
    }

    const clsName = isMobileMode() ? "top-5" : "top-10";
    return (
      <div>
        <div>
          <font size="4">{title}</font>
        </div>
        <div className={clsName}>{htmlLines}</div>
      </div>
    );
  }
}

export default AddressInfo;
