import {
  ADD_TO_CART,
  REMOVE_ITEM,
  SUB_QUANTITY,
  ADD_QUANTITY,
  CHECKOUT,
  CLEAR_CART,
  SET_ITEMS,
  SET_DISCOUNT,
  SET_CARTMSG,
} from "../actions/actionTypes";
import axios from "axios";
import { initCartState, stateToCart, saveCart } from "../State";
import { apiBaseUrl } from "../Util";
import { AXIOS_HEADER } from "../config/constants";

function checkout(state) {
  const cart = stateToCart(state);

  const url = apiBaseUrl() + "Checkout";
  axios.post(url, cart, { headers: AXIOS_HEADER }).catch((error) => {
    console.log(error);
  });
}

const cartReducer = (state = initCartState, action) => {
  if (action.type === ADD_TO_CART) {
    let addedItem = state.addedItems.find(
      (item) => item.sku === action.payload.sku
    );
    //check if the action id exists in the addedItems
    let existed_item = state.addedItems.find(
      (item) => action.payload.sku === item.sku
    );
    let newState;
    const newItemCount = state.itemCount + 1;

    if (existed_item) {
      addedItem.quantity += 1;
      newState = {
        ...state,
        itemCount: newItemCount,
        subtotal: state.subtotal + addedItem.price,
      };
    } else {
      let title;
      if (action.payload.prodVar && action.payload.prodVar.symLink) {
        if (action.payload.prodVar.fullName) {
          title = action.payload.prodVar.fullName;
        } else {
          title = action.payload.prodVar.variantValue;
        }
      } else {
        const prod = action.payload.item;
        const sku = action.payload.sku;
        const pv = prod.pvList.find((aPv) => aPv.sku === sku);

        if (pv && pv.fullName) {
          title = pv.fullName;
        } else {
          title = action.payload.item.name;
          if (
            action.payload.optionName !== undefined &&
            action.payload.optionName !== null
          ) {
            title = title + " - " + pv.variantValue;
          }
        }
      }

      addedItem = {
        product: action.payload.item,
        productId: action.payload.item.id,
        sku: action.payload.sku,
        title: title,
        quantity: 1,
        price: action.payload.price,
      };
      const newTotal = state.subtotal + addedItem.price;

      newState = {
        ...state,
        userId: action.userId,
        addedItems: [...state.addedItems, addedItem],
        itemCount: newItemCount,
        subtotal: newTotal,
      };
    }
    saveCart(newState);
    return newState;
  }

  if (action.type === SET_ITEMS) {
    let subtotal = 0;
    let newItemCount = 0;
    let i = 0;

    for (i = 0; i < action.items.length; i++) {
      subtotal += action.items[i].price * action.items[i].quantity;
      newItemCount += action.items[i].quantity;
    }
    const newState = {
      ...state,
      addedItems: action.items,
      itemCount: newItemCount,
      subtotal: subtotal,
    };
    saveCart(newState);
    return newState;
  }

  if (action.type === SET_CARTMSG) {
    let newState = {
      ...state,
      cartMessage: action.message,
    };
    return newState;
  }

  if (action.type === SET_DISCOUNT) {
    let newState = {
      ...state,
      discount: action.discount,
    };
    return newState;
  }

  if (action.type === REMOVE_ITEM) {
    let itemToRemove = state.addedItems.find((item) => action.sku === item.sku);
    let newState;

    if (state.addedItems.length <= 1 && itemToRemove !== undefined) {
      newState = initCartState;
    } else {
      let new_items = state.addedItems.filter(
        (item) => action.sku !== item.sku
      );
      let newTotal =
        state.subtotal - itemToRemove.price * itemToRemove.quantity;
      const newItemCount = state.itemCount - itemToRemove.quantity;

      newState = {
        ...state,
        addedItems: new_items,
        itemCount: newItemCount,
        subtotal: newTotal,
      };
    }
    saveCart(newState);
    return newState;
  }

  if (action.type === CLEAR_CART) {
    return initCartState;
  }

  if (action.type === ADD_QUANTITY) {
    let addedItem = state.addedItems.find((item) => item.sku === action.sku);
    addedItem.quantity += 1;
    let newTotal = state.subtotal + addedItem.price;
    const newItemCount = state.itemCount + 1;

    let newState = {
      ...state,
      itemCount: newItemCount,
      subtotal: newTotal,
    };

    saveCart(newState);
    return newState;
  }

  if (action.type === SUB_QUANTITY) {
    let addedItem = state.addedItems.find((item) => item.sku === action.sku);
    let newState;
    const newItemCount = state.itemCount - 1;

    if (addedItem.quantity === 1) {
      let new_items = state.addedItems.filter(
        (item) => item.sku !== action.sku
      );
      let newTotal = state.subtotal - addedItem.price;

      newState = {
        ...state,
        addedItems: new_items,
        itemCount: newItemCount,
        subtotal: newTotal,
      };
    } else {
      addedItem.quantity -= 1;
      if (addedItem.quantity === 0) {
        let itemToRemove = state.addedItems.find(
          (item) => action.sku === item.sku
        );
        let new_items = state.addedItems.filter(
          (item) => action.sku !== item.sku
        );

        let newTotal =
          state.subtotal - itemToRemove.price * itemToRemove.quantity;

        newState = {
          ...state,
          addedItems: new_items,
          itemCount: newItemCount,
          subtotal: newTotal,
        };
      } else {
        let newTotal = state.subtotal - addedItem.price;
        newState = {
          ...state,
          itemCount: newItemCount,
          subtotal: newTotal,
        };
      }
      console.log("SaveCart5");
      saveCart(newState);
      return newState;
    }
  }

  if (action.type === CHECKOUT) {
    checkout(state);
    return state;
  }

  return state;
};

export default cartReducer;
