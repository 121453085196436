import { store } from "../config/store";

export const USER_CUSTOMER = 0;
export const USER_CLERK = 2;
export const USER_KIOSK = 3;

export function getUserType() {
  const state = store.getState();
  const storeId = state.webOrder.storeId;
  const storeName = state.webOrder.storeName;
  const kiosk = state.webOrder.kiosk;
  if (storeName && storeId && storeId > 0) {
    if (kiosk) return USER_KIOSK;
    else return USER_CLERK;
  }
  return USER_CUSTOMER;
}

export function isKiosk() {
  return getUserType() === USER_KIOSK;
}

export function isClerk() {
  return getUserType() === USER_CLERK;
}

export function isInStore() {
  const ut = getUserType();
  return ut === USER_CLERK || ut === USER_KIOSK;
}

export function getStoreId() {
  return store.getState().webOrder.storeId;
}
export function getStoreName() {
  return store.getState().webOrder.storeName;
}
