import React from "react";
import { setTopContainerWrapperSettings, setInfoWrapperWidth } from "../Util";
import "../../info.css";

class TariffNote extends React.Component {
  constructor(props) {
    super(props);
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
    setInfoWrapperWidth();
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="info-container">
          <div className="info-wrapper">
            <div className="info-item">
              <font size="5">Tariff Surcharge</font>
              <br />
              <hr className="info-hr"></hr>
            </div>
            <div className="info-item">
              Most of the furnitures we sell are imported from countries like
              China and Vietnam. Due to recent imposed tariff by US government,
              our cost of these furnitures have gone up as much as <b>30%</b>.
            </div>

            <div className="info-item">
              <font color="orange">
                Starting 9/17/2019, we will add 10% surcharge on all items we
                sell.
              </font>
              &nbsp;&nbsp;While some of our competitors have factored this into
              base prices permanently, we only do it as a temporary measure. The
              surcharge will be removed from our prices once this tariff ends.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TariffNote;
