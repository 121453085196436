import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "./config/constants";
import { ORDERSTAGE_INIT } from "./order/orderStages";
import { apiBaseUrl } from "./Util";

export const initCartState = {
  addedItems: [],
  storeItems: [],
  itemCount: 0,
  subtotal: 0,
  userId: "",
  discount: null,
  cartMessage: null,
};

export const initSPCartState = {
  storeItems: [],
  itemCount: 0,
  subtotal: 0,
};

export const initOrderState = {
  contactInfo: null,
  outOfState: false,
  deliveryInfo: null,
  ratefees: null,
  orderNumber: "",
  orderEmail: "",
  codAmount: 0.0,
  orderCompleteMsg: null,
  stage: ORDERSTAGE_INIT,
  shippingChoice: "Delivery",
  pickupLocId: -1,
  pickupLeadTime: null,
  stagedOrder: null,
  stagedOrderNum: null,
  shippingChoiceDecided: false,
  scheduledDate: null,
  overrideDeliveryFee: -1,
  paymentError: null,
  progressiveInfo: null,
  leaseOkInfo: null,
};

export const initUserState = {
  userId: "",
  isLoggedIn: false,
  userInfo: null,
  pwdToken: null,
};

export const initSearchState = {
  categoryName: null,
  query: "",
  result: null,
};

export const initCacheState = {
  version: "2.9.10",
  android: false,
  supportHTML5: false,
  autoCompleteAddress: false,
  autoCompleteCDF: false,
  whLatitude: 0,
  whLongitude: 0,
  deliveryRadius: 0,
  debug: false,
  newArrivalCount: 0,
  productsByCat: [[], [], [], [], [], []],
  categories: null,
  useCatImage: false,
  locations: null,
  homeImageList: null,
  homePromList: null,
  homeVerPromList: null,
  homeViewedList: null,
  debugInfo: null,
};

export const initWebOrderState = {
  userId: null,
  storeId: 0,
  storeName: null,
  authToken: null,
  kiosk: false,
};

export function stateToCart(state) {
  const cartItems = state.addedItems.map((item) => {
    return {
      productId: item.product.productId,
      sku: item.sku,
      price: item.price,
      quantity: item.quantity,
    };
  });

  const cart = { userId: state.userId, itemList: cartItems };

  return cart;
}

export function saveCart(state) {
  const cart = stateToCart(state);
  const url = apiBaseUrl() + "SaveCart";
  axiosRetry(axios, { retries: 3 });
  axios.post(url, cart, { headers: AXIOS_HEADER }).catch((error) => {
    console.log(error);
  });
}
