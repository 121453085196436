import React from "react";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "../App.css";
import "../form.css";

class DialogBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      btnId: "",
      hiddenBtnId: this.props.hdnBtnId
        ? this.props.hdnBtnId
        : "showDialogBoxBtn",
      title: "",
      message: "",
      buttonTitle: "",
      hidden: false,
      buttonClsName: "",
      actionHandler: null,
      html: false,
      okLabel: this.props.okLabel ? this.props.okLabel : "OK",
      cancelLabel: this.props.cancelLabel ? this.props.cancelLabel : "Cancel",
      hideCancel: this.props.hideCancel ? true : false,
      showDialog: false,
    };
    this.showDialog = this.showDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.dialogBox = this.dialogBox.bind(this);
    this.initState = this.initState.bind(this);
  }

  componentDidMount() {
    this.initState();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.title != this.props.title ||
      prevProps.message != this.props.message
    ) {
      this.initState();
    }
  }
  initState() {
    this.setState({
      btnId: this.props.btnId,
      title: this.props.title,
      message: this.props.message,
      buttonTitle: this.props.buttonTitle,
      hidden: this.props.hidden,
      buttonClsName: this.props.btnClsName,
      actionHandler: this.props.actionHandler,
      html: this.props.html,
      okLabel: this.props.okLabel ? this.props.okLabel : "OK",
      cancelLabel: this.props.cancelLabel ? this.props.cancelLabel : "Cancel",
      hiddenBtnId: this.props.hdnBtnId
        ? this.props.hdnBtnId
        : "showDialogBoxBtn",
    });
  }
  showDialog() {
    document.getElementById(this.state.hiddenBtnId).click();
  }
  openDialog(event) {
    event.preventDefault();
    this.setState({ showDialog: true });
  }
  closeDialog() {
    this.setState({ showDialog: false });
  }

  hiddenActionButton() {
    return (
      <button
        id={this.state.hiddenBtnId}
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  handleOk() {
    this.closeDialog();

    if (this.state.actionHandler) {
      this.state.actionHandler();
    }
  }
  dialogBox() {
    return (
      <div>
        {this.hiddenActionButton()}
        <Dialog
          isOpen={this.state.showDialog}
          onDismiss={this.closeDialog}
          aria-label="Confirmation"
        >
          <b>{this.state.title}</b>
          {this.state.html ? (
            <div
              className="top-5"
              dangerouslySetInnerHTML={{ __html: this.state.message }}
            ></div>
          ) : (
            this.state.message
          )}
          <p />
          <button onClick={this.handleOk} className="btn-style">
            {this.state.okLabel}
          </button>
          &nbsp;&nbsp;
          {!this.state.hideCancel && (
            <button onClick={this.closeDialog} className="btn-style">
              {this.state.cancelLabel}
            </button>
          )}
        </Dialog>
      </div>
    );
  }

  render() {
    const clsName = this.state.buttonClsName
      ? this.state.buttonClsName
      : "btn-style";
    return (
      <div>
        {this.state.hidden ? (
          <button
            id={this.state.btnId}
            className={clsName}
            onClick={this.showDialog}
            style={{ display: "none" }}
          >
            {this.state.buttonTitle}
          </button>
        ) : (
          <button className={clsName} onClick={this.showDialog}>
            {this.state.buttonTitle}
          </button>
        )}
        {this.dialogBox()}
      </div>
    );
  }
}
export default DialogBox;
