import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import CartView from "../cart/CartView";
import Address from "../AddressInfo";
import { cartSummary } from "../cart/cartUtil";
import {
  setStagedOrder,
  setStagedOrderNum,
  setRateAndFees,
  setScheduledDate,
  setOrderStage,
  setOrderInfo,
  clearStagedOrder,
  setDeliveryInfo,
} from "../actions/orderActions";
import { clearCart } from "../actions/cartActions";
import { spClearCart } from "../actions/spCartActions";
import { ORDERSTAGE_DELIVERYFEE_PAID } from "../order/orderStages";
import { AXIOS_HEADER } from "../config/constants";
import {
  FormatCurrency,
  apiBaseUrl,
  rateToPercent,
  generateGuid,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
} from "../Util";
import log from "loglevel";
import "../myLogger";
import "../../App.css";
import "../../orderSum.css";
import "../../layaway.css";
import "../../payment.css";
import {
  isClerk,
  isInStore,
  getStoreId,
  getStoreName,
} from "../store/storeUtil";

class StoreLayawaySummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      taxRate: this.props.taxRate,
      summary: cartSummary(this.props.taxRate, 0),
      salespersonList: null,
      salesperson: null,
      customerDeposits: null,
      remainingBalance: null,
      paidInFull: false,
      pacIdx: 0,
      isLoading: true,
      errorMessage: null,
      feeErrorMessage: null,
    };

    this.setUserId = this.setUserId.bind(this);
    this.adjustSettings = this.adjustSettings.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.placeLayaway = this.placeLayaway.bind(this);
    this.fetchSalespersonList = this.fetchSalespersonList.bind(this);
    this.processLayawayResponse = this.processLayawayResponse.bind(this);
    this.emptyCart = this.emptyCart.bind(this);
    this.getTaxRate = this.getTaxRate.bind(this);
  }
  componentDidMount() {
    if (!this.props.contactInfo) {
      this.props.history.push("/cart");
      return;
    }
    if (!isInStore() || this.emptyCart()) {
      this.props.history.push("/");
      return;
    }
    this.getTaxRate();
    const summary = this.state.summary;
    if (
      !summary.isStoreLayaway ||
      summary.grandTotal <= 0 ||
      summary.depositPercent < 10.0
    ) {
      this.props.history.push("/");
      return;
    }
    this.fetchSalespersonList();
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  getTaxRate() {
    const url = apiBaseUrl() + "GetTaxRate";
    const req = {
      userId: this.props.userId,
      id: getStoreId(),
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        if (res.data) {
          this.setState({
            taxRate: res.data,
            summary: cartSummary(res.data, 0),
          });
        }
      })
      .catch((error) => {
        log.error(error);
        console.log(error);
      });
  }
  emptyCart() {
    if (!this.props.items || this.props.items.length < 1) {
      if (isInStore()) {
        if (this.props.storeItems && this.props.storeItems.length > 0) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  fetchSalespersonList() {
    if (isClerk()) {
      const url = apiBaseUrl() + "GetSalespersonList";
      const req = {
        userId: this.props.userId,
        id: getStoreId(),
      };

      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, {
          headers: AXIOS_HEADER,
        })
        .then((res) => {
          this.setState({ salespersonList: res.data });
        })
        .catch((error) => {
          log.error(error);
          console.log(error);
        });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
  }

  setUserId() {
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
  }

  processLayawayResponse(response) {
    if (response) {
      if (response.status === "Processed") {
        const payload = {
          orderNumber: response.orderNumber,
          orderEmail: response.email,
          orderCompleteMsg: response.orderCompleteMsg,
        };

        this.props.clearCart();
        this.props.spClearCart();
        this.props.clearStagedOrder();
        this.props.setOrderInfo(payload);
        this.props.setOrderStage(ORDERSTAGE_DELIVERYFEE_PAID);
        this.props.history.push("/layawayComplete");
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
        });
      }
    } else {
      this.setState({
        errorMessage: "Failed to process the layaway order.",
        submitBtnEnabled: true,
      });
    }
  }
  placeLayaway(event) {
    event.preventDefault();

    const summary = this.state.summary;
    const order = {
      userId: this.props.userId,
      items: this.props.items,
      storeItems: this.props.storeItems,
      contactInfo: this.props.contactInfo,
      subtotal: summary.subtotal,
      total: summary.grandTotal,
      discount: summary.discount,
    };
    const req = {
      userId: this.props.userId,
      order,
      storeId: getStoreId(),
      authToken: this.props.authToken,
      salesperson: this.state.salesperson,
      kiosk: this.props.kiosk,
    };
    const url = apiBaseUrl() + "SubmitLayaway";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processLayawayResponse(res.data);
      })
      .catch((error) => {
        log.error(error);
        console.log(error);
      });
  }
  showDeposits() {
    const summary = this.state.summary;
    return (
      <React.Fragment>
        {summary.deposits.map((cd, idx) => {
          const imageUrl = cd.product.imageUrl;
          const amount = cd.price > 0 ? cd.price : 0 - cd.price;
          const last4 =
            cd.last4 && cd.last4.trim().length > 0
              ? "(" + cd.last4 + ")"
              : null;
          return (
            <React.Fragment key={idx}>
              <div>
                {imageUrl && (
                  <img src={fullProductImageUrl(imageUrl)} height="15"></img>
                )}
                {cd.name}
                {last4}
              </div>
              <div className="left-10" align="right">
                <font color="red">
                  -<FormatCurrency amount={amount} />
                </font>
              </div>
            </React.Fragment>
          );
        })}
        <div>
          <i>Balance:</i>
        </div>
        <div align="right">
          <FormatCurrency amount={summary.balance} />
        </div>
      </React.Fragment>
    );
  }
  showSummary() {
    const summary = this.state.summary;
    const days = summary.depositPercent >= 20.0 ? 90 : 30;
    let nextPmtDate = new Date();

    nextPmtDate.setDate(nextPmtDate.getDate() + days);
    let dateStr = "";
    if (nextPmtDate.getMonth() < 9) dateStr += "0";
    dateStr += 1 + nextPmtDate.getMonth();
    dateStr += "/";
    if (nextPmtDate.getDate() < 10) dateStr += "0";
    dateStr += nextPmtDate.getDate();
    dateStr += "/" + nextPmtDate.getFullYear();

    return (
      <div>
        <div className="sum-title-div">
          <font size="4">Layaway Summary</font>
        </div>
        <div className="sum-tbl-container">
          {summary.discount && !summary.discount.afterTax && (
            <React.Fragment>
              <div className="sum-tbl-item-left">{summary.discount.name}:</div>
              <div className="sum-tbl-item-right">
                <font color="red">
                  -<FormatCurrency amount={summary.discount.amount} />
                </font>
              </div>
            </React.Fragment>
          )}
          <div className="sum-tbl-item-left">Subtotal:</div>
          <div className="sum-tbl-item-right">
            <FormatCurrency amount={summary.subtotal} />
          </div>
          <div className="sum-tbl-item-left">
            Sales Tax({rateToPercent(summary.taxRate)}):
          </div>
          <div className="sum-tbl-item-right">
            <FormatCurrency amount={summary.tax} />
          </div>
          {summary.coupons.map((coupon, idx) => (
            <React.Fragment key={idx}>
              <div className="sum-tbl-item-left">{coupon.name}:</div>
              <div className="sum-tbl-item-right">
                <font color="red">
                  <FormatCurrency amount={coupon.price} />
                </font>
              </div>
            </React.Fragment>
          ))}
          {summary.discount && summary.discount.afterTax && (
            <React.Fragment>
              <div className="sum-tbl-item-left">{summary.discount.name}:</div>
              <div className="sum-tbl-item-right">
                <font color="red">
                  -<FormatCurrency amount={summary.discount.amount} />
                </font>
              </div>
            </React.Fragment>
          )}
          <div className="sum-tbl-item-left">
            <b>Grand Total:</b>
          </div>
          <div className="sum-tbl-item-right">
            <FormatCurrency amount={summary.grandTotal} />
          </div>
          {this.showDeposits()}
          <div className="sum-tbl-item-leftt10">
            <i>Next Payment Due</i>:
          </div>
          <div className="sum-tbl-item-rightt10">{dateStr}</div>
        </div>
      </div>
    );
  }

  handleChange(event) {
    if (event.target.name === "salesperson") {
      this.setState({ salesperson: event.target.value });
    } else {
      this.setState({ scheduledDate: event.target.value });
      this.props.setScheduledDate(event.target.value);
    }
  }

  storeOrderBox() {
    if (isInStore()) {
      const list = this.state.salespersonList;
      return (
        <div>
          <div>&nbsp;</div>
          {isClerk() && (
            <div className="flex-b10">
              <div>
                <b>
                  <font color="red">{getStoreName()}</font>
                </b>
              </div>
              {list && list.length > 0 ? (
                <div className="left-10">
                  <select
                    name="salesperson"
                    value={this.state.salesperson || ""}
                    onChange={this.handleChange}
                  >
                    <option value="">[Select Salesperson]</option>
                    {list.map((nn) => {
                      return <option value={nn}>{nn}</option>;
                    })}
                  </select>
                </div>
              ) : (
                <div className="left-10">
                  <input
                    type="text"
                    name="salesperson"
                    value={this.state.salesperson || ""}
                    placeholder="Enter salesperson name"
                    onChange={this.handleChange}
                  ></input>
                </div>
              )}
            </div>
          )}
          <div>
            <button
              className="layaway-btn"
              name="layaway"
              onClick={this.placeLayaway}
            >
              Place Layaway
            </button>
          </div>
          <div>&nbsp;</div>
        </div>
      );
    }
  }
  render() {
    const addr = this.props.contactInfo;
    const addrTitle = "Customer Information";

    const title = "Review Customer Layaway";
    return (
      <div className="top-wrapper">
        <div className="sum-container">
          <div align="left">
            <font size="5">{title}</font>
            <p />
          </div>
          <div className="sum-wrapper">
            <div className="sum-ordersum-wrapper">{this.showSummary()}</div>
            <div className="sum-addr-wrapper">
              <Address address={addr} title={addrTitle} />
            </div>
          </div>
          {this.storeOrderBox()}
          <div align="left">
            <CartView title="Layaway Items" />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.cart.addedItems,
    discount: state.cart.discount,
    subtotal: state.cart.subtotal,
    contactInfo: state.order.contactInfo,
    deliveryInfo: state.order.deliveryInfo,
    ratefees: state.order.ratefees,
    shippingChoice: state.order.shippingChoice,
    pickupLocId: state.order.pickupLocId,
    userId: state.user.userId,
    orderStage: state.order.stage,
    shippingChoiceDecided: state.order.shippingChoiceDecided,
    android: state.cache.android,
    kiosk: state.webOrder.kiosk,
    storeItems: state.spCart.storeItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStagedOrder: (order) => {
      dispatch(setStagedOrder(order));
    },
    setStagedOrderNum: (orderNum) => {
      dispatch(setStagedOrderNum(orderNum));
    },
    setRateAndFees: (ratefees) => {
      dispatch(setRateAndFees(ratefees));
    },
    setScheduledDate: (date) => {
      dispatch(setScheduledDate(date));
    },
    setDeliveryInfo: (info) => {
      dispatch(setDeliveryInfo(info));
    },
    setOrderStage: (stage) => {
      dispatch(setOrderStage(stage));
    },
    clearCart: () => {
      dispatch(clearCart());
    },
    spClearCart: () => {
      dispatch(spClearCart());
    },
    setOrderInfo: (payload) => {
      dispatch(setOrderInfo(payload));
    },
    clearStagedOrder: () => {
      dispatch(clearStagedOrder());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreLayawaySummary);
