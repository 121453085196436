import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  adminSiteApiUrl,
  setTopContainerWrapperSettings,
  isEmptyStr,
  isMobileMode,
} from "../Util";
import CommonOrderDetail from "./CommonOrderDetail";
import Working from "../Working";
import "../../App.css";
import "../../generic.css";
import "../../form.css";

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      isLoading: true,
      errorMessage: null,
      orderStatus: 0,
      scheduledDate: null,
      mobileMode: false,
    };
    this.fetchOrder = this.fetchOrder.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.redirectToEsign = this.redirectToEsign.bind(this);
  }
  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchOrder();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  processResponse(response) {
    const order = response.data;
    let scheduledDate = null;
    if (order.summary.delivery) {
      if (order.summary.schDateStr) {
        const date = new Date(order.summary.schDateStr);
        const now = new Date();
        const delta = (date.getTime() - now.getTime()) / 3600000;

        if (delta > -24) {
          scheduledDate = date.toISOString().split("T")[0];
        } else {
          scheduledDate = order.summary.schDateStr;
        }
      }
    }
    this.setState({
      order,
      scheduledDate,
      isLoading: false,
    });
  }
  fetchOrder() {
    const url = adminSiteApiUrl() + "GetOrderForCustomer";
    const req = {
      userId: this.props.userId,
      password: this.props.pwdToken,
      sessionKey: this.props.pwdToken,
      value: this.props.match.params.orderNo,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showAddress(addr, showEmail) {
    let html = addr.firstName + " " + addr.lastName + "<br/>";
    html += addr.addressLine1 + "<br/>";
    if (addr.addressLine2) html += addr.addressLine2 + "<br/>";

    if (!isEmptyStr(addr.city) || !isEmptyStr(addr.zipCode)) {
      html += addr.city + ", " + addr.state + " " + addr.zipCode + "<br/>";
    }

    if (addr.phone) {
      html += addr.phone;
      if (addr.mobilePhone) {
        html += "/" + addr.mobilePhone;
      }
      html += "<br/>";
    }
    if (addr.email && showEmail) html += addr.email + "<br/>";

    return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
  }

  isEmptyAddress(addr) {
    if (addr) {
      if (
        !isEmptyStr(addr.firstName) ||
        !isEmptyStr(addr.lastName) ||
        !isEmptyStr(addr.addressLine1)
      ) {
        return false;
      }
    }
    return true;
  }
  showPickupLoc() {
    if (this.state.order.pickupLocation) {
      const info = this.state.order.pickupLocation.locationInfo;
      return (
        <div>
          {info.description}
          <br />
          {info.addressLine1}
          <br />
          {info.city}, {info.state} {info.zipCode}
          <br />
          {info.phone}
        </div>
      );
    }
  }
  showDeliveryOrPickupInfo() {
    if (this.state.order.summary.delivery) {
      return (
        <div>
          <p />
          <b>Delivery Information:</b>
          <br />
          {this.showAddress(
            this.state.order.deliveryAddress,
            !this.state.mobileMode
          )}
        </div>
      );
    } else {
      return (
        <div>
          <p />
          <b>Pickup Location:</b>
          <br />
          {this.showPickupLoc()}
        </div>
      );
    }
  }
  showProgressiveInfo(info) {
    if (info) {
      let status = info.funded ? (
        <font color="green">FUNDED</font>
      ) : (
        info.status
      );
      const okToDeliver = info.okToDeliver ? (
        <font color="blue">Yes</font>
      ) : (
        <font color="red">No</font>
      );
      if (this.state.leaseCancelled) {
        status = <font color="red">Cancelled</font>;
      }
      return (
        <React.Fragment>
          <div>Lease To Own:</div>
          <div>Progressive</div>
          <div>Lease ID:</div>
          <div>{info.leaseId}</div>
          <div>Status:</div>
          <div>{status}</div>
          {!info.funded && !this.state.leaseCancelled && (
            <React.Fragment>
              <div>OkToDeliver:</div>
              <div>{okToDeliver}</div>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div>Lease To Own:</div>
          <div>Progressive</div>
        </React.Fragment>
      );
    }
  }
  redirectToEsign() {
    document.getElementById("esignLink").click();
  }
  progressiveLink() {
    if (this.state.order && this.state.order.summary.leaseToOwn) {
      const coi = this.state.order.commonOrderInfo;
      const progressiveStatus = coi.progressiveStatus;
      const info = coi.leaseInfo;
      let esignUrl = info ? info.esignUrl : null;

      if (!esignUrl) {
        esignUrl = coi.order.esignUrl;
      }

      if (progressiveStatus) {
        if (progressiveStatus.status == "Contracts") {
          const action = this.state.order.summary.delivery
            ? "delivered"
            : "picked up";
          return (
            <div className="lease-esign-container">
              <font size="4" color="orange">
                Lease Agreement Not Signed
              </font>
              <div className="top-10">
                You have not signed your Progressive lease agreement. Your order
                can't be {action} until you sign it and make the intial payment
                to Progressive Leasing.
              </div>
              <p />
              <label className="blue-link-label" onClick={this.redirectToEsign}>
                Sign the Lease & Make Initial Payment
              </label>
              <a href={esignUrl} target="__Progressive" id="esignLink" />
            </div>
          );
        }
      }
    }
  }
  header() {
    const ordWrp = this.state.order;
    const coi = ordWrp.commonOrderInfo;
    const leaseToOwn = ordWrp.summary.leaseToOwn;
    const progressiveStatus = coi.progressiveStatus;
    let title = "Order: " + ordWrp.summary.orderNumber;
    if (ordWrp.summary.storeId > 0 && ordWrp.summary.storeName) {
      title = ordWrp.summary.storeName + " " + title;
    }
    return (
      <div>
        <div>
          <font size="5">{title}</font>
          <p />
        </div>
        <div className="twocol-form">
          <div>Date Ordered:</div>
          <div>{ordWrp.summary.dateStr}</div>
          {this.state.scheduledDate && (
            <React.Fragment>
              <div>Delivery Date:</div>
              <div>{this.state.scheduledDate}</div>
            </React.Fragment>
          )}
          {ordWrp.orderStatus && (
            <React.Fragment>
              <div>Order Status:</div>
              <div>{ordWrp.orderStatus.status}</div>
            </React.Fragment>
          )}
          {leaseToOwn && this.showProgressiveInfo(progressiveStatus)}
        </div>
        <p />
        <font color="red">{this.state.errorMessage}</font>
      </div>
    );
  }
  render() {
    if (this.state.isLoading) return <Working />;
    if (!this.state.order)
      return (
        <div>
          <font color="red">Order not found</font>
        </div>
      );
    const ordWrp = this.state.order;
    const clsName = this.state.mobileMode ? "none" : "flex-wrapper-top";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          {this.header()}
          {this.progressiveLink()}
          <div>
            <font size="4">Order Detail</font>
          </div>
          <CommonOrderDetail
            commonOrderInfo={this.state.order.commonOrderInfo}
            mobileMode={this.state.mobileMode}
          />

          <div className={clsName}>
            {!this.isEmptyAddress(ordWrp.contactAddress) && (
              <React.Fragment>
                <div>
                  <p />
                  <b>Contact Information:</b>
                  <br />
                  {this.showAddress(ordWrp.contactAddress, true)}
                </div>
                {!this.state.mobileMode && <div className="left-60"></div>}
              </React.Fragment>
            )}
            {this.showDeliveryOrPickupInfo()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    pwdToken: state.user.pwdToken,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
export default connect(mapStateToProps)(OrderDetail);
