import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import { setLeaseOkInfo } from "../actions/orderActions";
import { setTopContainerWrapperSettings, apiBaseUrl } from "../Util";
import Working from "../Working";
import log from "loglevel";
import "../myLogger";
import "../../orderComplete.css";

class AfterEsignProcess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWorking: true,
    };

    this.adjustSettings = this.adjustSettings.bind(this);
    this.sendLeaseOrderComplete = this.sendLeaseOrderComplete.bind(this);
  }

  componentDidMount() {
    if (!this.props.match.params.orderNumber) {
      this.props.history.push("/");
    }
    this.sendLeaseOrderComplete();
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  adjustSettings() {
    setTopContainerWrapperSettings();
  }
  sendLeaseOrderComplete() {
    const url = apiBaseUrl() + "LeaseOrderComplete";
    const req = {
      orderNumber: this.props.match.params.orderNumber,
      userId: this.props.userId,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          this.setState({ isWorking: false });
          this.props.setLeaseOkInfo(res.data.data);
          window.location.replace("/leaseComplete");
        } else {
          this.setState({
            errorMessage: res.data.errorMessage,
            isWorking: false,
          });
        }
      })
      .catch((error) => {
        log.error(error);
        this.setState({
          errorMessage: "An error has occurred",
          isWorking: false,
        });
      });
  }

  messageBox() {
    return (
      <div className="lease-esign-container">
        <font size="5">Please Wait ...</font>
        <p />
        Thank you for signing the lease agreement. We are doing some final
        processing to complete your order.
        <p />
        {this.state.isWorking && <Working />}
        <font color="red">{this.state.errorMessage}</font>
      </div>
    );
  }
  render() {
    return (
      <div className="top-wrapper">
        <div className="oc-container">{this.messageBox()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLeaseOkInfo: (info) => {
      dispatch(setLeaseOkInfo(info));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AfterEsignProcess);
