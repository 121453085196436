import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Link } from "react-router-dom";
import {
  removeItem,
  addQuantity,
  subtractQuantity,
  setCartMessage,
  setItems,
  setDiscount,
  clearCart,
} from "../actions/cartActions";
import {
  spRemoveItem,
  spAddQuantity,
  spSubtractQuantity,
  spClearCart,
} from "../actions/spCartActions";
import CartSum from "./CartSum";
import StoreCartSum from "./StoreCartSum";
import {
  FormatCurrency,
  isMobileMode,
  fullProductImageUrl,
  priceColor,
  getIconBaseUrl,
  getImageUrlForSKU,
  setTopContainerWrapperSettings,
  apiBaseUrl,
  generateGuid,
  getSkuDescription,
  getProdDetailUrl,
} from "../Util";
import Working from "../Working";
import { setUserId } from "../actions/userActions";
import { AXIOS_HEADER } from "../config/constants";
import ProductListView from "../product/ProductListView";
import { isInStore } from "../store/storeUtil";
import log from "loglevel";
import "../myLogger";
import "../../cart.css";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
      soItems: null,
      taxRate: 0.07,
      tariffRate: 0,
      cartMessage: "",
      lastUpdated: null,
      mobileMode: false,
      imageWidth: 150,
      showSignInAtCheckout: true,
      isLoading: true,
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.displayOneItem = this.displayOneItem.bind(this);
    this.quantityBox = this.quantityBox.bind(this);
    this.checkCart = this.checkCart.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.handleAddQuantity = this.handleAddQuantity.bind(this);
    this.handleAddQuantity = this.handleAddQuantity.bind(this);
  }

  //to add the quantity
  handleAddQuantity(item, isSP) {
    if (isSP) {
      this.props.spAddQuantity(item.sku);
    } else {
      this.props.addQuantity(item.sku);
    }
    this.setState({ lastUpdated: new Date() });
  }
  //to substruct from the quantity
  handleSubtractQuantity(item, isSP) {
    if (item.quantity > 1) {
      if (isSP) {
        this.props.spSubtractQuantity(item.sku);
      } else {
        this.props.subtractQuantity(item.sku);
      }
    } else {
      const count = this.props.itemCount + this.props.spItemCount;
      if (isSP) {
        this.props.spRemoveItem(item.sku);
      } else {
        this.props.removeItem(item.sku);
        if (this.props.discount) {
          window.location.reload(false);
        }
      }
      if (count <= 1) {
        this.props.clearCart();
        this.props.spClearCart();
      }
    }
    this.setState({ lastUpdated: new Date() });
  }

  componentDidMount() {
    this.checkCart();
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);

    if (this.props.cartMessage !== null) {
      this.setState({
        cartMessage: this.props.cartMessage,
      });
      this.props.setCartMessage(null);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  processResponse(response) {
    this.props.setDiscount(response.discount);

    if (response.changed) {
      this.setState({
        items: response.itemList,
        soItems: response.soldOutList,
        taxRate: response.taxRate,
        tariffRate: response.tariffRate,
        lastUpdated: new Date(),
        isLoading: false,
      });
      this.props.setItems(response.itemList);
    } else {
      this.setState({
        items: this.props.items,
        taxRate: response.taxRate,
        tariffRate: response.tariffRate,
        showSignInAtCheckout: response.showSignInAtCheckout,
        lastUpdated: new Date(),
        isLoading: false,
      });
    }
  }

  checkCart() {
    const url = apiBaseUrl() + "CheckCart";
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    const req = {
      userId: userId,
      itemList: this.props.items,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
        this.setState({ items: this.props.items, isLoading: false });
      });
  }

  adjustMode() {
    const width = isMobileMode() ? 80 : 150;

    this.setState({
      mobileMode: isMobileMode(),
      imageWidth: width,
    });
    setTopContainerWrapperSettings();
  }

  quantityBox(item, isSP) {
    return (
      <div className="qty-container">
        <div className="qty-minus" id="minus">
          <a
            href="#"
            onClick={() => {
              this.handleSubtractQuantity(item, isSP);
            }}
          >
            {item.quantity > 1 ? (
              <span className="other-sign">-</span>
            ) : (
              <span className="other-sign">x</span>
            )}
          </a>
        </div>

        <div className="qty-item">
          <div>
            <font size="4">{item.quantity}</font>
          </div>
        </div>
        <div className="qty-plus" id="plus">
          <a
            href="#"
            onClick={() => {
              this.handleAddQuantity(item, isSP);
            }}
          >
            <span className="other-sign">+</span>
          </a>
        </div>
      </div>
    );
  }

  displayOneItem(item, soldOut) {
    const description = getSkuDescription(item.product, item.sku);
    const len = description.length > 70 ? 70 : description.length;
    let desc = description.substring(0, len + 2) + " ...";
    let prodUrl = getProdDetailUrl(item.product);
    const height = (3 * this.state.imageWidth) / 4;

    if (item.product.pvList.length > 1) {
      prodUrl = prodUrl + "/" + item.sku;
    }

    try {
      desc = desc.replace("<p/>", "");
      desc = desc.replace("<br/>", "");
      desc = desc.replace("<p>", "");
      desc = desc.replace("</p>", "");
      desc = desc.replace("<br>", "");
      desc = desc.replace("</br>", "");
    } catch (error) {
      log.error(error);
    }

    return (
      <div className="items-container" key={item.sku}>
        <div className="item-wrapper">
          <div className="item-image">
            <Link to={prodUrl}>
              <img
                src={fullProductImageUrl(
                  getImageUrlForSKU(item.product, item.sku)
                )}
                alt={item.product.name}
                width={this.state.imageWidth}
                height={height}
              />
            </Link>
          </div>
          <div className="item-vt-wrapper">
            <div className="cart-line" align="left">
              {item.title}
            </div>
            <div className="cart-line" align="left">
              SKU: {item.sku}
            </div>
            <p />
            <div className="item-desc" align="left">
              {desc}
            </div>
            <div className="inner-row-wrapper">
              <div className="item-other">
                Unit Price:&nbsp;&nbsp;
                <font color={priceColor()}>
                  <FormatCurrency amount={item.price} />
                </font>
              </div>
              {soldOut ? (
                <div className="item-other">
                  <b>
                    <font color="red">&nbsp;SOLD OUT</font>
                  </b>
                </div>
              ) : (
                <React.Fragment>
                  {this.state.mobileMode ? (
                    ""
                  ) : (
                    <div className="item-other">Quantity: </div>
                  )}
                  {this.quantityBox(item)}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  removeItem(item) {
    this.props.spRemoveItem(item.sku);
    this.setState({ lastUpdated: new Date() });
  }
  displayOneStoreItem(item) {
    if (item.coupon || item.typeId > 0) return null;

    const imageUrl =
      item.product && item.product.imageUrl
        ? item.product.imageUrl
        : "store-items.jpg";
    const height = (3 * this.state.imageWidth) / 4;
    return (
      <div className="items-container" key={item.sku}>
        <div className="item-wrapper">
          <div className="item-image">
            <img
              src={fullProductImageUrl(imageUrl)}
              alt={item.title}
              width={this.state.imageWidth}
              height={height}
            />
          </div>
          <div className="item-vt-wrapper">
            <div className="cart-line" align="left">
              {item.title}
            </div>
            {item.sku &&
              !item.sku.startsWith("TYPED_") &&
              !item.sku.startsWith("COUPON_") && (
                <div className="cart-line" align="left">
                  SKU: {item.sku}
                </div>
              )}
            <div className="inner-row-wrapper">
              <div className="item-other">
                Unit Price:&nbsp;&nbsp;
                <font color={priceColor()}>
                  <FormatCurrency amount={item.price} />
                </font>
              </div>
              {this.state.mobileMode ? (
                ""
              ) : (
                <div className="item-other">Quantity: </div>
              )}
              {this.quantityBox(item, true)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  displayEmptyShoppingCart() {
    const imgUrl = getIconBaseUrl() + "shopping_cart2.png";
    return (
      <div className="top-wrapper">
        <div align="left" className="sc-container">
          <div className="sc-empty-wrapper" align="left">
            <font size="5">Shopping Cart</font>
            <p />
            <img src={imgUrl} alt="Cart" height="30"></img>Your shopping cart is
            empty.
            <p />
          </div>
          <ProductListView
            backendApi="SuggestedProducts"
            title="Suggested Products"
            isPost={true}
          />
        </div>
      </div>
    );
  }
  showSoldOutItems() {
    if (this.state.soItems && this.state.soItems.length > 0) {
      return (
        <div>
          <p />
          <b>The following items are no longer available:</b>
          <p />
          {this.state.soItems.map((item) => {
            return this.displayOneItem(item, true);
          })}
        </div>
      );
    }
  }
  isEmpty() {
    let count = this.props.items ? this.props.items.length : 0;
    if (count === 0 && isInStore()) {
      count = this.props.storeItems ? this.props.storeItems.length : 0;
    }
    return count === 0;
  }
  render() {
    if (this.state.isLoading) return <Working />;

    if (this.isEmpty()) {
      return this.displayEmptyShoppingCart();
    }

    let title = "Shopping Cart";
    if (this.props.title !== undefined && this.props.title !== "") {
      title = this.props.title;
    }

    return (
      <div className="top-wrapper">
        <div className="sc-container">
          <div className="sc-title" align="left">
            <font size="5">{title}</font>
          </div>
          {this.state.cartMessage && (
            <div className="items-container">
              <p />
              <b>
                <font color="red">{this.state.cartMessage}</font>
              </b>
              <p />
            </div>
          )}
          <br />

          {this.props.items.map((item) => {
            return this.displayOneItem(item, false);
          })}
          {isInStore() &&
            this.props.storeItems &&
            this.props.storeItems.map((item) => {
              return this.displayOneStoreItem(item);
            })}
          {isInStore() ? (
            <StoreCartSum
              show="true"
              taxRate={this.state.taxRate}
              tariffRate={this.state.tariffRate}
              mobileMode={this.state.mobileMode}
              lastUpdated={this.state.lastUpdated}
            />
          ) : (
            <CartSum
              show="true"
              taxRate={this.state.taxRate}
              tariffRate={this.state.tariffRate}
              mobileMode={this.state.mobileMode}
              showSignInAtCheckout={this.state.showSignInAtCheckout}
            />
          )}

          <p />
          {this.showSoldOutItems()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    items: state.cart.addedItems,
    itemCount: state.cart.itemCount,
    discount: state.cart.discount,
    cartMessage: state.cart.cartMessage,
    storeItems: state.spCart.storeItems,
    spItemCount: state.spCart.itemCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeItem: (sku) => {
      dispatch(removeItem(sku));
    },
    clearCart: () => {
      dispatch(clearCart());
    },
    addQuantity: (sku) => {
      dispatch(addQuantity(sku));
    },
    subtractQuantity: (sku) => {
      dispatch(subtractQuantity(sku));
    },
    setCartMessage: (msg) => {
      dispatch(setCartMessage(msg));
    },
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    setItems: (items) => {
      dispatch(setItems(items));
    },
    setDiscount: (discount) => {
      dispatch(setDiscount(discount));
    },
    spRemoveItem: (sku) => {
      dispatch(spRemoveItem(sku));
    },
    spClearCart: () => {
      dispatch(spClearCart());
    },
    spAddQuantity: (sku) => {
      dispatch(spAddQuantity(sku));
    },
    spSubtractQuantity: (sku) => {
      dispatch(spSubtractQuantity(sku));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
