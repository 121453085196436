import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import { getIconBaseUrl, apiBaseUrl, isMobileMode } from "../Util";
import { clearCart, setItems, setCartMessage } from "../actions/cartActions";
import {
  setOrderStage,
  setOrderInfo,
  clearStagedOrder,
} from "../actions/orderActions";
import { ORDERSTAGE_DELIVERYFEE_PAID } from "../order/orderStages";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import log from "loglevel";
import "../myLogger";
import "../../tables.css";
import "../../generic.css";
import "../../App.css";

class CreditCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      orderNumber: "",
      name: "",
      number: "",
      cvv: "",
      amount: this.props.amountChoiceList[0].amount,
      amountChoiceList: this.props.amountChoiceList,
      pacIdx: 0,
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "GA",
      zipCode: "",
      phone: "",
      email: "",
      stateList: null,
      expMonth: null,
      expYear: null,
      paymentName: "",
      useHTML5: false,
      submitBtnTitle: "Place Order",
      submitBtnEnabled: true,
      errorMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.populateAddress = this.populateAddress.bind(this);
    this.makePayment = this.makePayment.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.payLater = this.payLater.bind(this);
    this.handleExpChange = this.handleExpChange.bind(this);
    this.setCurrentDateAsExpDate = this.setCurrentDateAsExpDate.bind(this);
    this.processPmtOrderResponse = this.processPmtOrderResponse.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setInitState = this.setInitState.bind(this);
  }

  componentDidMount() {
    if (this.props.orderStage === 0) {
      this.props.history.push("/");
      return;
    }

    this.setInitState();
    this.populateAddress();
    this.setCurrentDateAsExpDate();

    const url = apiBaseUrl() + "StatesForPayment";
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ stateList: res.data });
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  setInitState() {
    const paymentName =
      this.state.amountChoiceList[this.state.pacIdx].displayName;
    const btnTitle = "Place Order";
    const name = this.props.contactInfo
      ? this.props.contactInfo.firstName + " " + this.props.contactInfo.lastName
      : "";
    const useHTML5 = isMobileMode() && this.props.supportHTML5 ? true : false;
    this.setState({
      paymentName,
      useHTML5,
      userId: this.props.userId,
      orderNumber: this.props.orderNumber,
      submitBtnTitle: btnTitle,
      amountChoiceList: this.props.amountChoiceList,
      name,
    });
  }
  componentDidUpdate(preProps) {
    if (this.props.amountChoiceList !== preProps.amountChoiceList) {
      this.setInitState();
    }
  }
  getThisMonAsString() {
    const nm = new Date().getMonth() + 1;
    const str = nm < 10 ? "0" + nm : "" + nm;
    return str;
  }

  setCurrentDateAsExpDate() {
    const year = new Date().getFullYear();
    const mon = this.getThisMonAsString();

    this.setState({ expMonth: mon, expYear: year });
  }

  handleChange(event) {
    const name = event.target.name;

    if (name === "pacIdx") {
      const pacIdx = parseInt(event.target.value);
      const amount = this.state.amountChoiceList[pacIdx].amount;
      const paymentName = this.state.amountChoiceList[pacIdx].displayName;
      this.setState({
        pacIdx,
        amount,
        paymentName,
      });
      if (this.props.amountChoiceCallback) {
        this.props.amountChoiceCallback(pacIdx);
      }
    } else {
      this.setState({
        [name]: event.target.value,
        errorMessage: null,
      });
    }
  }

  populateAddress(event) {
    const addr = this.props.contactInfo;

    if (addr === undefined || addr === null) return;

    if (!event || event.target.checked) {
      this.setState({
        firstName: addr.firstName,
        lastName: addr.lastName,
        addressLine1: addr.addressLine1,
        addressLine2: addr.addressLine2,
        city: addr.city,
        state: addr.state,
        zipCode: addr.zipCode,
        phone: addr.phone,
        email: addr.email,
      });
    }
  }

  processResponse(response) {
    this.props.setOrderStage(ORDERSTAGE_DELIVERYFEE_PAID);
    this.props.history.push("/orderComplete");
  }

  processPmtOrderResponse(response) {
    if (response) {
      if (response.status === "Processed") {
        const payload = {
          orderNumber: response.orderNumber,
          orderEmail: response.email,
          codAmount: response.codAmount,
          orderCompleteMsg: response.orderCompleteMsg,
        };

        this.props.clearCart();
        this.props.clearStagedOrder();
        this.props.setOrderInfo(payload);
        this.props.setOrderStage(ORDERSTAGE_DELIVERYFEE_PAID);
        this.props.history.push("/orderComplete");
      } else if (response.status == "PriceChanged") {
        this.props.setItems(response.newItemList);
        this.props.setCartMessage(response.errorMessage);
        this.props.history.push("/cart");
      } else if (response.status == "InvalidPromotion") {
        this.props.setCartMessage(response.errorMessage);
        this.props.history.push("/cart");
      } else if (response.status === "ItemSoldOut") {
        //this.props.setItems(response.newItemList);
        this.props.setCartMessage(response.errorMessage);
        this.props.history.push("/cart");
      } else if (response.status === "FatalError") {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
        });
        this.props.history.push("/");
      } else if (response.status === "PaymentError") {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
        });
      } else if (response.status === "ScheduleError") {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
        });
        if (this.props.scheduleErrCallback) {
          this.props.scheduleErrCallback(response.errorMessage);
        }
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
        });
      }
    } else {
      this.setState({
        errorMessage: "Failed to process your order.",
        submitBtnEnabled: true,
      });
    }
  }
  handleSubmit(event) {
    this.setState({ submitBtnEnabled: false });
    //this.submitPaymentAndOrder();
    this.props.callback(this.state, this.processPmtOrderResponse);
  }
  makePayment() {
    const url = apiBaseUrl() + "RemitPayment";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, this.state, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        log.error(error);
        console.log(error);
      });
  }

  payLater() {
    this.props.history.push("/orderComplete");
  }

  getStateList() {
    if (this.state.stateList === null) return "Georgia";
    else {
      return (
        <select
          name="state"
          value={this.state.state}
          onChange={this.handleChange}
        >
          {this.state.stateList.map((pair, idx) => {
            return (
              <option value={pair.value} key={idx}>
                {pair.key}
              </option>
            );
          })}
        </select>
      );
    }
  }

  handleExpChange(event) {
    let thisYear = new Date().getFullYear();
    const thisMon = this.getThisMonAsString();

    if (event.target.name === "year") {
      this.setState({ expYear: event.target.value });

      if (event.target.value === thisYear) {
        const selMon = document.getElementById("month").value;

        if (selMon < thisMon) {
          this.setState({ expMonth: thisMon });
        }
      }
    } else {
      this.setState({ expMonth: event.target.value });

      if (thisMon > event.target.value) {
        const selYear = document.getElementById("year").value;
        if (selYear === thisYear) {
          this.setState({ expYear: thisYear + 1 });
        }
      }
    }
  }

  expDate() {
    const thisYear = new Date().getFullYear();
    let years = [],
      i;
    for (i = 0; i < 15; i++) {
      years.push(thisYear + i);
    }

    return (
      <div className="tbl-container4">
        <div>
          <select
            id="month"
            name="month"
            value={this.state.expMonth || ""}
            onChange={this.handleExpChange}
          >
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
        <div>&nbsp;/&nbsp;</div>
        <div>
          <select
            id="year"
            name="year"
            value={this.state.expYear || ""}
            onChange={this.handleExpChange}
          >
            {years.map((yr) => {
              return (
                <option value={yr} key={yr}>
                  {yr}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }

  billingAddressForm() {
    const required = this.props.billingAddrRequired;
    if (required || this.props.useBillingAddress) {
      const numType = this.state.useHTML5 ? "number" : "text";
      const showSACI = this.props.stagedOrder ? false : true;

      return (
        <div>
          <p>
            <b>Billing Information</b> (Change if different than your contact
            info)
          </p>
          {showSACI && (
            <React.Fragment>
              <input type="checkbox" onClick={this.populateAddress}></input>
              Same as contact address
            </React.Fragment>
          )}
          <table>
            <tbody>
              <tr>
                <td>
                  <label>
                    First Name{required && <font color="red">*</font>}:{" "}
                  </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type="text"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["First Name is required"]}
                    />
                  ) : (
                    <input
                      type="text"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Last Name{required && <font color="red">*</font>}:{" "}
                  </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type="text"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["Last Name is required"]}
                    />
                  ) : (
                    <input
                      type="text"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Address Line1{required && <font color="red">*</font>}:{" "}
                  </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type="text"
                      name="addressLine1"
                      size="30"
                      value={this.state.addressLine1}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["Address is required"]}
                    />
                  ) : (
                    <input
                      type="text"
                      name="addressLine1"
                      size="30"
                      value={this.state.addressLine1}
                      onChange={this.handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>Address Line2: </label>
                </td>
                <td>
                  <TextInput
                    type="text"
                    name="addressLine2"
                    size="30"
                    value={this.state.addressLine2}
                    onChange={this.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>City{required && <font color="red">*</font>}: </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type="text"
                      name="city"
                      value={this.state.city}
                      onChange={this.handleChange}
                      onBlur={this.validateCity2}
                      validators={["required"]}
                      errorMessages={["City is required"]}
                    />
                  ) : (
                    <input
                      type="text"
                      name="city"
                      value={this.state.city}
                      onChange={this.handleChange}
                      onBlur={this.validateCity2}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>State{required && <font color="red">*</font>}: </label>
                </td>
                <td>{this.getStateList()}</td>
              </tr>
              <tr>
                <td>
                  <label>
                    ZIP Code{required && <font color="red">*</font>}:{" "}
                  </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type={numType}
                      name="zipCode"
                      size="10"
                      value={this.state.zipCode}
                      onChange={this.handleChange}
                      validators={[
                        "required",
                        "matchRegexp:^(\\d{5}(?:\\-\\d{4})?)$",
                      ]}
                      errorMessages={[
                        "ZIP Code is required",
                        "Invalid ZIP code",
                      ]}
                    />
                  ) : (
                    <input
                      type={numType}
                      name="zipCode"
                      size="10"
                      value={this.state.zipCode}
                      onChange={this.handleChange}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }
  paymentAmount() {
    const pac = this.state.amountChoiceList[this.state.pacIdx];
    const amount = pac.amount;
    let name = pac.displayName;

    if (this.props.deliveryFeeDisplayName && name == "Delivery Fee") {
      name = this.props.deliveryFeeDisplayName;
    }

    if (this.state.amountChoiceList.length === 1) {
      return (
        <tr>
          <td>Payment Amount: </td>
          <td>
            <b>${amount.toFixed(2)}</b> {name && <label>({name})</label>}
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td className="valign-tbl">Payment Amount:&nbsp; </td>
          <td className="valign-tbl">
            <select
              name="pacIdx"
              value={this.state.pacIdx}
              onChange={this.handleChange}
            >
              {this.state.amountChoiceList.map((choice, idx) => {
                const display =
                  choice.displayName + ": $" + choice.amount.toFixed(2);
                return (
                  <option value={idx} key={idx}>
                    {display}
                  </option>
                );
              })}
            </select>
            <br />
            <b>${amount.toFixed(2)}</b> ({name})
          </td>
        </tr>
      );
    }
  }
  render() {
    const imgUrl = getIconBaseUrl() + "CreditCard-Logos.png";

    const btnStyle = this.state.submitBtnEnabled
      ? "btn-style"
      : "disabled-btn-style";
    const numType = this.state.useHTML5 ? "number" : "text";
    const errMsg =
      '<font color="red">' + this.state.errorMessage + "</font><p/>";
    return (
      <div align="left">
        {this.state.errorMessage && (
          <div
            dangerouslySetInnerHTML={{
              __html: errMsg,
            }}
          ></div>
        )}
        <font size="4">Credit Card</font> &nbsp;&nbsp;
        <img src={imgUrl} height="20" alt="Credit Cards"></img>
        <p />
        <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
          <table>
            <tbody>
              <tr>
                <td>
                  <label>
                    Name on card<font color="red">*</font>:{" "}
                  </label>
                </td>
                <td>
                  <TextInput
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Name is required"]}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Card number<font color="red">*</font>:{" "}
                  </label>
                </td>
                <td>
                  {this.props.validateCCNumber ? (
                    <TextInput
                      type={numType}
                      name="number"
                      value={this.state.number}
                      onChange={this.handleChange}
                      placeholder="Card number, no space"
                      validators={[
                        "required",
                        "isNumber",
                        "minStringLength: 13",
                        "maxStringLength: 16",
                      ]}
                      errorMessages={[
                        "Card number is required",
                        "Must be a number, no space",
                        "Credit card number too short",
                        "Credit card number too long",
                      ]}
                    ></TextInput>
                  ) : (
                    <TextInput
                      type={numType}
                      name="number"
                      value={this.state.number}
                      onChange={this.handleChange}
                      placeholder="Card number, no space"
                      validators={["required"]}
                      errorMessages={["Card number is required"]}
                    ></TextInput>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Expiration date<font color="red">*</font>:{" "}
                  </label>
                </td>
                <td>{this.expDate()}</td>
              </tr>
              <tr>
                <td>
                  <label>
                    CVV<font color="red">*</font>:{" "}
                  </label>
                </td>
                <td>
                  <TextInput
                    type={numType}
                    size="4"
                    name="cvv"
                    value={this.state.cvv}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["CVV is required"]}
                  ></TextInput>
                </td>
              </tr>
              {!this.props.useBillingAddress && (
                <tr>
                  <td>Billing Zip Code:</td>
                  <td>
                    <input
                      type={numType}
                      size="10"
                      name="zipCode"
                      value={this.state.zipCode}
                      onChange={this.handleChange}
                    ></input>
                  </td>
                </tr>
              )}
              {this.paymentAmount()}
            </tbody>
          </table>

          {this.billingAddressForm()}
          <p />
          <div>
            <button
              id="submitBtn"
              className={btnStyle}
              type="submit"
              disabled={!this.state.submitBtnEnabled}
            >
              {this.state.submitBtnTitle}
            </button>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    address: state.order.deliveryInfo,
    deliveryFee: state.order.deliveryFee,
    contactInfo: state.order.contactInfo,
    isLoggedIn: state.user.isLoggedIn,
    orderNumber: state.order.orderNumber,
    userId: state.user.userId,
    shippingChoice: state.order.shippingChoice,
    ratefees: state.order.ratefees,
    orderStage: state.order.stage,
    stagedOrder: state.order.stagedOrder,
    stagedOrderNum: state.order.stagedOrderNum,
    scheduledDate: state.order.scheduledDate,
    supportHTML5: state.cache.supportHTML5,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOrderStage: (stage) => {
      dispatch(setOrderStage(stage));
    },
    clearCart: () => {
      dispatch(clearCart());
    },
    setOrderInfo: (payload) => {
      dispatch(setOrderInfo(payload));
    },
    clearStagedOrder: () => {
      dispatch(clearStagedOrder());
    },
    setItems: (items) => {
      dispatch(setItems(items));
    },
    setCartMessage: (msg) => {
      dispatch(setCartMessage(msg));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreditCard));
