import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { btnStyle } from "../Styles";
import {
  setDeliveryInfo,
  setContactInfo,
  setShippingChoice,
} from "../actions/orderActions";
import { setUserInfo } from "../actions/userActions";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  checkCityForDelivery,
  setTopContainerWrapperSettings,
  parseAddress,
} from "../Util";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import log from "loglevel";
import "../myLogger";
import "../../contact.css";
import "../../info.css";
import { isInStore } from "./storeUtil";

class InStoreCustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "GA",
      zipCode: "",
      phone: "",
      email: "",
      deliveryInstr: "",
      googleMapLink: null,
      formTitle: "Delivery Address",
      addressType: "Delivery",
      stateList: null,
      distance: null,
      driveTime: null,
      miles: 0,
      storeLoc: null,
      warehouseAddr: "4795 Fulton Ind. Blvd SW, Atlanta, GA 30336",
      errorMessage: "",
      outOfAreaMessage: null,
      dialogTitle: null,
      showStoreDialog: false,
      overrideDeliveryFee: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.adjustSettings = this.adjustSettings.bind(this);
    this.setStatesAndUserInfo = this.setStatesAndUserInfo.bind(this);
    this.gotoNextPage = this.gotoNextPage.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.setTypeAndTitle = this.setTypeAndTitle.bind(this);
    this.setDistanceInfo = this.setDistanceInfo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.processLocResponse = this.processLocResponse.bind(this);
    this.getDistance = this.getDistance.bind(this);
    this.autocomplete = null;

    this.validateCity = this.validateCity.bind(this);
    this.checkCityResponse = this.checkCityResponse.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    this.getStoreLoc();
    this.setTypeAndTitle();
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
    this.setStatesAndUserInfo();
  }
  processLocResponse(response) {
    const storeLoc = response;
    this.setState({ storeLoc });

    if (storeLoc.storeSetting.autoCompleteAddr) {
      const warehouse = storeLoc.warehouse;
      const radius = warehouse.deliveryRadius * 1600; //miles --> meters
      const circle = new window.google.maps.Circle({
        //center: new window.google.maps.LatLng(33.75511, -84.54704),
        center: new window.google.maps.LatLng(
          warehouse.latitude,
          warehouse.longitude
        ),
        radius: radius,
      });

      this.autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { bounds: circle.getBounds(), strictbounds: true }
      );
      this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
      const warehouseAddr =
        warehouse.addressLine1 +
        ", " +
        warehouse.city +
        ", " +
        warehouse.state +
        " " +
        warehouse.zipCode;
      this.setState({ warehouseAddr });
    }
  }
  getStoreLoc() {
    const url = apiBaseUrl() + "LocationInfo/" + this.props.storeId;
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, this.state, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processLocResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  setTypeAndTitle() {
    if (this.props.type === "2") {
      this.setState({
        formTitle: "Contact Information",
        addressType: "Pickup",
      });
    } else {
      this.setState({
        formTitle: "Delivery Address",
        addressType: "Delivery",
      });
    }
  }
  setDistanceInfo(response, status) {
    let distance = null;
    let driveTime = null;
    let miles = 0;

    if (
      response.rows &&
      response.rows.length > 0 &&
      response.rows[0].elements &&
      response.rows[0].elements.length > 0
    ) {
      const elem = response.rows[0].elements[0];
      if (elem.distance) {
        distance = elem.distance.text;
        miles = elem.distance.value / 1600;
      }
      if (elem.duration) driveTime = elem.duration.text;
    }
    this.setState({ distance, driveTime, miles });
  }
  handlePlaceSelect() {
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    const addrObj = parseAddress(address);

    try {
      this.setState({
        addressLine1: addrObj.addressLine1,
        city: addrObj.city,
        state: addrObj.state,
        zipCode: addrObj.zipCode,
        googleMapLink: addressObject.url,
      });
      document.getElementById("autocomplete").value = addrObj.addressLine1;
    } catch (error) {
      console.log(error);
    }
    const loc = this.state.storeLoc;
    if (loc && loc.storeSetting && loc.storeSetting.showDistanceInfo) {
      this.getDistance(addrObj);
    }
  }
  getDistance(addrObj) {
    const url = apiBaseUrl() + "GetDistance";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, addrObj, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        if (res.data && res.data.status) {
          this.setState({
            distance: "" + res.data.distance + " miles",
            driveTime: res.data.driveTime,
            miles: res.data.distance,
          });
        }
      })
      .catch((error) => {
        this.calcDistance(addrObj);
        log.error(error);
      });
  }
  async calcDistance(addrObj) {
    const destAddr =
      addrObj.addressLin1 +
      ", " +
      addrObj.city +
      ", " +
      addrObj.state +
      " " +
      addrObj.zipCode;
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [this.state.warehouseAddr],
        destinations: [destAddr],
        travelMode: "DRIVING",
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        drivingOptions: {
          departureTime: new Date(Date.now() + 1000),
          trafficModel: "optimistic",
        },
        avoidHighways: false,
        avoidTolls: true,
      },
      this.setDistanceInfo
    );
  }
  setStatesAndUserInfo() {
    if (!isInStore()) {
      if (this.props.addressType === "Delivery") {
        if (this.props.deliveryInfo) {
          this.setState(this.props.deliveryInfo);
        } else {
          const info = this.props.contactInfo
            ? this.props.contactInfo
            : this.props.userInfo;
          if (
            info &&
            info.state &&
            (info.state === "GA" || info.state == "Georgia")
          ) {
            this.setState(info);
          }
        }
      } else {
        const info = this.props.contactInfo
          ? this.props.contactInfo
          : this.props.userInfo;
        if (info) {
          this.setState(info);
        }
      }
    }

    const type =
      this.props.addressType === "LayawayContact"
        ? "Payment"
        : this.props.addressType;
    const url = apiBaseUrl() + "StatesFor" + type;
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ stateList: res.data });
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }

  componentDidUpdate(preProps) {
    if (
      this.props.title !== preProps.title ||
      this.props.addressType !== preProps.addressType
    ) {
      let url = "/shippingChoice";
      if (this.props.addressType !== "Delivery") url += "/2";
      this.props.history.push(url);
      window.location.reload(url);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }
  adjustSettings() {
    setTopContainerWrapperSettings();
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: null,
    });
  }
  gotoNextPage() {
    const name = this.state.name;
    let firstName = "";
    let lastName = "";
    if (name) {
      const pos = name.indexOf(" ");
      if (pos > 0) {
        firstName = name.substring(0, pos);
        lastName = name.substring(pos + 1);
      } else firstName = name;
    }
    const overrideDeliveryFee = this.state.overrideDeliveryFee
      ? parseInt(this.state.overrideDeliveryFee)
      : -1;
    const info = {
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      city: this.state.city,
      state: this.state.state,
      zipCode: this.state.zipCode,
      phone: this.state.phone,
      email: this.state.email,
      deliveryInstr: this.state.deliveryInstr,
      firstName,
      lastName,
      overrideDeliveryFee,
    };
    this.props.setUserInfo(info);
    this.props.setContactInfo(info);
    if (this.props.addressType === "LayawayContact") {
      this.props.history.push("/layawaySummary");
      return;
    }
    if (this.props.addressType === "Delivery") {
      this.props.setDeliveryInfo(info);
    } else {
      this.props.setShippingChoice("Pickup");
      this.props.setDeliveryInfo(null);
    }
    this.props.history.push("/orderSummary");
  }

  checkCityResponse(city, res) {
    if (res.status === true) {
      this.gotoNextPage();
    } else {
      const errorMessage = res.outOfAreaMessage
        ? "Please either choose Pickup or specify the Delivery Fee"
        : res.errorMessage;
      this.setState({
        outOfAreaMessage: res.outOfAreaMessage,
        errorMessage,
        dialogTitle: res.dialogTitle,
      });
      document.getElementById("cityWarnBtn").click();
    }
  }
  getOrderSubtotal() {
    let subtotal = this.props.subtotal;
    const storeItems = this.props.storeItems;
    const discount = this.props.discount;

    if (isInStore() && storeItems && storeItems.length > 0) {
      for (let i = 0; i < storeItems.length; i++) {
        if (!storeItems[i].coupon) {
          subtotal += storeItems[i].quantity * storeItems[i].price;
        }
      }
    }

    if (discount) subtotal = subtotal - discount.amount;
    return subtotal;
  }
  validateCity() {
    const city = this.state.city;
    const url = apiBaseUrl() + "CheckDeliveryCity";
    const address = {
      ...this.state,
      userId: this.props.userId,
    };
    const req = {
      address,
      orderSubtotal: this.getOrderSubtotal(),
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.checkCityResponse(city, res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  handleSubmit() {
    if (this.props.addressType === "Delivery") {
      let errorMessage = null;
      if (!this.state.name) errorMessage = "Name is required";
      else if (!this.state.phone) errorMessage = "Phone is required";
      else if (!this.state.addressLine1) errorMessage = "Address is required";
      else if (!this.state.city) errorMessage = "City is required";
      else if (!this.state.zipCode) errorMessage = "ZIP code is required";

      if (errorMessage) {
        this.setState({ errorMessage });
        return;
      } else {
        const overrideDeliveryFee = this.state.overrideDeliveryFee
          ? parseInt(this.state.overrideDeliveryFee)
          : -1;
        if (checkCityForDelivery() && overrideDeliveryFee <= 0) {
          this.validateCity();
        } else {
          this.gotoNextPage();
        }
      }
    } else if (this.props.addressType === "LayawayContact") {
      let errorMessage = null;
      if (!this.state.name) errorMessage = "Name is required";
      else if (!this.state.phone) errorMessage = "Phone is required";
      if (errorMessage) {
        this.setState({ errorMessage });
        return;
      } else {
        this.gotoNextPage();
      }
    } else this.gotoNextPage();
  }
  openDialog() {
    this.setState({ showStoreDialog: true });
  }
  closeDialog() {
    this.setState({ showStoreDialog: false });
  }

  hiddenButton() {
    return (
      <button
        id="cityWarnBtn"
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  showDialogMessage() {
    const title = this.state.dialogTitle
      ? this.state.dialogTitle
      : "Out Of Delivery Area";
    return (
      <div>
        {this.hiddenButton()}
        <Dialog
          isOpen={this.state.showStoreDialog}
          onDismiss={this.closeDialog}
        >
          <b>{title}</b>
          <p>{this.state.outOfAreaMessage}</p>
          You can either change to "Pickup" or specify the <b>Delivery Fee</b>
          &nbsp; below and click "Continue" again.
          <p />
          <button onClick={this.closeDialog} style={btnStyle}>
            Okay
          </button>
        </Dialog>
      </div>
    );
  }
  getStateList() {
    if (this.state.stateList === null) return "Georgia";

    return (
      <select
        name="state"
        value={this.state.state}
        onChange={this.handleChange}
      >
        {this.state.stateList.map((pair, idx) => {
          return (
            <option value={pair.value} key={idx}>
              {pair.key}
            </option>
          );
        })}
      </select>
    );
  }

  displayMessage() {
    if (this.state.errorMessage && this.state.errorMessage.length > 0) {
      return (
        <div className="ct-title">
          <p />
          <font color="red">{this.state.errorMessage}</font>
          <br />
        </div>
      );
    }
  }
  addressForm(required) {
    const txtLen = this.props.android ? 20 : 30;
    return (
      <React.Fragment>
        <div className="cttbl-item-left">
          {required && <font color="red">*</font>}
          Address:{" "}
        </div>
        <div className="cttbl-item-left">
          <input
            id="autocomplete"
            ref="input"
            type="text"
            name="addressLine1"
            size="45"
            onChange={this.handleChange}
          />
        </div>
        <div className="cttbl-item-left">
          {required && <span>&nbsp;</span>}Address2:
        </div>
        <div className="cttbl-item-left">
          <input
            type="text"
            name="addressLine2"
            size={txtLen}
            placeholder="(Apartment number, etc.)"
            value={this.state.addressLine2}
            onChange={this.handleChange}
          />
        </div>
        <div className="cttbl-item-left">
          {required && <font color="red">*</font>}City:{" "}
        </div>
        <div className="cttbl-item-left">
          <input
            type="text"
            name="city"
            value={this.state.city}
            onChange={this.handleChange}
          />
        </div>
        <div className="cttbl-item-left">
          {required && <font color="red">*</font>}State:{" "}
        </div>
        <div className="cttbl-item-left">{this.getStateList()}</div>
        <div className="cttbl-item-left">
          {required && <font color="red">*</font>}ZIP code:{" "}
        </div>
        <div className="cttbl-item-left">
          <input
            type="text"
            name="zipCode"
            size="10"
            value={this.state.zipCode}
            onChange={this.handleChange}
          />
        </div>
        {this.state.outOfAreaMessage && (
          <React.Fragment>
            <div className="cttbl-item-left">
              <b>Delivery Fee:</b>{" "}
            </div>
            <div className="cttbl-item-left">
              <input
                type="text"
                name="overrideDeliveryFee"
                size="8"
                value={this.state.overrideDeliveryFee || ""}
                onChange={this.handleChange}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  showDistanceInfo() {
    if (this.state.distance) {
      const color = this.state.miles > 50 ? "red" : null;
      return (
        <div className="left-10">
          <font color="darkblue">Driving Distance: </font>{" "}
          {color ? (
            <font color={color}>{this.state.distance}</font>
          ) : (
            <span>{this.state.distance}</span>
          )}
          <br />
          {this.state.driveTime && (
            <label>
              <font color="darkblue">Est. Driving Time: </font>{" "}
              {this.state.driveTime}
            </label>
          )}
          <p />
        </div>
      );
    }
  }
  deliveryForm() {
    const txtLen = this.props.android ? 20 : 30;
    const colCnt = this.props.android ? 40 : 50;
    const rowCnt = this.props.android ? 6 : 5;
    return (
      <div className="ct-wrapper">
        <div className="cttbl-container">
          <div className="cttbl-item-left">
            <font color="red">*</font>Name:{" "}
          </div>

          <div className="cttbl-item-left">
            <input
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">
            <font color="red">*</font>Phone:{" "}
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="phone"
              size="15"
              value={this.state.phone}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">&nbsp;Email: </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="email"
              size={txtLen}
              value={this.state.email}
              onChange={this.handleChange}
            />
          </div>
          {this.addressForm(true)}
        </div>
        {this.showDistanceInfo()}
        {this.props.addressType === "Delivery" && (
          <React.Fragment>
            <div align="left">
              <div>Add Delivery Instruction:</div>
              <textarea
                name="deliveryInstr"
                rows={rowCnt}
                cols={colCnt}
                value={this.state.deliveryInstr}
                onChange={this.handleChange}
              ></textarea>
            </div>
            <div className="cttbl-item-left">
              <button style={btnStyle} onClick={this.handleSubmit}>
                Continue
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
  pickupLayawayForm(layaway) {
    const txtLen = this.props.android ? 20 : 30;
    const colCnt = this.props.android ? 40 : 50;
    const rowCnt = this.props.android ? 6 : 5;
    return (
      <div className="ct-wrapper">
        <div className="cttbl-container">
          <div className="cttbl-item-left">
            Name{layaway && <font color="red">*</font>}:{" "}
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="name"
              value={this.state.name || ""}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">
            Phone{layaway && <font color="red">*</font>}:{" "}
          </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="phone"
              size="15"
              value={this.state.phone}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">Email: </div>
          <div className="cttbl-item-left">
            <input
              type="text"
              name="email"
              size={txtLen}
              value={this.state.email}
              onChange={this.handleChange}
            />
          </div>
          {this.addressForm(false)}
        </div>
        <div align="left" className="left-10">
          <div>Add Note:</div>
          <textarea
            name="deliveryInstr"
            rows={rowCnt}
            cols={colCnt}
            value={this.state.deliveryInstr}
            onChange={this.handleChange}
          ></textarea>
        </div>
        <div className="left-10">
          <button style={btnStyle} onClick={this.handleSubmit}>
            Continue
          </button>
        </div>
      </div>
    );
  }
  theForm() {
    if (this.props.addressType === "LayawayContact") {
      return this.pickupLayawayForm(true);
    } else {
      if (this.props.addressType === "Delivery") return this.deliveryForm();
      else return this.pickupLayawayForm(false);
    }
  }
  render() {
    return (
      <div>
        <div className="contact-title">
          <b>
            <font size="4">{this.props.title}</font>
          </b>
        </div>
        {this.displayMessage()}
        {this.theForm()}
        {this.showDialogMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    contactInfo: state.order.contactInfo,
    deliveryInfo: state.order.deliveryInfo,
    orderStage: state.order.stage,
    android: state.cache.android,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
    subtotal: state.cart.subtotal,
    storeItems: state.spCart.storeItems,
    discount: state.cart.discount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeliveryInfo: (info) => {
      dispatch(setDeliveryInfo(info));
    },
    setContactInfo: (info) => {
      dispatch(setContactInfo(info));
    },
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
    setShippingChoice: (choice) => {
      dispatch(setShippingChoice(choice));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InStoreCustomerForm));
