import React from "react";
import { connect } from "react-redux";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "../../App.css";
import "../../form.css";
import { fullProductImageUrl, isMobileMode } from "../Util";

class Deposit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      amount: null,
      nameOnCard: null,
      last4: null,
      approvalCode: null,
      errorMessage: null,
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleOK = this.handleOK.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showConfirmation = this.showConfirmation.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.item !== prevProps.item) {
      this.setState({ amount: null, nameOnCard: null, last4: null });
    }
  }
  showConfirmation() {
    const btnName = "showDepositBtn" + this.props.item.id;
    document.getElementById(btnName).click();
  }
  openDialog(event) {
    event.preventDefault();
    this.setState({ showDialog: true });
  }
  closeDialog() {
    this.setState({ showDialog: false });
  }
  hiddenActionButton() {
    const btnName = "showDepositBtn" + this.props.item.id;
    return (
      <button
        id={btnName}
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  handleOK() {
    let amount = 0;
    if (!this.state.amount || this.state.amount === 0) {
      this.setState({ errorMessage: "Amount is required" });
      return;
    } else {
      try {
        amount = parseFloat(this.state.amount);
      } catch (error) {
        console.log(error);
      }
      if (amount === 0 || isNaN(amount)) {
        this.setState({ errorMessage: "Invalid amount" });
        return;
      }
    }
    this.closeDialog();
    let sku = this.props.item.sku;
    if (this.state.last4 && this.state.last4.trim().length > 0) {
      sku = sku + "_" + this.state.last4.trim();
    }
    const deposit = {
      ...this.props.item,
      sku,
      amount,
      finalPrice: amount,
      nameOnCard: this.state.nameOnCard,
      last4: this.state.last4,
      approvalCode: this.state.approvalCode,
    };
    this.props.actionHandler(deposit);
  }
  handleChange(event) {
    const name = event.target.name;
    this.setState({ [name]: event.target.value, errorMessage: null });
  }
  confirmationDialog() {
    const mobile = isMobileMode();
    const rClass = mobile ? "none" : "form-wrapper";
    const lClass = mobile ? "none" : "form-item-label";
    const vClass = mobile ? "none" : "form-item-value";
    const imageUrl =
      this.props.item.depositType && this.props.item.depositType.imageUrl
        ? this.props.item.depositType.imageUrl
        : this.props.item.imageUrl;
    let messages = [];

    if (this.props.item.layaway && this.props.orderTotal > 0) {
      const pcnt10 = this.props.orderTotal * 0.1 - this.props.depositAmount;
      const pcnt20 = this.props.orderTotal * 0.2 - this.props.depositAmount;
      const more = this.props.depositAmount > 0 ? " more" : "";
      let k = 10;
      messages.push(
        <div key={k++}>Order Total: ${this.props.orderTotal.toFixed(2)}</div>
      );
      if (this.props.depositAmount > 0) {
        messages.push(
          <div key={k++}>
            Current Deposit: ${this.props.depositAmount.toFixed(2)}
          </div>
        );
      }
      if (pcnt10 > 0) {
        messages.push(
          <div key={k++}>
            10% Down: ${pcnt10.toFixed(2)}
            {more}
          </div>
        );
      }
      if (pcnt20 > 0) {
        messages.push(
          <div key={k++}>
            20% Down: ${pcnt20.toFixed(2)}
            {more}
          </div>
        );
      }
      messages.push(<div key={k++}>&nbsp;</div>);
    }
    return (
      <div>
        {this.hiddenActionButton()}
        <Dialog
          isOpen={this.state.showDialog}
          onDismiss={this.closeDialog}
          aria-label="Deposit"
        >
          <div className="flex-wrapper">
            <div>
              <img src={fullProductImageUrl(imageUrl)} height="30"></img>
            </div>
            <div>
              <b>&nbsp;{this.props.item.name}</b>
            </div>
          </div>
          {this.state.errorMessage && (
            <font color="red">
              {this.state.errorMessage}
              <br />
            </font>
          )}
          {messages}
          <div className={rClass}>
            <div className={lClass}>
              Amount<font color="red">*</font>:{" "}
            </div>
            <div className={vClass}>
              <input
                type="text"
                size="6"
                name="amount"
                inputMode="decimal"
                value={this.state.amount || ""}
                onChange={this.handleChange}
              ></input>
            </div>
            {this.props.item.depositType.cardDeposit && (
              <React.Fragment>
                <div className={lClass}>Name on Card: </div>
                <div className={vClass}>
                  <input
                    type="text"
                    size="20"
                    name="nameOnCard"
                    value={this.state.nameOnCard || ""}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className={lClass}>Last 4: </div>
                <div className={vClass}>
                  <input
                    type="text"
                    size="6"
                    name="last4"
                    value={this.state.last4 || ""}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className={lClass}>Approval Code: </div>
                <div className={vClass}>
                  <input
                    type="text"
                    size="20"
                    name="approvalCode"
                    value={this.state.approvalCode || ""}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </React.Fragment>
            )}
          </div>
          <p />
          <button onClick={this.handleOK} className="btn-style">
            OK
          </button>
          &nbsp;&nbsp;
          <button onClick={this.closeDialog} className="btn-style">
            Cancel
          </button>
        </Dialog>
      </div>
    );
  }

  render() {
    const clsName = this.props.buttonClsName
      ? this.props.buttonClsName
      : "btn-style";
    return (
      <div>
        {this.props.hidden ? (
          <button
            id={this.props.btnId}
            className={clsName}
            onClick={this.showConfirmation}
            style={{ display: "none" }}
          >
            {this.props.buttonTitle}
          </button>
        ) : (
          <button className={clsName} onClick={this.showConfirmation}>
            {this.props.buttonTitle}
          </button>
        )}
        {this.confirmationDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    android: state.cache.android,
  };
};
export default connect(mapStateToProps)(Deposit);
