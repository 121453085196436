import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { fullProductImageUrl, getProdDetailUrl, priceRange } from "../Util";

function ItemThumbnail(props) {
  const width = props.width === undefined ? 300 : props.width;
  const height = props.height === undefined ? 200 : props.height;
  const prod = props.item;
  const soldOut = prod.forSale === false ? "SOLD OUT" : "";
  const url = getProdDetailUrl(prod);
  const imageUrl = prod.useFirstPvImage
    ? prod.pvList[0].imageUrl
    : prod.thumbnailImage
    ? prod.thumbnailImage
    : prod.imageUrl;
  const label = prod.lowPriceVar === prod.highPriceVar ? "Our Price: " : "";
  const name =
    prod.useFirstPvImage && prod.pvList[0].fullName
      ? prod.pvList[0].fullName
      : prod.name;
  return (
    <div key={prod.productId}>
      <Link to={url}>
        <img
          src={fullProductImageUrl(imageUrl)}
          width={width}
          height={height}
          alt={name}
        ></img>
      </Link>
      <br />
      <b>{name}</b> <br />
      {label}
      {priceRange(prod)}
      <br />
      <b>
        <font color="red">{soldOut}</font>
      </b>
    </div>
  );
}
export default ItemThumbnail;
