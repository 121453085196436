export const btnStyle = {
  backgroundColor: "#3A4DAE",
  color: "white",
  padding: "5px 20px",
  fontSize: "13px"
};

export const marginStyle = {
  marginTop: "15px",
  marginLeft: "20px"
};
