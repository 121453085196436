import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, useIpForWebOrderReg } from "../Util";
import { registerWebOrder } from "../actions/webOrderActions";
import { clearCart } from "../actions/cartActions";
import { spClearCart } from "../actions/spCartActions";
import { clearUser } from "../actions/userActions";
import { clearOrder } from "../actions/orderActions";
import { clearSearch } from "../actions/searchActions";
import { clearCache } from "../actions/cacheActions";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorMessage: null };
    this.processResponse = this.processResponse.bind(this);
  }
  processResponse(response, kiosk) {
    if (response.status) {
      const payload = {
        userId: this.props.userId,
        storeId: response.storeId,
        storeName: response.storeName,
        authToken: response.authToken,
        kiosk,
      };
      this.props.registerWebOrder(payload);
      this.props.history.push("/");
    } else {
      this.setState({ errorMessage: response.errorMessage });
    }
  }
  componentDidMount() {
    if (this.props.match.params.token === "remove") {
      const payload = {
        userId: this.props.userId,
        storeId: 0,
        storeName: null,
        authToken: null,
        kiosk: false,
      };
      this.props.registerWebOrder(payload);
      this.props.clearCart();
      this.props.spClearCart();
      this.props.clearUser();
      this.props.clearOrder();
      this.props.clearSearch();
      this.props.history.push("/");
      return;
    }
    const kiosk =
      this.props.match.params.kiosk && this.props.match.params.kiosk === "kiosk"
        ? true
        : false;
    if (useIpForWebOrderReg()) {
      const payload = {
        userId: this.props.userId,
        storeId: this.props.match.params.storeId,
        kiosk,
      };
      this.props.registerWebOrder(payload);
      this.props.history.push("/");
    } else {
      const url = apiBaseUrl() + "RegisterStore";
      const req = {
        storeId: this.props.match.params.storeId,
        authToken: this.props.match.params.token,
      };

      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.processResponse(res.data, kiosk);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  render() {
    return (
      <div className="top-wrapper">
        {this.state.errorMessage ? (
          <div className="generic-wrapper">
            <b>Store Registration Failed</b>
            <p />
            <font color="red">{this.state.errorMessage}</font>
          </div>
        ) : (
          <div className="generic-wrapper">Registered</div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    registerWebOrder: (payload) => {
      dispatch(registerWebOrder(payload));
    },
    clearCart: () => {
      dispatch(clearCart());
    },
    spClearCart: () => {
      dispatch(spClearCart());
    },
    clearUser: () => {
      dispatch(clearUser());
    },
    clearOrder: () => {
      dispatch(clearOrder());
    },
    clearSearch: () => {
      dispatch(clearSearch());
    },
    clearCache: () => {
      dispatch(clearCache());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
