import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import CartView from "../cart/CartView";
import Address from "../AddressInfo";
import CreditCard from "../payment/CreditCard";
import {
  setStagedOrder,
  setStagedOrderNum,
  setSecurityDeposit,
  setRateAndFees,
  setScheduledDate,
  setOrderStage,
  setOrderInfo,
  clearStagedOrder,
  setDeliveryInfo,
} from "../actions/orderActions";
import { clearCart, setCartMessage, setItems } from "../actions/cartActions";
import { spClearCart } from "../actions/spCartActions";
import { ORDERSTAGE_DELIVERYFEE_PAID } from "../order/orderStages";
import { AXIOS_HEADER } from "../config/constants";
import ProgressiveLeasing from "../progressive/ProgressiveLeasing";

import {
  FormatCurrency,
  apiBaseUrl,
  rateToPercent,
  generateGuid,
  setTopContainerWrapperSettings,
  isMobileMode,
  getIconBaseUrl,
  fullProductImageUrl,
} from "../Util";
import Working from "../Working";
import log from "loglevel";
import "../myLogger";
import "../../App.css";
import "../../generic.css";
import "../../orderSum.css";
import "../../payment.css";

import {
  isClerk,
  isInStore,
  getStoreId,
  getStoreName,
} from "../store/storeUtil";
import DeliveryDatePicker from "./DeliveryDatePicker";
import StorePayment from "../store/StorePayment";

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subtotal: 0,
      tax: 0,
      leaseTax: 0,
      beforeTaxDiscount: 0,
      afterTaxDiscount: 0,
      total: 0,
      nonLeaseTotal: 0,
      codAmount: 0,
      taxRate: 0.08,
      savedTaxRate: 0.08,
      coupon: 0,
      deliveryFee: 0,
      realDeliveryFee: 0,
      securityDeposit: 0,
      pickupDepositTerm: "Security Deposit",
      pickupLoc: null,
      discount: this.props.discount,
      outOfAreaMessage: null,
      useBillingAddress: false,
      billingAddrRequired: false,
      validateCCNumber: false,
      stagedOrderNum: null,
      scheduleDates: null,
      availableOffsetList: null,
      excludedOffsetList: null,
      minDate: null,
      maxDate: null,
      scheduledDate: null,
      defScheduledDate: null,
      salespersonList: null,
      progressiveSetting: null,
      salesperson: null,
      amountChoiceList: null,
      customerDeposits: null,
      remainingBalance: null,
      delvDateUndetermined: false,
      paidInFull: false,
      pacIdx: 0,
      leaseToOwn: false,
      isLoading: true,
      orderBtnDisabled: false,
      errorMessage: null,
      feeErrorMessage: null,
      inStorePayment: null,
      paymentChoice: "CC",
      totalLabel: "Grand Total",
      applFee: 0,
      promotionAvailable: false,
      typedPromoCode: null,
      promotion: null,
      promoErrMsg: null,
    };

    this.processResponse = this.processResponse.bind(this);
    this.setUserId = this.setUserId.bind(this);
    this.adjustSettings = this.adjustSettings.bind(this);
    this.setOrderAmounts = this.setOrderAmounts.bind(this);
    this.getFeesAndInfo = this.getFeesAndInfo.bind(this);
    this.processFeeInfoResponse = this.processFeeInfoResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
    this.processOrderResponse = this.processOrderResponse.bind(this);
    this.amountChoiceChanged = this.amountChoiceChanged.bind(this);
    this.emptyCart = this.emptyCart.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.addStorePayment = this.addStorePayment.bind(this);
    this.adjustTaxAndTotalForLease = this.adjustTaxAndTotalForLease.bind(this);
    this.cleanupCartAndOrder = this.cleanupCartAndOrder.bind(this);
    this.getWebOrderSetting = this.getWebOrderSetting.bind(this);
    this.setLeaseTax = this.setLeaseTax.bind(this);
    this.setPmtChoice = this.setPmtChoice.bind(this);
    this.checkPromotion = this.checkPromotion.bind(this);
    this.processPromotionResponse = this.processPromotionResponse.bind(this);
    this.submitPaymentAndOrder = this.submitPaymentAndOrder.bind(this);
  }
  componentDidMount() {
    if (
      this.props.orderStage === 0 ||
      !this.props.contactInfo ||
      !this.props.shippingChoiceDecided
    ) {
      this.props.history.push("/cart");
      return;
    }
    if (!isInStore() && this.props.subtotal < 1) {
      this.props.history.push("/cart");
      return;
    }
    if (this.emptyCart()) {
      this.props.history.push("/");
      return;
    }
    this.getWebOrderSetting();
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
    this.getFeesAndInfo();
  }
  emptyCart() {
    if (!this.props.items || this.props.items.length < 1) {
      if (isInStore()) {
        if (this.props.storeItems && this.props.storeItems.length > 0) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  setPmtChoice(choice) {
    const isLease = choice == "Progressive" ? true : false;
    this.setState({ paymentChoice: choice });
    this.adjustTaxAndTotalForLease(isLease, null);
  }
  processFeeInfoResponse(response, amtChoiceOnly) {
    if (response) {
      if (response.status) {
        if (amtChoiceOnly) {
          this.setState({
            amountChoiceList: response.amountChoiceList,
          });
        } else {
          if (response.deliveryFee < 0) {
            const feeErrorMessage = "We can't determine the delivery fee";
            this.setState({ feeErrorMessage, isLoading: false });
            return;
          }

          let date = null;
          if (response.minDate && response.maxDate) {
            date = response.minDate;
          } else {
            if (response.scheduleDates && response.scheduleDates.length > 0) {
              date = response.scheduleDates[0];
            }
          }

          if (this.props.deliveryInfo && response.deliveryDistance) {
            const deliveryInfo = {
              ...this.props.deliveryInfo,
              deliveryDistance: response.deliveryDistance,
            };
            this.props.setDeliveryInfo(deliveryInfo);
          }
          console.log(response);
          this.setState({
            taxRate: response.taxRate,
            savedTaxRate: response.taxRate,
            deliveryFee: response.deliveryFee,
            realDeliveryFee: response.deliveryFee,
            securityDeposit: response.pickupDeposit,
            pickupDepositTerm: response.pickupDepositTerm,
            pickupLoc: response.pickupLoc,
            discount: response.discount,
            outOfAreaMessage: response.outOfAreaMessage,
            useBillingAddress: response.useBillingAddress,
            billingAddrRequired: response.billingAddrRequired,
            validateCCNumber: response.validateCCNumber,
            scheduleDates: response.scheduleDates,
            minDate: response.minDate,
            maxDate: response.maxDate,
            availableOffsetList: response.availableOffsetList,
            excludedOffsetList: response.excludedOffsetList,
            scheduledDate: date,
            defScheduledDate: date,
            amountChoiceList: response.amountChoiceList,
            progressiveSetting: response.progressiveSetting,
            promotionAvailable: response.promotionAvailable,
            assoProdSvcList: response.assoProdSvcList,
            isLoading: false,
          });
          this.props.setRateAndFees(response);
          this.props.setSecurityDeposit(
            response.pickupDeposit,
            response.pickupDepositTerm
          );
          this.setOrderAmounts(response);
          this.stageOrder(response.discount, response.deliveryDistance);
          this.props.setScheduledDate(date);
        }
      } else {
        this.setState({
          isLoading: false,
          errorMessage: response.errorMessage,
          feeErrorMessage: response.errorMessage,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: "An error has occurred",
      });
    }
  }
  getFeesAndInfo(amtChoiceOnly) {
    const url = apiBaseUrl() + "GetOrderFeesAndInfo";
    const request = this.getOrder();

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, request, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processFeeInfoResponse(res.data, amtChoiceOnly);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: "An error has occurred!",
        });
        log.error(error);
        console.log(error);
        this.props.history.push("/");
      });
  }
  processPromotionResponse(response) {
    let promotion = null;
    let deliveryFee = this.state.realDeliveryFee;
    if (response) {
      if (response.valid) {
        promotion = response;
        if (promotion.deliveryFee) {
          deliveryFee -= promotion.discount;
        }
        this.setState({
          promotion,
          deliveryFee,
          leaseToOwn: false,
          leaseTax: 0,
          promoErrMsg: null,
        });
        if (getStoreId() <= 0) {
          this.setPmtChoice("CC");
        }
      } else {
        const promoErrMsg = response.errorMessage
          ? response.errorMessage
          : "Invalid promotion code";
        this.setState({
          promoErrMsg,
          promotion,
        });
      }
    } else {
      this.setState({
        promoErrMsg: "Invalid promotion code",
        promotion,
      });
    }
    const data = {
      ...this.state,
      promotion,
      deliveryFee,
    };
    this.setOrderAmounts(data);
  }
  checkPromotion() {
    const url = apiBaseUrl() + "CheckPromotion";
    const order = this.getOrder();
    const promotion = {
      code: this.state.typedPromoCode,
    };
    const request = {
      ...order,
      subtotal: this.state.subtotal,
      promotion,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, request, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processPromotionResponse(res.data);
      })
      .catch((error) => {
        this.setState({
          promoErrMsg: "An error has occurred, please try again later.",
        });
        log.error(error);
        console.log(error);
      });
  }
  getOrder() {
    const overrideDeliveryFee =
      isInStore() &&
      this.props.shippingChoice === "Delivery" &&
      this.props.deliveryInfo
        ? this.props.deliveryInfo.overrideDeliveryFee
        : -1;
    const deliveryFee = this.state.deliveryFee;
    const deliveryDistance = this.props.deliveryInfo
      ? this.props.deliveryInfo.deliveryDistance
      : -1;
    const order = {
      userId: this.props.userId,
      delivery: this.props.shippingChoice === "Delivery",
      pickup: this.props.shippingChoice === "Pickup",
      pickupLocId: this.props.pickupLocId,
      deliveryInfo: this.props.deliveryInfo,
      items: this.props.items,
      storeItems: this.props.storeItems,
      overrideDeliveryFee,
      deliveryFee,
      deliveryDistance,
      storeId: getStoreId(),
      promotion: this.state.promotion,
    };
    return order;
  }
  getWebOrderSetting() {
    if (isClerk()) {
      const url = apiBaseUrl() + "GetWebOrderSetting";
      const req = {
        userId: this.props.userId,
        id: getStoreId(),
      };

      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, {
          headers: AXIOS_HEADER,
        })
        .then((res) => {
          if (res.data.status) {
            this.setState({
              salespersonList: res.data.salespersonList,
              progressiveSetting: res.data.progressiveSetting,
            });
          }
        })
        .catch((error) => {
          log.error(error);
          console.log(error);
        });
    }
  }
  setLeaseTax(tax) {
    this.setState({ leaseTax: tax });
    this.adjustTaxAndTotalForLease(true, tax);
  }
  setOrderAmounts(data) {
    let subtotal = this.props.subtotal;
    let afterTaxDiscount = 0;
    let beforeTaxDiscount = 0;

    if (data.discount) {
      if (data.discount.afterTax === false) {
        beforeTaxDiscount = data.discount.amount;
        subtotal = subtotal - beforeTaxDiscount;
      } else {
        afterTaxDiscount = data.discount.amount;
      }
    }

    if (data.promotion && data.promotion.valid) {
      if (!data.promotion.afterTax) {
        subtotal = subtotal - data.promotion.discount;
      }
    }

    let tax = subtotal * data.taxRate;
    const storeItems = this.props.storeItems;
    let coupon = 0;
    let customerDeposits = null;
    let totalDepAmount = 0;
    let paidInFull = false;
    let remainingBalance = null;
    if (isInStore() && storeItems && storeItems.length > 0) {
      let taxableAmount = 0;
      for (let i = 0; i < storeItems.length; i++) {
        if (storeItems[i].coupon) {
          coupon += storeItems[i].price;
        } else {
          if (!storeItems[i].typeId || storeItems[i].typeId <= 0) {
            taxableAmount += storeItems[i].quantity * storeItems[i].price;
          } else {
            if (!customerDeposits) customerDeposits = [];
            customerDeposits.push(storeItems[i]);
            if (storeItems[i].amount < 0) totalDepAmount -= storeItems[i].price;
            else totalDepAmount += storeItems[i].price;
          }
        }
      }
      subtotal += taxableAmount;
      tax += taxableAmount * data.taxRate;
    }

    if (data.leaseToOwn) tax = data.leaseTax; //overwrite

    if (subtotal < 1) {
      this.props.history.push("/");
      return;
    }

    let afterTaxPromotion = 0;
    if (data.promotion && data.promotion.valid) {
      if (!data.promotion.deliveryFee && data.promotion.afterTax) {
        afterTaxPromotion = data.promotion.discount;
      }
    }
    let codAmount = subtotal + tax - afterTaxDiscount - afterTaxPromotion;
    let securityDeposit = 0;
    let deliveryFee = 0;
    let total = codAmount;

    if (this.props.shippingChoice === "Pickup") {
      if (data.pickupLoc && data.pickupLoc.info.securityDeposit) {
        securityDeposit = data.pickupLoc.info.securityDeposit;
      }
      codAmount = codAmount - securityDeposit;
    } else {
      deliveryFee = data.deliveryFee;
      total = total + deliveryFee;
    }
    total += coupon;
    if (totalDepAmount > 0) {
      remainingBalance = total - totalDepAmount;
      paidInFull = remainingBalance < 0 || Math.abs(remainingBalance) < 1;
    }

    let nonLeaseTotal = total;
    if (tax == 0) {
      //lease
      nonLeaseTotal += this.state.savedTaxRate * subtotal;
    }
    this.setState({
      subtotal,
      tax,
      total,
      nonLeaseTotal,
      codAmount,
      beforeTaxDiscount,
      afterTaxDiscount,
      securityDeposit,
      coupon,
      customerDeposits,
      remainingBalance,
      paidInFull,
    });
    this.getFeesAndInfo(true);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
  }

  setUserId() {
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
  }
  securityDepositRequired() {
    if (this.props.shippingChoice === "Pickup") {
      if (
        this.state.pickupLoc &&
        this.state.pickupLoc.info.securityDeposit &&
        this.state.pickupLoc.info.securityDeposit > 0
      ) {
        return true;
      }
    }
    return false;
  }
  processResponse(response) {
    if (response.status === "Processed") {
      this.setState({ stagedOrderNum: response.orderNumber });
      this.props.setStagedOrderNum(response.orderNumber);
    }
  }
  stageOrder(discount, distance) {
    const order = this.createOrder(discount, distance);

    this.props.setStagedOrder(order);

    const url = apiBaseUrl() + "StageOrder";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, order, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        log.error(error);
        console.log(error);
      });
  }
  createOrder(theDiscount, distance) {
    const deliveryInfo =
      this.props.shippingChoice === "Delivery" ? this.props.deliveryInfo : null;
    const discount = theDiscount ? theDiscount : null;
    const overrideDeliveryFee =
      isInStore() &&
      this.props.shippingChoice === "Delivery" &&
      this.props.deliveryInfo
        ? this.props.deliveryInfo.overrideDeliveryFee
        : -1;
    let storeItems = this.props.storeItems;
    if (isInStore() && isClerk() && this.state.inStorePayment) {
      if (!storeItems) storeItems = [];
      storeItems.push(this.state.inStorePayment);
    }
    const promotion = this.state.promotion;
    const honorDeliveryFee = promotion && promotion.deliveryFee ? true : false;
    const order = {
      userId: this.props.userId,
      items: this.props.items,
      storeItems,
      contactInfo: this.props.contactInfo,
      deliveryInfo: deliveryInfo,
      subtotal: this.state.subtotal,
      taxRate: this.state.taxRate,
      tax: this.state.tax,
      tariff: 0,
      deliveryFee: this.state.deliveryFee,
      pickup: this.props.shippingChoice === "Pickup",
      pickupLocId: this.props.pickupLocId,
      total: this.state.total,
      securityDeposit: this.state.securityDeposit,
      codAmout: this.state.codAmount,
      discount,
      overrideDeliveryFee,
      deliveryDistance: distance,
      leaseToOwn: this.state.leaseToOwn,
      promotion,
      honorDeliveryFee,
    };
    return order;
  }

  cleanupCartAndOrder(info) {
    const payload = info
      ? {
          orderNumber: info.orderNumber,
          orderEmail: info.email,
          codAmount: info.codAmount,
          orderCompleteMsg: info.orderCompleteMsg,
        }
      : null;

    this.props.clearCart();
    this.props.spClearCart();
    this.props.clearStagedOrder();
    this.props.setOrderInfo(payload);
    this.props.setOrderStage(ORDERSTAGE_DELIVERYFEE_PAID);
  }
  processOrderResponse(response) {
    if (response) {
      if (response.status === "Processed") {
        this.cleanupCartAndOrder(response);
        this.props.history.push("/orderComplete");
      } else if (response.status == "PriceChanged") {
        this.props.setItems(response.newItemList);
        this.props.setCartMessage(response.errorMessage);
        this.props.history.push("/cart");
      } else if (response.status === "ItemSoldOut") {
        this.props.setItems(response.newItemList);
        this.props.setCartMessage(response.errorMessage);
        this.props.history.push("/cart");
      } else if (response.status === "FatalError") {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
          orderBtnDisabled: false,
        });
        this.props.history.push("/");
      } else if (response.status === "PaymentError") {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
          orderBtnDisabled: false,
        });
      } else if (response.status === "ScheduleError") {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
          orderBtnDisabled: false,
        });
        this.getFeesAndInfo();
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
          orderBtnDisabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Failed to process your order.",
        submitBtnEnabled: true,
      });
    }
  }
  placeOrder(event) {
    event.preventDefault();

    const distance = this.props.deliveryInfo
      ? this.props.deliveryInfo.deliveryDistance
      : 0;
    const order = this.createOrder(this.state.discount, distance);
    let scheduledDate = this.state.scheduledDate;
    if (this.state.delvDateUndetermined) {
      scheduledDate = null;
    } else {
      if (!scheduledDate) {
        scheduledDate = this.state.minDate;
      }
    }

    const req = {
      userId: this.props.userId,
      order,
      storeId: getStoreId(),
      authToken: this.props.authToken,
      salesperson: this.state.salesperson,
      kiosk: this.props.kiosk,
      stagedOrderNum: this.state.stagedOrderNum,
      scheduledDate,
    };
    this.setState({ orderBtnDisabled: true, errorMessage: null });
    const url = apiBaseUrl() + "SubmitOrder";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processOrderResponse(res.data);
      })
      .catch((error) => {
        log.error(error);
        console.log(error);
      });
  }

  submitPaymentAndOrder(payment, responseProcessor) {
    const url = apiBaseUrl() + "SubmitOrderPayment";
    const distance = this.props.deliveryInfo
      ? this.props.deliveryInfo.deliveryDistance
      : 0;
    const order = this.createOrder(this.state.discount, distance);
    let scheduledDate = this.state.scheduledDate;
    if (this.state.delvDateUndetermined) {
      scheduledDate = null;
    } else {
      if (!scheduledDate) {
        scheduledDate = this.state.minDate;
      }
    }

    const req = {
      userId: this.props.userId,
      order,
      storeId: getStoreId(),
      authToken: this.props.authToken,
      salesperson: this.state.salesperson,
      kiosk: this.props.kiosk,
      stagedOrderNum: this.state.stagedOrderNum,
      scheduledDate,
      payment,
      promotion: this.state.promotion,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        responseProcessor(res.data);
      })
      .catch((error) => {
        log.error(error);
        console.log(error);
      });
  }

  deliveryInfo() {
    if (this.props.shippingChoice !== "Delivery") return "";
    else {
      return (
        <tr>
          <td>Delivery: </td>
          <td width="30"></td>
          <td align="right">
            <FormatCurrency amount={this.props.deliveryFee} />
          </td>
        </tr>
      );
    }
  }
  showDepSect() {
    if (!isInStore()) return false;
    if (
      !this.state.inStorePayment &&
      (!this.state.customerDeposits || this.state.customerDeposits.length === 0)
    ) {
      return false;
    }
    return true;
  }
  showDeposits() {
    if (!this.showDepSect()) return null;

    const deposits = this.state.customerDeposits;
    const isp = this.state.inStorePayment;
    return (
      <React.Fragment>
        {deposits &&
          deposits.map((cd) => {
            const imageUrl = cd.product ? cd.product.imageUrl : null;
            const amount = cd.price > 0 ? cd.price : 0 - cd.price;
            const last4 =
              cd.last4 && cd.last4.trim().length > 0
                ? "(" + cd.last4 + ")"
                : null;
            return (
              <React.Fragment>
                <div>
                  {imageUrl && (
                    <img src={fullProductImageUrl(imageUrl)} height="15"></img>
                  )}
                  {cd.name}
                  {last4}
                </div>
                <div className="left-10" align="right">
                  <font color="red">
                    -<FormatCurrency amount={amount} />
                  </font>
                </div>
              </React.Fragment>
            );
          })}
        {isp && (
          <React.Fragment>
            <div>{isp.name}</div>
            <div className="left-10" align="right">
              <font color="red">
                -<FormatCurrency amount={isp.amount} />
              </font>
            </div>
          </React.Fragment>
        )}
        <div>
          <i>Balance:</i>
        </div>
        <div align="right">
          {this.state.paidInFull ? (
            <label>Paid in full</label>
          ) : (
            <FormatCurrency amount={this.state.remainingBalance} />
          )}
        </div>
      </React.Fragment>
    );
  }
  showSummary() {
    const unknown =
      this.props.shippingChoice === "Delivery" &&
      (!this.state.promotion || !this.state.promotion.deliveryFee) &&
      (!this.state.deliveryFee || this.state.deliveryFee < 0);
    const applFee = this.state.applFee;
    const setting = this.state.progressiveSetting;
    const salesTax = this.state.leaseToOwn
      ? this.state.leaseTax
      : this.state.tax;

    const promo = this.state.promotion;
    const showPromo =
      promo && !promo.afterTax && promo.discount > 0 ? true : false;
    const showPromoAT =
      promo && promo.afterTax && !promo.deliveryFee && promo.discount > 0
        ? true
        : false;
    const stLabel = showPromo ? "Item Total" : "Subtotal";
    const subtotal = showPromo
      ? this.state.subtotal + promo.discount
      : this.state.subtotal;
    const dfApplied = promo && promo.deliveryFee ? true : false;

    return (
      <div>
        <div className="sum-title-div">
          <font size="4">Order Summary</font>
        </div>
        <div className="sum-tbl-container">
          <div className="sum-tbl-item-left">{stLabel}:</div>
          <div className="sum-tbl-item-right">
            <FormatCurrency amount={subtotal} />
          </div>
          {showPromo && (
            <React.Fragment>
              <div className="sum-tbl-item-left">
                {promo.name ? promo.name : promo.code}:
              </div>
              <div className="sum-tbl-item-right">
                <font color="red">
                  <FormatCurrency amount={0 - promo.discount} />
                </font>
              </div>
              <div className="sum-tbl-item-left">Subtotal:</div>
              <div className="sum-tbl-item-right">
                <FormatCurrency amount={this.state.subtotal} />
              </div>
            </React.Fragment>
          )}
          <div className="sum-tbl-item-left">
            Sales Tax({rateToPercent(this.state.taxRate)}):
          </div>
          <div className="sum-tbl-item-right">
            <FormatCurrency amount={salesTax} />
          </div>
          {applFee > 0 && (
            <React.Fragment>
              <div className="sum-tbl-item-left">{setting.applFeeLabel}:</div>
              <div className="sum-tbl-item-right">
                <FormatCurrency amount={applFee} />
              </div>
            </React.Fragment>
          )}
          {this.props.shippingChoice === "Delivery" && (
            <React.Fragment>
              {dfApplied ? (
                <div className="sum-tbl-item-left">
                  Delivery Fee:
                  <br />
                  <font color="blue">(Discount applied)</font>
                </div>
              ) : (
                <div className="sum-tbl-item-left">Delivery Fee:</div>
              )}
              <div className="sum-tbl-item-right">
                {unknown ? (
                  <b>Unknown</b>
                ) : (
                  <FormatCurrency amount={this.state.deliveryFee} />
                )}
              </div>
            </React.Fragment>
          )}
          {showPromoAT && (
            <React.Fragment>
              <div className="sum-tbl-item-left">
                {promo.name ? promo.name : promo.code}:
              </div>
              <div className="sum-tbl-item-right">
                <font color="red">
                  <FormatCurrency amount={0 - promo.discount} />
                </font>
              </div>
            </React.Fragment>
          )}
          {this.state.coupon !== 0 && (
            <React.Fragment>
              <div className="sum-tbl-item-left">Coupon:</div>
              <div className="sum-tbl-item-right">
                <font color="red">
                  <FormatCurrency amount={this.state.coupon} />
                </font>
              </div>
            </React.Fragment>
          )}
          {this.state.afterTaxDiscount > 0 && (
            <React.Fragment>
              <div className="sum-tbl-item-left">
                {this.state.discount.name}:
              </div>
              <div className="sum-tbl-item-right">
                -<FormatCurrency amount={this.state.afterTaxDiscount} />
              </div>
            </React.Fragment>
          )}
          <div className="sum-tbl-item-left">
            <b>{this.state.totalLabel}:</b>
          </div>
          <div className="sum-tbl-item-right">
            <FormatCurrency amount={this.state.total} />
          </div>
          {this.showDeposits()}
        </div>
      </div>
    );
  }

  pickupLocInfo() {
    if (this.props.shippingChoice === "Pickup") {
      const label = isClerk()
        ? "Order pickup location"
        : "Your pickup location";
      if (this.state.pickupLoc !== null) {
        const url = "/location/" + this.state.pickupLoc.id;
        return (
          <div className="sum-pickup-loc" align="left">
            <b>{label}:</b>{" "}
            <a href={url} target="Pickup">
              {this.state.pickupLoc.name}
            </a>
          </div>
        );
      }
    }
  }
  paymentInstrStatic() {
    if (this.props.shippingChoice === "Pickup") {
      const fee = this.state.securityDeposit;
      const term = this.state.pickupDepositTerm.toLowerCase();
      if (this.state.codAmount <= 0) {
        return (
          <div>
            Please enter your credit card information and then click the "Place
            Order" button.
            <p />
          </div>
        );
      } else {
        return (
          <div>
            The store you choose to pickup your furnitures requires a{" "}
            <b>{term}</b>&nbsp;of ${fee}. Please enter your credit card info
            below, then click "Place Order". The remaining balance of &nbsp;
            <FormatCurrency amount={this.state.codAmount} />
            &nbsp; should be paid COD (cash on delivery). The deposit is
            non-refundable to cover the cost of preparation, gas, and labor,
            etc.
            <p />
          </div>
        );
      }
    } else {
      const fee = this.state.deliveryFee;
      return (
        <div>
          The <b>delivery fee</b> of ${fee} must be paid using a credit card or
          debit card. Please enter your credit card info below and then click
          the "Place Order" button. Once we successfully process your payment,
          we will call you to confirm delivery date. The remaining balance of
          &nbsp;
          <FormatCurrency amount={this.state.codAmount} />
          &nbsp;should be paid COD (cash on delivery).
          <p />
        </div>
      );
    }
  }
  CCPaymentInstr() {
    const pacList = this.state.amountChoiceList;

    if (!pacList || pacList.length === 0) return this.paymentInstrStatic();
    else {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: pacList[this.state.pacIdx].paymentMessage,
          }}
        ></div>
      );
    }
  }
  showCCAndCashInfo() {
    if (!isInStore()) {
      if (this.props.shippingChoice === "Pickup") {
        return (
          <div className="top-10">
            If you would like to order over the phone, please call
            (678)819-7591.
            <p />
          </div>
        );
      } else {
        return (
          <div className="top-10">
            If you don't have cash ready, please come to one of our stores to
            charge on your credit/debit card as our delivery driver have no card
            reader and our credit card company doesn't allow large amount on web
            orders. If you would like to order over the phone, please call
            (678)819-7591.
            <p />
          </div>
        );
      }
    }
  }
  amountChoiceChanged(idx) {
    this.setState({ pacIdx: idx });
  }
  getDefaultAmountChoices() {
    if (this.props.shippingChoice === "Pickup") {
      return [
        {
          id: 0,
          name: "SecurityDeposit",
          displayName: this.state.pickupDepositTerm,
          amount: this.state.securityDeposit,
        },
      ];
    } else {
      return [
        {
          id: 0,
          name: "DeliveryFee",
          displayName: "Delivery Fee",
          amount: this.state.deliveryFee,
        },
      ];
    }
  }

  creditCardPayment() {
    if (!isInStore()) {
      const amountChoiceList =
        this.state.amountChoiceList && this.state.amountChoiceList.length > 0
          ? this.state.amountChoiceList
          : this.getDefaultAmountChoices();
      const dfDisplayName =
        this.state.promotion && this.state.promotion.deliveryFee
          ? "Deposit"
          : "Delivery Fee";
      return (
        <div className="left10-top10">
          {this.CCPaymentInstr()}
          <div className="payment-line">
            <CreditCard
              amountChoiceList={amountChoiceList}
              amountChoiceCallback={this.amountChoiceChanged}
              useBillingAddress={this.state.useBillingAddress}
              billingAddrRequired={this.state.billingAddrRequired}
              validateCCNumber={this.state.validateCCNumber}
              scheduleErrCallback={this.getFeesAndInfo}
              deliveryFeeDisplayName={dfDisplayName}
              callback={this.submitPaymentAndOrder}
            />
          </div>
        </div>
      );
    }
  }
  paymentChoices() {
    if (!isInStore()) {
      const paypalEnabled = false;
      const choice = this.state.paymentChoice;
      const ccClsName = choice === "CC" ? "pmtchoice-sel" : "pmtchoice-nonsel";
      const ppClsName =
        choice === "PayPal" ? "pmtchoice-sel" : "pmtchoice-nonsel";
      let scheduledDate = this.state.scheduledDate;
      if (this.state.delvDateUndetermined) {
        scheduledDate = null;
      } else {
        if (!scheduledDate) {
          scheduledDate = this.state.minDate;
        }
      }
      const orderInfo = {
        order: this.createOrder(this.state.discount, 10),
        userId: this.props.userId,
        storeId: 0,
        stagedOrderNum: this.state.stagedOrderNum,
        scheduledDate,
      };
      const leaseTotal = this.state.total - this.state.tax; //Lease has no tax
      const minimum = this.state.progressiveSetting
        ? this.state.progressiveSetting.leaseMinimum
        : 400;
      const leaseAllowed = leaseTotal >= minimum ? true : false;
      let plClsName =
        choice === "Progressive" ? "pmtchoice-sel" : "pmtchoice-nonsel";
      if (!leaseAllowed) plClsName = "none";
      const ccTitle =
        choice === "CC" ? (
          <label>
            <b>Pay with Credit/Debit Card</b>
          </label>
        ) : (
          <label>Pay with Credit/Debit Card</label>
        );
      let progTitle =
        choice === "Progressive" ? (
          <label>
            <b>Progressive Leasing</b>
          </label>
        ) : (
          <label>Progressive Leasing</label>
        );

      if (!leaseAllowed) {
        progTitle = "Progressive Leasing (Minimum $" + minimum + ")";
      }

      return (
        <div className="top-10">
          <div className="flex-center">
            <div>
              <b>Payment</b>
            </div>
            <div className="left-10">
              <img src={getIconBaseUrl() + "secure_checkout.jpg"} height="22" />
            </div>
          </div>
          <div className="payment-choice-container">
            {leaseAllowed ? (
              <div
                className={plClsName}
                onClick={() => this.setPmtChoice("Progressive")}
              >
                <div className="flex-center">
                  <div>
                    <input
                      type="radio"
                      name="paymentChoice"
                      value="Progressive"
                      onChange={this.handleChange}
                      checked={choice === "Progressive"}
                      disabled={!leaseAllowed}
                    />
                  </div>
                  <div>
                    <img src={getIconBaseUrl() + "prog-trans.png"} width="18" />
                  </div>
                  <div>{progTitle}</div>
                </div>
                {choice === "Progressive" && (
                  <ProgressiveLeasing
                    orderInfo={orderInfo}
                    orderTotal={this.state.total}
                    scheduledDate={this.state.scheduledDate}
                    mobileMode={isMobileMode()}
                    orderValidationErrorHandler={this.processOrderResponse}
                    orderSuccessHandler={this.cleanupCartAndOrder}
                    leaseTaxHandler={this.setLeaseTax}
                    setting={this.state.progressiveSetting}
                  />
                )}
              </div>
            ) : (
              <div className="top-10">
                <div className="flex-center">
                  <div>
                    <input
                      type="radio"
                      name="paymentChoice"
                      value="Progressive"
                      onChange={this.handleChange}
                      checked={choice === "Progressive"}
                      disabled={!leaseAllowed}
                    />
                  </div>
                  <div>
                    <img src={getIconBaseUrl() + "prog-trans.png"} width="18" />
                  </div>
                  <div>{progTitle}</div>
                </div>
              </div>
            )}
            <div className={ccClsName} onClick={() => this.setPmtChoice("CC")}>
              <div className="flex-center">
                <div>
                  <input
                    type="radio"
                    name="paymentChoice"
                    value="CC"
                    onChange={this.handleChange}
                    checked={choice === "CC"}
                  />
                </div>
                <div>
                  <img
                    src={getIconBaseUrl() + "creditcard-trans.png"}
                    width="18"
                  />
                </div>
                <div>{ccTitle}</div>
              </div>
              {choice === "CC" && this.creditCardPayment()}
            </div>
            {paypalEnabled && (
              <div className={ppClsName}>
                <div className="flex-center">
                  <div>
                    <input
                      type="radio"
                      name="paymentChoice"
                      value="PayPal"
                      onChange={this.handleChange}
                      checked={choice === "PayPal"}
                    />
                  </div>
                  <div>
                    <img src={getIconBaseUrl() + "paypal.jpg"} width="18" />
                  </div>
                  <div>Checkout with PayPal</div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
  adjustTaxAndTotalForLease(isLease, plTax) {
    const leaseTax = plTax ? plTax : this.state.leaseTax;
    const leaseTaxRate = leaseTax > 0 ? this.state.savedTaxRate : 0.0;
    const nonLeaseTax = this.state.subtotal * this.state.savedTaxRate;
    const tax = isLease ? leaseTax : nonLeaseTax;
    const taxRate = isLease ? leaseTaxRate : this.state.savedTaxRate;
    const totalLabel = isLease ? "Order Total" : "Grand Total";
    let total = isLease
      ? this.state.nonLeaseTotal - nonLeaseTax + leaseTax
      : this.state.nonLeaseTotal;
    const setting = this.state.progressiveSetting;
    let applFee = 0;
    if (isLease && setting) {
      if (isInStore()) {
        applFee = setting.storeApplFee;
      } else {
        applFee = setting.applicationFee;
      }
    }
    total += applFee;
    this.setState({
      leaseToOwn: isLease,
      taxRate,
      tax,
      total,
      totalLabel,
      applFee,
    });
  }

  handleChange(event) {
    const name = event.target.name;
    if (name == "typedPromoCode") {
      this.setState({ typedPromoCode: event.target.value });
    } else if (name === "salesperson") {
      this.setState({ salesperson: event.target.value });
    } else if (name === "delvDateUndetermined") {
      this.setState({ delvDateUndetermined: event.target.checked });
      if (event.target.checked) {
        this.setState({ scheduledDate: null });
        this.props.setScheduledDate(null);
      } else {
        this.setState({ scheduledDate: this.state.defScheduledDate });
        this.props.setScheduledDate(this.state.defScheduledDate);
      }
    } else if (name === "leaseToOwn") {
      this.adjustTaxAndTotalForLease(event.target.checked, null);
    } else if (name === "paymentChoice") {
      let isLease = event.target.value == "Progressive" ? true : false;

      this.setState({ paymentChoice: event.target.value });
      this.adjustTaxAndTotalForLease(isLease, null);
    } else {
      this.setState({ scheduledDate: event.target.value });
      this.props.setScheduledDate(event.target.value);
    }
  }
  selectHandler(scheduledDate) {
    this.setState({ scheduledDate });
    this.props.setScheduledDate(scheduledDate);
  }
  showDeliveryDates() {
    if (
      this.props.shippingChoice === "Pickup" ||
      !this.state.scheduleDates ||
      this.state.scheduleDates.length === 0
    )
      return;

    const scheduleInfo = {
      minDate: this.state.minDate,
      maxDate: this.state.maxDate,
      availableOffsetList: this.state.availableOffsetList,
      excludedOffsetList: this.state.excludedOffsetList,
      scheduleDates: this.state.scheduleDates,
      scheduledDate: this.state.scheduledDate,
    };
    const clsName = isMobileMode() ? "top-15" : "left-20";
    if (isInStore()) {
      return (
        <div className={clsName}>
          <b>Delivery Date:</b>
          {isMobileMode() ? <label>&nbsp;</label> : <br />}
          {!this.state.delvDateUndetermined && (
            <DeliveryDatePicker
              scheduleInfo={scheduleInfo}
              selectHandler={this.selectHandler}
            />
          )}
          <p />
          <div>
            <input
              type="checkbox"
              name="delvDateUndetermined"
              defaultChecked={this.state.delvDateUndetermined}
              onChange={this.handleChange}
            />{" "}
            Undetermined (Will call)
          </div>
        </div>
      );
    } else {
      return (
        <div className={clsName}>
          <b>Delivery Date:</b>
          {isMobileMode() ? <label>&nbsp;</label> : <br />}
          <DeliveryDatePicker
            scheduleInfo={scheduleInfo}
            selectHandler={this.selectHandler}
          />
        </div>
      );
    }
  }
  calcBalance(payment) {
    let remainingBalance = this.state.remainingBalance;
    if (!remainingBalance) {
      return this.state.total - payment.amount;
    } else {
      return remainingBalance - payment.amount;
    }
  }
  addStorePayment(payment) {
    const name = payment.typeId == 1 ? "Cash payment" : "Credit/Debit payment";
    const inStorePayment = {
      ...payment,
      price: payment.amount,
      quantity: 1,
      name,
      productId: -1,
      sku: "InStore Payment",
    };

    this.setState({
      inStorePayment,
      remainingBalance: this.calcBalance(payment),
    });
  }
  addPaymentBox() {
    if (isInStore() && isClerk() && !this.state.inStorePayment) {
      const balance = this.state.remainingBalance
        ? this.state.remainingBalance
        : this.state.total;
      return (
        <div className="top10b15">
          <StorePayment balance={balance} callback={this.addStorePayment} />
        </div>
      );
    }
  }
  leaseToOwnBox() {
    if (isInStore() && isClerk()) {
      const setting = this.state.progressiveSetting;
      const min = setting ? setting.leaseMinimum : 400;
      const leaseTotal = this.state.total - this.state.tax;
      const disabled = leaseTotal < min;
      const label = disabled
        ? "Lease To Own Order (Min. $" + min + " before tax)"
        : "Lease To Own Order";

      return (
        <div className="storeord-top10b5">
          <input
            type="checkbox"
            name="leaseToOwn"
            checked={this.state.leaseToOwn}
            onChange={this.handleChange}
            disabled={disabled}
          />{" "}
          {label}
        </div>
      );
    }
  }
  progressiveOrderForm() {
    //For store
    if (this.state.leaseToOwn && this.state.progressiveSetting) {
      const setting = this.state.progressiveSetting;
      if (setting.enableStoreLookup || setting.enableStoreOrder) {
        const disableSubmitButton = setting.enableStoreOrder ? false : true;
        let scheduledDate = this.state.scheduledDate;
        if (this.state.delvDateUndetermined) {
          scheduledDate = null;
        } else {
          if (!scheduledDate) {
            scheduledDate = this.state.minDate;
          }
        }
        const orderInfo = {
          order: this.createOrder(this.state.discount, 10),
          userId: this.props.userId,
          stagedOrderNum: this.state.stagedOrderNum,
          scheduledDate,
          salesperson: this.state.salesperson,
          storeId: getStoreId(),
        };
        return (
          <fieldset className="progressive-container">
            <legend>
              <b>Progressive Leasing</b>
            </legend>
            <ProgressiveLeasing
              orderInfo={orderInfo}
              orderTotal={this.state.total}
              scheduledDate={this.state.scheduledDate}
              mobileMode={isMobileMode()}
              orderValidationErrorHandler={this.processOrderResponse}
              orderSuccessHandler={this.cleanupCartAndOrder}
              inStoreOrder={true}
              disableSubmitButton={disableSubmitButton}
              leaseTaxHandler={this.setLeaseTax}
              setting={this.state.progressiveSetting}
            />
          </fieldset>
        );
      }
    }
  }
  salespersonBox() {
    if (isClerk()) {
      const list = this.state.salespersonList;
      return (
        <div className="flex-t10">
          <div>
            <b>
              <font color="red">{getStoreName()}</font>
            </b>
          </div>
          {list && list.length > 0 ? (
            <div className="left-10">
              <select
                name="salesperson"
                value={this.state.salesperson || ""}
                onChange={this.handleChange}
              >
                <option value="">[Select Salesperson]</option>
                {list.map((nn) => {
                  return <option value={nn}>{nn}</option>;
                })}
              </select>
            </div>
          ) : (
            <div className="left-10">
              <input
                type="text"
                name="salesperson"
                value={this.state.salesperson || ""}
                placeholder="Enter salesperson name"
                onChange={this.handleChange}
              ></input>
            </div>
          )}
        </div>
      );
    }
  }
  storeOrderBox() {
    if (isInStore()) {
      const clsName = this.state.orderBtnDisabled
        ? "disabled-order-btn"
        : "order-btn";
      return (
        <div>
          <div>
            {this.state.errorMessage && (
              <font color="red">
                {this.state.errorMessage}
                <p />
              </font>
            )}
          </div>
          <div>
            <button
              className={clsName}
              name="order"
              onClick={this.placeOrder}
              disabled={this.state.orderBtnDisabled}
            >
              Place Order
            </button>
          </div>
          <div>&nbsp;</div>
        </div>
      );
    }
  }
  showCoupon() {
    if (this.state.promotionAvailable && !this.state.promotion) {
      const clsName = this.state.typedPromoCode
        ? "small-blue-btn"
        : "disabled-small-btn";
      return (
        <div className="top-20">
          <div>Promotional Code</div>
          {this.state.promoErrMsg && (
            <div>
              <font color="red">{this.state.promoErrMsg}</font>
            </div>
          )}
          <div className="twocol-tbl">
            <div>
              <input
                type="text"
                name="typedPromoCode"
                placeholder="Promo Code"
                size="20"
                onChange={this.handleChange}
              />
            </div>
            <div>
              <button
                className={clsName}
                name="applyPromo"
                onClick={this.checkPromotion}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      );
    }
    return "";
  }

  inStoreOrder() {
    if (isInStore()) {
      let show = true;
      if (this.state.leaseToOwn && this.state.progressiveSetting) {
        if (this.state.progressiveSetting.enableStoreOrder) {
          show = false;
        }
      }
      return (
        <div>
          {this.salespersonBox()}
          {this.leaseToOwnBox()}
          {show && (
            <div className="storeord-wrapper">
              <div className="storeord-top10b5">
                If the customer has made in-store payment towards the order,
                click the "Add InStore Payment" then enter the amount. Do NOT
                enter fees (such as lease application fees) here.
              </div>
              {this.addPaymentBox()}
              {this.storeOrderBox()}
            </div>
          )}
          {this.progressiveOrderForm()}
        </div>
      );
    }
  }
  render() {
    if (this.state.isLoading) return <Working />;

    if (this.state.feeErrorMessage) {
      return (
        <div className="top-wrapper">
          <div className="sum-container">
            An error has occurred while calculating your delivery fee.
            <p />
            <font color="red">{this.state.feeErrorMessage}</font>
            <p />
            Please go back to the previous page to make sure your delivery
            address is correct.
          </div>
        </div>
      );
    }
    const addr =
      this.props.shippingChoice === "Pickup"
        ? this.props.contactInfo
        : this.props.deliveryInfo;
    let addrTitle = "Delivery Address";

    if (this.props.shippingChoice === "Pickup") {
      if (isClerk()) addrTitle = "Contect Info";
      else addrTitle = "Your Contact Info";
    }
    const title = isClerk() ? "Review Customer Order" : "Review Your Order";
    return (
      <div className="top-wrapper">
        <div className="sum-container">
          <div align="left">
            <font size="5">{title}</font>
            {isMobileMode() ? <br /> : <p />}
          </div>
          <div className="sum-wrapper">
            <div className="sum-ordersum-wrapper">{this.showSummary()}</div>
            <div className="sum-addr-wrapper">
              <Address address={addr} title={addrTitle} />
              {!isMobileMode() && this.showDeliveryDates()}
            </div>
            {isMobileMode() && this.showDeliveryDates()}
          </div>
          {this.showCoupon()}
          {this.pickupLocInfo()}
          {this.paymentChoices()}
          {this.inStoreOrder()}
          <div align="left">
            <CartView title="Items" />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.cart.addedItems,
    discount: state.cart.discount,
    subtotal: state.cart.subtotal,
    contactInfo: state.order.contactInfo,
    deliveryInfo: state.order.deliveryInfo,
    ratefees: state.order.ratefees,
    shippingChoice: state.order.shippingChoice,
    pickupLocId: state.order.pickupLocId,
    userId: state.user.userId,
    orderStage: state.order.stage,
    shippingChoiceDecided: state.order.shippingChoiceDecided,
    android: state.cache.android,
    kiosk: state.webOrder.kiosk,
    storeItems: state.spCart.storeItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStagedOrder: (order) => {
      dispatch(setStagedOrder(order));
    },
    setStagedOrderNum: (orderNum) => {
      dispatch(setStagedOrderNum(orderNum));
    },
    setSecurityDeposit: (deposit, term) => {
      dispatch(setSecurityDeposit(deposit, term));
    },
    setRateAndFees: (ratefees) => {
      dispatch(setRateAndFees(ratefees));
    },
    setScheduledDate: (date) => {
      dispatch(setScheduledDate(date));
    },
    setDeliveryInfo: (info) => {
      dispatch(setDeliveryInfo(info));
    },
    setOrderStage: (stage) => {
      dispatch(setOrderStage(stage));
    },
    clearCart: () => {
      dispatch(clearCart());
    },
    spClearCart: () => {
      dispatch(spClearCart());
    },
    setOrderInfo: (payload) => {
      dispatch(setOrderInfo(payload));
    },
    clearStagedOrder: () => {
      dispatch(clearStagedOrder());
    },
    setCartMessage: (msg) => {
      dispatch(setCartMessage(msg));
    },
    setItems: (items) => {
      dispatch(setItems(items));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
