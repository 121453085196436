import React from "react";
import { connect } from "react-redux";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "../../App.css";
import "../../form.css";
import { getIconBaseUrl, isMobileMode } from "../Util";

class Waitlist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      name: this.props.name,
      email: this.props.email,
      phone: this.props.phone,
      errorMessage: null,
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleOK = this.handleOK.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.name !== prevProps.name ||
      this.props.email !== prevProps.email
    ) {
      this.setState({ name: this.props.name, email: this.props.email });
    }
  }
  showConfirmation() {
    document.getElementById("showDialogBtn").click();
  }
  openDialog(event) {
    event.preventDefault();
    this.setState({ showDialog: true });
  }
  closeDialog() {
    this.setState({ showDialog: false });
  }
  hiddenActionButton() {
    return (
      <button
        id="showDialogBtn"
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  handleOK() {
    const hasEmail =
      this.state.email && this.state.email.trim().length > 0 ? true : false;
    const hasPhone =
      this.state.phone && this.state.phone.trim().length > 0 ? true : false;

    if (!this.state.name || this.state.name.trim().length == 0) {
      this.setState({ errorMessage: "Name is required" });
      return;
    }

    if (!hasEmail && !hasPhone) {
      this.setState({ errorMessage: "Email/Phone is required" });
      return;
    }
    if (hasEmail && this.state.email.indexOf("@") <= 0) {
      this.setState({ errorMessage: "Invalid Email address" });
      return;
    }
    if (hasPhone && this.state.phone.trim().length < 10) {
      this.setState({ errorMessage: "Invalid Phone number" });
      return;
    }
    this.closeDialog();
    this.props.actionHandler(
      this.state.name,
      this.state.email,
      this.state.phone
    );
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  confirmationDialog() {
    const mobile = isMobileMode();
    const rClass = mobile ? "none" : "form-wrapper";
    const lClass = mobile ? "none" : "form-item-label";
    const vClass = mobile ? "none" : "form-item-value";
    const size = this.props.android ? 20 : 30;
    return (
      <div>
        {this.hiddenActionButton()}
        <Dialog isOpen={this.state.showDialog} onDismiss={this.closeDialog}>
          <img src={getIconBaseUrl() + "email.jpg"} height="14"></img>
          <b>&nbsp;Get Notified</b>
          <p />
          {this.props.registered ? (
            <label>
              You have already registered to receive notification for "
              {this.props.sku}". You can register again or under different
              email.
            </label>
          ) : (
            <label>
              Please enter your name and email address below, we will notify you
              once "{this.props.sku}" becomes available.
            </label>
          )}
          <p />
          <div className={rClass}>
            <div className={lClass}>Name: </div>
            <div className={vClass}>
              <input
                type="text"
                size={size}
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className={lClass}>Email: </div>
            <div className={vClass}>
              <input
                type="text"
                size={size}
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className={lClass}>Phone: </div>
            <div className={vClass}>
              <input
                type="text"
                size={10}
                name="phone"
                value={this.state.phone}
                onChange={this.handleChange}
              ></input>
            </div>
          </div>
          <p />
          <button onClick={this.handleOK} className="btn-style">
            OK
          </button>
          &nbsp;&nbsp;
          <button onClick={this.closeDialog} className="btn-style">
            Cancel
          </button>
        </Dialog>
      </div>
    );
  }

  render() {
    const clsName = this.props.buttonClsName
      ? this.props.buttonClsName
      : "btn-style";
    return (
      <div>
        {this.props.hidden ? (
          <button
            id={this.props.btnId}
            className={clsName}
            onClick={this.showConfirmation}
            style={{ display: "none" }}
          >
            {this.props.buttonTitle}
          </button>
        ) : (
          <button className={clsName} onClick={this.showConfirmation}>
            {this.props.buttonTitle}
          </button>
        )}
        {this.confirmationDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    android: state.cache.android,
  };
};
export default connect(mapStateToProps)(Waitlist);
