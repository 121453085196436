import {
  SEARCH_PRODUCTS,
  SET_CATEGORY,
  SET_SEARCH_RESULT,
  CLEAR_SEARCH
} from "../actions/actionTypes";
import { initSearchState } from "../State";

const searchReducer = (state = initSearchState, action) => {
  if (action.type === SEARCH_PRODUCTS) {
    return {
      ...state,
      query: action.query,
      result: null
    };
  } else if (action.type === SET_CATEGORY) {
    return {
      ...state,
      categoryName: action.categoryName,
      query: "",
      result: null
    };
  } else if (action.type === SET_SEARCH_RESULT) {
    return {
      ...state,
      result: action.result
    };
  } else if (action.type === CLEAR_SEARCH) {
    return initSearchState;
  }

  return state;
};

export default searchReducer;
