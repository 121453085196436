import React from "react";
import { connect } from "react-redux";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "../../App.css";
import "../../form.css";
import { getIconBaseUrl, isMobileMode, parseAddress } from "../Util";
import log from "loglevel";
import "../myLogger";

class DeliveryFeeCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDFCDialog: false,
      addressLine1: this.props.addressLine1,
      city: this.props.city,
      state: "GA",
      zipCode: this.props.zipCode,
      firstTime: true,
      errorMessage: null,
    };
    this.autocomplete = null;
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleOK = this.handleOK.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initAutoComplete = this.initAutoComplete.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.addressLine1 !== prevProps.addressLine1 ||
      this.props.city !== prevProps.city ||
      this.props.zipCode !== prevProps.zipCode
    ) {
      this.setState({
        addressLine1: this.props.addressLine,
        city: this.props.city,
        zipCode: this.props.zipCode,
      });
    }
  }
  initAutoComplete() {
    if (this.props.autoCompleteCDF) {
      const radius = this.props.deliveryRadius * 1600; //miles --> meters
      const circle = new window.google.maps.Circle({
        center: new window.google.maps.LatLng(
          this.props.whLatitude,
          this.props.whLongitude
        ),
        radius: radius,
      });
      this.autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { bounds: circle.getBounds(), strictbounds: true }
      );
      this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    }
  }
  handlePlaceSelect() {
    try {
      const addressObject = this.autocomplete.getPlace();

      if (addressObject) {
        const address = addressObject.address_components;
        const addrObj = parseAddress(address);

        this.setState({
          addressLine1: addrObj.addressLine1,
          city: addrObj.city,
          state: addrObj.state,
          zipCode: addrObj.zipCode,
        });
        document.getElementById("autocomplete").value = addrObj.addressLine1;
      }
    } catch (error) {
      log.error(error);
    }
  }
  showConfirmation() {
    document.getElementById("showDFCDialogBtn").click();
  }
  openDialog(event) {
    event.preventDefault();
    this.setState({ showDFCDialog: true });
    setTimeout(this.initAutoComplete, 1000);
  }
  closeDialog() {
    this.setState({ showDFCDialog: false });
  }
  hiddenActionButton() {
    return (
      <button
        id="showDFCDialogBtn"
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  handleOK() {
    if (
      !this.state.addressLine1 ||
      this.state.addressLine1.trim().length == 0
    ) {
      this.setState({ errorMessage: "Address is required" });
      return;
    }
    if (!this.state.city || this.state.city.trim().length === 0) {
      this.setState({ errorMessage: "City is required" });
      return;
    }
    this.closeDialog();
    this.props.actionHandler(this.state);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  confirmationDialog() {
    const mobile = isMobileMode();
    const city = mobile ? "City (Georgia)" : "City";
    const state = mobile ? "" : ", Georgia";
    const rClass = mobile ? "none" : "form-wrapper";
    const lClass = mobile ? "none" : "form-item-label";
    const vClass = mobile ? "none" : "form-item-value";
    const size = this.props.android ? 20 : 30;
    const zip = this.props.android ? "ZIP" : "ZIP Code";
    const btnName = this.props.android ? "Calc" : "Calculate";
    return (
      <div>
        {this.hiddenActionButton()}
        <Dialog
          isOpen={this.state.showDFCDialog}
          onDismiss={this.closeDialog}
          aria-label="Calculator"
        >
          <img src={getIconBaseUrl() + "deliveryFee.jpg"} height="20"></img>
          <b>&nbsp;{btnName} Delivery Fee</b>
          <p />
          Please enter your address, city, and ZIP code below, then click
          the&nbsp;
          {btnName} button.
          <p />
          <div className={rClass}>
            <div className={lClass}>Address: </div>
            <div className={vClass}>
              <input
                id="autocomplete"
                type="text"
                size={size}
                name="addressLine1"
                value={this.state.addressLine1}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className={lClass}>{city}: </div>
            <div className={vClass}>
              <input
                type="text"
                size={size}
                name="city"
                value={this.state.city}
                onChange={this.handleChange}
              ></input>
              {state}
            </div>
            {mobile ? (
              <div>
                {zip}:&nbsp;
                <input
                  type="text"
                  size="10"
                  name="zipCode"
                  value={this.state.zipCode}
                  onChange={this.handleChange}
                ></input>
              </div>
            ) : (
              <React.Fragment>
                <div className={lClass}>ZIP Code: </div>
                <div className={vClass}>
                  <input
                    type="text"
                    size="10"
                    name="zipCode"
                    value={this.state.zipCode}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </React.Fragment>
            )}
          </div>
          {this.state.errorMessage && (
            <font color="red">{this.state.errorMessage}</font>
          )}
          <p />
          <button onClick={this.handleOK} className="btn-style">
            {btnName}
          </button>
          &nbsp;&nbsp;
          <button onClick={this.closeDialog} className="btn-style">
            Cancel
          </button>
        </Dialog>
      </div>
    );
  }

  render() {
    const clsName = this.props.buttonClsName
      ? this.props.buttonClsName
      : "btn-style";
    return (
      <div>
        {this.props.hidden ? (
          <button
            id={this.props.btnId}
            className={clsName}
            onClick={this.showConfirmation}
            style={{ display: "none" }}
          >
            {this.props.buttonTitle}
          </button>
        ) : (
          <button className={clsName} onClick={this.showConfirmation}>
            {this.props.buttonTitle}
          </button>
        )}
        {this.confirmationDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    android: state.cache.android,
    autoCompleteCDF: state.cache.autoCompleteCDF,
    whLatitude: state.cache.whLatitude,
    whLongitude: state.cache.whLongitude,
    deliveryRadius: state.cache.deliveryRadius,
  };
};
export default connect(mapStateToProps)(DeliveryFeeCalculator);
