import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import { getIconBaseUrl, apiBaseUrl, isMobileMode } from "../Util";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { setPaymentError } from "../actions/orderActions";
import log from "loglevel";
import "../myLogger";
import "../../tables.css";
import "../../generic.css";
import "../../App.css";

class CreditCardEx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      creditCardInfo: this.ccInfo(),
      address: this.addressInfo(),
      amount: this.props.amountChoiceList[0].amount,
      amountChoiceList: this.props.amountChoiceList,
      pacIdx: 0,
      stateList: null,
      paymentName: "",
      useHTML5: false,
      submitBtnTitle: this.props.submitBtnTitle,
      submitBtnEnabled: true,
      errorMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleExpChange = this.handleExpChange.bind(this);
    this.setCurrentDateAsExpDate = this.setCurrentDateAsExpDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setInitState = this.setInitState.bind(this);
  }

  componentDidMount() {
    this.setInitState();
    this.setCurrentDateAsExpDate();

    const url = apiBaseUrl() + "StatesForPayment";
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ stateList: res.data });
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  setInitState() {
    const paymentName = this.state.amountChoiceList[this.state.pacIdx]
      .displayName;
    const useHTML5 = isMobileMode() && this.props.supportHTML5 ? true : false;
    this.setState({
      paymentName,
      useHTML5,
      userId: this.props.userId,
      amountChoiceList: this.props.amountChoiceList,
    });
  }
  componentDidUpdate(preProps) {
    if (this.props.amountChoiceList !== preProps.amountChoiceList) {
      this.setInitState();
    }
  }
  getThisMonAsString() {
    const nm = new Date().getMonth() + 1;
    const str = nm < 10 ? "0" + nm : "" + nm;
    return str;
  }

  setCurrentDateAsExpDate() {
    const year = new Date().getFullYear();
    const mon = this.getThisMonAsString();
    const creditCardInfo = {
      ...this.state.creditCardInfo,
      expMonth: mon,
      expYear: year,
    };
    this.setState({ creditCardInfo });
  }

  handleChange(event) {
    let name = event.target.name;

    if (name === "pacIdx") {
      const pacIdx = parseInt(event.target.value);
      const amount = this.state.amountChoiceList[pacIdx].amount;
      const paymentName = this.state.amountChoiceList[pacIdx].displayName;
      this.setState({
        pacIdx,
        amount,
        paymentName,
        submitBtnEnabled: true,
      });
      if (this.props.amountChoiceCallback) {
        this.props.amountChoiceCallback(pacIdx);
      }
    } else if (name.startsWith("ADDR_")) {
      name = name.substring(5);
      const address = {
        ...this.state.address,
        [name]: event.target.value,
      };
      this.setState({ address, submitBtnEnabled: true });
    } else if (name.startsWith("CC_")) {
      name = name.substring(3);
      const creditCardInfo = {
        ...this.state.creditCardInfo,
        [name]: event.target.value,
      };
      this.setState({ creditCardInfo, submitBtnEnabled: true });
    } else {
      this.setState({
        [name]: event.target.value,
        errorMessage: null,
      });
    }
    this.props.setPaymentError(null);
  }

  ccInfo() {
    const name = this.props.contactInfo
      ? this.props.contactInfo.firstName + " " + this.props.contactInfo.lastName
      : "";
    const info = {
      name,
      number: "",
      cvv: "",
      expMonth: null,
      expYear: null,
    };
    return info;
  }
  addressInfo() {
    if (this.props.contactInfo) {
      return this.props.contactInfo;
    } else {
      const address = {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "GA",
        zipCode: "",
      };
      return address;
    }
  }
  handleSubmit(event) {
    this.setState({ submitBtnEnabled: false });
    this.props.actionHandler(
      this.state.creditCardInfo,
      this.state.address,
      this.state.amount
    );
  }
  getStateList() {
    if (this.state.stateList === null) return "Georgia";
    else {
      return (
        <select
          name="ADDR_state"
          value={this.state.address.state}
          onChange={this.handleChange}
        >
          {this.state.stateList.map((pair) => {
            return (
              <option value={pair.value} key={pair.value}>
                {pair.key}
              </option>
            );
          })}
        </select>
      );
    }
  }

  handleExpChange(event) {
    let thisYear = new Date().getFullYear();
    const thisMon = this.getThisMonAsString();
    let expYear = this.state.creditCardInfo.expYear;
    let expMonth = this.state.creditCardInfo.expMonth;

    if (event.target.name === "year") {
      expYear = event.target.value;

      if (event.target.value === thisYear) {
        const selMon = document.getElementById("month").value;
        if (selMon < thisMon) {
          expMonth = thisMon;
        }
      }
    } else {
      expMonth = event.target.value;

      if (thisMon > event.target.value) {
        const selYear = document.getElementById("year").value;
        if (selYear === thisYear) {
          expYear = thisYear + 1;
        }
      }
    }
    const creditCardInfo = {
      ...this.state.creditCardInfo,
      expYear,
      expMonth,
    };
    this.setState({ creditCardInfo, submitBtnEnabled: true });
  }

  expDate() {
    const thisYear = new Date().getFullYear();
    let years = [],
      i;
    for (i = 0; i < 15; i++) {
      years.push(thisYear + i);
    }

    return (
      <div className="tbl-container4">
        <div>
          <select
            id="month"
            name="month"
            value={this.state.creditCardInfo.expMonth || ""}
            onChange={this.handleExpChange}
          >
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
        <div>&nbsp;/&nbsp;</div>
        <div>
          <select
            id="year"
            name="year"
            value={this.state.creditCardInfo.expYear || ""}
            onChange={this.handleExpChange}
          >
            {years.map((yr) => {
              return (
                <option value={yr} key={yr}>
                  {yr}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }

  billingAddressForm() {
    const required = this.props.billingAddrRequired;
    if (required || this.props.useBillingAddress) {
      const numType = this.state.useHTML5 ? "number" : "text";
      const addr = this.state.address;

      return (
        <div>
          <p>
            <b>Billing Information</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <label>
                    First Name{required && <font color="red">*</font>}:{" "}
                  </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type="text"
                      name="ADDR_firstName"
                      value={addr.firstName}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["First Name is required"]}
                    />
                  ) : (
                    <input
                      type="text"
                      name="ADDR_firstName"
                      value={addr.firstName}
                      onChange={this.handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Last Name{required && <font color="red">*</font>}:{" "}
                  </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type="text"
                      name="ADDR_lastName"
                      value={addr.lastName}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["Last Name is required"]}
                    />
                  ) : (
                    <input
                      type="text"
                      name="ADDR_lastName"
                      value={addr.lastName}
                      onChange={this.handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Address Line1{required && <font color="red">*</font>}:{" "}
                  </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type="text"
                      name="ADDR_addressLine1"
                      size="30"
                      value={addr.addressLine1}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["Address is required"]}
                    />
                  ) : (
                    <input
                      type="text"
                      name="ADDR_addressLine1"
                      size="30"
                      value={addr.addressLine1}
                      onChange={this.handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>Address Line2: </label>
                </td>
                <td>
                  <TextInput
                    type="text"
                    name="ADDR_addressLine2"
                    size="30"
                    value={addr.addressLine2}
                    onChange={this.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>City{required && <font color="red">*</font>}: </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type="text"
                      name="ADDR_city"
                      value={addr.city}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["City is required"]}
                    />
                  ) : (
                    <input
                      type="text"
                      name="ADDR_city"
                      value={addr.city}
                      onChange={this.handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>State{required && <font color="red">*</font>}: </label>
                </td>
                <td>{this.getStateList()}</td>
              </tr>
              <tr>
                <td>
                  <label>
                    ZIP Code{required && <font color="red">*</font>}:{" "}
                  </label>
                </td>
                <td>
                  {required ? (
                    <TextInput
                      type={numType}
                      name="ADDR_zipCode"
                      size="10"
                      value={addr.zipCode}
                      onChange={this.handleChange}
                      validators={[
                        "required",
                        "matchRegexp:^(\\d{5}(?:\\-\\d{4})?)$",
                      ]}
                      errorMessages={[
                        "ZIP Code is required",
                        "Invalid ZIP code",
                      ]}
                    />
                  ) : (
                    <input
                      type={numType}
                      name="ADDR_zipCode"
                      size="10"
                      value={addr.zipCode}
                      onChange={this.handleChange}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }
  paymentAmount() {
    const pac = this.state.amountChoiceList[this.state.pacIdx];
    const amount = pac.amount;
    const name = pac.displayName;
    if (this.state.amountChoiceList.length === 1) {
      return (
        <tr>
          <td>Payment Amount: </td>
          <td>
            <b>${amount.toFixed(2)}</b> ({name})
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td className="valign-tbl">Payment Amount:&nbsp; </td>
          <td className="valign-tbl">
            <select
              name="pacIdx"
              value={this.state.pacIdx}
              onChange={this.handleChange}
            >
              {this.state.amountChoiceList.map((choice, idx) => {
                const display = choice.name + ": $" + choice.amount.toFixed(2);
                return (
                  <option value={idx} key={idx}>
                    {display}
                  </option>
                );
              })}
            </select>
            <br />
            <b>${amount.toFixed(2)}</b> ({name})
          </td>
        </tr>
      );
    }
  }
  render() {
    const imgUrl = getIconBaseUrl() + "CreditCard-Logos.png";
    const numType = this.state.useHTML5 ? "number" : "text";
    const errMsg = this.state.errorMessage
      ? this.state.errorMessage
      : this.props.paymentError;
    const enabled = this.state.submitBtnEnabled || errMsg ? true : false;
    const btnStyle = enabled ? "btn-style" : "disabled-btn-style";
    const errMsgHtml = '<font color="red">' + errMsg + "</font><p/>";
    const cc = this.state.creditCardInfo;

    return (
      <div align="left">
        {errMsg && (
          <div
            dangerouslySetInnerHTML={{
              __html: errMsgHtml,
            }}
          ></div>
        )}
        <font size="4">Credit Card</font> &nbsp;&nbsp;
        <img src={imgUrl} height="20" alt="Credit Cards"></img>
        <p />
        <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
          <table>
            <tbody>
              <tr>
                <td>
                  <label>
                    Name on card<font color="red">*</font>:{" "}
                  </label>
                </td>
                <td>
                  <TextInput
                    type="text"
                    name="CC_name"
                    value={cc.name}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Name is required"]}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Card number<font color="red">*</font>:{" "}
                  </label>
                </td>
                <td>
                  {this.props.validateCCNumber ? (
                    <TextInput
                      type={numType}
                      name="CC_number"
                      value={cc.number}
                      onChange={this.handleChange}
                      placeholder="Card number, no space"
                      validators={[
                        "required",
                        "isNumber",
                        "minStringLength: 13",
                        "maxStringLength: 16",
                      ]}
                      errorMessages={[
                        "Card number is required",
                        "Must be a number, no space",
                        "Credit card number too short",
                        "Credit card number too long",
                      ]}
                    ></TextInput>
                  ) : (
                    <TextInput
                      type={numType}
                      name="CC_number"
                      value={cc.number}
                      onChange={this.handleChange}
                      placeholder="Card number, no space"
                      validators={["required"]}
                      errorMessages={["Card number is required"]}
                    ></TextInput>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Expiration date<font color="red">*</font>:{" "}
                  </label>
                </td>
                <td>{this.expDate()}</td>
              </tr>
              <tr>
                <td>
                  <label>
                    CVV<font color="red">*</font>:{" "}
                  </label>
                </td>
                <td>
                  <TextInput
                    type={numType}
                    size="4"
                    name="CC_cvv"
                    value={cc.cvv}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["CVV is required"]}
                  ></TextInput>
                </td>
              </tr>
              {!this.props.useBillingAddress && (
                <tr>
                  <td>Billing Zip Code:</td>
                  <td>
                    <input
                      type={numType}
                      size="10"
                      name="zipCode"
                      value={this.state.zipCode}
                      onChange={this.handleChange}
                    ></input>
                  </td>
                </tr>
              )}
              {this.paymentAmount()}
            </tbody>
          </table>

          {this.billingAddressForm()}
          <p />
          <div>
            <button
              id="submitBtn"
              className={btnStyle}
              type="submit"
              disabled={!enabled}
            >
              {this.state.submitBtnTitle}
            </button>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    contactInfo: state.order.contactInfo,
    isLoggedIn: state.user.isLoggedIn,
    userId: state.user.userId,
    supportHTML5: state.cache.supportHTML5,
    paymentError: state.order.paymentError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPaymentError: (paymentError) => {
      dispatch(setPaymentError(paymentError));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreditCardEx));
