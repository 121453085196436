import {
  SET_USERINFO,
  SET_LOGGEDIN,
  SET_USERID,
  CLEAR_USER,
} from "./actionTypes";

export const setUserInfo = (userInfo) => {
  return {
    type: SET_USERINFO,
    payload: userInfo,
  };
};

export const setLoggedIn = (pwdToken) => {
  return {
    type: SET_LOGGEDIN,
    pwdToken,
  };
};

export const setUserId = (userId) => {
  return {
    type: SET_USERID,
    userId: userId,
  };
};

export const clearUser = () => {
  return {
    type: CLEAR_USER,
  };
};
