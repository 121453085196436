import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
  FormatCurrency,
  generateGuid,
} from "../Util";
import { addToCart } from "../actions/cartActions";
import { setUserId } from "../actions/userActions";
import log from "loglevel";
import "../myLogger";
import "../../App.css";
import "../../generic.css";
import "../../bt.css";

class BuyTogether extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMode: false,
      btList: this.props.btList,
      prodList: null,
      isLoading: true,
      errorMessage: null,
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getProdList = this.getProdList.bind(this);
    this.addItemsToCart = this.addItemsToCart.bind(this);
  }

  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.getProdList();
  }
  componentDidUpdate(preProps) {
    if (preProps.btList !== this.props.btList) {
      this.setState({
        btList: this.props.btList,
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  getProdList() {
    const url = apiBaseUrl() + "GetBTProdList";
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    const req = {
      userId: userId,
      objList: this.props.btList,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ prodList: res.data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get Product List",
        });
      });
  }
  handleClick(event) {
    const name = event.target.name;
    const idx = parseInt(name.substring(3));
    let btList = [],
      i;

    for (i = 0; i < this.state.btList.length; i++) {
      if (i === idx) {
        const bt = {
          ...this.state.btList[i],
          checked: event.target.checked,
        };
        btList.push(bt);
      } else {
        btList.push(this.state.btList[i]);
      }
    }
    this.setState({ btList });
  }

  addItemsToCart(event) {
    event.preventDefault();
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    let i;
    for (i = 0; i < this.state.btList.length; i++) {
      const bt = this.state.btList[i];
      const prod = this.state.prodList[i];
      let optionName = null;
      if (prod.pvList.length > 1) {
        const pv = prod.pvList.find((aPv) => aPv.sku === bt.sku);
        if (pv) optionName = pv.variantValue;
      }

      if (bt.checked) {
        const cartItem = {
          sku: bt.sku,
          item: prod,
          price: bt.finalPrice,
          name: bt.name,
          optionName,
        };
        this.props.addToCart(userId, cartItem);
      }
    }
    this.props.history.push("/cart");
  }
  showBTI() {
    const btList = this.state.btList;
    let totalPrice = 0,
      itemCount = 0,
      i;
    for (i = 0; i < btList.length; i++) {
      if (btList[i].checked) {
        totalPrice += btList[i].finalPrice;
        itemCount++;
      }
    }

    let btnTitle = null;
    if (itemCount > 2) btnTitle = "Add " + itemCount + " Items";
    else if (itemCount === 2) btnTitle = "Add Both Items";
    else if (itemCount === 1) btnTitle = "Add Item";
    const label = this.props.android ? "Total" : "Total Price";
    const width = this.props.android ? 55 : 60;
    const height = this.props.android ? 42 : 45;
    let isFirst = true;
    return (
      <div>
        <div className="generic-flex2">
          {btList.map((bt, idx) => {
            if (bt.checked) {
              const url = "/product/" + bt.productId + "/" + bt.sku;
              const addPlus = !isFirst;
              isFirst = false;
              return (
                <React.Fragment>
                  {addPlus && <div>+</div>}
                  {idx > 0 ? (
                    <div>
                      <a href={url}>
                        <img
                          src={fullProductImageUrl(bt.imageUrl)}
                          width={width}
                          height={height}
                        />
                      </a>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={fullProductImageUrl(bt.imageUrl)}
                        width={width}
                        height={height}
                      />
                    </div>
                  )}
                </React.Fragment>
              );
            }
          })}
          {itemCount > 0 && (
            <React.Fragment>
              <div className="left-10">&nbsp;</div>
              <div>
                {label}:{" "}
                <b>
                  <font color="red">
                    <FormatCurrency amount={totalPrice} />
                  </font>
                </b>
                <br />
                {btnTitle && (
                  <button
                    className="atc-btn-style"
                    name="add"
                    onClick={this.addItemsToCart}
                  >
                    {btnTitle}
                  </button>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="bti-item-list">
          {btList.map((bt, idx) => {
            const name = "bt_" + idx;
            const title = idx > 0 ? bt.name : "This item";
            return (
              <React.Fragment>
                <input
                  type="checkbox"
                  className="bti-cb"
                  name={name}
                  defaultChecked={bt.checked}
                  onClick={this.handleClick}
                />
                {title} ({bt.sku})
                <br />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.btList || this.state.btList.length < 1) return "";

    if (
      this.state.isLoading ||
      !this.state.prodList ||
      this.state.prodList.length !== this.state.btList.length
    ) {
      return "";
    }

    return (
      <div className="top-10">
        <div>
          <b>Buy Together</b>
        </div>

        <div>{this.showBTI()}</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    android: state.cache.android,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (userId, payload) => {
      dispatch(addToCart(userId, payload));
    },
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuyTogether));
