import {
  SET_DELIVERYINFO,
  SET_CONTACTINFO,
  SET_ORDERINFO,
  SET_FEEANDRATE,
  SET_SHIPPING_CHOICE,
  SET_PICKUP_LOC,
  SET_ORDERSTAGE,
  CLEAR_ORDER,
  SET_STAGED_ORDER,
  SET_STAGED_ORDERNUM,
  CLEAR_STAGED_ORDER,
  SET_SECURITY_DEPOSIT,
  SET_SCHEDULED_DATE,
  SET_PAYMENT_ERROR,
  SET_PROGRESSIVE_INFO,
  SET_LEASEOK_INFO,
} from "./actionTypes";

export const setDeliveryInfo = (deliveryInfo) => {
  return {
    type: SET_DELIVERYINFO,
    payload: deliveryInfo,
  };
};
export const setContactInfo = (contactInfo) => {
  return {
    type: SET_CONTACTINFO,
    payload: contactInfo,
  };
};
export const setOrderInfo = (payload) => {
  return {
    type: SET_ORDERINFO,
    payload: payload,
  };
};

export const setRateAndFees = (ratefees) => {
  return {
    type: SET_FEEANDRATE,
    ratefees: ratefees,
  };
};
export const setPaymentError = (paymentError) => {
  return {
    type: SET_PAYMENT_ERROR,
    paymentError,
  };
};
export const setSecurityDeposit = (pickupDeposit, pickupDepositTerm) => {
  return {
    type: SET_SECURITY_DEPOSIT,
    pickupDeposit: pickupDeposit,
    pickupDepositTerm: pickupDepositTerm,
  };
};
export const setShippingChoice = (choice) => {
  return {
    type: SET_SHIPPING_CHOICE,
    choice: choice,
  };
};

export const setPickupLoc = (locId, leadTime) => {
  return {
    type: SET_PICKUP_LOC,
    pickupLocId: locId,
    pickupLeadTime: leadTime,
  };
};

export const setOrderStage = (stage) => {
  return {
    type: SET_ORDERSTAGE,
    stage: stage,
  };
};

export const setStagedOrder = (order) => {
  return {
    type: SET_STAGED_ORDER,
    stagedOrder: order,
  };
};

export const setStagedOrderNum = (orderNum) => {
  return {
    type: SET_STAGED_ORDERNUM,
    stagedOrderNum: orderNum,
  };
};

export const clearOrder = () => {
  return {
    type: CLEAR_ORDER,
  };
};
export const clearStagedOrder = () => {
  return {
    type: CLEAR_STAGED_ORDER,
  };
};
export const setScheduledDate = (date) => {
  return {
    type: SET_SCHEDULED_DATE,
    scheduledDate: date,
  };
};
export const setProgressiveInfo = (progInfo) => {
  return {
    type: SET_PROGRESSIVE_INFO,
    payload: progInfo,
  };
};
export const setLeaseOkInfo = (info) => {
  return {
    type: SET_LEASEOK_INFO,
    payload: info,
  };
};
