import {
  SET_USERINFO,
  SET_LOGGEDIN,
  SET_USERID,
  CLEAR_USER,
} from "../actions/actionTypes";
import { initUserState } from "../State";

const userReducer = (state = initUserState, action) => {
  if (action.type === SET_USERINFO) {
    return {
      ...state,
      userInfo: action.payload,
    };
  } else if (action.type === SET_LOGGEDIN) {
    return {
      ...state,
      pwdToken: action.pwdToken,
      isLoggedIn: action.pwdToken !== null,
    };
  } else if (action.type === SET_USERID) {
    return {
      ...state,
      userId: action.userId,
    };
  } else if (action.type === CLEAR_USER) {
    return initUserState;
  }

  return state;
};

export default userReducer;
