import React from "react";
import { setTopContainerWrapperSettings, setInfoWrapperWidth } from "../Util";
import "../../info.css";

class Layaway extends React.Component {
  constructor(props) {
    super(props);
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
    setInfoWrapperWidth();
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="info-container">
          <div className="info-wrapper">
            <div className="info-item">
              <font size="5">LAYAWAY PROGRAM</font>
              <br />
              <hr className="info-hr"></hr>
            </div>
            <div className="info-item">
              We offer 30 days and 90 days layaway for customers.
              <p />
              <font color="orange">10% down – 30 day hold.</font>
              <p />
              <font color="orange">20% down – 90 day hold.</font>
            </div>

            <div className="info-item">
              <b>Terms and Conditions</b>
              <p />
              <div className="info-content-wrapper">
                <ol>
                  <li>
                    If you put 10% down, we will hold your furnitures for 30
                    days. If you put 20% down, we will hold for 90 days.
                  </li>
                  <li>
                    We don't set fixed payment schedule during the hold time, so
                    you can make any number of payments or amounts. Every time
                    you make a layaway payment of 10% or more, we will extend
                    ohhold time accordingly. For example, if you make a 10%
                    layaway payment, we extend 30 days. 20% payment will extend
                    onhold time for 90 days, etc.
                  </li>
                  <li>
                    If you cancel the layaway, there is a <b>15%</b> restocking
                    fee of your total layaway furnitures, not just your
                    accumulated deposit.
                  </li>
                  <li>
                    You can avoid 15% restocking fee by reslecting different
                    items in our store, your deposit will be used as store
                    credit towards new purchase or new layway.
                  </li>
                  <li>
                    If no payment is made before/at the end of onhold time, we
                    consider your layaway payment is overdue. We will cancel
                    your layaway if your payment is 60 days overdue, and 15%
                    restocking fee of total layaway purchase will be assessed .
                    The remaining deposit, if any, will be refunded to you.
                  </li>
                  <li>
                    We reserve the right to cancel your layaway if the onhold
                    furnitures are damaged and the manufacture discontinues
                    production of the furnitures. In this case, your deposit
                    will be fully refunded.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layaway;
