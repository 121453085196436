import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { apiBaseUrl } from "../Util";
import { AXIOS_HEADER } from "../config/constants";

import "../../App.css";
import "../../form.css";
import "../../user.css";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errorMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendTempPass = this.sendTempPass.bind(this);
  }

  componentDidMount() {
    if (this.props.userId && this.props.userId.indexOf("@") > 0) {
      this.setState({ email: this.props.userId });
    }
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  sendTempPass(event) {
    event.preventDefault();

    if (!this.state.email) {
      this.setState({ errorMessage: "Email is required" });
      return;
    }
    const url = apiBaseUrl() + "ResetPassword";
    const req = {
      userId: this.props.userId,
      email: this.state.email,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.props.history.push("/changePass/reset");
      })
      .catch((error) => {
        this.setState({ errorMessage: "Unable to reset password" });
      });
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Reset Password</font>
          <p />
          Please enter your registered email address and then click "Reset
          Password" button. We will send a temporary password to this email.
          <p />
          <ValidatorForm ref="form" onSubmit={this.sendTempPass}>
            <div className="twocol-tbl">
              <div className="form-item-value">Email: </div>
              <div className="form-item-value">
                <TextInput
                  onChange={this.handleChange}
                  type="email"
                  name="email"
                  size="30"
                  value={this.state.email}
                  validators={["required", "isEmail"]}
                  errorMessages={["Email is required", "Invalid email address"]}
                />
              </div>
            </div>
            <div className="top-10">
              <button name="reset" type="submit" className="btn-style">
                Reset Password
              </button>
            </div>
          </ValidatorForm>
          <p />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userId: state.user.userId,
  };
};
export default connect(mapStateToProps)(withRouter(ResetPassword));
