import React, { Component } from "react";
import "../../tables.css";
import "../../generic.css";
import "../../App.css";
import { getIconBaseUrl } from "../Util";

class InStorePayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: 2,
      amount: this.props.amount,
      amtChangeable: this.props.amtChangeable,
      nameOnCard: this.props.nameOnCard,
      approvalCode: null,
      last4: null,
      note: null,
      cash: false,
      waitingForOrder: this.props.waitingForOrder,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.amount != this.props.amount ||
      prevProps.nameOnCard != this.props.nameOnCard ||
      prevProps.waitingForOrder != this.props.waitingForOrder
    ) {
      this.setState({
        amount: this.props.amount,
        amtChangeable: this.props.amtChangeable,
        waitingForOrder: this.props.waitingForOrder,
      });
    }
  }
  handleChange(event) {
    const name = event.target.name;

    if (name === "paymentMethod") {
      const paymentMethod = parseInt(event.target.value);
      const cash = paymentMethod === 1 ? true : false;
      this.setState({ paymentMethod, cash });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  }
  submitOrder(event) {
    if (this.props.actionHandler) {
      this.props.actionHandler(this.state);
    }
  }
  header() {
    return (
      <div className="generic-flex">
        <div>
          <b>Payment:</b>
        </div>
        <div className="left-10">
          <input
            type="radio"
            name="paymentMethod"
            value={2}
            checked={this.state.paymentMethod === 2}
            onChange={this.handleChange}
          ></input>
          Debit/Credit
        </div>
        <div className="left-10">
          <input
            type="radio"
            name="paymentMethod"
            value={1}
            checked={this.state.paymentMethod === 1}
            onChange={this.handleChange}
          ></input>{" "}
          Cash/Check
        </div>
      </div>
    );
  }
  paymentSection() {
    const btnStyle = this.state.waitingForOrder
      ? "disabled-btn-style"
      : "btn-style";
    return (
      <div>
        <div className="twocol-form">
          <div>
            Amount<font color="red">*</font>:
          </div>
          <div className="generic-flex">
            <div>
              <input
                type="text"
                size="6"
                name="amount"
                inputMode="decimal"
                value={this.state.amount || ""}
                onChange={this.handleChange}
                disabled={!this.state.amtChangeable}
              ></input>
            </div>
          </div>
          {this.state.paymentMethod === 2 && (
            <React.Fragment>
              <div>Name on Card:</div>
              <div>
                <input
                  type="text"
                  size="20"
                  name="nameOnCard"
                  value={this.state.nameOnCard || ""}
                  onChange={this.handleChange}
                ></input>
              </div>
              <div>Last4 CC:</div>
              <div>
                <input
                  type="text"
                  size="6"
                  name="last4"
                  value={this.state.last4 || ""}
                  onChange={this.handleChange}
                ></input>
              </div>
              <div>Approval Code:</div>
              <div>
                <input
                  type="text"
                  size="20"
                  name="approvalCode"
                  value={this.state.approvalCode || ""}
                  onChange={this.handleChange}
                ></input>
              </div>
            </React.Fragment>
          )}
          <div>Note:</div>
          <div>
            <input
              type="text"
              name="note"
              value={this.state.note || ""}
              onChange={this.handleChange}
            ></input>
          </div>
        </div>
        <div className="flex-t10">
          <button
            className={btnStyle}
            name="addPmt"
            disabled={this.state.waitingForOrder}
            onClick={this.submitOrder}
          >
            Submit Order
          </button>
          {this.state.waitingForOrder && (
            <div className="left-10">
              <img
                src={getIconBaseUrl() + "working.gif"}
                height="25"
                alt="working"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.header()}
        {this.paymentSection()}
      </div>
    );
  }
}

export default InStorePayment;
