import React from "react";
import { connect } from "react-redux";
import ProductListView from "../product/ProductListView";
import { setTopContainerWrapperSettings } from "../Util";
import "../../tables.css";
import "../../orderComplete.css";
import { isInStore } from "../store/storeUtil";
import { ORDERSTAGE_COMPLETE } from "../order/orderStages";
import { setOrderStage } from "../actions/orderActions";
import CreateAccount from "../user/CreateAccount";

class LayawayComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderNumber: "",
      orderEmail: "",
      orderCompleteMsg: null,
      alreadyRegistered: false,
    };
    this.adjustSettings = this.adjustSettings.bind(this);
    this.setRegistered = this.setRegistered.bind(this);
  }

  componentDidMount() {
    if (!this.props.orderNumber || isInStore()) {
      this.props.history.push("/");
      return;
    }

    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);

    this.setState({
      orderNumber: this.props.orderNumber,
      orderEmail: this.props.orderEmail,
      orderCompleteMsg: this.props.orderCompleteMsg,
    });
    this.props.setOrderStage(ORDERSTAGE_COMPLETE);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
  }
  setRegistered() {
    this.setState({ alreadyRegistered: true });
  }
  showCreateAccount() {
    if (!this.props.isLoggedIn) {
      return (
        <div>
          {!this.state.alreadyRegistered && (
            <div>
              If you don't have a login account, it is strongly recommended that
              you create one so you can view and update your layaway online.
              <p />
            </div>
          )}
          <CreateAccount registedHandler={this.setRegistered} />
          <hr className="hr-300" align="left" />
        </div>
      );
    }
  }
  displayNote() {
    return (
      <div className="oc-paid-wrapper">
        <div className="oc-paid-desc" align="left">
          <b>
            <font size="4" color="green">
              The layaway order was received.
            </font>
          </b>
          <p />
          {this.props.orderCompleteMsg ? (
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.orderCompleteMsg,
              }}
            ></div>
          ) : (
            <div>
              Your order number is: <b>{this.state.orderNumber}</b>.
              {this.state.orderEmail && (
                <span>
                  &nbsp;&nbsp;A confirmation email was sent to{" "}
                  {this.state.orderEmail}, please check your email for layaway
                  details and future payment instructions.
                </span>
              )}
              <p />
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const backendApi = "OrderRelatedProd/" + this.state.orderNumber;
    const title = "Related Items";
    return (
      <div className="top-wrapper">
        <div className="oc-container">
          {this.state.orderNumber ? (
            <React.Fragment>
              {this.displayNote()}
              {this.showCreateAccount()}
              <ProductListView backendApi={backendApi} title={title} />
            </React.Fragment>
          ) : (
            <div>"Please wait ..."</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderNumber: state.order.orderNumber,
    orderEmail: state.order.orderEmail,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
    kiosk: state.webOrder.kiosk,
    orderCompleteMsg: state.order.orderCompleteMsg,
    isLoggedIn: state.user.isLoggedIn,
    userInfo: state.user.userInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOrderStage: (stage) => {
      dispatch(setOrderStage(stage));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LayawayComplete);
