import { store } from "../config/store";

export function cartSummary(tRate, deliveryFee) {
  const state = store.getState();
  const storeId =
    state.webOrder && state.webOrder.storeId ? state.webOrder.storeId : 0;

  let totalQuant = 0;
  let subtotal = state.cart.subtotal;
  const items = state.cart.addedItems;

  for (let i = 0; i < items.length; i++) {
    totalQuant += items[i].quantity;
  }

  let coupons = [];
  let theStoreItems = [];
  let deposits = [];
  let couponAmount = 0; //Negative
  let depositAmount = 0; //Positive
  let isStoreLayaway = false;
  if (storeId > 0) {
    const storeItems = state.spCart.storeItems;
    if (storeItems && storeItems.length > 0) {
      for (let i = 0; i < storeItems.length; i++) {
        if (storeItems[i].coupon) {
          coupons.push(storeItems[i]);
          couponAmount += storeItems[i].price;
        } else {
          if (!storeItems[i].typeId || storeItems[i].typeId <= 0) {
            subtotal += storeItems[i].quantity * storeItems[i].price;
            totalQuant += storeItems[i].quantity;
            theStoreItems.push(storeItems[i]);
          } else {
            //Deposits
            deposits.push(storeItems[i]);
            depositAmount += storeItems[i].price;
            if (storeItems[i].layaway) isStoreLayaway = true;
          }
        }
      }
    }
  }

  const discount = state.cart.discount;
  if (discount) {
    if (discount.afterTax) {
      couponAmount -= discount.amount;
    } else {
      subtotal = subtotal - discount.amount;
    }
  }

  const taxRate = tRate ? tRate : 0.08;
  const tax = subtotal * taxRate;
  const grandTotal = subtotal + tax + couponAmount + deliveryFee;
  const balance = grandTotal - depositAmount;
  const depositPercent =
    grandTotal > 0 ? (depositAmount * 100.0) / grandTotal : 0;
  const summary = {
    storeId,
    storeName: state.webOrder.storeName,
    itemCount: totalQuant,
    subtotal,
    taxRate,
    tax,
    deliveryFee,
    grandTotal,
    depositAmount,
    balance,
    depositPercent,
    isStoreLayaway,

    discount,
    coupons,
    deposits,
    items,
    storeItems: theStoreItems,
  };
  return summary;
}
