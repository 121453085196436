//Cart action types
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SUB_QUANTITY = "SUB_QUANTITY";
export const ADD_QUANTITY = "ADD_QUANTITY";
export const SET_ITEMS = "SET_ITEMS";
export const SET_CARTMSG = "SET_CARTMSG";
export const CHECKOUT = "CHECKOUT";
export const SET_DISCOUNT = "SET_DISCOUNT";

//Order action types
export const SET_CONTACTINFO = "SET_CONTACTINFO";
export const SET_DELIVERYINFO = "SET_DELIVERYINFO";
export const SET_ORDERINFO = "SET_ORDERINFO";
export const SET_FEEANDRATE = "SET_FEEANDRATE";
export const SET_SHIPPING_CHOICE = "SET_SHIPPING_CHOICE";
export const SET_PICKUP_LOC = "SET_PICKUP_LOC";
export const SET_ORDERSTAGE = "SET_ORDERSTAGE";
export const SET_STAGED_ORDER = "SET_STAGED_ORDER";
export const SET_STAGED_ORDERNUM = "SET_STAGED_ORDERNUM";
export const CLEAR_STAGED_ORDER = "CLEAR_STAGED_ORDER";
export const SET_SECURITY_DEPOSIT = "SET_SECURITY_DEPOSIT";
export const SET_SCHEDULED_DATE = "SET_SCHEDULED_DATE";
export const SET_PAYMENT_ERROR = "SET_PAYMENT_ERROR";

//User action types
export const SET_USERID = "SET_USERID";
export const SET_USERINFO = "SET_USERINFO";
export const SET_LOGGEDIN = "SET_LOGGEDIN";

//Search action types
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";

//For all
export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_USER = "CLEAR_ORDER";
export const CLEAR_SEARCH = "CLEAR_ORDER";
export const CLEAR_CACHE = "CLEAR_CACHE";
export const CLEAR_WEBORDER = "CLEAR_WEBORDER";

//For cache
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_HOMES = "SET_HOMES";
export const SET_DEBUG_INFO = "SET_DEBUG_INFO";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_VERSION = "SET_VERSION";

//Web Order
export const REGISTER_WEBORDER = "REGISTER_WEBORDER";

//Store Products
export const SP_ADD_TO_CART = "SP_ADD_TO_CART";
export const SP_REMOVE_ITEM = "SP_REMOVE_ITEM";
export const SP_SUB_QUANTITY = "SP_SUB_QUANTITY";
export const SP_ADD_QUANTITY = "SP_ADD_QUANTITY";
export const SP_CLEAR_CART = "SP_CLEAR_CART";

//For Progressive leasing
export const SET_PROGRESSIVE_INFO = "SET_PROGRESSIVE_INFO";
export const SET_LEASEOK_INFO = "SET_LEASEOK_INFO";
