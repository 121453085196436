import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { btnStyle } from "../Styles";
import {
  AXIOS_HEADER,
  CUCAT_WARANTY,
  CUCAT_FEEDBACK,
  CUCAT_GENERAL,
  CUCAT_ORDER,
} from "../config/constants";
import {
  apiBaseUrl,
  generateGuid,
  getIconBaseUrl,
  getCompressSetting,
  previewImgBaseUrl,
  isMobileMode,
} from "../Util";
import { setUserId } from "../actions/userActions";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import TextAreaInput from "../validation/TextAreaInput";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import log from "loglevel";
import "../myLogger";
import "../../generic.css";
import "../../info.css";

const Compress = require("compress.js");

class ContactUsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messageId: null,
      userId: "",
      category: CUCAT_WARANTY,
      name: "",
      email: "",
      orderNumber: "",
      uploadedFiles: null,
      messageBody: "",
      mbLabel: "Problem Description",
      orderNumRequired: true,
      fileRequired: true,
      uploadSuccess: false,
      errorMessage: null,
      showDialog: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.processUploadResponse = this.processUploadResponse.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    let userId = this.props.userId;
    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }

    this.setState({ messageid: generateGuid(), userId });
  }

  processUploadResponse(response, fileName) {
    if (response.status) {
      let uploadedFiles = this.state.uploadedFiles;
      if (!uploadedFiles || uploadedFiles.length == 0) {
        uploadedFiles = [fileName];
      } else {
        if (uploadedFiles.length > 2) {
          let newArray = [];
          newArray.push(uploadedFiles[1]);
          newArray.push(uploadedFiles[2]);
          newArray.push(fileName);
          uploadedFiles = newArray;
        } else {
          uploadedFiles.push(fileName);
        }
      }
      this.setState({
        uploadedFiles,
        uploadSuccess: true,
      });
    } else {
      this.setState({
        uploadSuccess: false,
        errorMessage: response.errorMessage,
      });
    }
  }

  uploadFile(file, fileName) {
    const data = new FormData();
    data.append("file", file);

    const url = apiBaseUrl() + "UploadProdPic";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, data, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processUploadResponse(res.data, fileName);
      })
      .catch((error) => {
        this.setState({
          uploadSuccess: false,
          errorMessage: "Failed to upload",
        });
        console.log(error);
        log.error(error);
      });
  }

  compressFile(file) {
    const compress = new Compress();
    const setting = getCompressSetting();

    compress
      .compress([file], {
        size: setting.maxSize,
        quality: setting.quality,
        maxWidth: setting.maxWidth,
        maxHeight: setting.maxWidth,
        resize: true,
      })
      .then((results) => {
        const base64str = results[0].data;
        const imgExt = results[0].ext;
        const blob = Compress.convertBase64ToFile(base64str, imgExt);
        const newFile = new File([blob], file.name, { type: imgExt });

        this.uploadFile(newFile, file.name);
      })
      .catch((error) => {
        this.setState({
          uploadSuccess: false,
          errorMessage: "Failed to upload",
        });
        log.error(error);
      });
  }

  handleChange(event) {
    if (event.target.name === "file") {
      if (event.target.files && event.target.files.length > 0) {
        let i;
        const count =
          event.target.files.length > 3 ? 3 : event.target.files.length;
        for (i = 0; i < count; i++) {
          const file = event.target.files[i];
          if (file.size > 1048576) {
            this.compressFile(file);
          } else {
            this.uploadFile(file, file.name);
          }
        }
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });

      if (event.target.name === "category") {
        let label = "Your Questions";
        let onRequired = false;
        let fileRequired = false;
        switch (event.target.value) {
          case CUCAT_FEEDBACK:
            label = "Your Feedback";
            break;
          case CUCAT_WARANTY:
            label = "Problem Description";
            onRequired = true;
            fileRequired = true;
            break;
          case CUCAT_ORDER:
            onRequired = true;
            break;
          default:
            break;
        }
        this.setState({
          mbLabel: label,
          orderNumRequired: onRequired,
          fileRequired,
        });
      }
    }
  }

  processResponse(res) {
    document.getElementById("showDialogBtn").click();
  }

  handleSubmit(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "UserMessage";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, this.state, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }

  openDialog() {
    this.setState({ showDialog: true });
  }
  closeDialog() {
    this.setState({ showDialog: false });
    this.props.history.push("/");
  }

  hiddenButton() {
    return (
      <button
        id="showDialogBtn"
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }

  showDialogMessage() {
    if (this.state.category === CUCAT_WARANTY) {
      return (
        <div>
          {this.hiddenButton()}
          <Dialog isOpen={this.state.showDialog} onDismiss={this.closeDialog}>
            <b>Waranty Repair Request Received</b>
            <p>
              We have received your furniture waranty repair request, we will
              check it against our waranty policies. If your furnitures are
              under waranty, we will forward your request to the vendor within
              24 hours and fix your furnitures in five days.
            </p>
            You can find our Waranty Policies at the bottom of the page.
            <p />
            <button onClick={this.closeDialog} style={btnStyle}>
              Okay
            </button>
          </Dialog>
        </div>
      );
    } else if (this.state.category === CUCAT_FEEDBACK) {
      return (
        <div>
          {this.hiddenButton()}
          <Dialog isOpen={this.state.showDialog} onDismiss={this.closeDialog}>
            <b>Your Feedback Received</b>
            <p>
              Thanks for your feedback. We constantly trying to improve our
              site. Please come back later.
            </p>
            <button onClick={this.closeDialog} style={btnStyle}>
              Okay
            </button>
          </Dialog>
        </div>
      );
    } else {
      return (
        <div>
          {this.hiddenButton()}
          <Dialog isOpen={this.state.showDialog} onDismiss={this.closeDialog}>
            <b>Your Inquirt Received</b>
            <p>
              Thanks for your inquiry. We will try to get back to you as soon as
              possible.
            </p>
            <button onClick={this.closeDialog} style={btnStyle}>
              Okay
            </button>
          </Dialog>
        </div>
      );
    }
  }

  contactForm() {
    const imgSrc = this.state.uploadSuccess
      ? getIconBaseUrl() + "checkmark.png"
      : getIconBaseUrl() + "cross.png";
    const uploadedFiles = this.state.uploadedFiles
      ? this.state.uploadedFiles
      : [];
    const label = isMobileMode()
      ? "Please upload pictures:"
      : "Please upload pictures show damaged part:";
    const emailType =
      this.props.supportHTML5 && isMobileMode() ? "email" : "text";
    const telType = this.props.supportHTML5 && isMobileMode() ? "tel" : "text";
    return (
      <div className="info-contact-container">
        <div className="info-contact-comments" align="left">
          <font size="5">Contact Us</font>
        </div>

        <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
          <div className="info-contact-wrapper">
            <div className="info-contact-item-label">What is it About?</div>
            <div className="info-contact-item-value">
              <select
                className="info-cat-select"
                id="category"
                name="category"
                value={this.state.category}
                onChange={this.handleChange}
              >
                <option value={CUCAT_WARANTY}>{CUCAT_WARANTY}</option>
                <option value={CUCAT_GENERAL}>{CUCAT_GENERAL}</option>
                <option value={CUCAT_FEEDBACK}>{CUCAT_FEEDBACK}</option>
                <option value={CUCAT_ORDER}>{CUCAT_ORDER}</option>
              </select>
            </div>

            <div className="info-contact-item-label">
              <font color="red">*</font>Name:
            </div>
            <div className="info-contact-item-value">
              <TextInput
                onChange={this.handleChange}
                name="name"
                size="30"
                value={this.state.name}
                validators={["required"]}
                errorMessages={["Name is required"]}
              />
            </div>

            <div className="info-contact-item-label">
              <font color="red">*</font>Email:
            </div>
            <div className="info-contact-item-value">
              <TextInput
                onChange={this.handleChange}
                name="email"
                type={emailType}
                size="40"
                value={this.state.email}
                validators={["required", "isEmail"]}
                errorMessages={["Email is required", "Invalid email address"]}
              />
            </div>

            {this.state.orderNumRequired && (
              <React.Fragment>
                <div className="info-contact-item-label">
                  <font color="red">*</font>Phone Number:
                </div>
                <div className="info-contact-item-value">
                  <TextInput
                    onChange={this.handleChange}
                    type={telType}
                    name="orderNumber"
                    placeholder="Phone number on invoice"
                    value={this.state.orderNumber}
                    validators={["required"]}
                    errorMessages={["Order Number is required"]}
                  />
                </div>
              </React.Fragment>
            )}

            {this.state.fileRequired && (
              <React.Fragment>
                <div>&nbsp;</div>
                <div className="info-contact-item-value">{label}</div>
                <div className="info-contact-item-label">Upload Pictures:</div>
                <div className="info-contact-item-value">
                  <input
                    type="file"
                    multiple="true"
                    name="file"
                    onChange={this.handleChange}
                  />
                </div>
                {uploadedFiles && uploadedFiles.length > 0 && (
                  <React.Fragment>
                    <div>&nbsp;</div>
                    <div className="info-contact-item-value">
                      <div className="generic-flex">
                        <div>
                          <img src={imgSrc} alt="Status" height="15"></img>
                          &nbsp;
                        </div>
                        {uploadedFiles.map((img) => {
                          return (
                            <div className="right-5">
                              <img
                                src={previewImgBaseUrl() + img}
                                width="40"
                                height="30"
                              ></img>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>

          <div className="info-contact-comments" align="left">
            <div>
              <font color="red">*</font>
              {this.state.mbLabel}:
            </div>
            <TextAreaInput
              name="messageBody"
              rows={10}
              cols={60}
              value={this.state.messageBody}
              onChange={this.handleChange}
              validators={["required"]}
              errorMessages={["Comments is required"]}
            />
            <p />
            <div>
              <button className="info-btn-style" type="submit">
                Submit
              </button>
            </div>
          </div>
        </ValidatorForm>
        {this.showDialogMessage()}
      </div>
    );
  }

  render() {
    return this.contactForm();
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    supportHTML5: state.cache.supportHTML5,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactUsForm));
