import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  isMobileMode,
  fullProductImageUrl,
  virtualTourUrl,
  useEmbeddedGoogleMap,
  getIconBaseUrl,
  setTopContainerWrapperSettings,
  getGroupWrapperWidth,
} from "../Util";
import Working from "../Working";
import log from "loglevel";
import "../myLogger";

import "../../location.css";

class StoreLocation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: -1,
      name: null,
      info: null,
      imageWidth: 600,
      mobileMode: false,
    };
    this.adjustMode = this.adjustMode.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);

    this.fetchData();
  }

  fetchData() {
    const locId = this.props.locationId
      ? this.props.locationId
      : this.props.match.params.id;
    const url = apiBaseUrl() + "LocationInfo/" + locId;

    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState(res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      if (this.props.match.params.id !== prevProps.match.params.id) {
        this.fetchData();
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    const imgWidth = isMobileMode()
      ? window.innerWidth - 12
      : (getGroupWrapperWidth() * 60) / 100 - 30;

    setTopContainerWrapperSettings();
    this.setState({
      imageWidth: imgWidth,
      mobileMode: isMobileMode(),
    });
  }

  contactInfo() {
    const info = this.state.info;
    if (info === null) return <label>Loading ...</label>;

    return (
      <div>
        {info.description}
        <br />
        {info.addressLine1} {info.addressLine2}
        <br />
        {info.city}, {info.state} {info.zipCode}
        <p />
        Phone: {info.phone}
        <br />
        {info.phone2 && (
          <React.Fragment>
            Phone: {info.phone2}
            <br />
          </React.Fragment>
        )}
      </div>
    );
  }

  openInfo() {
    let info = this.state.info.open ? "OPEN" : "CLOSED";
    let color = this.state.info.open ? "green" : "red";

    if (this.state.info.specialInfo) {
      info = this.state.info.specialInfo;
      color = "red";
    }

    return (
      <div>
        Store Is:{" "}
        <b>
          <font color={color}>{info}</font>
        </b>
      </div>
    );
  }

  hoursOfOperation() {
    const hourList = this.state.info.hourList;
    let str = "<table>";
    let i;

    for (i = 0; i < hourList.length; i++) {
      str +=
        "<tr><td><b>" +
        hourList[i].key +
        ":</b></td><td width='5'></td><td>" +
        hourList[i].value +
        "</td></tr>";
    }
    str += "</table>";
    return str;
  }

  warehouseLink() {
    const vtIcon = getIconBaseUrl() + "virtualTour.png";
    if (this.state.info.warehouse) {
      return (
        <div className="loc-row-container">
          <a href={virtualTourUrl()} target="VirtualTour">
            <img src={vtIcon} alt="Virtual Tour" width="80"></img>
          </a>
        </div>
      );
    } else {
      return (
        <div className="loc-row-container">
          <div>
            <a href={virtualTourUrl()} target="_blank">
              <img src={vtIcon} alt="Virtual Tour" width="30"></img>
            </a>
          </div>
          <div className="loc-row-right-item">
            <Link to="/location/0">
              <font size="4" color="orange">
                Warehouse Location
              </font>
            </Link>
          </div>
        </div>
      );
    }
  }

  getGoogleMap(info) {
    if (useEmbeddedGoogleMap()) {
      const mapStyle = {
        border: 0,
      };
      const height = (this.state.imageWidth * 3) / 4;
      return (
        <iframe
          width={this.state.imageWidth}
          height={height}
          frameBorder="0"
          style={mapStyle}
          src={info.googleMapSrc}
          allowFullScreen
        ></iframe>
      );
    } else {
      return (
        <a href={info.mapLinkUrl} target="GoogleMap">
          <img
            src={fullProductImageUrl(info.imageUrl)}
            alt={info.name}
            width={this.state.imageWidth}
          ></img>
        </a>
      );
    }
  }

  displayMobile() {
    const info = this.state.info;

    return (
      <div>
        <div align="left" className="loc-title">
          <font size="5">{info.name}</font>
        </div>
        <div className="loc-container">
          <div className="loc-call-out" align="left">
            {this.getGoogleMap(info)}
          </div>
          <div className="loc-call-out" align="left">
            <font color="orange">CONTACT:</font>
            <br />
            {this.contactInfo()}
          </div>
          <div className="loc-call-out" align="left">
            <b>Hours of Operations:</b>
            <hr align="left" width="200"></hr>
            <p />
            <div
              dangerouslySetInnerHTML={{ __html: this.hoursOfOperation() }}
            ></div>
            <p />
            {this.openInfo()}
          </div>
          <div className="loc-call-out" align="left">
            <b>Pickup Instructions:</b>
            <hr align="left" width="200"></hr>
            <p />
            {info.pickup ? (
              <div dangerouslySetInnerHTML={{ __html: info.pickupInstr }}></div>
            ) : (
              <div>
                Pickup is NOT available in this location. Please go to our
                warehouse location (link below) to pickup.
              </div>
            )}
            <p />
            {this.warehouseLink()}
            <p />
          </div>
        </div>
      </div>
    );
  }

  displayRegular() {
    const info = this.state.info;
    const piWidth = isMobileMode()
      ? window.innerWidth - 50
      : (getGroupWrapperWidth() * 40) / 100 - 30;
    return (
      <div className="loc-container">
        <div align="left">
          <font size="5">{info.name}</font>
        </div>
        <p />
        <table>
          <tbody>
            <tr>
              <td valign="top" align="left">
                {this.getGoogleMap(info)}
                <p />
                <font color="orange">CONTACT:</font>
                <br />
                {this.contactInfo()}
              </td>
              <td width="20"></td>
              <td valign="top" align="left">
                <b>Hours of Operations:</b>
                <hr align="left" width="200"></hr>
                <p />
                <div
                  dangerouslySetInnerHTML={{ __html: this.hoursOfOperation() }}
                ></div>
                <p />
                {this.openInfo()}

                <p />
                <b>Pickup Instructions:</b>
                <hr align="left" width="200"></hr>
                <p />
                {info.pickup ? (
                  <div
                    style={{ width: piWidth }}
                    dangerouslySetInnerHTML={{ __html: info.pickupInstr }}
                  ></div>
                ) : (
                  <div>
                    Pickup is NOT available in this location. Please go to our
                    warehouse location (link below) to pickup.
                  </div>
                )}
                <p />
                {this.warehouseLink()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    if (this.state.info === null) return <Working />;

    return (
      <div className="top-wrapper">
        {this.state.mobileMode ? this.displayMobile() : this.displayRegular()}
      </div>
    );
  }
}

export default StoreLocation;
