import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  setTopContainerWrapperSettings,
  isMobileMode,
  faqImgBaseUrl,
  getGroupWrapperWidth,
} from "../Util";
import Working from "../Working";
import "../../App.css";
import "../../faq.css";

class FAQ extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      faqList: null,
      mobileMode: false,
      imageWidth: 0,
      isLoading: true,
      errorMessage: null,
    };
    this.fetchList = this.fetchList.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchList();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    setTopContainerWrapperSettings();
    const imageWidth = (getGroupWrapperWidth() * 25) / 100;
    this.setState({ mobileMode: isMobileMode(), imageWidth });
  }
  fetchList() {
    const url = apiBaseUrl() + "FaqList";

    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ faqList: res.data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showOneAnswer(faqIdx, answerIdx) {
    const mod = (faqIdx + answerIdx) % 2;
    const clsName = "grid-item-a" + mod;
    const imgClsName = "img-text-wrap" + mod;
    const answer = this.state.faqList[faqIdx].answerList[answerIdx];
    let html = answer.answer;
    if (answer.title) {
      html = "<i>" + answer.title + "</i><br/>" + html;
    }
    const key = "" + faqIdx + "_" + answerIdx;
    let imageWidth = this.state.imageWidth;

    if (answer.widthPercent > 0) {
      imageWidth = (getGroupWrapperWidth() * answer.widthPercent) / 100;
    } else if (answer.imageWidth > 0) {
      imageWidth = answer.imageWidth;
    }
    return (
      <React.Fragment key={key}>
        <div>&nbsp;</div>
        <div className={clsName}>
          {answer.imageUrl && (
            <div>
              <img
                src={faqImgBaseUrl() + answer.imageUrl}
                width={imageWidth}
                className={imgClsName}
              ></img>
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </React.Fragment>
    );
  }
  showOneFaq(faqIdx) {
    const faq = this.state.faqList[faqIdx];
    const liLabel = "" + (faqIdx + 1) + ".";
    return (
      <React.Fragment key={faqIdx}>
        <div className="faq-number">
          <b>{liLabel}</b>
        </div>
        <div className="grid-item-q">
          <b>{faq.question}</b>
        </div>
        {faq.answerList &&
          faq.answerList.length > 0 &&
          faq.answerList.map((answer, answerIdx) => {
            return this.showOneAnswer(faqIdx, answerIdx);
          })}
      </React.Fragment>
    );
  }

  showAll() {
    return (
      <div>
        <div className="faq-table">
          {this.state.faqList.map((faq, idx) => {
            return this.showOneFaq(idx);
          })}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) return <Working />;

    return (
      <div className="top-container">
        <div className="top-wrapper">
          <font size="5">Frequent Asked Questions</font>
          <p />
          {this.showAll()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    android: state.cache.android,
  };
};

export default connect(mapStateToProps)(FAQ);
