import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  fullProductImageUrl,
  priceRange,
  generateGuid,
  getGroupWrapperWidth,
  setTopContainerWrapperSettings,
  isMobileMode,
  getProdDetailUrl,
} from "../Util";
import Working from "../Working";
import { setUserId } from "../actions/userActions";
import log from "loglevel";
import "../myLogger";
import "../../App.css";
import "../../prodListView.css";

class ProductListView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
      isLoading: true,
      imageWidth: 150,
      imageHeight: 120,
      message: null,
    };
    this.adjustSettings = this.adjustSettings.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData() {
    const url = apiBaseUrl() + this.props.backendApi;

    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);

    if (this.props.isPost) {
      let userId = this.props.userId;

      if (userId === null || userId === undefined || userId === "") {
        userId = generateGuid();
        this.props.setUserId(userId);
      }

      const req = { userId: userId };
      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, req, { headers: AXIOS_HEADER })
        .then((res) => {
          this.setState({ items: res.data, isLoading: false });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            message: "Unable to fetch products, please try again later",
          });
          console.log(error);
          log.error(error);
        });
    } else {
      axios
        .get(url, { headers: AXIOS_HEADER })
        .then((res) => {
          this.setState({ items: res.data, isLoading: false });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            message: "Unable to fetch products, please try again later",
          });
          console.log(error);
          log.error(error);
        });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.backendApi !== prevProps.backendApi) {
      this.fetchData();
    }
  }

  adjustSettings() {
    let colCount = isMobileMode() ? 3 : 4;
    let width = (getGroupWrapperWidth() - 15) / colCount;

    width = Math.floor(width);
    document.documentElement.style.setProperty(
      "--thumbnail-item-width",
      "" + width + "px"
    );
    document.documentElement.style.setProperty(
      "--thumbnail-column-count",
      colCount
    );
    this.setState({
      imageWidth: width - 5,
      imageHeight: ((width - 5) * 2) / 3,
    });
    setTopContainerWrapperSettings();
  }

  render() {
    if (this.state.isLoading === true) return <Working />;

    if (!this.state.items) {
      return (
        <div>
          <font color="red">{this.state.message}</font>
        </div>
      );
    }

    return (
      <div className="top-wrapper">
        <div align="left">
          <b>{this.props.title}</b>
        </div>
        <div className="plv-wrapper">
          {this.state.items.map((item) => {
            const soldOut = item.forSale === false ? "SOLD OUT" : "";
            const url = getProdDetailUrl(item);
            const imageUrl = item.thumbnailImage
              ? item.thumbnailImage
              : item.imageUrl;
            return (
              <div className="plv-thumbnail-item" key={item.name}>
                <Link to={url}>
                  <img
                    src={fullProductImageUrl(imageUrl)}
                    width={this.state.imageWidth}
                    height={this.state.imageHeight}
                    alt={item.name}
                  ></img>
                </Link>
                <br />
                {item.name}
                <br />
                {priceRange(item)}
                <br />
                <b>
                  <i>
                    <font color="red">{soldOut}</font>
                  </i>
                </b>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductListView);
