import {
  SET_PRODUCTS,
  SET_CATEGORIES,
  SET_HOMES,
  SET_DEBUG_INFO,
  SET_LOCATIONS,
  CLEAR_CACHE,
  SET_VERSION,
} from "../actions/actionTypes";
import { initCacheState } from "../State";
import { catName2Id } from "../Util";

const cacheReducer = (state = initCacheState, action) => {
  if (action.type === SET_PRODUCTS) {
    let productsByCat = state.productsByCat;

    if (!productsByCat) {
      productsByCat = [[], [], [], [], [], []];
    }
    productsByCat[catName2Id(action.category)] = action.products;
    return {
      ...state,
      productsByCat,
    };
  } else if (action.type === SET_CATEGORIES) {
    return {
      ...state,
      categories: action.categories,
      useCatImage: action.useCatImage,
    };
  } else if (action.type === SET_HOMES) {
    return {
      ...state,
      android: action.payload.android,
      supportHTML5: action.payload.supportHTML5
        ? action.payload.supportHTML5
        : false,
      newArrivalCount: action.payload.newArrivalCount,
      homeImageList: action.payload.homeImageList,
      homePromList: action.payload.homePromList,
      homeVerPromList: action.payload.homeVerPromList,
      homeViewedList: action.payload.homeViewedList,
      autoCompleteAddress: action.payload.autoCompleteAddress,
      autoCompleteCDF: action.payload.autoCompleteCDF,
      whLatitude: action.payload.whLatitude,
      whLongitude: action.payload.whLongitude,
      deliveryRadius: action.payload.deliveryRadius,
    };
  } else if (action.type === SET_LOCATIONS) {
    return {
      ...state,
      locations: action.locations,
    };
  } else if (action.type === SET_DEBUG_INFO) {
    return {
      ...state,
      debugInfo: action.payload,
    };
  } else if (action.type === CLEAR_CACHE) {
    return initCacheState;
  } else if (action.type === "DEBUG") {
    return {
      ...state,
      debug: true,
    };
  } else if (action.type === SET_VERSION) {
    return {
      ...state,
      version: action.version,
    };
  }
  return state;
};
export default cacheReducer;
