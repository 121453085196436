import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { btnStyle } from "../Styles";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  setTopContainerWrapperSettings,
  isMobileMode,
  validatePhoneNo,
  parseAddress,
} from "../Util";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import log from "loglevel";
import "../myLogger";
import "../../contact.css";
import "../../info.css";

class UserInfoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: this.props.userInfo ? this.props.userInfo : this.initUserInfo(),
      formTitle: this.props.title,
      addressType: this.props.addressType,
      foundAddr: null,
      useHTML5: false,
      stateList: null,
      errMessage: "",
    };
    this.autocomplete = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchStateList = this.fetchStateList.bind(this);

    this.adjustSettings = this.adjustSettings.bind(this);
    this.initAutoComplete = this.initAutoComplete.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }

  initUserInfo() {
    const userInfo = {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "GA",
      zipCode: "",
      note: "",
    };
    return userInfo;
  }

  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
    this.fetchStateList();

    const useHTML5 = isMobileMode() && this.props.supportHTML5 ? true : false;
    this.setState({ useHTML5 });
    setTimeout(this.initAutoComplete, 1000);
  }
  fetchStateList() {
    const url = apiBaseUrl() + "StatesFor" + this.props.addressType;
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ stateList: res.data });
      })
      .catch((error) => {
        log.error(error);
      });
  }
  componentDidUpdate(preProps) {
    if (
      this.props.title !== preProps.title ||
      this.props.addressType !== preProps.addressType ||
      this.props.userInfo != preProps.userInfo
    ) {
      this.setState({
        formTitle: this.props.title,
        addressType: this.props.addressType,
        errMessage: "",
        userInfo: this.props.userInfo
          ? this.props.userInfo
          : this.initUserInfo(),
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
    this.setState({ errMessage: "" });
  }
  adjustSettings() {
    setTopContainerWrapperSettings();
  }
  initAutoComplete() {
    if (this.props.autoCompleteAddress) {
      const radius = this.props.deliveryRadius * 1600; //miles --> meters
      const circle = new window.google.maps.Circle({
        center: new window.google.maps.LatLng(
          this.props.whLatitude,
          this.props.whLongitude
        ),
        radius: radius,
      });
      this.autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { bounds: circle.getBounds(), strictbounds: true }
      );
      this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    }
  }
  handlePlaceSelect() {
    try {
      const addressObject = this.autocomplete.getPlace();

      if (addressObject) {
        const addressComp = addressObject.address_components;
        const address = parseAddress(addressComp);
        const userInfo = {
          ...this.state.userInfo,
          addressLine1: address.addressLine1,
          city: address.city,
          state: address.state,
          zipCode: address.zipCode,
        };
        this.setState({ userInfo });
        document.getElementById("autocomplete").value = address.addressLine1;
      }
    } catch (error) {
      log.error(error);
    }
  }

  handleChange(event) {
    event.preventDefault();

    const userInfo = {
      ...this.state.userInfo,
      [event.target.name]: event.target.value,
    };
    this.setState({ userInfo });
  }
  handleSubmit(event) {
    event.preventDefault();

    this.props.actionHandler(this.state.userInfo);
  }
  showStateList() {
    if (this.state.stateList === null) return "Georgia";

    return (
      <select
        name="state"
        value={this.state.userInfo.state}
        onChange={this.handleChange}
      >
        {this.state.stateList.map((pair, idx) => {
          return (
            <option value={pair.value} key={idx}>
              {pair.key}
            </option>
          );
        })}
      </select>
    );
  }

  showErrMessage() {
    if (this.state.errMessage) {
      return (
        <div className="ct-title">
          <font color="red">{this.state.errMessage}</font>
          <p />
        </div>
      );
    }
  }
  required(fldName) {
    if (this.props.requiredFields) {
      const ff = this.props.requiredFields.find((item) => item === fldName);
      return ff ? true : false;
    }
    return false;
  }
  addressForm() {
    if (
      this.props.showAddress ||
      (this.props.userInfo && this.props.userInfo.address)
    ) {
      const txtLen = this.props.android ? 20 : 30;
      const zipValidator = this.required("zipCode")
        ? ["required", "matchRegexp:^(\\d{5}(?:\\-\\d{4})?)$"]
        : [];
      const numType = this.state.useHTML5 ? "number" : "text";
      const addr = this.state.userInfo;
      return (
        <React.Fragment>
          <div className="cttbl-item-left">
            Address{this.required("addressLine1") && <font color="red">*</font>}
            :{" "}
          </div>
          <div className="cttbl-item-left">
            <TextInput
              id="autocomplete"
              type="text"
              name="addressLine1"
              size={txtLen}
              value={addr.addressLine1}
              onChange={this.handleChange}
              validators={this.required("addressLine1") ? ["required"] : []}
              errorMessages={["Address is required"]}
            />
          </div>
          <div className="cttbl-item-left">Address2:</div>
          <div className="cttbl-item-left">
            <TextInput
              type="text"
              name="addressLine2"
              size={txtLen}
              placeholder="(Apartment number, etc.)"
              value={addr.addressLine2}
              onChange={this.handleChange}
            />
          </div>
          <div className="cttbl-item-left">
            City{this.required("city") && <font color="red">*</font>}:{" "}
          </div>
          <div className="cttbl-item-left">
            <TextInput
              type="text"
              name="city"
              value={addr.city}
              onChange={this.handleChange}
              validators={this.required("city") ? ["required"] : []}
              errorMessages={["City is required"]}
            />
          </div>
          <div className="cttbl-item-left">
            <label>
              State{this.required("state") && <font color="red">*</font>}:{" "}
            </label>
          </div>
          <div className="cttbl-item-left">{this.showStateList()}</div>
          <div className="cttbl-item-left">
            ZIP Code{this.required("zipCode") && <font color="red">*</font>}:{" "}
          </div>
          <div className="cttbl-item-left">
            <TextInput
              type={numType}
              name="zipCode"
              size="10"
              value={addr.zipCode}
              onChange={this.handleChange}
              validators={zipValidator}
              errorMessages={["ZIP Code is required", "Invalid ZIP code"]}
            />
          </div>
        </React.Fragment>
      );
    }
  }
  theForm() {
    const txtLen = this.props.android ? 20 : 30;
    const colCnt = this.props.android ? 40 : 50;
    const rowCnt = this.props.android ? 6 : 5;
    const emailType = this.state.useHTML5 ? "email" : "text";
    const telType = this.state.useHTML5 ? "tel" : "text";
    const info = this.state.userInfo;
    return (
      <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
        <div className="ct-wrapper">
          <div className="cttbl-container">
            <div className="cttbl-item-left">
              First Name
              {this.required("firstName") && <font color="red">*</font>}:
            </div>

            <div className="cttbl-item-left">
              <TextInput
                type="text"
                name="firstName"
                value={info.firstName}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["First Name is required"]}
              />
            </div>
            <div className="cttbl-item-left">
              Last Name{this.required("lastName") && <font color="red">*</font>}
              :
            </div>
            <div className="cttbl-item-left">
              <TextInput
                type="text"
                name="lastName"
                value={info.lastName}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["Last Name is required"]}
              />
            </div>
            <div className="cttbl-item-left">
              Phone{this.required("phone") && <font color="red">*</font>}:
            </div>
            <div className="cttbl-item-left">
              {validatePhoneNo() ? (
                <TextInput
                  type={telType}
                  name="phone"
                  size="15"
                  value={info.phone}
                  onChange={this.handleChange}
                  validators={[
                    "required",
                    "matchRegexp:^\\(?([0-9]{3})\\)?[-..]?([0-9]{3})[-..]?([0-9]{4})$",
                  ]}
                  errorMessages={["Phone is required", "Invalid phone number"]}
                />
              ) : (
                <TextInput
                  type={telType}
                  name="phone"
                  size="15"
                  value={info.phone}
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["Phone is required"]}
                />
              )}
            </div>
            <div className="cttbl-item-left">
              Email{this.required("email") && <font color="red">*</font>}:
            </div>
            <div className="cttbl-item-left">
              <TextInput
                type={emailType}
                name="email"
                size={txtLen}
                value={info.email}
                onChange={this.handleChange}
                validators={["required", "isEmail"]}
                errorMessages={["Email is required", "Invalid email address"]}
              />
            </div>
            {this.addressForm()}
          </div>
          {this.props.noteTitle && (
            <React.Fragment>
              <div align="left" className="left-10">
                <div>{this.props.noteTitle}:</div>
                <textarea
                  name="note"
                  rows={rowCnt}
                  cols={colCnt}
                  value={this.state.note}
                  onChange={this.handleChange}
                ></textarea>
              </div>
            </React.Fragment>
          )}
          <div className="cttbl-item-left">
            <button style={btnStyle} type="submit">
              Continue
            </button>
          </div>
        </div>
      </ValidatorForm>
    );
  }
  render() {
    return (
      <div>
        <div className="contact-title">
          <b>
            <font size="4">{this.props.title}</font>
          </b>
        </div>
        {this.showErrMessage()}
        {this.theForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    android: state.cache.android,
    supportHTML5: state.cache.supportHTML5,
    whLatitude: state.cache.whLatitude,
    whLongitude: state.cache.whLongitude,
    deliveryRadius: state.cache.deliveryRadius,
  };
};

export default connect(mapStateToProps)(withRouter(UserInfoForm));
