import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { setLoggedIn, setUserId, setUserInfo } from "../actions/userActions";
import { apiBaseUrl } from "../Util";
import { AXIOS_HEADER } from "../config/constants";

import "../../App.css";
import "../../form.css";

class VerifyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      verifyCode: null,
      email: this.props.userId,
      password: "",
      displayMessage: false,
      errorMessage: null,
      verifySuccess: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.verifyUser = this.verifyUser.bind(this);
    this.processVerifyResponse = this.processVerifyResponse.bind(this);
    this.getVerifyRequest = this.getVerifyRequest.bind(this);
  }

  componentDidMount() {
    const encStr = this.props.match.params.encStr;
    if (encStr && encStr.length > 0) {
      this.getVerifyRequest();
    } else {
      if (this.props.userId && this.props.userId.indexOf("@") > 0) {
        this.setState({ displayMessage: true });
      } else {
        this.props.history.push("/");
      }
    }
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  processVerifyResponse(response) {
    if (response.status) {
      this.props.setUserId(this.state.email);
      this.props.setLoggedIn(response.pwdToken);
      this.setState({ errorMessage: null });
      this.props.setUserInfo(response.contactAddr);

      if (this.props.returnUrl) {
        this.props.history.push(this.props.returnUrl);
      } else {
        this.setState({ verifySuccess: true });
      }
    } else {
      this.setState({ errorMessage: "Invalid user ID or password" });
    }
  }
  getVerifyRequest() {
    const url = apiBaseUrl() + "GetVerifyRequest";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, this.props.match.params.encStr, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data) {
          this.setState({
            email: res.data.email,
            verifyCode: res.data.verifyCode,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMessage: "Verification failed" });
      });
  }
  verifyUser(event) {
    event.preventDefault();

    const url = apiBaseUrl() + "VerifyUser";
    const req = {
      email: this.state.email,
      verifyCode: this.state.verifyCode,
      password: this.state.password,
    };
    console.log(req);
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processVerifyResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMessage: "Verification failed" });
      });
  }
  displayMessage() {
    if (this.state.displayMessage) {
      return (
        <div>
          Thank you, your account has been successfully created. We have sent an
          8-digit verification code to your email address at {this.state.email}.
          You can either enter the code below or follow the link in that email
          to verify your account.
          <p />
        </div>
      );
    }
  }
  displaySuccessMessage() {
    return (
      <div>
        Thank you! Your account has been successfully verified. You will be able
        to login using your email address and password.
        <p />
      </div>
    );
  }
  render() {
    if (this.state.verifySuccess) {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">Successfully Verified</font>
            <p />
            {this.displaySuccessMessage()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <font size="5">Verify Account</font>
            <p />
            {this.displayMessage()}
            <ValidatorForm ref="form" onSubmit={this.verifyUser}>
              <div className="twocol-tbl">
                <div className="form-item-label">Verification Code: </div>
                <div className="form-item-value">
                  <TextInput
                    name="verifyCode"
                    value={this.state.verifyCode || ""}
                    placeholder="8-digit code"
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Verification Code is required"]}
                  ></TextInput>
                </div>
                <div className="form-item-label">Password: </div>
                <div className="form-item-value">
                  <TextInput
                    name="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Password is required"]}
                  ></TextInput>
                </div>
              </div>
              <div>
                <button name="verify" type="submit" className="btn-style">
                  Verify
                </button>
              </div>
            </ValidatorForm>
            <p />
            <font color="red">{this.state.errorMessage}</font>
            <p />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userId: state.user.userId,
    loginTime: state.user.loginTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (pwdToken) => {
      dispatch(setLoggedIn(pwdToken));
    },
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    setUserInfo: (userInfo) => {
      dispatch(setUserInfo(userInfo));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VerifyAccount));
