import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  FormatCurrency,
  fullProductImageUrl,
  isMobileMode,
  priceColor,
  getImageUrlForSKU,
} from "../Util";
import "../../orderSum.css";
import { isInStore } from "../store/storeUtil";

class CartView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgWidth: 120,
    };
    this.adjustMode = this.adjustMode.bind(this);
  }
  adjustMode() {
    let width = 120;
    if (isMobileMode()) {
      width = 100;
    }
    this.setState({ imgWidth: width });
  }
  componentDidMount() {
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  showItemDetail(item) {
    let title = "";
    if (item && item.title) {
      title =
        item.title.length > 30
          ? item.title.substring(0, 28) + "..."
          : item.title;
    }

    return (
      <div className="sum-cart-item">
        {title}
        <br />
        SKU: {item.sku}
        <br />
        Quantity: {item.quantity}
        <br />
        Unit Price:{" "}
        <font color={priceColor()}>
          <FormatCurrency amount={item.price} />
        </font>
      </div>
    );
  }

  displayOneItem(item) {
    const height = (3 * this.state.imgWidth) / 4;
    let prodUrl = "/product/" + item.product.productId;
    if (item.product.pvList.length > 1) {
      prodUrl = prodUrl + "/" + item.sku;
    }

    return (
      <React.Fragment key={item.sku}>
        <div className="sum-cart-item">
          <Link to={prodUrl}>
            <img
              src={fullProductImageUrl(
                getImageUrlForSKU(item.product, item.sku)
              )}
              alt={item.product.name}
              width={this.state.imgWidth}
              height={height}
            />
          </Link>
        </div>
        {this.showItemDetail(item)}
      </React.Fragment>
    );
  }
  displayOneStoreItem(item) {
    if (item.typeId && item.typeId > 0) return null;

    const imageUrl =
      item.product && item.product.imageUrl
        ? item.product.imageUrl
        : "store-items.jpg";
    const height = (3 * this.state.imgWidth) / 4;
    return (
      <React.Fragment key={item.sku}>
        <div className="sum-cart-item">
          {item.coupon ? (
            <img
              src={fullProductImageUrl("coupon.jpg")}
              alt={item.product.name}
              width={this.state.imgWidth}
            />
          ) : (
            <img
              src={fullProductImageUrl(imageUrl)}
              alt={item.product.name}
              width={this.state.imgWidth}
              height={height}
            />
          )}
        </div>
        {item.coupon ? (
          <div className="sum-cart-item">
            {item.title}
            <br />
            Discount:{" "}
            <font color={priceColor()}>
              <FormatCurrency amount={item.price} />
            </font>
          </div>
        ) : (
          <div className="sum-cart-item">
            {item.title}
            <br />
            {item.sku &&
              !item.sku.startsWith("TYPED_") &&
              !item.sku.startsWith("COUPON_") && (
                <React.Fragment>
                  SKU: {item.sku}
                  <br />
                </React.Fragment>
              )}
            Quantity: {item.quantity}
            <br />
            Unit Price:{" "}
            <font color={priceColor()}>
              <FormatCurrency amount={item.price} />
            </font>
          </div>
        )}
      </React.Fragment>
    );
  }
  isEmpty() {
    let count = this.props.items ? this.props.items.length : 0;
    if (count === 0 && isInStore()) {
      count = this.props.storeItems ? this.props.storeItems.length : 0;
    }
    return count === 0;
  }
  displayCartItems() {
    return (
      <React.Fragment>
        {this.props.items &&
          this.props.items.map((item) => {
            return this.displayOneItem(item);
          })}
        {isInStore() &&
          this.props.storeItems &&
          this.props.storeItems.map((item) => {
            return this.displayOneStoreItem(item);
          })}
      </React.Fragment>
    );
  }
  render() {
    let title = "Shopping Cart";
    if (this.props.title !== undefined && this.props.title !== "") {
      title = this.props.title;
    }

    return (
      <div>
        <font size="4">{title}</font>
        <hr />
        <div className="sum-cart-container">
          {this.isEmpty() ? (
            <p>Your shopping cart is empty.</p>
          ) : (
            this.displayCartItems()
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.addedItems,
    storeItems: state.spCart.storeItems,
  };
};

export default connect(mapStateToProps)(CartView);
