import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import { apiBaseUrl, isMobileMode } from "../Util";
import Working from "../Working";
import { setShippingChoice, setPickupLoc } from "../actions/orderActions";
import log from "loglevel";
import "../myLogger";
import "../../tables.css";
import "../../pickupLoc.css";

class PickupLocations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [{ id: 0, name: null, info: null }],
      checked: [],
      isLoading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.setChecked = this.setChecked.bind(this);
  }

  processResponse(data) {
    this.setState({ locations: data, isLoading: false });
    this.setChecked(this.props.pickupLocId);

    if (data && data.length > 0) {
      this.setChecked(data[0].id);
      this.props.setShippingChoice("Pickup");
      this.props.setPickupLoc(data[0].id, data[0].info.pickupLeadTime);
    }
  }

  setChecked(locId) {
    let i;
    let checked = [];
    for (i = 0; i < this.state.locations.length; i++) {
      checked.push(this.state.locations[i].id === locId);
    }
    this.setState({ checked: checked });
  }

  componentDidMount() {
    const url = apiBaseUrl() + "AllPickupLoc";
    const req = {
      userId: this.props.userId,
      id: this.props.storeId,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleChange(event) {
    if (event.target.checked) {
      const result = this.state.locations.filter(
        (loc) => loc.id === parseInt(event.target.value)
      );

      let leadTime = "";
      if (result && result.length > 0 && result[0].info) {
        leadTime = result[0].info.pickupLeadTime;
      }
      this.props.setShippingChoice("Pickup");
      this.props.setPickupLoc(event.target.value, leadTime);
      this.setChecked(parseInt(event.target.value));
    }
  }

  storeInfo(loc) {
    if (loc.info === null) return <label>Loading ...</label>;

    const url = "/location/" + loc.id;
    const info = loc.info;

    return (
      <div>
        <Link to={url}>
          <b>{info.description}</b>
        </Link>
        <br />
        {info.addressLine1}
        {info.addressLine2 && (
          <label>
            <br />
            {info.addressLine2}
          </label>
        )}
        <br />
        {info.city}, {info.state} {info.zipCode}
        <br />
        Phone: {info.phone}
        <br />
      </div>
    );
  }

  hoursOfOperations(info) {
    const clsName = isMobileMode() ? "tbl-container2m" : "tbl-container2";
    return (
      <div className={clsName}>
        {info.hourList.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <div className="tbl-item-left">{item.key}: </div>
              <div className="tbl-item-left">{item.value}</div>
            </React.Fragment>
          );
        })}
        <div className="tbl-item-left">Pickup:</div>
        <div className="tbl-item-left">
          <b>{info.pickupLeadTime}</b>
        </div>
      </div>
    );
  }
  infoAndHours(location) {
    if (isMobileMode()) {
      return (
        <div>
          <div>{this.storeInfo(location)}</div>
          {this.hoursOfOperations(location.info)}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div>{this.storeInfo(location)}</div>
          <div className="pickup-last-div">
            {this.hoursOfOperations(location.info)}
          </div>
        </React.Fragment>
      );
    }
  }
  locationInfo(location, idx) {
    return (
      <React.Fragment key={idx}>
        <div>
          <input
            className="pickup-rb-button"
            type="radio"
            name="pickupLocId"
            value={location.id}
            onChange={this.handleChange}
            checked={this.state.checked[idx]}
          ></input>
        </div>
        {this.infoAndHours(location)}
      </React.Fragment>
    );
  }
  render() {
    if (this.state.isLoading) return <Working />;

    return (
      <div className="pickup-container">
        <div className="pickup-col-wrapper">
          <div align="left">
            <b>
              <font size="4">Pickup Locations</font>
            </b>
          </div>
          <div className="pickup-row-wrapper">
            {this.state.locations.map((location, idx) => {
              return this.locationInfo(location, idx);
            })}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pickupLocId: state.order.pickupLocId,
    userId: state.user.userId,
    storeId: state.webOrder.storeId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPickupLoc: (locId, leadTime) => {
      dispatch(setPickupLoc(locId, leadTime));
    },
    setShippingChoice: (choice) => {
      dispatch(setShippingChoice(choice));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PickupLocations);
