import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { apiBaseUrl, isMobileMode, useCache } from "./Util";
import Working from "./Working";
import { setLocations } from "./actions/cacheActions";
import { AXIOS_HEADER } from "./config/constants";
import log from "loglevel";
import "./myLogger";
import "../footer.css";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      locations: this.props.locations,
      mobileMode: false,
    };
    this.adjustMode = this.adjustMode.bind(this);
  }

  componentDidMount() {
    const url = apiBaseUrl() + "Locations";

    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({
          isLoading: false,
          locations: res.data,
        });
        if (useCache()) {
          this.props.setLocations(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
  }

  information() {
    const { virtualTourUrl } = window["runConfig"];
    const rpTitle = isMobileMode()
      ? "Waranty & Return"
      : "Waranty and Return Policy";

    return (
      <div className="ft-group1" align="left">
        <div className="ft-title">
          <font size="4">Information</font>
        </div>
        <div className="ft-link-grp">
          <div className="ft-link-item">
            <Link to="/aboutUs">About Us</Link>
          </div>
          <div className="ft-link-item">
            <Link to="/contactUs">Contact Us</Link>
          </div>
          <div className="ft-link-item">
            <Link to="/privacyPolicy">Privacy Policy</Link>
          </div>
          <div className="ft-link-item">
            <a href={virtualTourUrl} target="VirtualTour">
              Virtual Tour
            </a>
          </div>
          <div className="ft-link-item">
            <Link to="/assemblyFee">Assembly & Delivery Fees</Link>
          </div>
          <div className="ft-link-item">
            <Link to="/returnPolicy">{rpTitle}</Link>
          </div>
        </div>
      </div>
    );
  }

  financing() {
    const { noCreditUrl } = window["runConfig"];

    return (
      <div className="ft-group2" align="left">
        <div className="ft-title">
          <font size="4">Financing</font>
        </div>
        <div className="ft-link-grp">
          <div className="ft-link-item">
            <Link to="/layaway">Layaway</Link>
          </div>
          <div className="ft-link-item">
            <Link to="/leaseToOwn">Lease-to-Own</Link>
          </div>
          <div className="ft-link-item">
            <a href={noCreditUrl} target="Progressive">
              Progressive Application
            </a>
          </div>
        </div>
      </div>
    );
  }

  storeLocations() {
    if (!this.state.locations) return <Working />;

    const urlPrefix = "/location/";
    return (
      <div className="ft-group3" align="left">
        <div className="ft-title">
          <font size="4">Store Locations</font>
        </div>
        <div className="ft-link-grp">
          <div className="ft-link-item">
            <Link to="/locationsMap">Location Map</Link>
          </div>
          {this.state.locations.map((loc) => {
            return (
              <div className="ft-link-item" key={loc.id}>
                <Link to={urlPrefix + loc.id}>
                  {this.state.mobileMode ? loc.shortName : loc.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="ft-container">
          {this.information()}
          {this.financing()}
          {this.storeLocations()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locations: state.cache.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocations: (locations) => {
      dispatch(setLocations(locations));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
