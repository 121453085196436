import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../App.css";
import "../../orderSum.css";
import "../../payment.css";

class DeliveryDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickerType: "select", //text, select, DatePicker
      minDate: null,
      maxDate: null,
      scheduledDate: null,
      excludedDates: null,
      scheduleDates: null,
    };
    this.init = this.init.bind(this);
    this.handleDateSelect = this.handleDateSelect.bind(this);
  }
  componentDidMount() {
    this.init(false);
  }
  componentDidUpdate(preProps) {
    if (preProps.scheduleInfo != this.props.scheduleInfo) {
      this.init(true);
    }
  }
  init(updated) {
    if (this.props.scheduleInfo) {
      const info = this.props.scheduleInfo;
      if (info.minDate && info.maxDate) {
        this.setState({
          pickerType: "date",
          minDate: info.minDate,
          maxDate: info.maxDate,
          scheduledDate: info.scheduledDate,
        });
      } else if (info.availableOffsetList) {
        const avList = info.availableOffsetList;
        const exList = info.excludedOffsetList;
        let first = 1;

        if (avList && avList.length > 0) {
          first = avList[0];
          this.setState({
            pickerType: "DatePicker",
            minDate: this.addDays(first),
            maxDate: this.addDays(avList[avList.length - 1]),
          });
        }
        if (exList && exList.length > 0) {
          let excludedDates = [];
          for (let i = 0; i < exList.length; i++) {
            excludedDates.push(this.addDays(exList[i]));
          }
          this.setState({ excludedDates });
        }
        if (updated && info.scheduledDate) {
          this.setState({ scheduledDate: new Date(info.scheduledDate) });
        } else {
          const sd = info.scheduledDate
            ? new Date(info.scheduledDate)
            : this.addDays(first);
          this.setState({ scheduledDate: sd });
        }
      } else {
        this.setState({
          pickerType: "select",
          scheduledDate: info.scheduledDate,
          scheduleDates: info.scheduleDates,
        });
      }
    }
  }
  handleDateSelect(event) {
    if (this.state.pickerType === "DatePicker") {
      const selected = new Date(event);
      const year = selected.getFullYear();
      const month = (1 + selected.getMonth()).toString().padStart(2, "0");
      const day = selected.getDate().toString().padStart(2, "0");
      const dateStr = month + "/" + day + "/" + year;

      this.setState({ scheduledDate: selected });
      if (this.props.selectHandler) {
        this.props.selectHandler(dateStr);
      }
    } else {
      this.setState({ scheduledDate: event.target.value });
      if (this.props.selectHandler) {
        this.props.selectHandler(event.target.value);
      }
    }
  }
  addDays(days) {
    let newDate = new Date();
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  render() {
    if (this.state.pickerType === "DatePicker") {
      const DatepickerInput = ({ ...props }) => (
        <input type="text" {...props} readOnly />
      );
      return (
        <DatePicker
          name="deliveryDate"
          selected={this.state.scheduledDate}
          timeFormat={false}
          excludeDates={this.state.excludedDates}
          onSelect={this.handleDateSelect}
          minDate={this.state.minDate}
          maxDate={this.state.maxDate}
          customInput={<DatepickerInput />}
        />
      );
    } else if (this.state.pickerType === "date") {
      return (
        <input
          type="date"
          name="scheduledDate"
          value={this.state.scheduledDate}
          min={this.state.minDate}
          max={this.state.maxDate}
          onChange={this.handleDateSelect}
        ></input>
      );
    } else {
      if (this.state.scheduleDates && this.state.scheduleDates.length > 0) {
        return (
          <select
            name="scheduledDate"
            value={this.state.scheduledDate}
            onChange={this.handleDateSelect}
          >
            {this.state.scheduleDates.map((date, idx) => {
              return (
                <option value={date} key={idx}>
                  {date}
                </option>
              );
            })}
          </select>
        );
      }
    }
    return "";
  }
}

export default DeliveryDatePicker;
