import React from "react";
import DocumentMeta from "react";
import CurrencyFormat from "react-currency-format";

export function FormatCurrency(props) {
  return (
    <CurrencyFormat
      value={props.amount}
      decimalScale={2}
      fixedDecimalScale={true}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
}

export function isMobileMode() {
  const { mobileWidthThreshold } = window["runConfig"];

  if (
    window.innerWidth <= mobileWidthThreshold ||
    document.documentElement.clientWidth <= mobileWidthThreshold
  )
    return true;
  else return false;
}

export function apiBaseUrl() {
  const { apiUrl } = window["runConfig"];

  return apiUrl;
}
export function adminSiteApiUrl() {
  const { adminSiteApiUrl } = window["runConfig"];

  return adminSiteApiUrl;
}
export function useCache() {
  const { useCache } = window["runConfig"];

  if (useCache === undefined) return true;
  //default
  else return useCache;
}
export function validatePhoneNo() {
  const { validatePhone } = window["runConfig"];

  return validatePhone;
}
export function catName2Id(categoryName) {
  let name = categoryName.toLowerCase();
  switch (name) {
    case "living":
      return 0;
    case "dining":
      return 1;
    case "bedroom":
      return 2;
    case "mattress":
      return 3;
    case "specials":
      return 4;
    default:
      return 5;
  }
}

export function fullProductImageUrl(url) {
  if (url === undefined || url === null) return "";

  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    const { prodImgBaseUrl } = window["runConfig"];

    if (
      prodImgBaseUrl === undefined ||
      prodImgBaseUrl === null ||
      prodImgBaseUrl === ""
    ) {
      return url;
    } else {
      return prodImgBaseUrl + url;
    }
  }
}

export function getIconBaseUrl() {
  const { iconBaseUrl } = window["runConfig"];

  if (iconBaseUrl !== undefined && iconBaseUrl !== null) {
    return iconBaseUrl;
  } else {
    return "/icons/";
  }
}
export function manualBaseUrl() {
  const { manualBaseUrl } = window["runConfig"];
  return manualBaseUrl;
}
export function virtualTourUrl() {
  const { virtualTourUrl } = window["runConfig"];

  return virtualTourUrl;
}

export function getLogoUrl() {
  const { logo } = window["runConfig"];

  return logo;
}
export function getMobileLogoUrl() {
  const { logo_mobile } = window["runConfig"];

  return logo_mobile;
}
export function getSoldoutIcon() {
  const { soldout_icon } = window["runConfig"];

  return soldout_icon;
}
export function useEmbeddedGoogleMap() {
  const { embedGoogleMap } = window["runConfig"];

  return embedGoogleMap;
}

export function showPickupDeliveryTime() {
  const { showPickupDeliveryTime } = window["runConfig"];

  return showPickupDeliveryTime;
}

export function showTariffNote() {
  const { showTariffNote } = window["runConfig"];

  return showTariffNote;
}

export function checkCityForDelivery() {
  const { checkCityForDelivery } = window["runConfig"];

  return checkCityForDelivery;
}

export function priceColor() {
  const { priceColor } = window["runConfig"];

  return priceColor;
}

export function getHomeImageMaxWidth() {
  const { homeImageMaxWidth } = window["runConfig"];

  return homeImageMaxWidth;
}

export function getItemsPerPage() {
  const { itemsPerPage } = window["runConfig"];

  return itemsPerPage;
}
export function validateCCNumber() {
  const { validateCCNumber } = window["runConfig"];

  return validateCCNumber;
}
export function pickupAddress() {
  const { pickupAddress } = window["runConfig"];

  return pickupAddress;
}
export function maxWidth() {
  const { maxWidth } = window["runConfig"];

  return maxWidth;
}
export function showTariffInfo() {
  const { showTariffInfo } = window["runConfig"];
  return showTariffInfo;
}
export function useFixedImageSize() {
  const { fixedImageSize } = window["runConfig"];
  return fixedImageSize;
}
export function soldOutImageClickable() {
  const { soldOutImageClickable } = window["runConfig"];
  return soldOutImageClickable;
}
export function getLeftMargin() {
  const { desktopLeftMargin, mobileLeftMargin } = window["runConfig"];

  return isMobileMode() ? mobileLeftMargin : desktopLeftMargin;
}

export function priceRange(item) {
  const color = item.forSale === false ? "black" : priceColor();
  if (item.lowPriceVar === item.highPriceVar) {
    return (
      <font color={color}>
        <FormatCurrency amount={item.lowPriceVar} />
      </font>
    );
  } else {
    return (
      <font color={color}>
        <FormatCurrency amount={item.lowPriceVar} /> -
        <FormatCurrency amount={item.highPriceVar} />
      </font>
    );
  }
}

export function getImageUrlForSKU(product, sku) {
  if (product.pvList && product.pvList.length > 1) {
    let i;

    for (i = 0; i < product.pvList.length; i++) {
      if (product.pvList[i].sku === sku) {
        if (
          product.pvList[i].imageUrl !== null &&
          product.pvList[i].imageUrl !== ""
        ) {
          return product.pvList[i].imageUrl;
        }
      }
    }
  }
  return product.imageUrl;
}
export function getSkuDescription(product, sku) {
  if (product.pvList && product.pvList.length > 1) {
    let i;

    for (i = 0; i < product.pvList.length; i++) {
      if (product.pvList[i].sku === sku) {
        if (product.pvList[i].useDesc && product.pvList[i].description) {
          return product.pvList[i].description;
        } else {
          if (product.pvList[i].symLink) {
            return product.pvList[i].description;
          }
        }
      }
    }
  }
  return product.description;
}
export function rateToPercent(rate) {
  return "" + (rate * 100.0).toFixed(1) + "%";
}

export function getGroupWrapperWidth() {
  const { maxWidth } = window["runConfig"];
  const screenSize = document.documentElement.clientWidth;
  const topContainerWidth = screenSize > maxWidth ? maxWidth : screenSize;

  const grpWrapperWidth = topContainerWidth - getLeftMargin() * 2;

  return grpWrapperWidth;
}

export function getLocationsMapSrc() {
  const { locationsMapSrc } = window["runConfig"];

  return locationsMapSrc;
}

export function getHomeImageRatio() {
  const { homeImageHWRatio } = window["runConfig"];
  return homeImageHWRatio;
}

export function setTopContainerWrapperSettings() {
  const { maxWidth, sliderPreColor, sliderNextColor } = window["runConfig"];
  const screenSize = document.documentElement.clientWidth;
  const topContainerWidth = screenSize > maxWidth ? maxWidth : screenSize;
  const grpWrapperWidth = topContainerWidth - getLeftMargin() * 2;

  document.documentElement.style.setProperty(
    "--top-container-width",
    "" + topContainerWidth + "px"
  );

  document.documentElement.style.setProperty(
    "--group-wrapper-width",
    "" + grpWrapperWidth + "px"
  );

  document.documentElement.style.setProperty(
    "--slider-pre-color",
    sliderPreColor
  );
  document.documentElement.style.setProperty(
    "--slider-next-color",
    sliderNextColor
  );
  document.documentElement.style.setProperty(
    "--faq-answer-width",
    "" + (grpWrapperWidth - 30) + "px"
  );
}

export function setInfoWrapperWidth() {
  const { mobileLeftMargin } = window["runConfig"];
  const leftMargin = isMobileMode() ? mobileLeftMargin : 50;
  const wrapperWidth = getGroupWrapperWidth() - leftMargin * 2;

  document.documentElement.style.setProperty(
    "--info-wrapper-width",
    "" + wrapperWidth + "px"
  );
  document.documentElement.style.setProperty(
    "--info-wrapper-margin-left",
    "" + leftMargin + "px"
  );
}

export function getCompressSetting() {
  const { compressMaxSize, compressQuality, compressMaxWidth } =
    window["runConfig"];

  return {
    maxSize: compressMaxSize,
    quality: compressQuality,
    maxWidth: compressMaxWidth,
  };
}

export function documentMeta() {
  const meta = {
    title: "Furniture Way Less",
    description: "Lowest priced furnitures in Atlanta area",
    meta: {
      name: "viewpoint",
      content: { width: "device-width", userScalable: "no" },
    },
  };

  return <DocumentMeta {...meta} />;
}

export function generateGuid() {
  let S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };

  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}
export function genRandom() {
  let S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4();
}

export function getProdDetailUrl(prod) {
  if (prod) {
    const url = prod.comboExists
      ? "/combo/" + prod.comboId
      : "/product/" + prod.productId;
    return url;
  }
  return "/";
}

export function previewImgBaseUrl() {
  const { previewImgBaseUrl } = window["runConfig"];

  return previewImgBaseUrl;
}
export function faqImgBaseUrl() {
  const { faqImgBaseUrl } = window["runConfig"];

  return faqImgBaseUrl;
}

export function useIpForWebOrderReg() {
  const { useIpForWebOrder } = window["runConfig"];
  const flag = useIpForWebOrder ? useIpForWebOrder : true;
  return flag;
}

export function parseAddress(address) {
  let numIdx = -1;
  let routeIdx = 1;
  let cityIdx = 2;
  let stateIdx = 4;
  let zipIdx = 6;

  if (address.length > 6 && address[6].types[0] === "country") {
    cityIdx++;
    stateIdx++;
    zipIdx++;
  }
  try {
    for (let i = 0; i < address.length; i++) {
      if (address[i].types) {
        if (address[i].types.includes("locality")) cityIdx = i;
        else if (address[i].types.includes("administrative_area_level_1"))
          stateIdx = i;
        else if (address[i].types.includes("postal_code")) zipIdx = i;
        else if (address[i].types.includes("street_number")) numIdx = i;
        else if (address[i].types.includes("route")) routeIdx = i;
      }
    }
  } catch (error2) {
    numIdx = 0;
    console.log(error2);
  }
  let addressLine1 = "";
  if (numIdx >= 0) addressLine1 = address[numIdx].short_name + " ";
  addressLine1 += address[routeIdx].long_name;

  const addrObj = {
    addressLine1,
    city: address[cityIdx].short_name,
    state: address[stateIdx].short_name,
    zipCode: address[zipIdx].short_name,
  };
  return addrObj;
}

export function toAmount(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      "$" +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
    if (amount < 0) return "-$" + (0 - amount);
    else return "$" + amount;
  }
}
export function toAmountEx(x) {
  let amt = x;
  let sign = "";

  if (amt < 0) {
    amt = 0 - amt;
    sign = "-";
  }
  const comma = amt >= 1000 ? true : false;
  let amtStr = "" + amt.toFixed(2);
  if (comma) {
    const pos = amtStr.length - 6;
    amtStr = amtStr.substring(0, pos) + "," + amtStr.substring(pos);
  }
  return sign + "$" + amtStr;
}
export function isEmptyStr(str) {
  if (str && str.trim().length > 0) return false;
  return true;
}

//Remove unuseful information from Order
export function toShortOrder(order) {
  if (order && order.items) {
    let items = [];
    for (let i = 0; i < order.items.length; i++) {
      const item = order.items[i];
      if (item.product) {
        const product = {
          ...item.product,
          pvList: [],
          prodImageList: [],
          imageInfoList: [],
          installManualList: [],
          description: "",
          imageUrl: "",
          keywords: "",
          thumbnailImage: "",
          imageInfo: null,
        };
        const newItem = {
          ...item,
          product,
        };
        items.push(newItem);
      } else items.push(item);
    }
    const newOrder = {
      ...order,
      items,
    };
    console.log("New Order");
    console.log(newOrder);
    return newOrder;
  }
  return order;
}
export function getItemCount(coi) {
  let count = 0;
  if (coi) {
    const orderItems = coi.orderItemList;
    if (orderItems) {
      for (let i = 0; i < orderItems.length; i++) {
        count += orderItems[i].item.quantity;
      }
    }
    const storeItems = coi.storeItemList;
    if (storeItems) {
      for (let i = 0; i < storeItems.length; i++) {
        if (storeItems[i].quantity > 0 && storeItems[i].typeId < 1) {
          count += storeItems[i].quantity;
        }
      }
    }
  }
  return count;
}

export function getMainSiteUrl() {
  const { mainSiteUrl } = window["runConfig"];

  return mainSiteUrl;
}
