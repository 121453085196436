import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import ItemThumbnail from "./ItemThumbnail";
import Home from "../Home";
import {
  apiBaseUrl,
  isMobileMode,
  generateGuid,
  getGroupWrapperWidth,
  getItemsPerPage,
  catName2Id,
  useCache,
} from "../Util";
import Working from "../Working";
import { setCategory } from "../actions/searchActions";
import { setUserId } from "../actions/userActions";
import { setProducts } from "../actions/cacheActions";
import log from "loglevel";
import "../myLogger";
import "../../generic.css";
import "../../prodByCat.css";

class ProductsByCat extends React.Component {
  constructor(props) {
    super(props);

    const items = this.props.productsByCat
      ? this.props.productsByCat[catName2Id(this.props.match.params.name)]
      : null;
    this.state = {
      items: items,
      imageWidth: 300,
      imageHeight: 200,
      isLoading: true,
      startingIdx: 0,
      endingIdx: 35,
      page: 0,
    };
    this.fetchItems = this.fetchItems.bind(this);
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);

    this.fetchItems();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }
  adjustSettings() {
    const colCount = isMobileMode() ? 2 : 3;
    const width = (getGroupWrapperWidth() - (colCount - 1) * 5) / colCount;

    this.setState({
      imageWidth: width,
      imageHeight: (width * 2) / 3,
    });
    document.documentElement.style.setProperty(
      "--prodcat-column-count",
      colCount
    );
  }

  componentDidUpdate(preProps) {
    if (this.props.match.params.name !== preProps.match.params.name) {
      const items = this.props.productsByCat
        ? this.props.productsByCat[catName2Id(this.props.match.params.name)]
        : null;
      this.setState({ isLoading: true, items: items });
      this.fetchItems();
    }

    if (
      this.props.match.params.page &&
      this.props.match.params.page !== this.state.page
    ) {
      const maxCount = getItemsPerPage();
      const startingIdx = this.props.match.params.page * maxCount;

      this.setState({
        startingIdx,
        endingIdx: startingIdx + maxCount - 1,
        page: this.props.match.params.page,
      });
    }
  }

  fetchItems() {
    let url = apiBaseUrl();
    let userId = this.props.userId;
    const categoryName = this.props.match.params.name;
    let categoryId = 0;

    if (categoryName === "specials") {
      url += "Specials";
    } else if (categoryName === "newarrivals") {
      url += "NewArrivals";
    } else if (categoryName && categoryName.startsWith("BS_")) {
      url += "BestSellers";
      categoryId = parseInt(categoryName.substring(3));
    } else {
      url += "ProdByCat";
    }

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }

    if (categoryName && !categoryName.startsWith("BS_")) {
      this.props.setCategory(categoryName);
    }

    const req = {
      categoryId,
      categoryName,
      userId: userId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data) console.log("Items found: " + res.data.length);
        this.setState({
          items: res.data,
          isLoading: false,
          page: 0,
          startingIdx: 0,
          endingIdx: getItemsPerPage() - 1,
        });
        if (useCache()) {
          this.props.setProducts(this.props.match.params.name, res.data);
        } else {
          console.log("Cache is disabled.");
        }
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }

  showItem(idx) {
    return idx >= this.state.startingIdx && idx <= this.state.endingIdx;
  }

  showPageNumbers() {
    if (this.state.items.length > getItemsPerPage()) {
      let count = Math.ceil(this.state.items.length / getItemsPerPage());
      let i;
      let pages = [];
      for (i = 0; i < count; i++) {
        pages.push(i);
      }

      return (
        <div className="prodbycat-page-wrapper">
          <div className="prodbycat-page-space"></div>
          <div>{this.state.items.length} items: </div>
          {pages.map((idx) => {
            if (idx === this.state.page) {
              return <div className="prodbycat-page-item">{idx + 1}</div>;
            } else {
              const url =
                "/product-category/" + this.props.match.params.name + "/" + idx;
              return (
                <div className="prodbycat-page-item">
                  <Link to={url}>{idx + 1}</Link>
                </div>
              );
            }
          })}
        </div>
      );
    }
  }

  render() {
    if (!this.state.items || this.state.items.length < 1) {
      if (this.state.isLoading) {
        return <Working />;
      } else {
        const errorMessage =
          "No products found for category " + this.props.match.params.name;
        return <Home errorMessage={errorMessage} />;
      }
    }
    let redTitle = null;
    let title = "Shop by Category";
    const catName = this.props.match.params.name;
    let catId = 0;

    if (catName === "specials") redTitle = "Monthly Specials";
    else if (catName === "newarrivals") redTitle = "New Arrivals";
    else if (catName && catName.startsWith("BS_")) {
      try {
        catId = parseInt(catName.substring(3));
      } catch (error) {}

      if (catId > 0) {
        title = "Best Sellers";
        if (this.state.items && this.state.items.length > 0) {
          const cn = this.state.items[0].categoryName;
          if (cn && cn.length > 0) title += " in " + cn;
        }
      } else {
        title = "Overall Best Sellers";
      }
    }

    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          {redTitle ? (
            <div align="center">
              <b>
                <font color="red" size="5">
                  {redTitle}
                </font>
              </b>
            </div>
          ) : (
            <div align="left">
              <font size="4">{title}</font>
            </div>
          )}

          <div className="prodbycat-wrapper">
            {this.state.items.map((item, idx) => {
              if (this.showItem(idx)) {
                return (
                  <div className="prodbycat-grid-item" key={idx}>
                    <ItemThumbnail
                      item={item}
                      width={this.state.imageWidth}
                      height={this.state.imageHeight}
                    />
                  </div>
                );
              }
              return "";
            })}
          </div>
          {this.showPageNumbers()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    productsByCat: state.cache.productsByCat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCategory: (categoryName) => {
      dispatch(setCategory(categoryName));
    },
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    setProducts: (category, products) => {
      dispatch(setProducts(category, products));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsByCat);
