import React from "react";
import { setTopContainerWrapperSettings, setInfoWrapperWidth } from "../Util";

import "../../App.css";
import "../../info.css";

class ReturnPolicy extends React.Component {
  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
    setInfoWrapperWidth();
  }

  render() {
    return (
      <div className="top-wrapper">
        <div className="info-container">
          <div className="info-wrapper">
            <div className="info-item">
              <font size="5">Waranty and Return Policies</font>
            </div>
            <div className="info-item" align="left">
              <font size="4">RETURNS</font>
              <br />
              <ul>
                <li>
                  We offer 3 days return policy after the furniturs are
                  delivered or picked up.{" "}
                </li>
                <li>There is 15% restcoking fee for all return items.</li>
              </ul>
            </div>
            <div className="info-item" align="left">
              <font size="4">WARANTY REPAIRS</font>
              <br />
              <ul>
                <li>
                  We also offer waranty repairs within 3 days after the
                  furnitures are delivered or picked up.
                </li>
                <li>
                  Please visist <a href="/contactUs">Contact Us</a> page to fill
                  the form. Make sure you upload a picture that shows the
                  damaged part. Without the picture, we might not be able to
                  identify the parts needed for the repair.
                </li>
                <li>
                  We will forward your request to our vendor within 24 hours and
                  fix it within five days.
                </li>
              </ul>
            </div>
            <div className="info-item" align="left">
              <font size="4">DISCLAIMERS</font>
              <br />
              <ul>
                <li>
                  We don't accept mattress returns if the plastic has been
                  removed. That's bacause, by law, used mattresses need go
                  through sterilization process which we can't perform.
                </li>
                <li>
                  We don't accept returns of physically damaged furnitures.
                </li>
                <li>
                  Furnitures with broken glasses can't be returned after
                  delivery driver left the house.
                </li>
                <li>
                  Waranty repair only covers defectives. If your furnitures are
                  physically damaged, our waranty doesn't cover it.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReturnPolicy;
