import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import { checkout, clearCart } from "../actions/cartActions";
import { setUserInfo } from "../actions/userActions";
import {
  spRemoveItem,
  spClearCart,
  spAddToCart,
} from "../actions/spCartActions";
import {
  FormatCurrency,
  generateGuid,
  fullProductImageUrl,
  apiBaseUrl,
} from "../Util";
import { setUserId } from "../actions/userActions";
import { isInStore } from "../store/storeUtil";
import DeliveryFeeCalculator from "../order/DeliveryFeeCalculator";
import ActionConfirmation from "../ActionConfirmation";
import { cartSummary } from "./cartUtil";
import Deposit from "../store/Deposit";
import log from "loglevel";
import "../myLogger";
import "../../cart.css";

class StoreCartSum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foundAddrStr: null,
      addressLine1: null,
      city: null,
      state: null,
      zipCode: null,
      fee: -1,
      deliveryFee: -1,
      layaway: false,
      items: null,
      summary: cartSummary(this.props.taxRate, 0),
      dfErrorMessage: null,
      mobileMode: this.props.mobileMode,
    };
    this.redirectToCheckout = this.redirectToCheckout.bind(this);
    this.emptyCart = this.emptyCart.bind(this);
    this.continueShopping = this.continueShopping.bind(this);
    this.calculateDeliveryFee = this.calculateDeliveryFee.bind(this);
    this.useFoundAddress = this.useFoundAddress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  componentDidMount() {
    const summary = this.state.summary;
    if (summary && summary.storeId > 0 && summary.grandTotal > 0) {
      const pcnt = (summary.depositAmount * 100) / summary.grandTotal;
      if (
        summary.depositAmount <= 0 ||
        (summary.isStoreLayaway && pcnt < 10.0)
      ) {
        this.fetchItems();
      }
    }
    this.setState({ summary });
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.lastUpdated !== prevProps.lastUpdated) {
      this.setState({ summary: cartSummary(this.props.taxRate, 0) });
    }
  }
  redirectToCheckout() {
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    if (this.state.summary) {
      if (this.state.summary.storeId > 0 && this.state.summary.isStoreLayaway) {
        this.props.history.push("/layawayContact");
        return;
      }
    }
    this.props.history.push("/shippingChoice");
  }

  continueShopping() {
    this.props.history.push("/");
  }
  emptyCart() {
    this.props.clearCart();
    this.props.spClearCart();
  }
  isEmpty() {
    let count = this.props.items ? this.props.items.length : 0;
    if (count === 0 && isInStore()) {
      count = this.props.storeItems ? this.props.storeItems.length : 0;
    }
    return count === 0;
  }
  removeItem(item) {
    this.props.spRemoveItem(item.sku);
    this.setState({ summary: cartSummary(this.props.taxRate, 0) });
  }
  fetchItems() {
    const url = apiBaseUrl() + "GetStoreProducts";
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
    const req = {
      userId: userId,
      id: this.state.summary.storeId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({
          items: res.data.storeItems,
        });
      })
      .catch((error) => {
        console.log(error);
        log.error(error);
      });
  }
  calculateDeliveryFee(addr) {
    const summary = cartSummary(this.props.taxRate, 0);
    const url = apiBaseUrl() + "GetDeliveryFee";
    const address = {
      ...addr,
      userId: this.props.userId,
    };
    const req = {
      address,
      orderSubtotal: summary.subtotal,
    };

    let userInfo = addr;
    if (this.props.userInfo) {
      userInfo = {
        ...this.props.userInfo,
        addressLine1: addr.addressLine1,
        city: addr.city,
        state: addr.state,
        zipCode: addr.zipCode,
      };
    }
    this.props.setUserInfo(userInfo);

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data && res.data.status) {
          this.setState({ deliveryFee: res.data.fee, dfErrorMessage: null });
        } else {
          if (res.data.found) {
            const addr = res.data.found;
            const str =
              addr.addressLine1 +
              "<br/>" +
              addr.city +
              ", " +
              addr.state +
              " " +
              addr.zipCode;
            const foundAddrStr =
              "We are not able to deliver to the address you provided. " +
              "However, we found the deliverable address below:<p/>" +
              str +
              "<p/>Click Yes if you would like to use this address instead.";
            this.setState({
              addressLine1: addr.addressLine1,
              city: addr.city,
              state: addr.state,
              zipCode: addr.zipCode,
              fee: addr.fee,
              foundAddrStr,
            });
            document.getElementById("hiddenConfirmBtn").click();
          }
          const dfErrorMessage =
            res.data.minAmount > 0
              ? "Sorry, we require minimum purchase of $" +
                res.data.minAmount +
                " to deliver in your area."
              : "Sorry, we don't deliver to your area right now.";
          this.setState({ deliveryFee: -1, dfErrorMessage });
        }
      })
      .catch((error) => {
        this.setState({ dfErrorMessage: "An error has occurred" });
        log.error(error);
      });
  }
  useFoundAddress() {
    this.setState({
      deliveryFee: this.state.fee,
      dfErrorMessage: null,
    });
    let userInfo;
    if (this.props.userInfo) {
      userInfo = {
        ...this.props.userInfo,
        addressLine1: this.state.addressLine1,
        city: this.state.city,
        state: this.state.state,
        zipCode: this.state.zipCode,
      };
    } else {
      userInfo = {
        addressLine1: this.state.addressLine1,
        city: this.state.city,
        state: this.state.state,
        zipCode: this.state.zipCode,
      };
    }
    this.props.setUserInfo(userInfo);
  }
  showDFCDialog() {
    document.getElementById("dfcDialog").click();
  }
  showDFInfo() {
    if (this.state.deliveryFee <= 0) {
      let addressLine1 = "";
      let city = "";
      let zipCode = "";
      let addr = null;

      if (!isInStore()) {
        addr = this.props.deliveryInfo
          ? this.props.deliveryInfo
          : this.props.contactInfo;

        if (!addr) addr = this.props.userInfo;
        if (addr) {
          addressLine1 = addr.addressLine1;
          city = addr.city;
          zipCode = addr.zipCode;
        }
      }

      return (
        <div className="nddiv">
          {this.state.dfErrorMessage && (
            <font color="red">
              {this.state.dfErrorMessage} But you can pickup your furniture at
              our warehouse.
              <p />
            </font>
          )}
          <DeliveryFeeCalculator
            addressLine1={addressLine1}
            city={city}
            zipCode={zipCode}
            actionHandler={this.calculateDeliveryFee}
            hidden={true}
            btnId="dfcDialog"
          />
        </div>
      );
    }
  }
  showCurrentDeposits(summary) {
    if (
      summary.storeId <= 0 ||
      !summary.deposits ||
      summary.deposits.length === 0
    ) {
      return null;
    }

    let remaining = summary.balance;
    if (remaining > 0 && Math.abs(remaining) < 1) remaining = 0;
    return (
      <React.Fragment>
        {summary.deposits.map((sp, idx) => {
          if (sp.typeId && sp.typeId > 0) {
            const amount = sp.price > 0 ? sp.price : 0 - sp.price;
            const last4 =
              sp.last4 && sp.last4.length > 0 ? "(" + sp.last4 + ")" : null;
            return (
              <React.Fragment key={idx}>
                <div>
                  {sp.name}
                  {last4}:
                </div>
                <div align="right">
                  {" "}
                  <font color="red">
                    -<FormatCurrency amount={amount} />
                  </font>
                </div>
                <div>
                  <img
                    src={fullProductImageUrl("remove-item.jpg")}
                    height="12"
                    onClick={() => this.removeItem(sp)}
                  ></img>
                </div>
              </React.Fragment>
            );
          }
        })}
        <div>
          <i>Balance:</i>
        </div>
        <div align="right">
          <FormatCurrency amount={remaining} />
        </div>
        <div>&nbsp;</div>
      </React.Fragment>
    );
  }
  handleChange(event) {
    if (event.target.name === "layaway") {
      this.setState({ layaway: event.target.checked });
    }
  }
  showDepositDialog(item) {
    const name = "depositDialog" + item.id;
    document.getElementById(name).click();
  }
  addToCart(item) {
    this.props.spAddToCart(item);

    const summary = cartSummary(this.props.taxRate, 0);
    this.setState({ summary });

    if (summary.isStoreLayaway && summary.depositPercent >= 10.0) {
      this.props.history.push("/layawayContact");
    }
  }
  showLayawayDepositSection(summary) {
    console.log(summary);
    if (summary.storeId > 0 && summary.grandTotal > 0) {
      if (!summary.isStoreLayaway && summary.depositAmount > 0) {
        return "";
      }

      if (summary.isStoreLayaway && summary.depositPercent >= 10.0) {
        return "";
      }

      let layawayDeposits = [];
      const items = this.state.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].layaway) {
            layawayDeposits.push(items[i]);
          }
        }
      }
      if (layawayDeposits.length === 0) return "";

      return (
        <div className="left-40">
          {summary.depositAmount <= 0 && (
            <div className="bottom-15">
              <input
                type="checkbox"
                name="layaway"
                defaultChecked={this.state.layaway}
                onChange={this.handleChange}
              ></input>{" "}
              Is Layaway
            </div>
          )}
          {(summary.depositAmount > 0 || this.state.layaway) && (
            <div className="threecol-wrapper">
              {layawayDeposits.map((item, idx) => {
                const btnId = "depositDialog" + item.id;
                const imageUrl = item.imageUrl ? item.imageUrl : "no-image.jpg";
                return (
                  <div className="generic-flex" key={idx}>
                    <div>
                      <img
                        src={fullProductImageUrl(imageUrl)}
                        alt="Image"
                        width={50}
                      ></img>
                    </div>
                    <div className="left-10">
                      {item.name}
                      <br />
                      <button
                        name="addDeposit"
                        className="small-green-btn"
                        onClick={() => this.showDepositDialog(item)}
                      >
                        Add Deposit
                      </button>
                    </div>
                    <Deposit
                      actionHandler={this.addToCart}
                      hidden={true}
                      btnId={btnId}
                      item={item}
                      orderTotal={summary.grandTotal}
                      depositAmount={summary.depositAmount}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }
  }
  render() {
    if (this.isEmpty() || this.props.show === "false") {
      return "";
    }

    const summary = this.state.summary;
    const taxRatePcnt = "" + (summary.taxRate * 100).toFixed(1) + "%";
    let checkoutDisabled = false;
    let message = null;
    if (
      summary.storeId > 0 &&
      summary.isStoreLayaway &&
      summary.depositPercent < 10.0
    ) {
      const min = summary.grandTotal * 0.1;
      checkoutDisabled = true;
      message = "Minimum deposit required: $" + min.toFixed(2) + " (10%)";
    }
    const coBtnStyle = checkoutDisabled ? "disabled-btn-style" : "btn-style";

    return (
      <div>
        <hr />
        <div className="flex-wrapper">
          <div className="threecol-tbl">
            {summary.discount && (
              <React.Fragment>
                <div>{summary.discount.name}:</div>
                <div align="right">
                  <font color="red">
                    -<FormatCurrency amount={summary.discount.amount} />
                  </font>
                </div>
                <div>&nbsp;</div>
              </React.Fragment>
            )}
            <div>Subtotal({summary.itemCount} items):</div>
            <div align="right">
              <FormatCurrency amount={summary.subtotal} />
            </div>
            <div>&nbsp;</div>
            <div>Sales Tax ({taxRatePcnt}):</div>
            <div align="right">
              <FormatCurrency amount={summary.tax} />
            </div>
            <div>&nbsp;</div>
            {this.state.deliveryFee > 0 && (
              <React.Fragment>
                <div>Delivery Fee:</div>
                <div align="right">
                  <FormatCurrency amount={this.state.deliveryFee} />
                </div>
                <div>&nbsp;</div>
              </React.Fragment>
            )}
            {summary.coupons.length > 0 &&
              summary.coupons.map((item) => {
                return (
                  <React.Fragment>
                    <div>{item.title}:</div>
                    <div align="right">
                      <font color="red">
                        <FormatCurrency amount={item.price} />
                      </font>
                    </div>
                    <div>
                      &nbsp;{" "}
                      <img
                        src={fullProductImageUrl("remove-item.jpg")}
                        height="12"
                        onClick={() => this.removeItem(item)}
                      ></img>
                    </div>
                  </React.Fragment>
                );
              })}
            <div>
              <b>Total:</b>
            </div>
            <div align="right">
              <b>
                <FormatCurrency amount={summary.grandTotal} />
              </b>
            </div>
            <div>&nbsp;</div>
            {this.showCurrentDeposits(summary)}
          </div>
          {this.showLayawayDepositSection(summary)}
        </div>
        {this.state.deliveryFee <= 0 && (
          <div className="nddiv-t10">
            <a href="#" onClick={this.showDFCDialog}>
              Calculate Delivery Fee
            </a>
            <p />
          </div>
        )}
        {message && (
          <div>
            <font color="red">{message}</font>
            <p />
          </div>
        )}
        {this.showDFInfo()}
        <div>
          <button className="btn-style" onClick={this.continueShopping}>
            Continue Shopping
          </button>
          &nbsp;&nbsp;
          {isInStore() && (
            <span>
              <button className="btn-style" onClick={this.emptyCart}>
                Empty Cart
              </button>
              &nbsp;&nbsp;
            </span>
          )}
          <button
            className={coBtnStyle}
            onClick={this.redirectToCheckout}
            disabled={checkoutDisabled}
          >
            <b>Checkout</b>
          </button>
        </div>
        <ActionConfirmation
          hidden={true}
          btnId="hiddenConfirmBtn"
          actionHandler={this.useFoundAddress}
          buttonTitle="Hidden"
          buttonClsName="btn-style"
          title="Use Adddress Below"
          message={this.state.foundAddrStr}
          html={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.addedItems,
    subtotal: state.cart.subtotal,
    discount: state.cart.discount,
    userId: state.user.userId,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
    kiosk: state.webOrder.kiosk,
    storeItems: state.spCart.storeItems,
    android: state.cache.android,
    userInfo: state.user.userInfo,
    contactInfo: state.order.contactInfo,
    deliveryInfo: state.order.deliveryInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkout: () => {
      dispatch(checkout());
    },
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    spRemoveItem: (sku) => {
      dispatch(spRemoveItem(sku));
    },
    clearCart: () => {
      dispatch(clearCart());
    },
    spAddToCart: (payload) => {
      dispatch(spAddToCart(payload));
    },
    spClearCart: () => {
      dispatch(spClearCart());
    },
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StoreCartSum));
