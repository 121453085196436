import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import { setLoggedIn, setUserId, setUserInfo } from "../actions/userActions";
import { apiBaseUrl, isMobileMode } from "../Util";
import { AXIOS_HEADER } from "../config/constants";

import "../../App.css";
import "../../form.css";
import "../../user.css";

class LoginUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      verifyCode: "",
      needVerify: false,
      newCodeSent: false,
      errorMessage: null,
    };
    this.userRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.processLoginResponse = this.processLoginResponse.bind(this);
    this.gotoCreateAccount = this.gotoCreateAccount.bind(this);
    this.sendNewCode = this.sendNewCode.bind(this);
  }

  componentDidMount() {
    this.userRef.current.input.focus();
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  processLoginResponse(response) {
    if (response.status) {
      this.props.setLoggedIn(response.pwdToken);
      this.props.setUserId(this.state.email);
      this.props.setUserInfo(response.contactAddr);
      this.setState({ errorMessage: null });

      if (this.props.returnUrl) {
        this.props.history.push(this.props.returnUrl);
      } else {
        if (this.props.embedded && this.props.actionHandler) {
          this.props.actionHandler(response.contactAddr);
        } else {
          this.props.history.push("/myAccount");
        }
      }
    } else {
      if (response.needVerify) {
        this.setState({
          needVerify: true,
        });
      } else {
        this.setState({ errorMessage: "Invalid user ID or password" });
      }
    }
  }

  handleLogin(event) {
    event.preventDefault();

    if (!this.state.email) {
      this.setState({ errorMessage: "Email is required" });
      return;
    }

    if (!this.state.password) {
      this.setState({ errorMessage: "Password is required" });
      return;
    }

    const url = this.state.needVerify
      ? apiBaseUrl() + "VerifyUser"
      : apiBaseUrl() + "Login";
    const req = {
      email: this.state.email,
      password: this.state.password,
      verifyCode: this.state.verifyCode,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processLoginResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMessage: "Unable to login" });
      });
  }
  gotoCreateAccount(event) {
    this.props.history.push("/createAccount/checkout");
  }
  sendNewCode(event) {
    const url = apiBaseUrl() + "SendNewCode";
    const req = {
      email: this.state.email,
      password: this.state.password,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.status) {
          this.setState({ newCodeSent: true });
        } else {
          this.setState({ errorMessage: res.data.errorMessage });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showVerifyInfo() {
    if (this.state.needVerify) {
      const clsName = isMobileMode() ? "none" : "user-msg-wrapper";
      return (
        <div className={clsName}>
          {this.state.newCodeSent ? (
            <label>We just emailed you a new code.</label>
          ) : (
            <label>You have not verified your email address.</label>
          )}
          &nbsp;Please enter the <b>8-digit</b> verification code we emailed
          you. If you didn't get that email, click
          <p />
          <button
            className="small-btn"
            name="sendCode"
            onClick={this.sendNewCode}
          >
            Send New Code
          </button>
        </div>
      );
    }
  }
  render() {
    const clsName = this.props.embedded ? "none" : "top-wrapper";
    const clsName2 = this.props.embedded ? "none" : "generic-wrapper";
    return (
      <div className={clsName}>
        <div className={clsName2}>
          <font size="5">Login</font>
          <p />
          <ValidatorForm ref="form" onSubmit={this.handleLogin}>
            <div className="twocol-tbl">
              <div className="form-item-label">User ID: </div>
              <div className="form-item-value">
                <TextInput
                  name="email"
                  value={this.state.email || ""}
                  placeholder="email"
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["User ID is required"]}
                  ref={this.userRef}
                ></TextInput>
              </div>
              <div className="form-item-label">Password: </div>
              <div className="form-item-value">
                <TextInput
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["Password is required"]}
                ></TextInput>
              </div>
              {this.state.needVerify && (
                <React.Fragment>
                  <div className="form-item-label">Verification Code: </div>
                  <div className="form-item-value">
                    <TextInput
                      name="verifyCode"
                      size="10"
                      value={this.state.verifyCode}
                      onChange={this.handleChange}
                      placeholder="8-digit code"
                      validators={["required"]}
                      errorMessages={["Verification Code is required"]}
                    ></TextInput>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div>
              <button name="login" type="submit" className="btn-style">
                Login
              </button>
            </div>
          </ValidatorForm>
          <p />
          <font color="red">{this.state.errorMessage}</font>
          <p />
          {this.showVerifyInfo()}
          {!this.state.needVerify && !this.props.hideResetCreate && (
            <div className="nodec-div">
              Forgot your password? You can{" "}
              <Link to="/resetPass">Reset Password</Link>.&nbsp;&nbsp; <p />
              Do not have an account?{" "}
              <button
                name="create"
                className="btn-style"
                onClick={this.gotoCreateAccount}
              >
                Register
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userId: state.user.userId,
    loginTime: state.user.loginTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (pwdToken) => {
      dispatch(setLoggedIn(pwdToken));
    },
    setUserId: (payload) => {
      dispatch(setUserId(payload));
    },
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginUser));
