import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Link } from "react-router-dom";
import ProductListView from "../product/ProductListView";
import { apiBaseUrl } from "../Util";
import { AXIOS_HEADER } from "../config/constants";
import { ORDERSTAGE_SUBMITTED, ORDERSTAGE_COMPLETE } from "./orderStages";
import { setOrderStage } from "../actions/orderActions";
import log from "loglevel";
import { setTopContainerWrapperSettings, FormatCurrency } from "../Util";
import PrintOrder from "../store/PrintOrder";
import "../myLogger";
import "../../tables.css";
import "../../orderComplete.css";

class OrderComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: null,
      paymentName: "Delivery Fee",
      amount: 0,
      shippingChoice: "Delivery",
      isPickup: false,
      orderStagae: "",
      orderNumber: "",
      orderEmail: "",
      orderCompleteMsg: null,
      scheduledDate: null,
    };
    this.adjustSettings = this.adjustSettings.bind(this);
    this.displayThankYouNote = this.displayThankYouNote.bind(this);
    this.showCODInfo = this.showCODInfo.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  componentDidMount() {
    if (
      this.props.orderStage &&
      this.props.orderStage >= ORDERSTAGE_SUBMITTED
    ) {
      this.adjustSettings();
      window.addEventListener("resize", this.adjustSettings);

      let paymentName = "Delivery Fee";
      if (this.props.shippingChoice === "Pickup") {
        paymentName =
          this.props.ratefees && this.props.ratefees.pickupDepositTerm
            ? this.props.ratefees.pickupDepositTerm
            : "Security Deposit";
      } else if (this.props.shippingChoice === "Layaway")
        paymentName = "Down Payment";
      this.setState({
        paymentName,
        shippingChoice: this.props.shippingChoice,
        amount:
          this.props.shippingChoice == "Pickup"
            ? this.props.ratefees.pickupDeposit
            : this.props.ratefees.deliveryFee, //TODO
        isPickup: this.props.shippingChoice == "Pickup",
        codAmount: this.props.codAmount,
        orderStage: this.props.orderStage,
        orderNumber: this.props.orderNumber,
        orderEmail: this.props.orderEmail,
        orderCompleteMsg: this.props.orderCompleteMsg,
        scheduledDate: this.props.scheduledDate,
      });

      this.props.setOrderStage(ORDERSTAGE_COMPLETE);

      const url = apiBaseUrl() + "LocationDetails";
      axiosRetry(axios, { retries: 3 });
      axios
        .get(url, { headers: AXIOS_HEADER })
        .then((res) => {
          this.setState({
            locations: res.data,
          });
        })
        .catch((error) => {
          console.log(error);
          log.error(error);
        });
    } else {
      this.props.history.push("/");
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
  }

  displayOneLoc(loc) {
    const url = "/location/" + loc.id;

    return (
      <div className="tbl-item-left">
        <div className="oc-store-item">
          <Link to={url}>{loc.info.description}</Link>
        </div>
        <div className="oc-store-item2">
          {loc.info.phone}
          <br />
          {loc.info.phone2}
        </div>
      </div>
    );
  }

  storeLocations() {
    if (this.state.locations === null) return "Fetching ...";
    return (
      <div className="tbl-container2">
        {this.state.locations.map((loc) => {
          return this.displayOneLoc(loc);
        })}
      </div>
    );
  }
  showCODInfo() {
    if (this.state.codAmount) {
      const codAmount = parseFloat(this.state.codAmount);
      if (codAmount > 0) {
        const title =
          this.state.shippingChoice == "Pickup"
            ? "Pay On Pickup"
            : "Cash On Delivery (COD)";
        const message = this.state.isPickup
          ? " need to pay cash or credit card on pickup."
          : " need to pay driver in cash on delivery or you can visit one of our stores to pay by card.";
        const pmtType =
          this.state.shippingChoice == "Pickup"
            ? this.state.paymentName.toLowerCase()
            : "deposit";
        return (
          <div className="oc-paid-desc2">
            <div>
              <font size="4" color="green">
                {title}
              </font>
            </div>
            <div>
              <b>
                We have only charged{" "}
                <FormatCurrency amount={this.state.amount} /> ({pmtType}) to
                your credit card.
              </b>
              &nbsp;The remaining balance of{" "}
              <b>
                <FormatCurrency amount={codAmount} />
              </b>
              {message}
            </div>
          </div>
        );
      }
    }
  }
  goHomePage() {
    this.props.history.push("/");
  }
  displayMsgForClerk() {
    return (
      <div className="oc-container">
        <div className="oc-paid-wrapper">
          <div className="oc-paid-desc" align="left">
            <b>
              <font size="4" color="green">
                Order Successfully Submitted
              </font>
            </b>
            <p />
            <div>
              Order number: <b>{this.state.orderNumber}</b>.
              <p />
            </div>
            {this.state.orderEmail && this.state.orderEmail.length > 0 && (
              <div>
                A confirmation email has been sent to {this.state.orderEmail},
                please ask the customer check email to make sure everything is
                correct.
                <p />
              </div>
            )}
            {this.state.shippingChoice !== "Pickup" &&
              this.state.scheduledDate && (
                <div>
                  The scheduled delivery date is:{" "}
                  <b>{this.state.scheduledDate}</b>.
                </div>
              )}
            <PrintOrder
              orderNumber={this.state.orderNumber}
              disableSmsIfAutoSend={true}
            />
          </div>
        </div>
        <p />
        <button className="order-done-btn" onClick={this.goHomePage}>
          Done
        </button>
      </div>
    );
  }
  displayMsgForKiosk() {
    const backendApi = "OrderRelatedProd/" + this.state.orderNumber;
    const title = "Other Items You Might Be Interested";

    return (
      <div className="oc-container">
        <div className="oc-paid-wrapper">
          <div className="oc-paid-desc" align="left">
            <b>
              <font size="4" color="green">
                Thank You! We have received your order.
              </font>
            </b>
            <p />
            <div>
              Your order number is: <b>{this.state.orderNumber}</b>.&nbsp;
              {this.state.orderEmail && (
                <label>
                  A confirmation email has been sent to {this.state.orderEmail},
                  please check your email for order details and other
                  information.
                </label>
              )}
              <p />
            </div>
            {this.state.shippingChoice == "Pickup" ? (
              <div>You have choosen to pick up your furnitures.</div>
            ) : this.state.scheduledDate ? (
              <div>
                Your scheduled delivery date is:{" "}
                <b>{this.state.scheduledDate}</b>. If you have problem with this
                date, please talk to a store clerk to make changes.
              </div>
            ) : (
              <div>Please talk to a store clerk to schedule your delivery.</div>
            )}
          </div>
          {this.state.orderCompleteMsg && (
            <div className="oc-paid-desc2">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.orderCompleteMsg,
                }}
              ></div>
            </div>
          )}
        </div>
        <div>
          <button className="order-done-btn" onClick={this.goHomePage}>
            Done
          </button>
          <p />
        </div>
        <ProductListView backendApi={backendApi} title={title} />
      </div>
    );
  }
  displayThankYouNote() {
    const backendApi = "OrderRelatedProd/" + this.state.orderNumber;
    const title = "Other Items You Might Be Interested";

    return (
      <div className="oc-container">
        <div className="oc-paid-wrapper">
          <div className="oc-paid-desc" align="left">
            <b>
              <font size="4" color="green">
                Thank You! We have received your order.
              </font>
            </b>
            <p />
            <div>
              Your order number is: <b>{this.state.orderNumber}</b>. A
              confirmation email has been sent to {this.state.orderEmail},
              please check your email for order details and other information.
              <p />
            </div>
            {this.state.shippingChoice == "Pickup" ? (
              <div>
                Once your payment is successfully processed, you can come to the
                store to pickup your items.
              </div>
            ) : this.state.scheduledDate ? (
              <div>
                Your scheduled delivery date is:{" "}
                <b>{this.state.scheduledDate}</b>. If you have problem with this
                date, please call our store during regular business hours to
                reschedule it.
              </div>
            ) : (
              <div>
                Once your payment is successfully processed, we will call you to
                schedule your delivery.
              </div>
            )}
          </div>
          {this.showCODInfo()}
        </div>
        <ProductListView backendApi={backendApi} title={title} />
      </div>
    );
  }
  displayOrderComplete() {
    if (
      this.props.storeId > 0 &&
      this.props.storeName &&
      this.props.storeName.length > 0
    ) {
      if (this.props.kiosk) {
        return this.displayMsgForKiosk();
      } else {
        return this.displayMsgForClerk();
      }
    } else {
      return this.displayThankYouNote();
    }
  }
  render() {
    if (this.state.orderNumber === "") return "Loading ...";

    return <div className="top-wrapper">{this.displayOrderComplete()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    orderStage: state.order.stage,
    orderNumber: state.order.orderNumber,
    orderEmail: state.order.orderEmail,
    codAmount: state.order.codAmount,
    shippingChoice: state.order.shippingChoice,
    ratefees: state.order.ratefees,
    scheduledDate: state.order.scheduledDate,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
    kiosk: state.webOrder.kiosk,
    orderCompleteMsg: state.order.orderCompleteMsg,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOrderStage: (stage) => {
      dispatch(setOrderStage(stage));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderComplete);
