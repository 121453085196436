import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { AXIOS_HEADER } from "../config/constants";
import {
  isMobileMode,
  setTopContainerWrapperSettings,
  apiBaseUrl,
} from "../Util";
import LoginUser from "../user/LoginUser";

import "../../App.css";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.userInfo !== null ? this.props.userInfo.email : "",
      password: "",
    };

    this.continueAsGuest = this.continueAsGuest.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
  }
  componentDidMount() {
    if (this.props.isLoggedIn && this.props.userInfo) {
      this.props.history.push("/shippingChoice");
      return;
    }
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);

    const url = apiBaseUrl() + "ShowSignInAtCheckout";
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        if (!res.data) {
          this.props.history.push("/shippingChoice");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  continueAsGuest() {
    this.props.history.push("/shippingChoice");
  }
  adjustMode() {
    this.setState({
      mobileMode: isMobileMode(),
    });
    setTopContainerWrapperSettings();
  }
  render() {
    const vrStyle = {
      borderLeft: "1px solid #000",
      height: "150px",
      paddingLeft: "0px",
      paddingRight: "20px",
    };
    const clsName = this.state.mobileMode ? "none" : "generic-flex";
    const clsName2 = this.state.mobileMode ? "top-20" : "none";
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">Checkout</font>
          <p />
          <div className={clsName}>
            <div>
              <LoginUser
                isCheckout={true}
                email={this.state.email}
                embedded={true}
                returnUrl="/shippingChoice"
              />
            </div>
            {!this.state.mobileMode && <div style={vrStyle}></div>}

            <div className={clsName2}>
              <font size="5">Guests</font>
              <p />
              <p>
                Continue to checkout as a guest. If you plan to do layaway, we
                strongly recommand you to create an account.
              </p>
              <button className="btn-style" onClick={this.continueAsGuest}>
                Continue As Guest
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userInfo: state.user.userInfo,
  };
};

export default connect(mapStateToProps)(Checkout);
