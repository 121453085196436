import React from "react";
import { connect } from "react-redux";
import { setProgressiveInfo, setLeaseOkInfo } from "../actions/orderActions";
import {
  getIconBaseUrl,
  setTopContainerWrapperSettings,
  getMainSiteUrl,
} from "../Util";
import Working from "../Working";
import "../../orderComplete.css";
import { isInStore } from "../store/storeUtil";

class LeaseOrderSubmitted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progressiveInfo: null,
      showSignedBtn: false,
      timer: null,
      buttonLabel: "Sign the Lease & Complete the Order",
    };

    this.adjustSettings = this.adjustSettings.bind(this);
    this.checkInfo = this.checkInfo.bind(this);
    this.setStatesAndProps = this.setStatesAndProps.bind(this);
    this.gotoEsign = this.gotoEsign.bind(this);
    this.gotoAfterEsign = this.gotoAfterEsign.bind(this);
    this.setShowButton = this.setShowButton.bind(this);
  }

  componentDidMount() {
    if (!this.props.progressiveInfo) {
      setTimeout(this.checkInfo, 5000);
    } else {
      this.setStatesAndProps();
    }
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  checkInfo() {
    if (!this.props.progressiveInfo) {
      this.props.history.push("/");
    } else {
      this.setStatesAndProps();
    }
  }
  setShowButton() {
    console.log("setShowButton called");
    this.setState({ showSignedBtn: true, timer: null });
  }
  setStatesAndProps() {
    const progressiveInfo = this.props.progressiveInfo;
    const timer = setTimeout(this.setShowButton, 60000);

    this.setState({ progressiveInfo, timer });
    this.props.setProgressiveInfo(null);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
  }
  gotoEsign() {
    if (document.getElementById("esignUrl")) {
      document.getElementById("esignUrl").click();
      try {
        if (this.state.timer) {
          clearTimeout(this.state.timer);
        }
      } catch (error) {}
    }
  }
  gotoAfterEsign() {
    const url = "/afterEsign/" + this.state.progressiveInfo.orderIdentifier;

    this.props.history.push(url);
  }
  orderCompleteMsg() {
    const rsp = this.state.progressiveInfo;
    if (rsp) {
      let message;
      if (isInStore()) {
        const addInfo = rsp.email ? (
          <label>
            &nbsp;Once complete this step, the customer will receive an order
            confirmation at {rsp.email}
          </label>
        ) : (
          ""
        );
        message = (
          <div>
            We have successfully submitted the order to Progressive, the order
            number: <b>{rsp.orderNumber}</b>. <p />
            On the next step, you will be prompted to review and sign for the
            lease agreement and make the initial payment to Progressive Leasing.
            <p />
            The customer should have received an email containing e-sign URL.
            {addInfo}
          </div>
        );
      } else {
        message = (
          <div>
            We have successfully submitted your order to Progressive, your order
            number: <b>{rsp.orderNumber}</b>. <p />
            Your next step is to review and sign the lease agreement and make
            your initial payment to Progressive Leasing. Once you complete this
            step, you will receive an order confirmation at {rsp.email}.
          </div>
        );
      }

      const url =
        rsp.esignUrl +
        "?ReturnUrl=" +
        getMainSiteUrl() +
        "afterEsign/" +
        rsp.orderNumber;
      return (
        <div className="lease-info">
          {message}
          <br />
          <label
            className="blue-link-label"
            id="esignLabel"
            onClick={this.gotoEsign}
          >
            {this.state.buttonLabel}
          </label>
          <a href={url} id="esignUrl"></a>
          <p />
          {isInStore() && this.state.showSignedBtn && (
            <div className="top-10">
              &nbsp;
              <p />
              If the customer has signed the lease agreement on another device,
              click the button below to finish and print the order.
              <p />
              <label
                className="green-link-label"
                id="custSigned"
                onClick={this.gotoAfterEsign}
              >
                Customer Has Signed the Lease
              </label>
              <p />
            </div>
          )}
        </div>
      );
    }
  }
  render() {
    if (!this.state.progressiveInfo) return <Working />;

    return (
      <div className="top-wrapper">
        <div className="oc-container">
          <div className="generic-flex">
            <div>
              <img
                src={getIconBaseUrl() + "prog-web-05-Prog-Mark-Icon-Bl02.jpg"}
                height="25"
              />
            </div>
            <div className="left-10">
              <font size="4">Lease Order</font>
            </div>
          </div>

          <p />
          <div className="lease-complete-wrapper">
            {this.orderCompleteMsg()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    progressiveInfo: state.order.progressiveInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setProgressiveInfo: (progInfo) => {
      dispatch(setProgressiveInfo(progInfo));
    },
    setLeaseOkInfo: (info) => {
      dispatch(setLeaseOkInfo(info));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaseOrderSubmitted);
