import React from "react";
import { Link } from "react-router-dom";
import {
  getIconBaseUrl,
  virtualTourUrl,
  setTopContainerWrapperSettings,
  setInfoWrapperWidth,
} from "../Util";

import "../../info.css";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
    setInfoWrapperWidth();
  }

  render() {
    const vtIcon = getIconBaseUrl() + "virtualTour.png";

    return (
      <div className="top-wrapper">
        <div className="info-container">
          <div className="info-wrapper">
            <div className="info-item">
              <font size="5">About Us</font>
              <br />
              <hr width="80%"></hr>
            </div>
            <div className="info-item">
              <font color="red" size="5">
                WHO WE ARE & WHAT WE DO
              </font>
            </div>

            <div className="info-content-wrapper">
              <div className="info-paragraph">
                Furniture Way Less has been providing our customers in metro
                Atlanta area with quality furnitures at bulk prices since 2008.
              </div>
              <div className="info-paragraph">
                Furniture Way Less was founded as a wholesale store. As a result
                of many rsetail customers walking in and asking for retail
                prices, the store became what it is now giving our customers
                what they want: quality new furnitures at super low clearance
                prices.
              </div>
              <div className="info-paragraph">
                The furnitures we sell are mostly clearances, close out,
                overstock, custom design and factory direct items. And we sell
                them at bulk prices. As such, our prices are 50-75% lower than
                most other furniture retail stores in Metro Atlanta.
              </div>
              <div className="info-paragraph">
                We don't want our customers to wait days or weeks for their
                furnitures. 95% of what we sell are in stock, so we are able to
                offer same day pickup and next day delivery.
              </div>
              <div className="info-paragraph">
                We try our best to provide our customers with lowest prices
                possible. In doing so, some of our stores might be understaffed,
                please bear with us and be patient as lines in our stores may be
                long sometimes.
              </div>

              <div className="info-paragraph">
                <a href={virtualTourUrl()} target="FWLVT">
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src={vtIcon}
                              alt="Virtual Tour"
                              width="80"
                            ></img>
                          </td>
                          <td valign="center">
                            <b>
                              <font size="4" color="orange">
                                Virtual Tour
                              </font>
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </a>
              </div>
              <p />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
