import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { AXIOS_HEADER } from "../config/constants";
import {
  apiBaseUrl,
  adminSiteApiUrl,
  fullProductImageUrl,
  toAmount,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
} from "../Util";
import Working from "../Working";
import CustomerInfo from "./CustomerInfo";
import DeliveryDatePicker from "../order/DeliveryDatePicker";
import LayawayCCPayment from "../payment/LayawayCCPayment";
import ActionConfirmation from "../ActionConfirmation";

import "../../App.css";
import "../../generic.css";
import "../../form.css";
import "../../layaway.css";

class LayawayDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layaway: null,
      isLoading: true,
      errorMessage: null,
      note: null,
      statusNote: null,
      paymentMethod: 1,
      layawayStatus: 0,
      mobileMode: false,
      shipping: 1,
      origShipping: 1,
      shippingExpanded: false,
      mkpmtExpanded: false,
      deliveryInfo: null,
      selectedDate: null,
      deliUpdBtnDisabled: false,
      updPicBtnDisabled: true,
      scheduleInfo: null,
      pickupNote: null,
      allowDeliveryDate: true,
    };
    this.fetchLayaway = this.fetchLayaway.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.adjustMode = this.adjustMode.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.updateDeliveryInfo = this.updateDeliveryInfo.bind(this);
    this.updatePickup = this.updatePickup.bind(this);
    this.getScheduleInfo = this.getScheduleInfo.bind(this);
    this.deliveryDateHandler = this.deliveryDateHandler.bind(this);
    this.layawayPaymentMade = this.layawayPaymentMade.bind(this);
    this.switchToDelivery = this.switchToDelivery.bind(this);
  }
  componentDidMount() {
    if (!this.props.isLoggedIn) {
      this.props.history.push("/login");
      return;
    }

    let newDate = new Date();
    newDate.setDate(newDate.getDate() + 1);

    let dateStr = "" + newDate.getFullYear() + "-";
    if (newDate.getMonth < 9) dateStr += "0";
    dateStr += "" + (newDate.getMonth() + 1) + "-";
    if (newDate.getDate() < 10) dateStr += "0";
    dateStr += "" + newDate.getDate();

    this.setState({ selectedDate: dateStr });
    this.adjustMode();
    window.addEventListener("resize", this.adjustMode);
    this.fetchLayaway();
    this.getScheduleInfo();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustMode);
  }

  adjustMode() {
    this.setState({ mobileMode: isMobileMode() });
    setTopContainerWrapperSettings();
  }
  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;

    if (name.startsWith("CB_")) {
      name = name.substring(3);
      val = event.target.checked;
    } else if (name === "shipping") {
      val = parseInt(val);
      if (val === 2) {
        //Change from pickup to delivery
        if (this.state.layaway.balance > 1) {
          document.getElementById("hiddenConfirmBtn").click();
          return;
        }
      }
    }

    const deliUpdBtnDisabled =
      name === "selectedDate" ? false : this.state.deliUpdBtnDisabled;
    const updPicBtnDisabled =
      name === "pickupNote" ? false : this.state.updPicBtnDisabled;
    this.setState({
      [name]: val,
      deliUpdBtnDisabled,
      updPicBtnDisabled,
    });
  }
  handleClick(type) {
    if (type === 2) {
      this.setState({ mkpmtExpanded: !this.state.mkpmtExpanded });
    } else {
      this.setState({ shippingExpanded: !this.state.shippingExpanded });
    }
  }
  processResponse(response) {
    const layaway = response.dataObj;
    if (layaway) {
      const shipping = layaway.delivery ? 2 : 1;
      let deliveryInfo = layaway.deliveryAddress
        ? layaway.deliveryAddress
        : layaway.customer;
      if (deliveryInfo && !layaway.deliveryAddress) {
        if (
          deliveryInfo.state &&
          deliveryInfo.state != "GA" &&
          deliveryInfo.state != "Georgia"
        ) {
          //non-Georgia address
          deliveryInfo = {
            ...deliveryInfo,
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            zipCode: null,
          };
        }
      }
      let selectedDate = this.state.selectedDate;
      if (layaway.deliveryDateStr) {
        selectedDate = layaway.deliveryDateStr;
      }

      const pickupNote = shipping === 1 ? layaway.deliveryInstr : null;
      this.setState({
        layaway,
        shipping,
        origShipping: shipping,
        deliveryInfo,
        layawayStatus: layaway.statusId,
        selectedDate,
        deliUpdBtnDisabled: shipping === 2,
        nextPaymentDate: layaway.nextPmtDateStr,
        pickupNote,
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: "Layaway not found: " + this.props.match.params.orderNo,
        isLoading: false,
      });
    }
  }
  fetchLayaway() {
    const url = apiBaseUrl() + "GetLayawayDetail";
    const req = {
      userId: this.props.userId,
      email: this.props.userId,
      password: this.props.pwdToken,
      objectId: this.props.match.params.orderNo,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getScheduleInfo() {
    const url = apiBaseUrl() + "GetScheduleInfo";
    const req = {
      userId: this.props.userId,
      email: this.props.userId,
      password: this.props.pwdToken,
      orderNumber: this.props.match.params.orderNo,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ scheduleInfo: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  updateDeliveryInfo(contact) {
    const url = adminSiteApiUrl() + "UpdateLayawayShippingByCustomer";
    let req;
    if (contact) {
      req = {
        userId: this.props.userId,
        sessionKey: this.props.pwdToken,
        orderNumber: this.state.layaway.orderNumber,
        delivery: this.state.shipping == 2,
        deliveryFee: contact.deliveryFee,
        deliveryDate: this.state.selectedDate,
        address: contact,
        note: contact.deliveryInstr,
      };
    } else {
      req = {
        userId: this.props.userId,
        sessionKey: this.props.pwdToken,
        orderNumber: this.state.layaway.orderNumber,
        delivery: false,
        note: this.state.pickupNote,
      };
    }
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (res.data.status) {
          window.location.reload();
        } else {
          this.setState({
            errorMessage: "Failed to update shipping",
            message: null,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  updatePickup() {
    this.updateDeliveryInfo(null);
  }
  layawayDetailHtml() {
    const layaway = this.state.layaway;
    const orderItems = layaway.orderItems;
    const storeItems = layaway.storeItems;
    const storeCoupons = layaway.storeCoupons;
    let html =
      "<style>table, th, td {padding: 5px;border: 1px solid black; border-collapse: collapse;}</style>";
    html +=
      '<table><tr><th style="background-color: #D0D0D0">Product</th><th style="background-color: #D0D0D0">Name</th><th style="background-color: #D0D0D0">Qty.</th><th style="background-color: #D0D0D0">Price</th></tr>';

    for (let idx = 0; idx < orderItems.length; idx++) {
      const item = orderItems[idx];
      const url = "/product/" + item.productId;
      const imgUrl = fullProductImageUrl(item.imageUrl);
      const description = item.productName;
      html +=
        '<tr><td><a href="' +
        url +
        '"><img src="' +
        imgUrl +
        '" width="45" height="30"></img></a>&nbsp;&nbsp;';
      if (!item.pvExists) html += '<font color="red">';
      html += item.item.sku;
      if (!item.pvExists) html += "</font>";
      html += "</td>";
      html += "<td width='300'>" + description + "</td>";
      html += '<td align="center">' + item.item.quantity + "</td>";
      html +=
        '<td align="right">' + toAmount(item.item.unitPrice) + "</td></tr>";
    }

    if (storeItems && storeItems.length > 0) {
      for (let idx = 0; idx < storeItems.length; idx++) {
        const item = storeItems[idx];
        if (item.typeId <= 0) {
          const imgUrl = fullProductImageUrl(item.imageUrl);

          html +=
            '<tr><td><img src="' +
            imgUrl +
            '" width="45" height="30"></img>&nbsp;&nbsp;Store Item</td>';
          html += "<td>" + item.name + "</td>";
          html += '<td align="center">' + item.quantity + "</td>";
          html += '<td align="right">' + toAmount(item.price) + "</td></tr>";
        }
      }
    }
    if (layaway.discountName && layaway.discount > 0) {
      html +=
        '<tr><td colspan="3">' +
        layaway.discountName +
        ':</td><td align="right">' +
        toAmount(0 - layaway.discount) +
        "</td></tr>";
    }
    html +=
      '<tr><td colspan="3">Subtotal:</td><td align="right">' +
      toAmount(layaway.subtotal) +
      "</td></tr>";
    html +=
      '<tr><td colspan="3">GA Tax: </td><td align="right">' +
      toAmount(layaway.tax) +
      "</td></tr>";
    if (layaway.deliveryFee && layaway.deliveryFee > 0) {
      html +=
        '<tr><td colspan="3">Delivery Fee: </td><td align="right">' +
        toAmount(layaway.deliveryFee) +
        "</td></tr>";
    }
    if (storeCoupons && storeCoupons.length > 0) {
      for (let idx = 0; idx < storeCoupons.length; idx++) {
        html +=
          '<tr><td colspan="3">' +
          storeCoupons[idx].name +
          ':</td><td align="right"><font color="red">' +
          toAmount(storeCoupons[idx].price) +
          "</font></td></tr>";
      }
    }
    html +=
      '<tr><td colspan="3"><b>Total:</b> </td><td align="right"><b>' +
      toAmount(layaway.totalPrice) +
      "</b></td></tr>";
    let totalDeposit = 0;
    if (storeItems && storeItems.length > 0) {
      for (let idx = 0; idx < storeItems.length; idx++) {
        const item = storeItems[idx];
        if (item.typeId > 0) {
          const amount = item.price > 0 ? item.price : 0 - item.price;
          const isReturn = item.price < 0;
          const img =
            item.depositType && item.depositType.imageUrl
              ? item.depositType.imageUrl
              : item.imageUrl;
          const imgUrl = fullProductImageUrl(img);
          let name = item.name;
          let properties = "";

          if (isReturn) {
            name += ' (<font color="red">Return</font>)';
          }
          if (item.properties && item.properties.length > 0) {
            for (let k = 0; k < item.properties.length; k++) {
              properties +=
                "<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
                item.properties[k].key +
                ": " +
                item.properties[k].value;
            }
          }

          html +=
            '<tr><td colspan="3"><img src="' +
            imgUrl +
            '" height="20"></img>&nbsp;&nbsp;' +
            name +
            properties +
            "</td>";
          if (isReturn) {
            html += '<td align="right">' + toAmount(amount) + "</td></tr>";
          } else {
            html +=
              '<td align="right"><font color="red">-' +
              toAmount(amount) +
              "</font></td></tr>";
          }

          totalDeposit += item.price;
        }
      }
    }
    if (totalDeposit > 0) {
      let balance = layaway.totalPrice - totalDeposit;

      if (Math.abs(balance) < 1) balance = 0;

      html += '<tr><td colspan="3"><i>Balance:</i></td>';
      html += '<td align="right">$' + balance.toFixed(2) + "</td></tr>";
    }
    html += "</table>";
    return html;
  }
  paymentHistory() {
    const layaway = this.state.layaway;

    if (layaway && layaway.paymentList && layaway.paymentList.length > 0) {
      const dateLabel = this.state.mobileMode ? "Date" : "Payment Date";
      const amountLabel = this.state.mobileMode ? "Amount" : "Payment Amount";
      const clsName = this.state.mobileMode ? "la-table-c4" : "la-table-c5";
      return (
        <div>
          <div className="la-sect-header">
            <div>
              <img
                src={getIconBaseUrl() + "payment-hist.jpg"}
                height="22"
              ></img>
            </div>
            <div className="la-sect-title">
              <font size="4">Payment History</font>
            </div>
          </div>
          <div className={clsName}>
            <div className="la-item-hd">&nbsp;</div>
            <div className="la-item-hd">{dateLabel}</div>
            <div className="la-item-hd">{amountLabel}</div>
            {!this.state.mobileMode && <div className="la-item-hd">Info</div>}
            <div className="la-item-hd">Note</div>
            {layaway.paymentList.map((pmt, idx) => {
              const clsName = idx % 2 === 0 ? "la-item-ev" : "la-item-od";
              const img = pmt.icon ? pmt.icon : "layaway-deposit.jpg";
              let info = "";
              if (pmt.nameOnCard)
                info += "Name on Card: " + pmt.nameOnCard + "<br/>";
              if (pmt.last4) info += "Number: " + pmt.last4 + "<br/>";
              if (pmt.approvalCode)
                info += "Approval Code: " + pmt.approvalCode + "<br/>";
              return (
                <React.Fragment key={idx}>
                  <div className={clsName}>
                    <img src={fullProductImageUrl(img)} width="25"></img>
                  </div>
                  <div className={clsName}>{pmt.pmtDateStr}</div>
                  <div className={clsName}>${pmt.amount.toFixed(2)}</div>
                  {!this.state.mobileMode && (
                    <div
                      className={clsName}
                      dangerouslySetInnerHTML={{ __html: info }}
                    ></div>
                  )}
                  <div className={clsName}>{pmt.note}</div>
                </React.Fragment>
              );
            })}
          </div>
          <p />
        </div>
      );
    }
  }
  deliveryDateHandler(dateStr) {
    this.setState({ selectedDate: dateStr });
  }
  titleAndDateSelector() {
    const scheduleInfo = {
      ...this.state.scheduleInfo,
      scheduledDate: this.state.selectedDate,
    };

    return (
      <div className="la-date-title">
        <div>
          <b>
            <font size="4">Delivery Info</font>
          </b>
        </div>
        {this.state.allowDeliveryDate && (
          <React.Fragment>
            <div className="left-20">Date: &nbsp;</div>
            <div>
              <DeliveryDatePicker
                scheduleInfo={scheduleInfo}
                selectHandler={this.deliveryDateHandler}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
  shippingInfo() {
    if (this.state.layaway && this.state.layaway.statusId < 6) {
      const icon = this.state.shippingExpanded ? "minus.jpg" : "plus.jpg";
      const btnTitle =
        this.state.origShipping == 2 ? "Change to Pickup" : "Update Note";
      const updPicBtnDisabled =
        this.state.origShipping == 2 ? false : this.state.updPicBtnDisabled;
      const btnStyle = updPicBtnDisabled ? "disabled-btn-style" : "btn-style";
      let toggleTitle = null;

      if (this.state.shipping == this.state.origShipping) {
        if (this.state.origShipping == 2) {
          toggleTitle = "View/Update Delivery Info";
        } else {
          if (this.state.layaway.deliveryInstr)
            toggleTitle = "View/Update Note";
          else toggleTitle = "Add Note";
        }
      }

      let showAddrForm = !toggleTitle && this.state.shipping == 2;
      let showNoteForm = !toggleTitle && this.state.shipping == 1;
      if (this.state.shippingExpanded) {
        if (this.state.shipping == 2) showAddrForm = true;
        else showNoteForm = true;
      }

      return (
        <div className="top-10">
          <div className="la-sect-header">
            <div>
              <img src={getIconBaseUrl() + "shipping.jpg"} height="22"></img>
            </div>
            <div className="la-sect-title">
              <font size="4">Shipping</font>
            </div>
          </div>
          <div className="flex-wrapper">
            <div>
              <input
                type="radio"
                name="shipping"
                value={1}
                checked={this.state.shipping == 1}
                onChange={this.handleChange}
              ></input>{" "}
              Pickup
            </div>
            <div className="left-20">
              <input
                type="radio"
                name="shipping"
                value={2}
                checked={this.state.shipping == 2}
                onChange={this.handleChange}
              ></input>{" "}
              Delivery
            </div>
            {toggleTitle && (
              <div className="la-view-upd">
                <img
                  src={getIconBaseUrl() + icon}
                  width="15"
                  onClick={this.handleClick}
                  alt="PM"
                ></img>
                &nbsp;{toggleTitle}
              </div>
            )}
          </div>
          {showNoteForm && (
            <div className="top-20">
              <div>Pickup Note:</div>
              <textarea
                name="pickupNote"
                rows={5}
                cols={50}
                value={this.state.pickupNote || ""}
                onChange={this.handleChange}
              ></textarea>
              <p />
              <button
                name="updPickup"
                className={btnStyle}
                onClick={this.updatePickup}
                disabled={updPicBtnDisabled}
              >
                {btnTitle}
              </button>
            </div>
          )}
          {showAddrForm && (
            <div>
              {this.titleAndDateSelector()}
              <CustomerInfo
                userId={this.props.userId}
                contact={this.state.deliveryInfo}
                layaway={this.state.layaway}
                actionHandler={this.updateDeliveryInfo}
                initDisabled={this.state.deliUpdBtnDisabled}
                buttonName={
                  this.state.origShipping == 2
                    ? "Update Delivery Info"
                    : "Change to Delivery"
                }
              />
            </div>
          )}
          <div>&nbsp;</div>
          <hr className="hr-300" align="left" />
        </div>
      );
    }
  }
  summary() {
    const layaway = this.state.layaway;
    const dateInfo = layaway.deliveryDateStr
      ? " (" + layaway.deliveryDateStr + ")"
      : "";
    const shipping =
      this.state.origShipping === 1 ? "Pickup" : "Delivery" + dateInfo;
    const balance = layaway.totalPrice - layaway.depositAmount;

    return (
      <div>
        <div className="twocol-form">
          <div>Date:</div>
          <div>{layaway.dateOrderedStr}</div>
          <div>Total Amount:</div>
          <div>${layaway.totalPrice.toFixed(2)}</div>
          <div>Deposit Amount:</div>
          <div>
            ${layaway.depositAmount.toFixed(2)} (
            {layaway.percentPaid.toFixed(2)}%)
          </div>
          <div>Balance:</div>
          <div>${balance.toFixed(2)}</div>
          <div>Next Payment Due:</div>
          <div>{layaway.nextPmtDateStr}</div>
          <div>Shipping:</div>
          <div>{shipping}</div>
        </div>
      </div>
    );
  }
  customerInfo() {
    const info = this.state.layaway.customer;
    if (info) {
      let html = "";
      html = info.firstName + " " + info.lastName + "<br/>";
      if (info.addressLine1 && info.addressLine1.trim().length > 0) {
        html += info.addressLine1 + "<br/>";
        if (info.addressLine2) {
          html += info.addressLine2 + "<br/>";
        }
        html += info.city + ", " + info.state + " " + info.zipCode + "<br/>";
      }
      if (info.phone) {
        html += "Phone: " + info.phone + "<br/>";
      }
      if (info.email) {
        html += "Email: " + info.email + "<br/>";
      }

      return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
    }
  }
  summaryAndCustomerInfo() {
    if (this.state.mobileMode) {
      return (
        <div>
          {this.summary()}
          <br />
          <div>
            <font size="4">Contact Info</font>
          </div>
          {this.customerInfo()}
        </div>
      );
    } else {
      return (
        <div className="topalign-flex">
          {this.summary()}
          <div className="left-40"></div>
          {this.customerInfo()}
        </div>
      );
    }
  }
  layawayPaymentMade(amount) {
    window.location.reload();

    const url = adminSiteApiUrl() + "UpdateLayawayDatByCustomer";
    const req = {
      userId: this.props.userId,
      sessionKey: this.props.pwdToken,
      value: this.state.layaway.orderNumber,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        if (!res.data.status) {
          console.log("Failed to update dat");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  paymentSect() {
    const layaway = this.state.layaway;

    if (layaway && layaway.userPaymentAllowed) {
      const layawayInfo = {
        orderNumber: layaway.orderNumber,
        totalAmount: layaway.totalPrice,
        balance: layaway.balance,
      };
      const mkpmtExpanded = this.state.mkpmtExpanded;
      const icon = mkpmtExpanded ? "minus.jpg" : "plus.jpg";
      return (
        <div>
          <img
            src={getIconBaseUrl() + icon}
            width="15"
            onClick={() => this.handleClick(2)}
            alt="PM"
          ></img>{" "}
          Make payment
          <br />
          {mkpmtExpanded && (
            <LayawayCCPayment
              contactInfo={this.state.layaway.customer}
              layawayInfo={layawayInfo}
              callback={this.layawayPaymentMade}
              useBillingAddress={true}
            />
          )}
        </div>
      );
    }
  }
  switchToDelivery() {
    this.setState({ shipping: 2 });
  }
  pickupToDelivery() {
    const layaway = this.state.layaway;

    if (layaway && layaway.balance > 1) {
      let message =
        "You have not paid off your layaway. You can switch to delivery and set a delivery date if you plan to pay the remaining balance in cash on delivery.<p/>";
      message += "Are you going to pay cash on delivery?";
      return (
        <ActionConfirmation
          hidden={true}
          btnId="hiddenConfirmBtn"
          actionHandler={this.switchToDelivery}
          buttonTitle="Hidden"
          buttonClsName="btn-style"
          title="Pay Cash On Delivery"
          message={message}
          html={true}
        />
      );
    }
  }
  render() {
    if (this.state.isLoading) return <Working />;
    if (!this.state.layaway) {
      const errMsg = this.state.errorMessage
        ? this.state.errorMessage
        : "Layaway not found: " + this.props.match.params.orderNo;
      return (
        <div className="top-wrapper">
          <div className="generic-wrapper">
            <p />
            <div>
              <font color="red">{errMsg}</font>
            </div>
            <p />
          </div>
        </div>
      );
    }

    const layaway = this.state.layaway;
    const title = "Layaway: " + layaway.orderNumber;
    return (
      <div className="top-wrapper">
        <div className="generic-wrapper">
          <font size="5">{title}</font>
          <p />
          <div>
            {this.summaryAndCustomerInfo()}
            <hr className="hr-300" align="left" />
            {this.paymentHistory()}
            {this.paymentSect()}
            <hr className="hr-300" align="left" />
            {this.shippingInfo()}
            <font color="red">{this.state.errorMessage}</font>
          </div>
          <div className="la-sect-header">
            <div>
              <img
                src={getIconBaseUrl() + "order-detail.jpg"}
                height="22"
              ></img>
            </div>
            <div className="la-sect-title">
              <font size="4">Layaway Order Detail</font>
            </div>
          </div>
          <p />
          <div
            dangerouslySetInnerHTML={{ __html: this.layawayDetailHtml() }}
          ></div>
          <p />
          {this.pickupToDelivery()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    isLoggedIn: state.user.isLoggedIn,
    pwdToken: state.user.pwdToken,
    sessionKey: state.user.sessionKey,
    userState: state.user,
  };
};
export default connect(mapStateToProps)(LayawayDetail);
