import {
  ADD_TO_CART,
  ADD_SP_TO_CART,
  REMOVE_ITEM,
  SUB_QUANTITY,
  ADD_QUANTITY,
  CHECKOUT,
  CLEAR_CART,
  SET_ITEMS,
  SET_DISCOUNT,
  SET_CARTMSG,
} from "./actionTypes";

//add cart action
export const addToCart = (userId, payload) => {
  return {
    type: ADD_TO_CART,
    userId: userId,
    payload: payload,
  };
};
//set discount
export const setDiscount = (discount) => {
  return {
    type: SET_DISCOUNT,
    discount: discount,
  };
};

//remove item action
export const removeItem = (sku) => {
  return {
    type: REMOVE_ITEM,
    sku,
  };
};
//subtract qt action
export const subtractQuantity = (sku) => {
  return {
    type: SUB_QUANTITY,
    sku,
  };
};
//add qt action
export const addQuantity = (sku) => {
  return {
    type: ADD_QUANTITY,
    sku,
  };
};

export const checkout = () => {
  return {
    type: CHECKOUT,
  };
};

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  };
};

export const setItems = (items) => {
  return {
    type: SET_ITEMS,
    items,
  };
};

export const setCartMessage = (msg) => {
  return {
    type: SET_CARTMSG,
    message: msg,
  };
};
