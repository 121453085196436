import React from "react";
import {
  getLocationsMapSrc,
  setTopContainerWrapperSettings,
  setInfoWrapperWidth,
  getGroupWrapperWidth,
} from "../Util";

import "../../App.css";
import "../../generic.css";

class LocationsMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapWidth: 600,
    };
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    this.setState({ mapWidth: getGroupWrapperWidth() });
    setTopContainerWrapperSettings();
    setInfoWrapperWidth();
  }

  render() {
    const mapStyle = {
      border: 0,
    };
    const height = (this.state.mapWidth * 3) / 4;
    return (
      <div className="top-wrapper">
        <span>
          <b>
            <font size="4">Five Locations in Metro Atlanta Area</font>
          </b>
        </span>
        <div className="generic-wrapper">
          <iframe
            title="Furniture Way Less"
            width={this.state.mapWidth}
            height={height}
            frameborder="0"
            style={mapStyle}
            src={getLocationsMapSrc()}
            allowfullscreen
          ></iframe>
        </div>
      </div>
    );
  }
}

export default LocationsMap;
