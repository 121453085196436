import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import CartView from "../cart/CartView";
import Address from "../AddressInfo";
import { cartSummary } from "../cart/cartUtil";
import {
  setOrderStage,
  setOrderInfo,
  clearStagedOrder,
  setPaymentError,
} from "../actions/orderActions";
import { clearCart, setItems, setCartMessage } from "../actions/cartActions";
import { spClearCart } from "../actions/spCartActions";
import CreditCardEx from "../payment/CreditCardEx";
import { ORDERSTAGE_DELIVERYFEE_PAID } from "../order/orderStages";
import { AXIOS_HEADER } from "../config/constants";
import {
  FormatCurrency,
  apiBaseUrl,
  rateToPercent,
  generateGuid,
  setTopContainerWrapperSettings,
  isMobileMode,
} from "../Util";
import { isInStore } from "../store/storeUtil";
import log from "loglevel";
import "../myLogger";
import "../../App.css";
import "../../orderSum.css";
import "../../layaway.css";
import "../../payment.css";

class LayawaySummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      taxRate: this.props.taxRate,
      summary: cartSummary(this.props.taxRate, 0),
      pmtChoices: null,
      paymentMessage: null,
      mobileMode: false,
      isLoading: true,
      errorMessage: null,
    };

    this.setUserId = this.setUserId.bind(this);
    this.adjustSettings = this.adjustSettings.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.placeLayaway = this.placeLayaway.bind(this);
    this.processLayawayResponse = this.processLayawayResponse.bind(this);
    this.emptyCart = this.emptyCart.bind(this);
    this.getPaymentChoices = this.getPaymentChoices.bind(this);
    this.submitLayawayAndPayment = this.submitLayawayAndPayment.bind(this);
  }
  componentDidMount() {
    if (isInStore()) {
      this.props.history.push("/layawaySummary");
      return;
    } else if (!this.props.contactInfo) {
      this.props.history.push("/cart");
      return;
    }
    this.getPaymentChoices();
    this.adjustSettings();
    this.props.setPaymentError(null);
    window.addEventListener("resize", this.adjustSettings);
  }
  emptyCart() {
    if (!this.props.items || this.props.items.length < 1) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
    this.setState({ mobileMode: isMobileMode() });
  }

  setUserId() {
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }
  }
  processLayawayResponse(response) {
    if (response) {
      if (response.status === "Processed") {
        const payload = {
          orderNumber: response.orderNumber,
          orderEmail: response.email,
          codAmount: response.codAmount,
          orderCompleteMsg: response.orderCompleteMsg,
        };

        this.props.clearCart();
        this.props.spClearCart();
        this.props.clearStagedOrder();
        this.props.setOrderInfo(payload);
        this.props.setOrderStage(ORDERSTAGE_DELIVERYFEE_PAID);
        this.props.history.push("/laComplete");
      } else if (response.status == "PriceChanged") {
        this.props.setItems(response.newItemList);
        this.props.setCartMessage(response.errorMessage);
        this.props.history.push("/cart");
      } else if (response.status === "ItemSoldOut") {
        //this.props.setItems(response.newItemList);
        this.props.setCartMessage(response.errorMessage);
        this.props.history.push("/cart");
      } else if (response.status === "FatalError") {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
        });
        this.props.history.push("/");
      } else if (response.status === "PaymentError") {
        this.props.setPaymentError(response.errorMessage);
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
        });
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          submitBtnEnabled: true,
        });
      }
    } else {
      this.setState({
        errorMessage: "Failed to process your order.",
        submitBtnEnabled: true,
      });
    }
  }
  getPaymentChoices() {
    const url = apiBaseUrl() + "GetLayawayInfoForOrderEx";
    const req = {
      ...this.state.summary,
      userId: this.props.userId,
    };

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        const paymentMessage = this.state.mobileMode
          ? res.data.beforePaymentMessageMobile
          : res.data.beforePaymentMessage;
        this.setState({
          pmtChoices: res.data.paymentChoices,
          paymentMessage,
          taxRate: res.data.taxRate,
          summary: cartSummary(res.data.taxRate, 0),
        });
      })
      .catch((error) => {
        log.error(error);
      });
  }

  placeLayaway(payment) {
    const summary = this.state.summary;
    const order = {
      userId: this.props.userId,
      items: this.props.items,
      storeItems: this.props.storeItems,
      contactInfo: this.props.contactInfo,
      subtotal: summary.subtotal,
      total: summary.grandTotal,
      discount: summary.discount,
      taxRate: summary.taxRate,
      tax: summary.tax,
      tariff: 0,
      pickup: true,
      layaway: true,
    };
    const req = {
      userId: this.props.userId,
      order,
      storeId: 0,
      payment,
    };
    const url = payment
      ? apiBaseUrl() + "SubmitLayawayPayment"
      : apiBaseUrl() + "SubmitLayaway";

    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processLayawayResponse(res.data);
      })
      .catch((error) => {
        log.error(error);
        console.log(error);
      });
  }
  submitLayawayAndPayment(ccInfo, address, amount) {
    const payment = {
      ...ccInfo,
      ...address,
      amount,
    };
    this.placeLayaway(payment);
  }

  showSummary() {
    const summary = this.state.summary;
    return (
      <div>
        <div className="sum-title-div">
          <font size="4">Layaway Summary</font>
        </div>
        <div className="sum-tbl-container">
          {summary.discount && !summary.discount.afterTax && (
            <React.Fragment>
              <div className="sum-tbl-item-left">{summary.discount.name}:</div>
              <div className="sum-tbl-item-right">
                <font color="red">
                  -<FormatCurrency amount={summary.discount.amount} />
                </font>
              </div>
            </React.Fragment>
          )}
          <div className="sum-tbl-item-left">Subtotal:</div>
          <div className="sum-tbl-item-right">
            <FormatCurrency amount={summary.subtotal} />
          </div>
          <div className="sum-tbl-item-left">
            Sales Tax({rateToPercent(summary.taxRate)}):
          </div>
          <div className="sum-tbl-item-right">
            <FormatCurrency amount={summary.tax} />
          </div>
          {summary.coupons.map((coupon, idx) => (
            <React.Fragment key={idx}>
              <div className="sum-tbl-item-left">{coupon.name}:</div>
              <div className="sum-tbl-item-right">
                <font color="red">
                  <FormatCurrency amount={coupon.price} />
                </font>
              </div>
            </React.Fragment>
          ))}
          {summary.discount && summary.discount.afterTax && (
            <React.Fragment>
              <div className="sum-tbl-item-left">{summary.discount.name}:</div>
              <div className="sum-tbl-item-right">
                <font color="red">
                  -<FormatCurrency amount={summary.discount.amount} />
                </font>
              </div>
            </React.Fragment>
          )}
          <div className="sum-tbl-item-left">
            <b>Grand Total:</b>
          </div>
          <div className="sum-tbl-item-right">
            <FormatCurrency amount={summary.grandTotal} />
          </div>
        </div>
      </div>
    );
  }

  handleChange(event) {
    if (event.target.name === "salesperson") {
      this.setState({ salesperson: event.target.value });
    }
  }
  render() {
    const addr = this.props.contactInfo;
    const addrTitle = "Contact Information";
    const title = "Review Your Layaway";

    return (
      <div className="top-wrapper">
        <div className="sum-container">
          <div align="left">
            <font size="5">{title}</font>
            <p />
          </div>
          <div className="sum-wrapper">
            <div className="sum-ordersum-wrapper">{this.showSummary()}</div>
            <div className="sum-addr-wrapper">
              <Address address={addr} title={addrTitle} />
            </div>
          </div>
          <div>
            <hr className="hr-300" align="left"></hr>
            <b>Layaway Down Payment</b>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: this.state.paymentMessage }}
          ></div>
          {this.state.pmtChoices && this.state.pmtChoices.length > 0 && (
            <div className="payment-line">
              <CreditCardEx
                amountChoiceList={this.state.pmtChoices}
                useBillingAddress={true}
                billingAddrRequired={false}
                validateCCNumber={false}
                submitBtnTitle="Place Layaway"
                actionHandler={this.submitLayawayAndPayment}
              />
            </div>
          )}
          {!this.state.pmtChoices ||
            (this.state.pmtChoices.length === 0 && (
              <div>
                <button
                  className="layaway-btn"
                  name="layaway"
                  onClick={() => this.placeLayaway()}
                >
                  Place Layaway
                </button>
              </div>
            ))}
          <p />
          <div align="left">
            <CartView title="Layaway Items" />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.cart.addedItems,
    discount: state.cart.discount,
    subtotal: state.cart.subtotal,
    contactInfo: state.order.contactInfo,
    deliveryInfo: state.order.deliveryInfo,
    ratefees: state.order.ratefees,
    shippingChoice: state.order.shippingChoice,
    pickupLocId: state.order.pickupLocId,
    userId: state.user.userId,
    orderStage: state.order.stage,
    shippingChoiceDecided: state.order.shippingChoiceDecided,
    android: state.cache.android,
    kiosk: state.webOrder.kiosk,
    storeItems: state.spCart.storeItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderStage: (stage) => {
      dispatch(setOrderStage(stage));
    },
    clearCart: () => {
      dispatch(clearCart());
    },
    spClearCart: () => {
      dispatch(spClearCart());
    },
    setOrderInfo: (payload) => {
      dispatch(setOrderInfo(payload));
    },
    clearStagedOrder: () => {
      dispatch(clearStagedOrder());
    },
    setPaymentError: (paymentError) => {
      dispatch(setPaymentError(paymentError));
    },
    setItems: (items) => {
      dispatch(setItems(items));
    },
    setCartMessage: (msg) => {
      dispatch(setCartMessage(msg));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayawaySummary);
