import React from "react";
import {
  getIconBaseUrl,
  setInfoWrapperWidth,
  setTopContainerWrapperSettings,
} from "../Util";

import "../../info.css";

class LeaseToOwn extends React.Component {
  constructor(props) {
    super(props);
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  componentDidMount() {
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
    setInfoWrapperWidth();
  }

  render() {
    const { noCreditUrl } = window["runConfig"];
    const imgUrl = getIconBaseUrl() + "proglogo-web-03-Horiz02.jpg";
    return (
      <div className="top-wrapper">
        <div className="info-container">
          <div className="info-wrapper">
            <div className="info-item">
              <img src={imgUrl} alt="Progressive"></img>
            </div>
            <div className="info-item">
              <font size="5">Flexible lease-to-own options.</font>
              <br />
              <font size="4">No credit needed.</font>
              <br />
            </div>
            <div className="info-item">
              1. Over 18 years or older
              <p />
              2. Valid SSN or EIN number
              <p />
              3. Valid Debit or credit card
              <p />
              4. Routing and account number from a active checking account.
              <p />
            </div>
            <div className="info-item">
              Customer service:877-898-1970
              <p />
              Fax:877-966-2888
              <p />
              Email:app@progleasing.com
            </div>
            <div>
              <a href={noCreditUrl} target="_blank">
                <img
                  src={
                    getIconBaseUrl() + "prog-web-09-296x60-Apply-Now-Bl02.jpg"
                  }
                />
              </a>
            </div>
            <div className="info-item">
              ***Furniture Way Less requires the order to be more than $400 to
              use the Lease-to-Own Option***
            </div>
            <div className="disclosure">
              <div className="left-10">DISCLOSURE</div>
              <ul>
                <li>
                  The advertised service is lease-to-own or a rental- or
                  lease-purchase agreement provided by Prog Leasing, LLC, or its
                  affiliates. Acquiring ownership by leasing costs more than the
                  retailer’s cash price. Leasing available on select items at
                  participating locations only. Not available in MN, NJ, VT, WI,
                  WY.
                </li>
                <li>
                  <b>No Credit Needed:</b> Progressive Leasing obtains
                  information from credit bureaus. Not all applicants are
                  approved.
                </li>
                <li>
                  <b>90-Day Purchase Option: </b>Standard agreement offers 12
                  months to ownership. 90-day purchase options cost more than
                  the retailer’s cash price (except 3-month option in CA). To
                  purchase early call 877-898-1970.
                </li>
                <li>
                  <b>Initial Payment Amount: </b>The initial payment (plus tax)
                  is charged at lease signing. Remaining lease payments
                  determined upon item selection.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeaseToOwn;
