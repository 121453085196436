import React from "react";
import { connect } from "react-redux";

import { clearCart } from "./actions/cartActions";
import { spClearCart } from "./actions/spCartActions";
import { clearUser } from "./actions/userActions";
import { clearOrder } from "./actions/orderActions";
import { clearSearch } from "./actions/searchActions";
import { clearCache } from "./actions/cacheActions";
import { clearWebOrder } from "./actions/webOrderActions";

class ClearStates extends React.Component {
  componentDidMount() {
    this.props.clearCart();
    this.props.spClearCart();
    this.props.clearUser();
    this.props.clearOrder();
    this.props.clearSearch();
    this.props.clearWebOrder();

    this.props.history.push("/");
  }

  render() {
    return <div></div>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: () => {
      dispatch(clearCart());
    },
    spClearCart: () => {
      dispatch(spClearCart());
    },
    clearUser: () => {
      dispatch(clearUser());
    },
    clearOrder: () => {
      dispatch(clearOrder());
    },
    clearSearch: () => {
      dispatch(clearSearch());
    },
    clearCache: () => {
      dispatch(clearCache());
    },
    clearWebOrder: () => {
      dispatch(clearWebOrder());
    },
  };
};

export default connect(null, mapDispatchToProps)(ClearStates);
