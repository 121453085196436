import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";
import { setUserId, setUserInfo } from "../actions/userActions";
import { AXIOS_HEADER } from "../config/constants";

import {
  apiBaseUrl,
  generateGuid,
  setTopContainerWrapperSettings,
  fullProductImageUrl,
  getGroupWrapperWidth,
} from "../Util";
import Working from "../Working";
import log from "loglevel";
import "../myLogger";
import "../../App.css";
import "../../navBar.css";
import "../../product.css";
import "../../generic.css";

class FlyerProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageMapList: [],
      imageWidth: 0,
      isLoading: true,
    };

    this.fetchData = this.fetchData.bind(this);
    this.adjustImageWidthAndRatio = this.adjustImageWidthAndRatio.bind(this);
    this.processResponse = this.processResponse.bind(this);
  }

  fetchData() {
    let mapId = this.props.match.params.mapId;
    const allMaps = mapId == null || mapId == undefined ? true : false;
    const url = allMaps
      ? apiBaseUrl() + "GetAllImageMaps"
      : apiBaseUrl() + "GetImageMap";
    let userId = this.props.userId;

    if (userId === null || userId === undefined || userId === "") {
      userId = generateGuid();
      this.props.setUserId(userId);
    }

    const req = {
      userId: userId,
      value: mapId,
    };
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data, allMaps);
      })
      .catch((error) => {
        log.error(error);
        this.setState({
          isLoading: false,
          errorMessage: "Failed to get product detail",
        });
        this.props.history.push("/");
      });
  }

  processResponse(data, allMaps) {
    if (data) {
      let imageMapList;
      if (allMaps) {
        imageMapList = data;
      } else {
        imageMapList = [data];
      }
      for (let i = 0; i < imageMapList.length; i++) {
        const ratio =
          (this.state.imageWidth * 1.0) / (imageMapList[i].imageWidth * 1.0);
        imageMapList[i].ratio = ratio;
      }

      this.setState({ imageMapList, isLoading: false });
    } else {
      this.setState({ imageMapList: [], isLoading: false });
      this.props.history.push("/");
    }
  }
  adjustImageWidthAndRatio() {
    let imageMapList = this.state.imageMapList;
    let imageWidth = getGroupWrapperWidth() - 15;

    if (imageMapList && imageMapList.length > 0) {
      for (let i = 0; i < imageMapList.length; i++) {
        const ratio = (imageWidth * 1.0) / (imageMapList[i].imageWidth * 1.0);
        imageMapList[i].ratio = ratio;
      }
    }
    this.setState({
      imageWidth,
      imageMapList,
    });
  }

  componentDidMount() {
    setTopContainerWrapperSettings();
    this.adjustImageWidthAndRatio();
    window.addEventListener("resize", this.adjustImageWidthAndRatio);
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.match.params.mapId !== prevProps.match.params.mapId) {
      this.fetchData();
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustImageWidthAndRatio);
  }

  getCoords(map, item) {
    const x1 = Math.round(item.upperLeftX * map.ratio);
    const y1 = Math.round(item.upperLeftY * map.ratio);
    const x2 = Math.round(item.lowerRightX * map.ratio);
    const y2 = Math.round(item.lowerRightY * map.ratio);

    const coords = "" + x1 + "," + y1 + "," + x2 + "," + y2;
    return coords;
  }

  showImageMap(map) {
    if (map.itemList && map.itemList.length > 0) {
      return (
        <div>
          {map.showMapName && (
            <div align="center">
              <b>
                <font color="red" size="5">
                  {map.mapName}
                </font>
              </b>
            </div>
          )}
          <div>
            <img
              src={fullProductImageUrl(map.imageURL)}
              alt={map.mapName}
              width={this.state.imageWidth}
              usemap={"#" + map.mapID}
            />
            <map name={map.mapID}>
              {map.itemList.map((item) => {
                return (
                  <area
                    shape="rect"
                    coords={this.getCoords(map, item)}
                    alt={item.productSKU}
                    href={item.productURL}
                  />
                );
              })}
            </map>
          </div>
          <div className="top-20"></div>
        </div>
      );
    }
  }

  showAllImageMaps() {
    const imageMapList = this.state.imageMapList;

    if (imageMapList && imageMapList.length > 0) {
      return <div>{imageMapList.map((map) => this.showImageMap(map))}</div>;
    } else return <div></div>;
  }

  render() {
    if (this.state.isLoading === true) return <Working />;

    if (this.state.imageMapList == null) {
      return (
        <div className="prod-detail-info" align="left">
          <font color="red">{this.state.errorMessage}</font>
        </div>
      );
    }

    return <div className="top-wrapper">{this.showAllImageMaps()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userInfo: state.user.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlyerProducts);
