import React from "react";
import { connect } from "react-redux";
import { setDebug } from "./actions/cacheActions";
import DeliveryDatePicker from "./order/DeliveryDatePicker";
import "../App.css";
import "../home.css";

class DebugInfo extends React.Component {
  showDebugInfo() {
    this.props.setDebug();
    if (this.props.debugInfo) {
      const info = this.props.debugInfo;
      return (
        <div>
          <ul>
            <li>ScreenWidth: {window.screen.width}</li>
            <li>InnerWidth: {info.innerWidth}</li>
            <li>ClientWidth: {document.documentElement.clientWidth}</li>
            <li>CatTop: {info.catTop}</li>
            <li>CatWrapper: {info.catWrapper}</li>
            <li>MenuTop: {info.menuTop}</li>
            <li>HomeTop: {info.homeTop}</li>
            <li>HomeWrapper: {info.homeWrapper}</li>
            <li>PromWrapper: {info.promWrapper}</li>
            <li>GroupWidth: {info.groupWidth}</li>
            <li>BigImageWidth: {info.bigImageWidth}</li>
            <li>SmallImageWidth: {info.smallImageWidth}</li>
          </ul>
        </div>
      );
    } else {
      return <div>No debug info available</div>;
    }
  }

  render() {
    const avList = [
      1,
      2,
      3,
      4,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
    ];
    const exList = [5, 13];
    return (
      <div className="top-wrapper">
        <div className="hm-container">{this.showDebugInfo()}</div>
        <p />
        <DeliveryDatePicker
          availableOffsetList={avList}
          excludedOffsetList={exList}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    android: state.cache.android,
    debugInfo: state.cache.debugInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDebug: () => {
      dispatch(setDebug());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DebugInfo);
