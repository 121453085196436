import React, { Component } from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import log from "loglevel";
import { getIconBaseUrl, apiBaseUrl, toAmountEx, toShortOrder } from "../Util";
import { getStoreId, isInStore } from "../store/storeUtil";
import { AXIOS_HEADER } from "../config/constants";
import DialogBox from "../DialogBox";
import CreditCardEx from "../payment/CreditCardEx";
import InStorePayment from "../payment/InStorePayment";
import {
  setProgressiveInfo,
  setDeliveryInfo,
  setContactInfo,
} from "../actions/orderActions";
import "../myLogger";
import "../../tables.css";
import "../../generic.css";
import "../../App.css";

class ProgressiveLeasing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderInfo: this.props.orderInfo,
      orderTotal: this.props.orderTotal,
      scheduledDate: this.props.scheduledDate,
      userId: null,
      leaseId: "",
      phoneNumber: "",
      last4SSN: "",
      lastName: "",
      birthMonth: "01",
      birthYear: null,
      lookupBtnDisabled: true,
      submitBtnDisabled: false,
      leaseEstimateInfo: null,
      leaseLookupResult: null,
      tempResponse: null,
      olPmtMethod: null, //Over approval limit payment method
      mobileMode: this.props.mobileMode,
      lookupType: 1, //1 -- by phone, 2 -- by name, 3 -- by leaseId
      findLeaseErrMsg: null,
      addrMismatchMessage: null,
      nameMismatchMessage: null,
      dialogTitle: "",
      dialogMessage: "",
      setting: this.props.setting,
      waitingForEstimate: false,
      waitingForLeaseInfo: false,
      waitingForOrder: false,
      script: null,
      snippetState: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.initState = this.initState.bind(this);
    this.getLeaseEstimateCosts = this.getLeaseEstimateCosts.bind(this);
    this.lookupLease = this.lookupLease.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.doSubmitOrder = this.doSubmitOrder.bind(this);
    this.submitPaymentOrder = this.submitPaymentOrder.bind(this);
    this.submitStorePmtOrder = this.submitStorePmtOrder.bind(this);
    this.setLookupType = this.setLookupType.bind(this);
    this.setOlPmtMethod = this.setOlPmtMethod.bind(this);
    this.replaceAddress = this.replaceAddress.bind(this);
    this.replaceNames = this.replaceNames.bind(this);
    this.setLeaseLookupResponse = this.setLeaseLookupResponse.bind(this);
    this.processLeaseLookupResponse =
      this.processLeaseLookupResponse.bind(this);
    this.handleScript = this.handleScript.bind(this);
    this.loadEstimatorSnippet = this.loadEstimatorSnippet.bind(this);
  }
  componentDidMount() {
    this.initState();
  }
  componentDidUpdate(prevProp) {
    if (
      prevProp.orderInfo != this.props.orderInfo ||
      prevProp.orderTotal != this.props.orderTotal ||
      prevProp.scheduledDate != this.props.scheduledDate
    ) {
      this.initState();
    }
  }
  initState() {
    let phoneNumber = "";
    const orderInfo = this.props.orderInfo;
    const order = orderInfo.order;
    if (order.contactInfo) {
      phoneNumber = order.contactInfo.phone;
    }

    const setting = this.props.setting;
    this.setState({
      orderTotal: this.props.orderTotal,
      orderInfo,
      phoneNumber,
      userId: order.userId,
      mobileMode: this.props.mobileMode,
      scheduledDate: this.props.scheduledDate,
      setting,
    });

    if (setting) {
      let useSnippet = false;
      if (this.props.mobileMode) {
        useSnippet = setting.useMobileSnippet;
      } else {
        useSnippet = setting.useEstimatorSnippet;
      }
      if (useSnippet && setting.snippetUrl && setting.snippetUrl.length > 0) {
        this.loadEstimatorSnippet(setting.snippetUrl);
      }
    }
  }
  getLeaseEstimateCosts() {
    const url = apiBaseUrl() + "EstimateLeaseCost";
    const req = {
      userId: this.state.userId,
      data: this.state.orderTotal,
    };

    this.setState({ waitingForEstimate: true });
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.setState({ waitingForEstimate: false });
        if (res.data.status) {
          this.setState({
            leaseEstimateInfo: res.data.data,
            leaseId: res.data.data.leaseId,
          });
          this.setLeaseEstimateDialogInfo(res.data.data);
          document.getElementById("hdnDialogBoxBtn").click();
        }
      })
      .catch((error) => {
        log.error(error);
        console.log(error);
        this.setState({ waitingForEstimate: false });
      });
  }
  replaceAddress() {
    const res = this.state.tempResponse;
    this.setLeaseLookupResponse(res);

    const addr = res.correctAddress;
    const contactInfo = {
      ...this.props.contactInfo,
      addressLine1: addr.streetAddress1,
      addressLine2: addr.streetAddress2,
      city: addr.city,
      state: addr.state,
      zipCode: addr.zipCode,
    };
    this.props.setContactInfo(contactInfo);
    if (this.props.deliveryInfo) {
      const deliveryInfo = {
        ...this.props.deliveryInfo,
        addressLine1: addr.streetAddress1,
        addressLine2: addr.streetAddress2,
        city: addr.city,
        state: addr.state,
        zipCode: addr.zip,
      };
      this.props.setDeliveryInfo(deliveryInfo);
    }
  }
  replaceNames() {
    const res = this.state.tempResponse;
    this.setLeaseLookupResponse(res);

    const contactInfo = {
      ...this.props.contactInfo,
      firstName: res.correctFirstName,
      lastName: res.correctLastName,
      name: res.correctFirstName + " " + res.correctLastName,
    };
    this.props.setContactInfo(contactInfo);
    if (this.props.deliveryInfo) {
      const deliveryInfo = {
        ...this.props.deliveryInfo,
        firstName: res.correctFirstName,
        lastName: res.correctLastName,
        name: res.correctFirstName + " " + res.correctLastName,
      };
      this.props.setDeliveryInfo(deliveryInfo);
    }
  }
  setLeaseLookupResponse(response) {
    this.setState({
      leaseLookupResult: response,
      waitingForLeaseInfo: false,
      tempResponse: null,
    });
    if (this.props.leaseTaxHandler) {
      const leaseTax =
        response.exceededLimit && response.overLimitTax > 0
          ? response.overLimitTax
          : 0;
      this.props.leaseTaxHandler(leaseTax);
    }
  }

  processLeaseLookupResponse(response) {
    if (response.status) {
      if (response.addressMismatch) {
        this.setState({ waitingForLeaseInfo: false });

        const addr = response.correctAddress;
        const line2 = addr.streetAddress2 ? (
          <label>
            {addr.streetAddress2}
            <br />
          </label>
        ) : (
          ""
        );
        const addrMismatchMessage = (
          <div>
            Due to leasing requirements, your shipping address must match the
            address on your lease application below:
            <p />
            Would you like to switch to this address?
            <p />
            {addr.streetAddress1}
            <br />
            {line2}
            {addr.city}, {addr.state} {addr.zip}
            <p />
          </div>
        );
        this.setState({ tempResponse: response, addrMismatchMessage });
        document.getElementById("hdnMismatchBoxBtn").click();
      } else if (response.nameMismatch) {
        this.setState({ waitingForLeaseInfo: false });
        const info = this.props.contactInfo;
        const name = info.name
          ? info.name
          : info.firstName + " " + info.lastName;
        let color = "black";
        if (response.similarity >= 70) color = "blue";
        else if (response.similarity < 30) color = "red";

        const nameMismatchMessage = (
          <div>
            &nbsp;
            <br />
            The first name and last name you provided (<b>{name}</b>) don't
            match the lease, Similarity:{" "}
            <font color={color}>{response.similarity}%</font>.
            <p />
            Would you like to change to the following names?
            <p />
            First Name: {response.correctFirstName}
            <br />
            Last Name: {response.correctLastName}
            <p />
          </div>
        );
        this.setState({ tempResponse: response, nameMismatchMessage });
        document.getElementById("hdnNameMismatchBoxBtn").click();
      } else {
        this.setLeaseLookupResponse(response);
      }
    } else {
      const dialogTitle = response.errorTitle
        ? response.errorTitle
        : "Lease Approval Not Found";
      const findLeaseErrMsg = (
        <div
          className="top-10"
          dangerouslySetInnerHTML={{ __html: response.errorMessage }}
        ></div>
      );
      this.setState({
        findLeaseErrMsg,
        waitingForLeaseInfo: false,
        dialogTitle: this.formatDialogTitle(dialogTitle, true),
        dialogMessage: findLeaseErrMsg,
      });
      document.getElementById("hdnDialogBoxBtn").click();
    }
  }
  lookupLease() {
    const url = apiBaseUrl() + "LookupLease";
    const storeId = isInStore() ? getStoreId() : 0;
    const order = {
      ...this.state.orderInfo.order,
      orderNumber: this.state.orderInfo.stagedOrderNum,
      scheduledDate: this.state.scheduledDate,
      storeId,
    };
    const req = {
      ...this.state,
      order: toShortOrder(order),
      storeId,
    };
    axiosRetry(axios, { retries: 3 });
    this.setState({ waitingForLeaseInfo: true, lookupBtnDisabled: true });

    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        this.processLeaseLookupResponse(res.data);
      })
      .catch((error) => {
        log.error(error);
        this.setState({
          waitingForLeaseInfo: false,
          lookupBtnDisabled: false,
          findLeaseErrMsg: "An error has occurred, please try again later",
          dialogTitle: this.formatDialogTitle("Lease Approval Not Found", true),
          dialogMessage: "An error has occurred, please try again later",
        });
        document.getElementById("hdnDialogBoxBtn").click();
        console.log(error);
      });
  }
  submitOrder() {
    this.doSubmitOrder(null, null);
  }

  submitPaymentOrder(ccInfo, address, amount) {
    const payment = {
      ...ccInfo,
      ...address,
      amount,
    };
    this.doSubmitOrder(payment, null);
  }
  submitStorePmtOrder(inStorePayment) {
    const name =
      inStorePayment.paymentMethod == 1
        ? "Cash payment"
        : "Credit/Debit payment";
    const storeItem = {
      ...inStorePayment,
      price: inStorePayment.amount,
      typeId: inStorePayment.paymentMethod,
      quantity: 1,
      name,
      productId: -1,
      sku: "InStore Payment",
    };
    this.doSubmitOrder(null, storeItem);
  }
  doSubmitOrder(payment, stItem) {
    const url = apiBaseUrl() + "SubmitInvoiceOrder";
    const res = this.state.leaseLookupResult;
    const total = this.state.orderInfo.order.total;
    let storeItems = this.state.orderInfo.order.storeItems;

    if (stItem) {
      if (!storeItems) storeItems = [];
      storeItems.push(stItem);
    }
    const leasePriceInfo = res ? res.leasePriceInfo : null;
    const storeId = isInStore() ? getStoreId() : 0;
    const order = {
      ...this.state.orderInfo.order,
      orderNumber: this.state.orderInfo.stagedOrderNum,
      scheduledDate: this.state.scheduledDate,
      salesperson: this.state.orderInfo.salesperson,
      storeId: this.state.orderInfo.storeId,
      tax: res.overLimitTax,
      total,
      storeItems,
      leasePriceInfo,
      storeId,
    };

    const cod =
      res.exceededLimit && this.state.olPmtMethod == "COD" ? true : false;
    const leaseAmount = res.leasePriceInfo.approvalLimit;
    const balance = total - leaseAmount;
    const req = {
      ...this.state,
      order: toShortOrder(order),
      leaseId: res.leasePriceInfo.leaseId,
      emailOnLease: res.leasePriceInfo.emailOnLease,
      payment,
      exceededLimit: res.exceededLimit,
      cod,
      leaseAmount,
      overLimitAmount: res.overLimitAmount,
      overLimitTax: res.overLimitTax,
      balance,
    };

    axiosRetry(axios, { retries: 3 });
    this.setState({ waitingForOrder: true, submitBtnDisabled: true });

    axios
      .post(url, req, {
        headers: AXIOS_HEADER,
      })
      .then((res) => {
        if (res.data.status) {
          this.props.setProgressiveInfo(res.data);
          this.setState({ waitingForOrder: false });
          if (this.props.orderSuccessHandler) {
            this.props.orderSuccessHandler(res.data);
          }
          window.location.replace("/leaseOrderSubmitted");
        } else {
          if (
            res.data.orderValidationResult &&
            this.props.orderValidationErrorHandler
          ) {
            this.props.orderValidationErrorHandler(
              res.data.orderValidationResult
            );
            return;
          }

          const dialogTitle = "Submit Order Failed";
          const errMsg = (
            <div
              className="top-10"
              dangerouslySetInnerHTML={{ __html: res.data.errorMessage }}
            />
          );
          this.setState({
            waitingForOrder: false,
            submitBtnDisabled: false,
            dialogTitle: this.formatDialogTitle(dialogTitle, true),
            dialogMessage: errMsg,
          });
          document.getElementById("hdnDialogBoxBtn").click();
        }
      })
      .catch((error) => {
        log.error(error);
        this.setState({
          waitingForLeaseInfo: false,
          lookupBtnDisabled: false,
          findLeaseErrMsg: "An error has occurred, please try again later",
          dialogTitle: this.formatDialogTitle("Lease Approval Not Found", true),
          dialogMessage: "An error has occurred, please try again later",
        });
        document.getElementById("hdnDialogBoxBtn").click();
        console.log(error);
      });
  }
  handleChange(event) {
    const name = event.target.name;

    if (name === "lookupType") {
      const lookupType = parseInt(event.target.value);
      const order = this.state.orderInfo ? this.state.orderInfo.order : null;
      let lastName = "";
      let phoneNumber = "";
      let leaseId = "";

      if (lookupType < 3 && order && order.contactInfo) {
        if (lookupType == 1) {
          phoneNumber = order.contactInfo.phone;
        } else {
          lastName = order.contactInfo.lastName;
        }
      }
      this.setState({ lookupType, lastName, phoneNumber, leaseId });
    } else if (name === "olPmtMethod") {
      this.setState({ olPmtMethod: event.target.value });
    } else {
      const state = {
        ...this.state,
        [name]: event.target.value,
      };

      let lookupBtnDisabled = true;
      if (state.leaseId && state.leaseId.length > 3) {
        lookupBtnDisabled = false;
      } else {
        if (state.last4SSN && state.last4SSN.length >= 4) {
          if (state.phoneNumber && state.phoneNumber.length >= 10) {
            lookupBtnDisabled = false;
          } else {
            if (
              state.lastName &&
              state.lastName.length > 0 &&
              state.birthYear &&
              state.birthYear.length == 4
            ) {
              lookupBtnDisabled = false;
            }
          }
        }
      }
      this.setState({
        [name]: event.target.value,
        lookupBtnDisabled,
      });
    }
  }
  setLookupType(type) {
    this.setState({ lookupType: type });
  }
  lookupTypes() {
    return (
      <div className="generic-flex">
        <div>
          <input
            type="radio"
            name="lookupType"
            value={1}
            checked={this.state.lookupType == 1}
            onChange={this.handleChange}
          />
          <label className="choice-label" onClick={() => this.setLookupType(1)}>
            By Phone
          </label>
        </div>
        <div className="left-10">
          <input
            type="radio"
            name="lookupType"
            value={2}
            checked={this.state.lookupType == 2}
            onChange={this.handleChange}
          />
          <label className="choice-label" onClick={() => this.setLookupType(2)}>
            By Name
          </label>
        </div>
        <div className="left-10">
          <input
            type="radio"
            name="lookupType"
            value={3}
            checked={this.state.lookupType == 3}
            onChange={this.handleChange}
          />
          <label className="choice-label" onClick={() => this.setLookupType(3)}>
            By Lease ID
          </label>
        </div>
      </div>
    );
  }
  lookupForm() {
    const clsName = this.state.lookupBtnDisabled
      ? "disabled-lightgreen-btn"
      : "lightgreen-btn";
    const reqLabel = (
      <label>
        (<font color="red">*</font>)
      </label>
    );

    const lookupType = this.state.lookupType;
    const inStoreLookup =
      lookupType == 3 && this.props.inStoreOrder ? true : false;

    const ssnReq = inStoreLookup ? "" : reqLabel;

    return (
      <div>
        {this.props.inStoreOrder ? (
          <div>
            Enter SSN+Phone Number, SSN+Last Name+Birth Date, or Lease ID to
            lookup the lease:
          </div>
        ) : (
          <div>
            <hr className="hr-240" align="left" />
            <b>Already been approved?</b> You can find it by phone, name, or
            lease ID (Progressive Leasing account number):
          </div>
        )}

        {this.lookupTypes()}
        <ValidatorForm ref="form" onSubmit={this.lookupLease}>
          <div className="tbl-container3">
            <div>Last 4 SSN{ssnReq}: </div>
            <div className="left-10">
              {inStoreLookup ? (
                <input
                  type="text"
                  className="password-mask"
                  type="tel"
                  size="5"
                  name="last4SSN"
                  value={this.state.last4SSN}
                  onChange={this.handleChange}
                />
              ) : (
                <TextInput
                  className="password-mask"
                  type="tel"
                  size="5"
                  name="last4SSN"
                  value={this.state.last4SSN}
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["SSN is required"]}
                />
              )}
            </div>
            {lookupType == 1 && (
              <React.Fragment>
                <div>Phone Number{reqLabel}: </div>
                <div className="left-10">
                  <TextInput
                    type="tel"
                    size="15"
                    name="phoneNumber"
                    placeholder="Numbers only"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Phone Number is required"]}
                  />
                </div>
              </React.Fragment>
            )}
            {lookupType == 2 && (
              <React.Fragment>
                <div>Last Name{reqLabel}: </div>
                <div className="left-10">
                  <TextInput
                    type="text"
                    size="20"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Last Name is required"]}
                  />
                </div>
                <div>Brith Date{reqLabel}: </div>
                <div className="generic-flex-l10">
                  <div>
                    <select
                      name="birthMonth"
                      onChange={this.handleChange}
                      value={this.state.birthMonth}
                    >
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    &nbsp;&nbsp;
                  </div>

                  <TextInput
                    type="text"
                    size="5"
                    name="birthYear"
                    value={this.state.birthYear || ""}
                    placeholder="Year"
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Year is required"]}
                  />
                </div>
              </React.Fragment>
            )}
            {lookupType == 3 && (
              <React.Fragment>
                <div>Lease ID{reqLabel}:</div>
                <div className="left-10">
                  <TextInput
                    size="15"
                    name="leaseId"
                    value={this.state.leaseId}
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Lease ID is required"]}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="generic-flex">
            <button
              className={clsName}
              disabled={this.state.lookupBtnDisabled}
              type="submit"
              name="submit"
            >
              Lookup Lease Approval
            </button>
            {this.state.waitingForLeaseInfo && (
              <div className="left-10">
                <img src={getIconBaseUrl() + "working.gif"} height="30" />
              </div>
            )}
          </div>
        </ValidatorForm>
      </div>
    );
  }
  leaseEstimateInfo(info) {
    if (info) {
      const clsName = this.state.mobileMode ? "left-60" : "left-10";
      const wrpClsName = this.state.mobileMode ? "none" : "twocol-form";

      return (
        <div className="top-10">
          The following estimate is based on {info.paymentFrequency} payment
          frequency. The minimum required "Approval Limit" for this purchase is{" "}
          {toAmountEx(this.state.orderTotal)}. If you approval limit is less
          than that amount, you can pay the balance by credit/debit card or
          cash.
          <p />
          <div className={wrpClsName}>
            <div>Cash Price:</div>
            <div className={clsName}>{toAmountEx(this.state.orderTotal)}</div>
            <div>Initial Pmt Amount:</div>
            <div className={clsName}>{toAmountEx(info.initialPayment)}</div>
            <div>Periodic Pmt Amount:</div>
            <div className={clsName}>{toAmountEx(info.periodicPayment)}</div>
            <div>Payment Frequency:</div>
            <div className={clsName}>{info.paymentFrequency}</div>
            <div>Number of Payments:</div>
            <div className={clsName}>{info.numberOfPayments}</div>
            <div>Lease-to-own Cost:</div>
            <div className={clsName}>{toAmountEx(info.costOfRental)}</div>
            <div>12-Month Lease-to-own Total:</div>
            <div className={clsName}>{toAmountEx(info.totalLeasePrice)}</div>
          </div>
        </div>
      );
    }
    return "";
  }
  formatDialogTitle(title, error) {
    const img = error ? "error.jpg" : "prog-web-05-Prog-Mark-Icon-Bl02.jpg";
    return (
      <div>
        <img src={getIconBaseUrl() + img} height="18" />
        &nbsp;&nbsp;{title}
      </div>
    );
  }
  setLeaseEstimateDialogInfo(theInfo) {
    const title = this.state.mobileMode
      ? "Lease Estimate"
      : "Lease Payments Estimate";
    const dialogTitle = this.formatDialogTitle(title);

    this.setState({
      dialogTitle,
      dialogMessage: this.leaseEstimateInfo(theInfo),
    });
  }

  handleScript(e) {
    const snippetState = e.type === "load" ? "ready" : "error";
    this.setState({ snippetState });
    if (this.state.script) {
      this.state.script.removeEventListener("load", this.handleScript);
      this.state.script.removeEventListener("error", this.handleScript);
    }
  }
  loadEstimatorSnippet(url) {
    let script = document.querySelector(`script[src="${url}"]`);

    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      this.setState({ script });
      script.addEventListener("load", this.handleScript);
      script.addEventListener("error", this.handleScript);
    }
  }
  paymentEstimator() {
    const setting = this.state.setting;
    let useSnippet = false;
    let state = null;

    if (setting) {
      if (this.state.mobileMode) {
        useSnippet = setting.useMobileSnippet;
      } else {
        useSnippet = setting.useEstimatorSnippet;
      }
      if (useSnippet) state = this.state.snippetState;
    }
    return (
      <div className="nodec-div">
        <i>
          Standard agreement offers 12 months to ownership and could cost more
          than double the cash price.&nbsp;
        </i>
        {useSnippet && state && (
          <label> Click Learn More to estimate your payments.</label>
        )}
        <br />
        <div className="generic-flex">
          {state === "ready" ? (
            <pg-pdp
              placement="right"
              price={this.state.orderTotal}
              state-code="GA"
            ></pg-pdp>
          ) : (
            <div>
              <label className="alabel" onClick={this.getLeaseEstimateCosts}>
                Estimate Lease Payments
              </label>
            </div>
          )}
          {this.state.waitingForEstimate && (
            <div className="left-10">
              <img src={getIconBaseUrl() + "working.gif"} height="25" />
            </div>
          )}
        </div>
      </div>
    );
  }
  applyInfo() {
    if (this.props.inStoreOrder) {
      return (
        <div>
          {this.paymentEstimator()}
          <hr className="hr-300" align="left" />
        </div>
      );
    } else {
      const { noCreditOnlineUrl } = window["runConfig"];
      let applyUrl =
        this.state.setting && this.state.setting.applyUrl
          ? this.state.setting.applyUrl
          : noCreditOnlineUrl;
      return (
        <div>
          {this.paymentEstimator()}
          <div className="top-10">
            If you haven't been approved by Progressive for the leasing, please
            click the button below to apply. After approved, come back here to
            continue.
          </div>
          <div className="top-10">
            <a href={applyUrl} target="_blank">
              <img
                src={getIconBaseUrl() + "prog-web-09-296x60-Apply-Now-Bl02.jpg"}
                height="40"
              />
            </a>
          </div>
        </div>
      );
    }
  }
  leaseInfoAndForm() {
    return (
      <div className="left10-top10">
        {this.applyInfo()}
        {this.lookupForm()}
      </div>
    );
  }
  setOlPmtMethod(method) {
    this.setState({ olPmtMethod: method });
  }
  overLimitPayment() {
    const res = this.state.leaseLookupResult;
    if (res && res.exceededLimit) {
      if (
        isInStore() ||
        (res.setting && (res.setting.allowCod || res.setting.allowCreditCard))
      ) {
        const allowCod = isInStore() || res.setting.allowCod ? true : false;
        const allowCreditCard =
          !isInStore() && res.setting.allowCreditCard ? true : false;
        const allowInStorePmt = isInStore() ? true : false;
        const amt = toAmountEx(res.balance);

        let message;
        if (allowCod && allowCreditCard) {
          message = (
            <span>
              With taxes, your balance is <b>{amt}</b>. You can either pay it
              using credit/debit card or pay cash on delivery.
            </span>
          );
        } else if (allowCod && allowInStorePmt) {
          message = (
            <span>
              With taxes, the balance is <b>{amt}</b>. Please either select "Pay
              COD" or "Pay in store" option.
            </span>
          );
        } else if (allowCod) {
          message = (
            <span>
              With taxes, your balance is <b>{amt}</b>. You can pay cash on
              delivery.
            </span>
          );
        } else {
          message = (
            <span>
              With taxes, your balance is <b>{amt}</b>. You can pay it using
              credit/debit card.
            </span>
          );
        }
        return (
          <div>
            <div className="bottom-5">{message}</div>
            {allowCod && (
              <React.Fragment>
                <input
                  type="radio"
                  name="olPmtMethod"
                  value="COD"
                  checked={this.state.olPmtMethod == "COD"}
                  onChange={this.handleChange}
                />{" "}
                <label
                  className="choice-label"
                  onClick={() => this.setOlPmtMethod("COD")}
                >
                  Pay {amt} by cash on delivery
                </label>
                <br />
              </React.Fragment>
            )}
            {allowCreditCard && (
              <React.Fragment>
                <input
                  type="radio"
                  name="olPmtMethod"
                  value="CreditCard"
                  checked={this.state.olPmtMethod == "CreditCard"}
                  onChange={this.handleChange}
                />{" "}
                <label
                  className="choice-label"
                  onClick={() => this.setOlPmtMethod("CreditCard")}
                >
                  Pay {amt} by credit/debit card
                </label>
              </React.Fragment>
            )}
            {allowInStorePmt && (
              <React.Fragment>
                <input
                  type="radio"
                  name="olPmtMethod"
                  value="InStorePayment"
                  checked={this.state.olPmtMethod == "InStorePayment"}
                  onChange={this.handleChange}
                />{" "}
                <label
                  className="choice-label"
                  onClick={() => this.setOlPmtMethod("InStorePayment")}
                >
                  Pay {amt} in store
                </label>
              </React.Fragment>
            )}
          </div>
        );
      }
    }
  }
  overLimitCreditCardPayment() {
    const res = this.state.leaseLookupResult;
    if (
      res &&
      res.exceededLimit &&
      res.setting &&
      res.setting.allowCreditCard &&
      this.state.olPmtMethod == "CreditCard"
    ) {
      let pmtChoices = [];
      pmtChoices.push({
        name: "Balance",
        displayName: "Balance",
        amount: res.balance,
        userId: this.props.userId,
        delivery: true,
      });
      return (
        <div className="left-10">
          <hr className="hr-240" align="left" />
          <CreditCardEx
            amountChoiceList={pmtChoices}
            useBillingAddress={true}
            billingAddrRequired={false}
            validateCCNumber={false}
            submitBtnTitle="Submit Order"
            actionHandler={this.submitPaymentOrder}
          />
        </div>
      );
    }
  }
  inStorePayment() {
    const res = this.state.leaseLookupResult;
    if (
      res &&
      res.exceededLimit &&
      this.state.olPmtMethod == "InStorePayment"
    ) {
      const order = this.state.orderInfo.order;
      let nameOnCard = "";
      if (order && order.contactInfo) {
        nameOnCard =
          order.contactInfo.firstName + " " + order.contactInfo.lastName;
      }
      return (
        <div className="left-10">
          <hr className="hr-240" align="left" />
          <InStorePayment
            amount={res.balance}
            amtChangeable={false}
            nameOnCard={nameOnCard}
            waitingForOrder={this.state.waitingForOrder}
            actionHandler={this.submitStorePmtOrder}
          />
        </div>
      );
    }
  }
  messageForUser(lookupResult) {
    if (this.props.disableSubmitButton) {
      return (
        <div>
          Below is the lease and payment details, please review the information
          carefully.
        </div>
      );
    } else {
      if (lookupResult && lookupResult.exceededLimit) {
        return (
          <div dangerouslySetInnerHTML={{ __html: lookupResult.message }} />
        );
      } else {
        return (
          <div>
            Below is your lease and payment details. Please review the
            information carefully, then click the "Submit Order" button. Once
            clicked and order successfully verified, you will be prompted to
            e-sign the lease agreement to finish your order.&nbsp;
          </div>
        );
      }
    }
  }
  leaseAndPaymentInfo() {
    const res = this.state.leaseLookupResult;
    const setting = res.setting;
    const info = res.leasePriceInfo;
    const label = res.exceededLimit ? "Approval limit" : "Cash price";
    return (
      <div>
        {this.messageForUser(res)}
        <div className="tbl-progressive">
          <div>Lease Id:</div>
          <div className="left-10">{info.leaseId}</div>
          <div>{label}:</div>
          <div className="left-10">{toAmountEx(info.cashPrice)}</div>
          <div>Lease-to-own cost:</div>
          <div className="left-10">{toAmountEx(info.costOfRental)}</div>
          <div>12-month lease-to-own total:</div>
          <div className="left-10">{toAmountEx(info.totalLeasePrice)}</div>
          {setting.show90Day && (
            <React.Fragment>
              <div>90-day purchase option:</div>
              <div className="left-10">{toAmountEx(info.ninetyDayBuyout)}</div>
            </React.Fragment>
          )}
          <div>Initial payment due today:</div>
          <div className="left-10">{toAmountEx(info.initialPayment)}</div>
        </div>
        <div className="top-5">
          <i>
            Applicable taxes will be added to all payments collected by
            Progressive Leasing
          </i>
        </div>
      </div>
    );
  }
  disclaimer90Day() {
    const res = this.state.leaseLookupResult;
    if (
      !this.props.disableSubmitButton &&
      res.setting &&
      res.setting.show90Day
    ) {
      return (
        <div className="disclaimer-container">
          Disclaimer:{" "}
          <i>
            Standard agreement offers 12 months to ownership. 90-day purchase
            options cost more than the retailer's cash price (except 3-month
            option in CA). To purchase early call 877-898-1970.
          </i>
        </div>
      );
    }
  }
  submitOrderForm() {
    const result = this.state.leaseLookupResult;
    let submitDisabled = this.state.submitBtnDisabled;
    let submitHidden = false;

    if (result.exceededLimit) {
      if (!this.state.olPmtMethod) {
        submitDisabled = true;
      }
      if (
        this.state.olPmtMethod == "CreditCard" ||
        this.state.olPmtMethod == "InStorePayment"
      ) {
        submitHidden = true;
      }
    }
    const btnStyle = submitDisabled
      ? "disabled-lightgreen-btn"
      : "lightgreen-btn";

    return (
      <div className="progressive-container">
        {this.leaseAndPaymentInfo()}
        {this.overLimitPayment()}
        {this.overLimitCreditCardPayment()}
        {this.inStorePayment()}
        {!this.props.disableSubmitButton && !submitHidden && (
          <div className="top-10">
            <div className="generic-flex">
              <button
                name="submitOrder"
                className={btnStyle}
                onClick={this.submitOrder}
                disabled={submitDisabled}
              >
                Submit Order
              </button>
              {this.state.waitingForOrder && (
                <div className="left-10">
                  <img src={getIconBaseUrl() + "working.gif"} height="30" />
                </div>
              )}
            </div>
          </div>
        )}
        {this.disclaimer90Day()}
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.state.leaseLookupResult
          ? this.submitOrderForm()
          : this.leaseInfoAndForm()}
        <DialogBox
          hidden={true}
          btnId="hdnDialogBoxBtn"
          buttonTitle="Hidden"
          buttonClsName="btn-style"
          title={this.state.dialogTitle}
          message={this.state.dialogMessage}
          hideCancel={true}
        />
        <DialogBox
          hidden={true}
          btnId="hdnMismatchBoxBtn"
          buttonTitle="Hidden"
          buttonClsName="btn-style"
          title="You Lease Approval Has Been Found"
          message={this.state.addrMismatchMessage}
          hideCancel={false}
          okLabel="Yes"
          cancelLabel="No, Choose Another Payment Method"
          actionHandler={this.replaceAddress}
          hdnBtnId="mismatchHdnBtn"
        />
        <DialogBox
          hidden={true}
          btnId="hdnNameMismatchBoxBtn"
          buttonTitle="Hidden"
          buttonClsName="btn-style"
          title="Name Mismatch"
          message={this.state.nameMismatchMessage}
          hideCancel={false}
          okLabel="Yes"
          cancelLabel="No"
          actionHandler={this.replaceNames}
          hdnBtnId="nameMismatchHdnBtn"
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    contactInfo: state.order.contactInfo,
    deliveryInfo: state.order.deliveryInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setProgressiveInfo: (progInfo) => {
      dispatch(setProgressiveInfo(progInfo));
    },
    setDeliveryInfo: (info) => {
      dispatch(setDeliveryInfo(info));
    },
    setContactInfo: (info) => {
      dispatch(setContactInfo(info));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProgressiveLeasing);
