import log from "loglevel";
import remote from "loglevel-plugin-remote";

const { loggerApiUrl } = window["runConfig"];

const customJSON = log => ({
  msg: log.message,
  level: log.level.label,
  stacktrace: log.stacktrace
});

remote.apply(log, { format: customJSON, url: loggerApiUrl });
log.enableAll();
