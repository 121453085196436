import { REGISTER_WEBORDER, CLEAR_WEBORDER } from "./actionTypes";

export const registerWebOrder = (payload) => {
  return {
    type: REGISTER_WEBORDER,
    payload,
  };
};
export const clearWebOrder = () => {
  return {
    type: CLEAR_WEBORDER,
  };
};
