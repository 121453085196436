import { REGISTER_WEBORDER, CLEAR_WEBORDER } from "../actions/actionTypes";
import { initWebOrderState } from "../State";

const webOrderReducer = (state = initWebOrderState, action) => {
  if (action.type === REGISTER_WEBORDER) {
    return {
      ...state,
      userId: action.payload.userId,
      storeId: action.payload.storeId,
      storeName: action.payload.storeName,
      authToken: action.payload.authToken,
      kiosk: action.payload.kiosk,
    };
  } else if (action.type === CLEAR_WEBORDER) {
    return initWebOrderState;
  }
  return state;
};

export default webOrderReducer;
