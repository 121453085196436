import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getIconBaseUrl } from "./Util";

import "../navBar.css";
import "../hammenu.css";

class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    document.documentElement.style.setProperty(
      "--hammenu-display-mode",
      "none"
    );
  }
  handleClick(url) {
    document.getElementById("hiddenCB").click();
    if (url !== null) {
      this.props.history.push(url);
    }
  }
  handleChange(event) {
    const mode = event.target.checked ? "normal" : "none";
    document.documentElement.style.setProperty("--hammenu-display-mode", mode);
  }
  accountMenu() {
    const acctIcon = getIconBaseUrl() + "menu_user.jpg";
    const logoutIcon = getIconBaseUrl() + "logout.jpg";
    const url = this.props.isLoggedIn ? "/myAccount" : "/login";
    const name = this.props.userInfo
      ? this.props.userInfo.firstName
      : "Customer";
    const title = this.props.isLoggedIn ? "Hello, " + name : "Login/Register";
    return (
      <React.Fragment>
        <li>
          <div className="flex-wrapper">
            <div>
              <img src={acctIcon} height="20" alt="Account"></img>
            </div>
            <div>
              <a onClick={() => this.handleClick(url)} href="#">
                {title}
              </a>
            </div>
          </div>
        </li>
        {this.props.isLoggedIn && (
          <li>
            <div className="flex-wrapper">
              <div>
                <img src={logoutIcon} height="20" alt="Logout"></img>
              </div>
              <div>
                <a onClick={() => this.handleClick("/logout")} href="#">
                  Logout
                </a>
              </div>
            </div>
          </li>
        )}
      </React.Fragment>
    );
  }
  render() {
    const { virtualTourUrl } = window["runConfig"];
    const baseUrl = getIconBaseUrl();
    const locIcon = baseUrl + "location.png";
    const contactIcon = baseUrl + "contactUs.jpg";
    const layawayIcon = baseUrl + "layaway.jpg";
    const noCreditIcon = baseUrl + "prog-web-05-Prog-Mark-Icon-Bl02.jpg";
    const specialsIcon = baseUrl + "specials.jpg";
    const aboutIcon = baseUrl + "aboutUs.png";
    const warantyIcon = baseUrl + "waranty.jpg";
    const virtualTourIcon = baseUrl + "virtualTour.png";
    const newIcon = baseUrl + "NewArrival.jpg";

    return (
      <div id="menuToggle">
        <input type="checkbox" id="hiddenCB" onChange={this.handleChange} />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu" align="left">
          {this.accountMenu()}
          {this.props.newArrivalCount && this.props.newArrivalCount > 0 && (
            <li>
              <div className="flex-wrapper">
                <div>
                  <img src={newIcon} height="20" alt="New Arrivals"></img>
                </div>
                <div>
                  <a
                    onClick={() =>
                      this.handleClick("/product-category/newarrivals")
                    }
                    href="#"
                  >
                    New Arrivals
                  </a>
                </div>
              </div>
            </li>
          )}
          <li>
            <div className="flex-wrapper">
              <div>
                <img src={aboutIcon} height="20" alt="About Us"></img>
              </div>
              <div>
                <a onClick={() => this.handleClick("/aboutUs")} href="#">
                  About Us
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="flex-wrapper">
              <div>
                <img src={contactIcon} height="20" alt="Contact Us"></img>
              </div>
              <div>
                <a onClick={() => this.handleClick("/contactUs")} href="#">
                  Contact Us
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="flex-wrapper">
              <div>
                <img src={warantyIcon} height="20" alt="Waranty"></img>
              </div>
              <div>
                <a onClick={() => this.handleClick("/returnPolicy")} href="#">
                  Waranty & Return
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="flex-wrapper">
              <div>
                <img src={locIcon} height="20" alt="LocMap"></img>
              </div>
              <div>
                <a onClick={() => this.handleClick("/locationsMap")} href="#">
                  Location Map
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="flex-wrapper">
              <div>
                <img src={layawayIcon} height="20" alt="Layaway"></img>
              </div>
              <div>
                <a onClick={() => this.handleClick("/layaway")} href="#">
                  Layaway
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="flex-wrapper">
              <div>
                <img src={noCreditIcon} height="20" alt="Lease to Own"></img>
              </div>
              <div>
                <a onClick={() => this.handleClick("/leaseToOwn")} href="#">
                  Lease-to-Own
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="flex-wrapper">
              <div>
                <img src={specialsIcon} height="20" alt="Specials"></img>
              </div>
              <div>
                <a
                  onClick={() => this.handleClick("/product-category/specials")}
                  href="#"
                >
                  Specials
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="flex-wrapper">
              <div>
                <img src={virtualTourIcon} height="20" alt="Virtual Tour"></img>
              </div>
              <div>
                <a href={virtualTourUrl} target="VirtualTour">
                  Virtual Tour
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    newArrivalCount: state.cache.newArrivalCount,
    isLoggedIn: state.user.isLoggedIn,
    userInfo: state.user.userInfo,
  };
};
export default connect(mapStateToProps)(withRouter(HamburgerMenu));
