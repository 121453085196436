import React from "react";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "../App.css";
import "../form.css";

class ActionConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleYes = this.handleYes.bind(this);
  }

  showConfirmation() {
    document.getElementById("showDialogBtn").click();
  }
  openDialog(event) {
    event.preventDefault();
    this.setState({ showDialog: true });
  }
  closeDialog() {
    this.setState({ showDialog: false });
  }

  hiddenActionButton() {
    return (
      <button
        id="showDialogBtn"
        onClick={this.openDialog}
        style={{ display: "none" }}
      ></button>
    );
  }
  handleYes() {
    this.closeDialog();
    this.props.actionHandler();
  }
  confirmationDialog() {
    return (
      <div>
        {this.hiddenActionButton()}
        <Dialog
          isOpen={this.state.showDialog}
          onDismiss={this.closeDialog}
          aria-label="Confirmation"
        >
          <b>{this.props.title}</b>
          {this.props.html ? (
            <div
              className="top-5"
              dangerouslySetInnerHTML={{ __html: this.props.message }}
            ></div>
          ) : (
            <p>{this.props.message}</p>
          )}
          <p />
          <button onClick={this.handleYes} className="btn-style">
            Yes
          </button>
          &nbsp;&nbsp;
          <button onClick={this.closeDialog} className="btn-style">
            Cancel
          </button>
        </Dialog>
      </div>
    );
  }

  render() {
    const clsName = this.props.buttonClsName
      ? this.props.buttonClsName
      : "btn-style";
    return (
      <div>
        {this.props.hidden ? (
          <button
            id={this.props.btnId}
            className={clsName}
            onClick={this.showConfirmation}
            style={{ display: "none" }}
          >
            {this.props.buttonTitle}
          </button>
        ) : (
          <button className={clsName} onClick={this.showConfirmation}>
            {this.props.buttonTitle}
          </button>
        )}
        {this.confirmationDialog()}
      </div>
    );
  }
}
export default ActionConfirmation;
