import React from "react";
import { connect } from "react-redux";
import ProductListView from "../product/ProductListView";
import { setTopContainerWrapperSettings } from "../Util";
import "../../tables.css";
import "../../orderComplete.css";
import { isInStore } from "./storeUtil";
import { ORDERSTAGE_COMPLETE } from "../order/orderStages";
import { setOrderStage } from "../actions/orderActions";
import PrintOrder from "./PrintOrder";

class StoreLayawayComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderNumber: "",
      orderEmail: "",
      orderCompleteMsg: null,
    };
    this.adjustSettings = this.adjustSettings.bind(this);
  }

  componentDidMount() {
    if (this.props.orderNumber && isInStore()) {
      this.adjustSettings();
      window.addEventListener("resize", this.adjustSettings);

      this.setState({
        orderNumber: this.props.orderNumber,
        orderEmail: this.props.orderEmail,
        orderCompleteMsg: this.props.orderCompleteMsg,
      });
      this.props.setOrderStage(ORDERSTAGE_COMPLETE);
    } else {
      this.props.history.push("/");
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
  }

  adjustSettings() {
    setTopContainerWrapperSettings();
  }

  displayNote() {
    const backendApi = "OrderRelatedProd/" + this.state.orderNumber;
    const title = "Related Items";

    return (
      <div className="oc-container">
        <div className="oc-paid-wrapper">
          <div className="oc-paid-desc" align="left">
            <b>
              <font size="4" color="green">
                The layaway order was received.
              </font>
            </b>
            <p />
            <div>
              The order number is: <b>{this.state.orderNumber}</b>.
              {this.state.orderEmail && (
                <span>
                  &nbsp;&nbsp;A confirmation email was sent to{" "}
                  {this.state.orderEmail}, please ask the customer to check
                  email for acuracy.
                </span>
              )}
              <p />
            </div>
            <PrintOrder orderNumber={this.state.orderNumber} isLayaway={true} />
          </div>
        </div>
        <ProductListView backendApi={backendApi} title={title} />
      </div>
    );
  }

  render() {
    if (this.state.orderNumber === "") return "Loading ...";

    return <div className="top-wrapper">{this.displayNote()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    orderNumber: state.order.orderNumber,
    orderEmail: state.order.orderEmail,
    storeId: state.webOrder.storeId,
    storeName: state.webOrder.storeName,
    kiosk: state.webOrder.kiosk,
    orderCompleteMsg: state.order.orderCompleteMsg,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOrderStage: (stage) => {
      dispatch(setOrderStage(stage));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreLayawayComplete);
