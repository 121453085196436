import {
  SP_ADD_TO_CART,
  SP_REMOVE_ITEM,
  SP_SUB_QUANTITY,
  SP_ADD_QUANTITY,
  SP_CLEAR_CART,
} from "../actions/actionTypes";
import { initSPCartState } from "../State";

const spCartReducer = (state = initSPCartState, action) => {
  if (action.type === SP_ADD_TO_CART) {
    //check if the action id exists in the addedItems
    const found = state.storeItems.find(
      (item) => action.payload.sku === item.sku
    );
    let newState = state;
    const newItemCount =
      action.payload.coupon ||
      (action.payload.typeId && action.payload.typeId > 0)
        ? state.itemCount
        : state.itemCount + 1;

    if (found) {
      if (!action.payload.typeId || action.payload.typeId <= 0) {
        found.quantity += 1;
        newState = {
          ...state,
          itemCount: newItemCount,
          subtotal: state.subtotal + found.finalPrice,
        };
      } else {
        //more deposit with the same payment method
        found.price += action.payload.finalPrice;
        found.amount += action.payload.finalPrice;
      }
    } else {
      const addedItem = {
        product: action.payload,
        productId: action.payload.id,
        sku: action.payload.sku,
        title: action.payload.name,
        name: action.payload.name,
        imageUrl: action.payload.imageUrl,
        quantity: 1,
        price: action.payload.finalPrice,
        coupon: action.payload.coupon,
        typeId: action.payload.typeId,
        nameOnCard: action.payload.nameOnCard,
        last4: action.payload.last4,
        amount: action.payload.amount,
        approvalCode: action.payload.approvalCode,
        datFieldName: action.payload.datFieldName,
        layaway: action.payload.layaway,
      };
      const newTotal = state.subtotal + addedItem.finalPrice;

      newState = {
        ...state,
        userId: action.userId,
        storeItems: [...state.storeItems, addedItem],
        itemCount: newItemCount,
        subtotal: newTotal,
      };
    }
    return newState;
  } else if (action.type === SP_REMOVE_ITEM) {
    const itemToRemove = state.storeItems.find(
      (item) => action.sku === item.sku
    );
    let newState;

    if (state.storeItems.length <= 1 && itemToRemove) {
      newState = initSPCartState;
    } else {
      const new_items = state.storeItems.filter(
        (item) => action.sku !== item.sku
      );
      const newTotal =
        state.subtotal - itemToRemove.finalPrice * itemToRemove.quantity;
      const newItemCount =
        itemToRemove.coupon || (itemToRemove.typeId && itemToRemove.typeId > 0)
          ? state.itemCount
          : state.itemCount - itemToRemove.quantity;

      newState = {
        ...state,
        storeItems: new_items,
        itemCount: newItemCount,
        subtotal: newTotal,
      };
    }
    return newState;
  } else if (action.type === SP_CLEAR_CART) {
    const emptyState = {
      ...initSPCartState,
      storeItems: [],
    };
    return emptyState;
  } else if (action.type === SP_ADD_QUANTITY) {
    const addedItem = state.storeItems.find((item) => item.sku === action.sku);
    addedItem.quantity += 1;
    const newTotal = state.subtotal + addedItem.finalPrice;
    const newItemCount = state.itemCount + 1;

    const newState = {
      ...state,
      itemCount: newItemCount,
      subtotal: newTotal,
    };
    return newState;
  } else if (action.type === SP_SUB_QUANTITY) {
    const addedItem = state.storeItems.find((item) => item.sku === action.sku);
    let newState;
    const newItemCount = state.itemCount - 1;

    if (addedItem.quantity === 1) {
      const new_items = state.storeItems.filter(
        (item) => item.sku !== action.sku
      );
      const newTotal = state.subtotal - addedItem.finalPrice;

      newState = {
        ...state,
        storeItems: new_items,
        itemCount: newItemCount,
        subtotal: newTotal,
      };
    } else {
      addedItem.quantity -= 1;
      if (addedItem.quantity === 0) {
        const itemToRemove = state.storeItems.find(
          (item) => action.sku === item.sku
        );
        const new_items = state.storeItems.filter(
          (item) => action.sku !== item.sku
        );

        const newTotal =
          state.subtotal - itemToRemove.finalPrice * itemToRemove.quantity;

        newState = {
          ...state,
          storeItems: new_items,
          itemCount: newItemCount,
          subtotal: newTotal,
        };
      } else {
        let newTotal = state.subtotal - addedItem.finalPrice;
        newState = {
          ...state,
          itemCount: newItemCount,
          subtotal: newTotal,
        };
      }
      return newState;
    }
  }

  return state;
};

export default spCartReducer;
