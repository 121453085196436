import React from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { AXIOS_HEADER } from "../config/constants";
import { setUserId, setUserInfo } from "../actions/userActions";
import {
  apiBaseUrl,
  isMobileMode,
  setTopContainerWrapperSettings,
  getIconBaseUrl,
} from "../Util";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "../validation/TextInput";
import log from "loglevel";
import "../myLogger";
import "../../contact.css";
import "../../info.css";

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "GA",
      zipCode: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      stateList: null,
      errMessage: "",
      emailError: "",
      hasAll: false,
      expanded: false,
      showSignIn: false,
      mobileMode: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchStateList = this.fetchStateList.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.adjustSettings = this.adjustSettings.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    const info = this.props.userInfo;
    if (info) {
      let hasAll = false;
      if (
        info.firstName &&
        info.lastName &&
        info.email &&
        info.addressLine1 &&
        info.city &&
        (info.state || info.zipCode)
      ) {
        hasAll = true;
      }
      this.setState({
        firstName: info.firstName,
        lastName: info.lastName,
        phone: info.phone,
        email: info.email,
        addressLine1: info.addressLine1,
        addressLine2: info.addressLine2,
        city: info.city,
        state: info.state,
        zipCode: info.zipCode,
        hasAll,
      });
      if (info.email && info.email.indexOf("@") > 0) {
        this.checkEmail(info.email);
      }
    }
    this.adjustSettings();
    window.addEventListener("resize", this.adjustSettings);
    this.fetchStateList();
  }
  fetchStateList() {
    const url = apiBaseUrl() + "StatesForContact";
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url, { headers: AXIOS_HEADER })
      .then((res) => {
        this.setState({ stateList: res.data });
      })
      .catch((error) => {
        log.error(error);
      });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustSettings);
    this.setState({ errMessage: "" });
  }
  adjustSettings() {
    setTopContainerWrapperSettings();
    this.setState({ mobileMode: isMobileMode() });
  }
  handleChange(event) {
    event.preventDefault();

    const name = event.target.name;
    let errMessage = this.state.errMessage;
    let emailError = this.state.emailError;

    if (name === "email") emailError = null;
    else errMessage = null;

    this.setState({
      [name]: event.target.value,
      errMessage,
      emailError,
    });
  }
  processResponse(response) {
    if (response.status) {
      this.props.setUserId(this.state.email);
      this.props.setUserInfo(response.contactAddr);
      if (
        this.props.match.params.purpose &&
        this.props.match.params.purpose === "checkout"
      ) {
        this.props.history.push("/shippingChoice");
      } else {
        this.props.history.push("/verifyAccount");
      }
    } else {
      this.setState({ errorMessage: response.errMessage });
    }
  }
  handleSubmit(event) {
    event.preventDefault();

    if (this.state.password != this.state.confirmPassword) {
      this.setState({ errorMessage: "The two passwords don't match!" });
      return;
    }
    const url = apiBaseUrl() + "CreateAccount";
    axiosRetry(axios, { retries: 3 });
    axios
      .post(url, this.state, { headers: AXIOS_HEADER })
      .then((res) => {
        this.processResponse(res.data);
      })
      .catch((error) => {
        log.error(error);
      });
  }
  showStateList() {
    if (this.state.stateList === null) return "Georgia";

    return (
      <select
        name="state"
        value={this.state.state}
        onChange={this.handleChange}
      >
        {this.state.stateList.map((pair, idx) => {
          return (
            <option value={pair.value} key={idx}>
              {pair.key}
            </option>
          );
        })}
      </select>
    );
  }

  showErrMessage() {
    if (this.state.errMessage || this.state.emailError) {
      const msg = this.state.errMessage
        ? this.state.errMessage
        : this.state.emailError;
      return (
        <div className="ct-title">
          <br />
          <font color="red">{msg}</font>&nbsp;&nbsp;
          {this.state.emailError && this.state.showSignIn && (
            <Link to="/login"> Sign in</Link>
          )}
          <p />
        </div>
      );
    }
  }
  addressForm() {
    const txtLen = this.props.android ? 20 : 30;
    const numType = this.state.useHTML5 ? "number" : "text";
    return (
      <React.Fragment>
        <div className="cttbl-item-left">Address:</div>
        <div className="cttbl-item-left">
          <input
            type="text"
            name="addressLine1"
            size={txtLen}
            value={this.state.addressLine1}
            onChange={this.handleChange}
          />
        </div>
        <div className="cttbl-item-left">Address Line2:</div>
        <div className="cttbl-item-left">
          <input
            type="text"
            name="addressLine2"
            size={txtLen}
            placeholder="(Apartment number, etc.)"
            value={this.state.addressLine2}
            onChange={this.handleChange}
          />
        </div>
        <div className="cttbl-item-left">City:</div>
        <div className="cttbl-item-left">
          <input
            type="text"
            name="city"
            value={this.state.city}
            onChange={this.handleChange}
          />
        </div>
        <div className="cttbl-item-left">State:</div>
        <div className="cttbl-item-left">{this.showStateList()}</div>
        <div className="cttbl-item-left">ZIP Code:</div>
        <div className="cttbl-item-left">
          <input
            type={numType}
            name="zipCode"
            size="10"
            value={this.state.zipCode}
            onChange={this.handleChange}
          />
        </div>
      </React.Fragment>
    );
  }
  checkEmail(email) {
    const emailAddr = email ? email : this.state.email;

    if (emailAddr && emailAddr.indexOf("@") > 0) {
      const url = apiBaseUrl() + "CheckEmail";
      axiosRetry(axios, { retries: 3 });
      axios
        .post(url, emailAddr, { headers: AXIOS_HEADER })
        .then((res) => {
          if (!res.data) {
            const emailError = email
              ? "You already have an account."
              : "The email address is already registered!";
            this.setState({
              emailError,
              showSignIn: email !== null,
            });
            try {
              if (email && this.props.registeredHandler) {
                this.props.registeredHandler();
              }
            } catch (errore) {}
          }
        })
        .catch((error) => {
          log.error(error);
        });
    }
  }
  handleClick(event) {
    this.setState({ expanded: !this.state.expanded });
  }
  showExpandShrink() {
    if (this.state.hasAll) {
      const icon = this.state.expanded ? "minus.jpg" : "plus.jpg";
      return (
        <React.Fragment>
          <div className="cttbl-item-left">
            <img
              src={getIconBaseUrl() + icon}
              width="16"
              onClick={this.handleClick}
              alt="PM"
            ></img>
            &nbsp;Contact Info
          </div>
          <div>&nbsp;</div>
        </React.Fragment>
      );
    }
  }
  theForm() {
    const txtLen = this.props.android ? 20 : 30;
    const emailType = "email";
    const telType = "tel";
    const confirmLabel = this.state.mobileMode ? "Confirm" : "Confirm Password";
    const btnStyle = this.state.emailError ? "disabled-btn-style" : "btn-style";

    return (
      <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
        <div className="ct-wrapper">
          <div className="cttbl-container">
            <div className="cttbl-item-left">
              Email<font color="red">*</font>:
            </div>
            <div className="cttbl-item-left">
              <TextInput
                type={emailType}
                name="email"
                size={txtLen}
                value={this.state.email}
                onChange={this.handleChange}
                onBlur={() => this.checkEmail()}
                validators={["required", "isEmail"]}
                errorMessages={["Email is required", "Invalid email address"]}
              />
            </div>
            <div className="cttbl-item-left">
              Password<font color="red">*</font>:
            </div>
            <div className="cttbl-item-left">
              <TextInput
                type="password"
                name="password"
                size={txtLen}
                value={this.state.password}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["Password is required"]}
              />
            </div>
            <div className="cttbl-item-left">
              {confirmLabel}
              <font color="red">*</font>:
            </div>
            <div className="cttbl-item-left">
              <TextInput
                type="password"
                name="confirmPassword"
                size={txtLen}
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["Confirm Password is required"]}
              />
            </div>
            {this.showExpandShrink()}
            {(!this.state.hasAll || this.state.expanded) && (
              <React.Fragment>
                <div className="cttbl-item-left">First Name:</div>
                <div className="cttbl-item-left">
                  <input
                    type="text"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="cttbl-item-left">Last Name:</div>
                <div className="cttbl-item-left">
                  <input
                    type="text"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="cttbl-item-left">Phone:</div>
                <div className="cttbl-item-left">
                  <TextInput
                    type={telType}
                    name="phone"
                    size="15"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    validators={[
                      "matchRegexp:^\\(?([0-9]{3})\\)?[-..]?([0-9]{3})[-..]?([0-9]{4})$",
                    ]}
                    errorMessages={["Invalid phone number"]}
                  />
                </div>
              </React.Fragment>
            )}
            {(!this.state.hasAll || this.state.expanded) && this.addressForm()}
          </div>
          <div className="cttbl-item-left">
            <button
              className={btnStyle}
              type="submit"
              disabled={this.state.emailError}
            >
              Create Account
            </button>
          </div>
        </div>
      </ValidatorForm>
    );
  }

  render() {
    if (this.state.showSignIn) {
      return (
        <div>
          We found you have already registered, you can sign in using your email
          to see your orders and layaways online.
          <p />
        </div>
      );
    } else {
      return (
        <div>
          <div className="contact-title">
            <b>
              <font size="4">Create an Account</font>
            </b>
          </div>
          {this.showErrMessage()}
          {this.theForm()}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    android: state.cache.android,
    userInfo: state.user.userInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (userId) => {
      dispatch(setUserId(userId));
    },
    setUserInfo: (info) => {
      dispatch(setUserInfo(info));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateAccount));
